import {useEffect, useState} from "react";
import {useAccounts} from "../accounts/context";
import {
    approveCoinsRequestAPI,
    getActiveCoinRequests,
    holdCoinRequestAPI,
    rejectCoinRequestAPI
} from "../../api/rewards";
import {ICoinRequestType, isValidEmail} from "../../utils";
import {CommunityTableContainer, TableHeaders} from "../communities/engaging";
import toast from "react-hot-toast";
import {Modal} from "flowbite-react";

const ActiveCoinRequests = () => {

    const [activeCoinRequestList, setActiveCoinRequestList] = useState<Array<ICoinRequestType>>([]);
    const [isBtnDisabled, setIsBtnDisabled] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [rejectRequestReason, setRejectRequestReason] = useState("");
    const [currentRequestId, setCurrentRequestId] = useState(-1);

    const {
        state: {curEnv, user}
    } = useAccounts();

    useEffect(() => {
        const getActiveCoinRequestList = () => {
            getActiveCoinRequests(curEnv)
                .then((results: any) => {
                    if (results !== undefined && results.length > 0) {
                        setActiveCoinRequestList([]);
                        results.forEach((item: any) => {
                            const requestItem = {} as ICoinRequestType;
                            requestItem.approvalStatus = item["approvalstatus"];
                            requestItem.campaignDate = item["campaigndate"];
                            requestItem.campaignDescription = item["campaigndescription"];
                            requestItem.campaignName = item["campaignname"];
                            requestItem.campaignType = item["campaingtype"];
                            requestItem.message = item["message"];
                            requestItem.requestedBy = item["requestedby"];
                            requestItem.requestedOn = item["requestedon"];
                            requestItem.requestId = item["requestid"];
                            requestItem.requiredCoins = item["requiredcoins"];

                            setActiveCoinRequestList(oldData => [...oldData, requestItem]);
                        });
                    }
                });
        }
        getActiveCoinRequestList();
    }, [curEnv]);

    const takeAction = (e: any, actionType: string, requestId: number) => {
        e.preventDefault();
        setIsBtnDisabled(true);
        switch (actionType) {
            case "APPROVE":
                if (requestId !== undefined && requestId > 0 && user?.email !== undefined && isValidEmail(user?.email)) {
                    approveCoinsRequestAPI(1, requestId, user?.email)
                        .then(data => {
                            toast.success("The coin request has been approved.")
                        }).catch(err => {
                            toast.error("Error while approving the coin request.");
                            console.log(err);
                        }).finally(() => {
                           setIsBtnDisabled(false);
                        });
                }
                return;
            case "REJECT":
                if (requestId !== undefined && requestId > 0) {
//                    console.log("Request Id: ", requestId);
//                    console.log("Current Request Id: ", currentRequestId);
//                    console.log("Reason: ", rejectRequestReason);
//                    setShowModal(false);
                    rejectCoinRequestAPI(1, requestId, rejectRequestReason)
                        .then(data => {
                            toast.success("The coin request has been rejected.")
                        }).catch(err => {
                            toast.error("Error while rejecting the coin request");
                        }).finally(() => {
                            setIsBtnDisabled(false);
                            setShowModal(false);
                            setCurrentRequestId(-1);
                            setRejectRequestReason("");
                        });
                }
                return;
            case "HOLD":
                if (requestId !== undefined && requestId > 0) {
                    holdCoinRequestAPI(1, requestId)
                        .then(data => {
                            toast.success("Request put on hold");
                        }).catch(err => {
                            toast.error("Error while putting the request on hold");
                        }).finally(() => {
                            setIsBtnDisabled(false);
                        });
                }
                return;
            default:
                setIsBtnDisabled(false);
                return;
        }
    }

    const displayModal = (e: any, actionType: string, requestId: number) => {
        setShowModal(true);
        setCurrentRequestId(requestId);
    }

    const hideModal = () => {
        setShowModal(false);
        setRejectRequestReason("");
        setCurrentRequestId(-1);
    }

    const rejectRequest = (e: any, requestId: number) => {
        e.preventDefault();
        takeAction(e, "REJECT", requestId);
    }

    const fillTable = () => {
        return activeCoinRequestList.map((item, index) => {
            return <tbody key={index}>
                <tr className="border-b">
                    <td className="px-6 py-2 text-sm font-light text-gray-900">
                        {item.campaignName}
                    </td>
                    <td className="px-6 py-2 text-sm font-light text-gray-900">
                        {item.campaignDescription}
                    </td>
                    <td className="px-6 py-2 text-sm font-light text-gray-900">
                        {item.requestedBy}
                    </td>
                    <td className="px-6 py-2 text-sm font-light text-gray-900">
                        {item.requiredCoins}
                    </td>
                    <td className="px-6 py-2 text-sm font-light text-gray-900">
                        {item.message}
                    </td>
                    <td className="px-6 py-2 text-sm font-light text-gray-900">
                        {item.approvalStatus}
                    </td>
                    <td className="px-6 py-2 text-sm font-light text-gray-900">
                        {item.requestedOn.split('T')[0]}
                    </td>
                    <td className="px-6 py-2 text-sm font-light text-gray-900">
                        <tr>
                            <td>
                                <button
                                    className="px-6 py-2.5 bg-green-500 text-white font-medium
                                    text-xs leading-tight uppercase rounded-full shadow-md
                                    hover:bg-green-600 hover:shadow-lg focus:bg-green-600 focus:shadow-lg
                                    focus:outline-none focus:ring-0
                                    active:bg-green-700 active:shadow-lg transition duration-150 ease-in-out"
                                    onClick={e => takeAction(e, "APPROVE", item.requestId)}
                                    disabled={isBtnDisabled}
                                >
                                    Approve
                                </button>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <button
                                    className="px-6 py-2.5 bg-red-600 text-white font-medium
                                    text-xs leading-tight uppercase rounded-full shadow-md
                                    hover:bg-red-700 hover:shadow-lg focus:bg-red-700 focus:shadow-lg
                                    focus:outline-none focus:ring-0
                                    active:bg-red-800 active:shadow-lg transition duration-150 ease-in-out"
                                    onClick={e => displayModal(e, "REJECT", item.requestId)}
                                    disabled={isBtnDisabled}
                                >
                                    Reject
                                </button>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <button
                                    className="px-6 py-2.5 bg-yellow-400 text-white font-medium
                                    text-xs leading-tight uppercase rounded-full shadow-md
                                    hover:bg-yellow-600 hover:shadow-lg focus:bg-yellow-600 focus:shadow-lg
                                    focus:outline-none focus:ring-0
                                    active:bg-yellow-700 active:shadow-lg transition duration-150 ease-in-out"
                                    onClick={e => takeAction(e, "HOLD", item.requestId)}
                                    disabled={isBtnDisabled}
                                >
                                    Hold
                                </button>
                            </td>
                        </tr>
                    </td>
                </tr>
            </tbody>
        });
    }
    return (
            activeCoinRequestList.length > 0 ?
                <div>
                    <h4 className="font-bold text-center">Active Coin Request</h4>
                    <CommunityTableContainer>
                        <TableHeaders cols={[
                            "Campaign Name",
                            "Campaign Description",
                            "Requested By",
                            "Required Coins",
                            "Message",
                            "Approval Status",
                            "Requested On",
                            "Actions"
                        ]} />
                        {
                            fillTable()
                        }
                    </CommunityTableContainer>
                    <Modal
                        show={showModal}
                        size="md"
                        popup={true}
                        onClose={() => hideModal()}
                        >
                        <Modal.Header>
                            Reason to reject the coin request
                        </Modal.Header>
                        <Modal.Body>
                            <textarea
                                className="w-full"
                                rows={4}
                                placeholder="Write a reason"
                                value={rejectRequestReason}
                                onChange={e => setRejectRequestReason(e.target.value)}
                                ></textarea>
                            <div className="flex justify-between items-center py-2 px-3 border-t dark:border-gray-600">
                                <button
                                    className="inline-flex items-center py-2.5 px-4 text-xs font-medium text-center text-white bg-blue-700 rounded-lg focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-900 hover:bg-blue-800"
                                    onClick={e => rejectRequest(e, currentRequestId)}
                                    >
                                    Reject Request
                                </button>
                                <button
                                    className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                                    onClick={() => hideModal()}
                                    >
                                    Cancel
                                </button>
                            </div>
                        </Modal.Body>
                    </Modal>
                </div>
            : <h4 className="text-2xl font-extrabold dark:text-white">No Active Coin Request</h4>
    );
}

export default ActiveCoinRequests;