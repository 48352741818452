
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getCoinsEarnedByCampaignIdAPI, getConversionCountByCampaignIdAPI } from "../../api/rewards";
import { useAccounts } from "../../components/accounts/context";
import CampaignLeaderBoard from "../../components/rewards/campaignLeaderBoard";
import ConversionList from "../../components/rewards/conversionList";
import { EqualWidthContainer, PageTitleWithoutContent } from "../dashboard/community/all";
import { PageContainer } from "../dashboard/feedback";
import ErrorBoundary from "../ErrorBoundary";

const CampaignDetails = () => {
    const {id} = useParams();
    const [conversionCount, setConversionCount] = useState(0);
    const [coinsEarned, setcoinsEarned] = useState(0);

    const {
        state: { curEnv }
    } = useAccounts();

    useEffect(() => {
        const getConversionCount = async () => {
            const result = await getConversionCountByCampaignIdAPI(curEnv, Number(id));
            const coinsEarnedResult = await getCoinsEarnedByCampaignIdAPI(curEnv, Number(id));
            setcoinsEarned(coinsEarnedResult[0]["coins_earned"]);
            setConversionCount(result[0]["total_conversions"]);
            console.log("conversion count: ", conversionCount);
        }
        getConversionCount();
    }, [curEnv]);

    return (
        <ErrorBoundary>
            <div className="flex flex-col p-3">
                <PageTitleWithoutContent title="Campaign Details" />
                <div className="flex mr-3">
                    <div className="p-5 text-center rounded-lg bg-rose">
                        <p className="text-2xl font-extrabold">{conversionCount}</p>
                        <p>Total Conversions</p>
                    </div>&nbsp;&nbsp;
                    <div className="p-5 text-center rounded-lg bg-rose">
                        <p className="text-2xl font-extrabold">{coinsEarned}</p>
                        <p>Total Coins Earned</p>
                    </div>
                </div>                
            </div>
            <PageContainer>
                <EqualWidthContainer>
                    <ConversionList campaignId={Number(id)} />
                </EqualWidthContainer>
                <EqualWidthContainer>
                    <CampaignLeaderBoard campaignId={Number(id)}/>
                </EqualWidthContainer>
            </PageContainer>
        </ErrorBoundary>
    );    
}

export default CampaignDetails;