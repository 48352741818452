import { ReactNode, useEffect, useMemo, useState } from "react";

import { getAllChartsDataAPI, getCommCountsAPI, getCommunityLeftUsers } from "../../../api/community";
import CommunityCards from "../../../components/dashboard/panels/communities/components/cards";
import CommunityCharts from "../../../components/dashboard/panels/communities/components/charts";
import Loading from "../../../components/Loading";
import { AddCommPageStats } from "../../../redux/communities/actions";
import { useAppState } from "../../../redux/context";
import { normalizeData, getLabels, jsonToCsv, downloadCsv } from "../../../utils";
import ErrorBoundary from "../../ErrorBoundary";
import { PageContainer } from "../feedback";
import GrowthChart from "../../../components/communities/growthChart";
import GrowthCard from "../../../components/communities/growthCards";
import EngagingCommunties, { CommunityTableContainer, TableHeaders } from "../../../components/communities/engaging";
import InactiveCommunities from "../../../components/communities/inactive";
import { useAccounts } from "../../../components/accounts/context";

const Community = () => {
  const [loading, setLoading] = useState(true);
  const { dispatch, state } = useAppState();
  const [comGrowths, setComGrowths] = useState<Array<Array<number>>>([]);
  const [adminGrowths, setAdminGrowths] = useState<Array<Array<number>>>([]);
  const [memGrowths, setMemGrowths] = useState<Array<Array<number>>>([]);
  const [commStart, setCommStart] = useState(0);
  const [commEnd, setCommEnd] = useState(1);
  const [adminStart, setAdminStart] = useState(0);
  const [adminEnd, setAdminEnd] = useState(1);
  const [chart1type, setChart1type] = useState(0);
  const [chart2type, setChart2type] = useState(1);
  const [highestCommunityPosts, setHighestCommunityPosts] = useState<Array<any>>([]);
  const [communityTypeData, setCommunityTypeData] = useState({"public_communities": 0, "private_communities":0, "broadcast_communities":0});
  const [highestMemberGrowth, setHighestMemberGrowth] = useState<Array<any>>([]);
  const {
    state: { curEnv }
  } = useAccounts();

  async function downloadCommunityLeftData() {
    const data = await getCommunityLeftUsers(curEnv);
    if (data.length > 0) {
      const csvData = jsonToCsv(data);
      downloadCsv(csvData, "community_left_data");
    } else {
      console.log("no data to download");
    }
  }

  useEffect(() => {
    const onLoad = async () => {
      try {
        const data = await getCommCountsAPI(curEnv);        
        const publicCount = data[4][0];
        const privateCount = data[5][0];
        const broadcastCount = data[6][0];
        setHighestCommunityPosts([...data[7]]);
        setHighestMemberGrowth([...data[8]]);
        // highestCommunityPosts.push(...data[7]);
        setCommunityTypeData({
          public_communities: Number(publicCount.public_communities),
          private_communities: Number(privateCount.private_communities),
          broadcast_communities: Number(broadcastCount.broadcast_communities)
        });
        AddCommPageStats(dispatch, data);
        const charts = await getAllChartsDataAPI(curEnv);
        // console.log("charts data: ", charts);
        setCommStart(charts[0][charts[0].length - 1]?.count ?? 0);
        setCommEnd(charts[0][0].count ?? 0);
        setAdminStart(charts[5][charts[5].length - 1]?.count ?? 0);
        setAdminEnd(charts[5][0].count ?? 0);
        setComGrowths([
          normalizeData(charts[0]), 
          normalizeData(charts[1]), 
          normalizeData(charts[2]), 
          normalizeData(charts[3]), 
          normalizeData(charts[4]),
          normalizeData(charts[5]),
          normalizeData(charts[6])
        ]);
        setAdminGrowths([
          normalizeData(charts[7]), 
          normalizeData(charts[8]), 
          normalizeData(charts[9]), 
          normalizeData(charts[10]), 
          normalizeData(charts[11]),
          normalizeData(charts[12]),
          normalizeData(charts[13])
        ]);
        setMemGrowths([
          normalizeData(charts[14]), 
          normalizeData(charts[15]), 
          normalizeData(charts[16]), 
          normalizeData(charts[17]), 
          normalizeData(charts[18]),
          normalizeData(charts[19]),
          normalizeData(charts[20]),
        ]);        
      } catch (error) {
      } finally {
        setLoading(false);
      }
    }
    onLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [curEnv]);

  const data1 = useMemo(() => {
    return {
      labels: getLabels(chart1type),
      datasets: [{
        label: 'Communities Growth',
        data: comGrowths?.[chart1type] ?? [],
        borderColor: 'rgb(104, 3, 252, 0.8)',
        backgroundColor: 'rgba(104, 3, 252, 0.1)',
        tension: 0.1,
      }, {
        label: 'Community Admin Growth',
        data: adminGrowths?.[chart1type] ?? [],
        borderColor: 'rgb(80, 255, 245, 0.8)',
        backgroundColor: 'rgba(80, 255, 245, 0.1)',
        tension: 0.1
      }]
    }
  }, [adminGrowths, chart1type, comGrowths]);

  const fillHighestCommunityPostsTable = () => {    
    return highestCommunityPosts.map((data: any, index: number) => {
      return <tbody key={index}>
        <tr className="border-b">
          <td className="px-6 py-2 text-sm font-light text-gray-900 whitespace-nowrap">
            {data.communityname}
          </td>
          <td className="px-6 py-2 text-sm font-light text-gray-900 whitespace-nowrap">
            {data.firstname + " " + data.lastname}
          </td>
          <td className="px-6 py-2 text-sm font-light text-gray-900 whitespace-nowrap">
            {data.post_count}
          </td>
          <td className="px-6 py-2 text-sm font-light text-gray-900 whitespace-nowrap">
            {data.community_members}
          </td>
        </tr>
      </tbody>
    })
  }

  const fillHighestCommunityUserGrowthTable = () => {
    return highestMemberGrowth.map((data: any, index: number) => {
      return <tbody key={index}>
        <tr className="border-b">
          <td className="px-6 py-2 text-sm font-light text-gray-900 whitespace-nowrap">
            {data.communityname}
          </td>
          <td className="px-6 py-2 text-sm font-light text-gray-900 whitespace-nowrap">
            {data.member_count}
          </td>
        </tr>
      </tbody>
    });
  }

  const data2 = useMemo(() => {  
    return {
      labels: getLabels(chart2type),
      datasets: [{
        label: 'Members Growth',
        data: memGrowths?.[chart2type] ?? [],
        borderColor: 'rgb(252, 108, 3, 0.8)',
        backgroundColor: 'rgba(252, 108, 3, 0.1)',
        tension: 0.1
      }]
    }
  }, [chart2type, memGrowths]);
  return (
    <ErrorBoundary>
      <PageContainer>
        <EqualWidthContainer>

          {loading ? <Loading /> : <CommunityCards data={state.Comms} />}
          {loading ? <Loading /> : <GrowthChart title="Community vs Admins Growth" data={data1} chartType={chart1type} setChart={setChart1type} />}
          <button 
            className="rounded-full bg-primary-500 hover:bg-primary-700 text-white"
            onClick={downloadCommunityLeftData}
          >
              Download Community Left Data
          </button>
          {loading ? <Loading /> : <div className="flex">
            <GrowthCard title="Community" start={commStart} end={commEnd} />
            <GrowthCard title="Creators" start={adminStart} end={adminEnd} />
          </div>}
          <EngagingCommunties />
          <br />
          <div className="p-2 border-2 rounded">
          <h4 className="font-bold text-center">Types of Communities</h4>
            <CommunityTableContainer>
              <TableHeaders cols={["Public Communities", "Private Communities", "Broadcast Communities"]} />
              <tbody>
                <tr className="border-b">
                  <td className="px-6 py-2 text-sm font-light text-gray-900 whitespace-nowrap">
                    {communityTypeData.public_communities}
                  </td>
                  <td className="px-6 py-2 text-sm font-light text-gray-900 whitespace-nowrap">
                    {communityTypeData.private_communities}
                  </td>
                  <td className="px-6 py-2 text-sm font-light text-gray-900 whitespace-nowrap">
                    {communityTypeData.broadcast_communities}
                  </td>
                </tr>
              </tbody>
            </CommunityTableContainer>
          </div>
          <br />
          {
            loading
            ? <Loading />
            : <>
              <div className="p-2 border-2 rounded">
                <h4 className="font-bold text-center">Top Communities With Highest User Growth (Weekly)</h4>
                <CommunityTableContainer>
                  <TableHeaders cols={[
                    "Community Name", 
                    "Member Growth"
                    ]} />
                    {
                      fillHighestCommunityUserGrowthTable()
                    }
                </CommunityTableContainer>
              </div>
            </>
          }
        </EqualWidthContainer>
        <EqualWidthContainer>
          {loading ? <Loading /> : <CommunityCharts />}
          {loading ? <Loading /> : <GrowthChart title="Members Growth" data={data2} chartType={chart2type} setChart={setChart2type} />}
          <InactiveCommunities />
          <br />
          {
            loading
            ? <Loading />
            : 
            <>
              <div className="p-2 border-2 rounded">
              <h4 className="font-bold text-center">Highest Comunity Posts (Weekly)</h4>
              <CommunityTableContainer>
                <TableHeaders cols={[
                  "Commmunity Name", 
                  "Admin", 
                  "Post Count",
                  "Members"
                  ]} />
                  {
                    fillHighestCommunityPostsTable()
                  }
              </CommunityTableContainer>
              </div>
            </>
          }
        </EqualWidthContainer>
      </PageContainer>
    </ErrorBoundary>
  );
}

export default Community;

export const EqualWidthContainer = ({ children }: { children: ReactNode }) => (
  <div className="flex flex-col w-full p-3">
    {children}
  </div>
)

export const PageTitleWithoutContent = ({ title }: { title: string }) => (
  <div className="mx-3">
    <h1 className="py-2 text-2xl font-semibold">{title}</h1>
  </div>
)

