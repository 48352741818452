import clsx from 'clsx';


type IFormInputProps = {
  label?: string;
  placeholder?: string;
  type?: string;
  inputMode?:
  | 'text'
  | 'none'
  | 'tel'
  | 'url'
  | 'email'
  | 'numeric'
  | 'decimal'
  | 'search'
  | undefined;
  // value: string | number;
  name: string;
  error?: string;
  // touched: boolean;
  // key: string;
  readOnly?: boolean;
  // onChange: ChangeEventHandler<HTMLInputElement>;
  min?: string | number;
  max?: string | number;
  value?: string | number;
  onChange?: React.ChangeEventHandler<HTMLInputElement>
};

export const TextInput = ({
  label = 'Label',
  placeholder = 'Enter something...',
  type = 'text',
  inputMode = 'text',
  name,
  error,
  min = undefined,
  max = undefined,
  readOnly = false,
  value,
  onChange
}: IFormInputProps) => (
  <>
    <label className="block">
      <div className="text-sm text-default">{label}</div>
      <input
        type={type}
        inputMode={inputMode}
        className="block w-full mt-1 text-sm border-0 rounded-lg drop-shadow-md"
        placeholder={placeholder}
        min={min}
        max={max}
        readOnly={readOnly}
        value={value}
        onChange={onChange}
      />

      <span
        className={clsx('text-xs text-red-500', {
          hidden: !error,
        })}
      >
        {error}
      </span>
    </label>
  </>
);
