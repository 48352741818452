import { Listbox } from '@headlessui/react'
import { FeedBackStatus, StatusArray, FeedbackStatusItemElement } from '../../feedbackCard';


const StatusSelect = ({ status, onChange }: { status: FeedBackStatus; onChange: (x: number) => void; }) => {

  const onStatusChange = (val: number) => {
    onChange(val);
  }
  return (
    <div className="w-36">
      <Listbox value={status} onChange={val => onStatusChange(val)}>
        <div className="relative">
          <Listbox.Button className="relative w-full px-5 py-2 text-left transition duration-150 ease-in-out bg-white border border-gray-300 rounded-md cursor-default focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5">
            <div className="flex space-x-2">
              <FeedbackStatusItemElement item={StatusArray[status]} />
            </div>
          </Listbox.Button>
          <Listbox.Options className="absolute w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {StatusArray.slice(0, 4).map((statusItem, index) => (
              <Listbox.Option
                key={statusItem.title}
                value={index}
                className={({ active }) =>
                  `cursor-default select-none ${active ? 'text-amber-900 bg-amber-100' : 'text-gray-900'
                  }`}
              >
                {({ selected }) => (<div className={`p-2 ${selected ? 'bg-amber-200' : ''}`}>
                  <FeedbackStatusItemElement item={statusItem} />
                </div>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </div>
      </Listbox>
    </div>
  );
}



export default StatusSelect;
