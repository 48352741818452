import { ReactNode } from 'react';
import { Link, useMatch, useNavigate } from 'react-router-dom';
import { ReactComponent as ReportIcon } from '../../assets/report.svg';
import FeedbackFilter from '../../components/feedback/feedbackFilter';
import FeedbackList from '../../components/feedback/feedbackList';
import FeedbackStatics from '../../components/feedback/statistics';
import FeedbackView from '../../components/feedback/feedbackView';
import { FeedbackContextProvider, useFeedbackContext } from '../../components/feedback/context';
import ErrorBoundary from '../ErrorBoundary';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faBackspace, faBackward } from '@fortawesome/free-solid-svg-icons';

const Feedback = () => {
  const match = useMatch({
    path: '/dashboard/feedbacks/:item',
    end: true,
    caseSensitive: true
  });

  return (
    <ErrorBoundary>
      <FeedbackContextProvider>
        <PageContainer>
          <PageLeftContainer>
            <PageTitle title="Feedback">
              <ReportTitleIcon />
            </PageTitle>
            <FeedbackFilter />
            <FeedbackList />
          </PageLeftContainer>

          <PageRightContainer>
            {!match?.params.item ? <FeedbackStatics /> :
              match?.params.item === 'report' ?
                <FeedbackStatics /> :
                <FeedbackView />
            }
          </PageRightContainer>
        </PageContainer>
      </FeedbackContextProvider>
    </ErrorBoundary>
  );
}

export default Feedback;

export const PageTitle = ({ title, children }: { title: string; children?: ReactNode }) => {
  const { state, actions } = useFeedbackContext();
  const navigate = useNavigate();

  const moveBack = (e: any) => {
    e.preventDefault();
    actions.setSelected && actions.setSelected(-1);
    navigate('/dashboard/feedbacks');
  }
  return (
    <div className="flex items-center justify-between mx-3">      
      <h1 className="py-2 text-2xl font-semibold">
        <button className="mr-2" onClick={e => moveBack(e)}>
          <FontAwesomeIcon icon={faArrowLeft} />
        </button>
        {title}
      </h1>
      {children}
    </div>
  );
}

export const ReportTitleIcon = () => (
  <Link to="report" type="button" className="flex items-center px-2 py-2 tracking-wide hover:border-primary-500 hover:border-b-2 focus:outline-none ">
    <ReportIcon className='mr-3' />
    Report
  </Link>
)
export const PageLeftContainer = ({ children }: { children: ReactNode }) => {
  return (
    <div className="flex w-full h-full max-w-md">
      <div className="flex flex-col w-full h-full">
        {children}
      </div>
    </div>
  )
}

export const PageRightContainer = ({ children }: { children: ReactNode }) => {
  return (
    <div className="flex w-full">
      {children}
    </div>
  );
}

export const PageContainer = ({ children }: { children: ReactNode }) => {
  return (
    <div className="flex w-full h-full">
      {children}
    </div>
  );
}
