import { ReactNode } from "react";
import Loading from "../Loading";
import { useFeedbackContext } from "./context";
import FeedbackCard from "./feedbackCard";

const FeedbackList = () => {
  const { state, actions } = useFeedbackContext();
  const pageSize = 10;
  return (
    <FeedContainer>
      {state.loading ? <Loading /> : (state.list.length === 0 ? <div className="font-bold text-center">List Empty</div> : '')}
      {state.list
        .slice(
          (state.currentPage * pageSize) - pageSize, 
          (state.currentPage * pageSize)
        )
        .map((el, index) => <FeedbackCard 
          key={el.fbid} 
          selected={state.selected === ((state.currentPage - 1) * pageSize) + index} 
          data={el} index={index} 
          onSelect={() => actions.setSelected && actions.setSelected(
            ((state.currentPage - 1) * pageSize) + index
          )} />)}
      <div className="pb-12 mt-10"></div>
    </FeedContainer>
  );
}

export default FeedbackList;

export const FeedContainer = ({ children }: { children: ReactNode }) => {
  return (
    <div className="h-0 min-h-0 overflow-y-auto bg-gray-100 shadow-inner grow shrink basis-auto">
      <div className="flex flex-col h-auto pt-5 ">
        {children}
      </div>
    </div>)
}
