import clsx from "clsx";


const GrowthCard = ({ title, start, end }: { title: string; start: number; end: number }) => {
  const diff = end - start;
  if (start === 0) start = 1; // handle divide by zero
  const percent = Math.round(diff * 100 * 10 / start) / 10;
  return (
    <div className="flex flex-col items-center w-full p-2 m-3 border">
      <h1 className="underline underline-offset-8">{title}</h1>
      <div className="flex">
        <div className="flex flex-col items-center p-3">
          <h2 className="text-gray-600">Last 24hrs</h2>
          <h2 className={clsx(["text-xl font-bold", { 'text-green-800': diff >= 0, 'text-red-600': diff < 0 }])}>{`${diff >= 0 ? '+' : '-'} ${Math.abs(diff)}`}</h2>
        </div>
        <div className="flex flex-col items-center p-3">
          <h2 className="text-gray-600">Growth %</h2>
          <h2 className={clsx(["text-xl font-bold", { 'text-green-800': diff >= 0, 'text-red-600': diff < 0 }])}>{`${percent >= 0 ? '▲' : '▼'} ${Math.abs(percent)} %`}</h2>
        </div>
      </div>
    </div>
  )
}
export default GrowthCard;
