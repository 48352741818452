import { ON_LOGOUT } from "../context";
import { ADD_REP_COUNT } from "./actions";

export interface IreportType {
  id?: string
}
const initialState = {};

export function reportReducer(state: IreportType = initialState, action: any) {
  switch (action.type) {
    case ADD_REP_COUNT:
      return { ...state, ...action.payload }
    case ON_LOGOUT:
      return initialState;
    default:
      return state;
  }
}
