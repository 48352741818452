import "../../styles/rewardManagement.css";
import {useState} from "react";
import {refillVaultAPI} from "../../api/rewards";
import {useAccounts} from "../accounts/context";
import {isValidEmail} from "../../utils";
import toast from "react-hot-toast";
const AddCoinsToVault = () => {
    const {
        state: {curEnv, user}
    } = useAccounts();

    const [coinAmount, setCoinAmount] = useState(0);
    const [btnDisabled, setBtnDisabled] = useState(false);

    const handleCoinChange = (e: any, type: string) => {
        e.preventDefault();
        switch (type) {
            case "INC":
                setCoinAmount(coinAmount + 1);
                return;
            case "DEC":
                if (coinAmount === 0 || coinAmount < 0){
                    return;
                }
                setCoinAmount(coinAmount - 1);
                return;
            default:
                if (e.target.value < 0) {
                    setCoinAmount(e.target.value * -1);
                } else {
                    setCoinAmount(e.target.value);
                }
                return;
        }
    }

    const refillVault = (e: any) => {
        e.preventDefault();
        if (coinAmount > 0 && user?.email !== undefined && isValidEmail(user?.email)) {
            setBtnDisabled(true);
            refillVaultAPI(curEnv, user?.email, coinAmount)
                .then(data => {
                    toast.success("Vault refilled with " + coinAmount + " coins");
                    setCoinAmount(0);
                }).catch(err => {
                    toast.error("Error refilling the vault");
                    console.log(err);
                }).finally(() => {
                    setBtnDisabled(false);
                });
        }
    }
    return (
            <div
                className="add-coins-input h-10 w-32"
            >
                <div className="flex flex-row h-10 w-full rounded-lg relative bg-transparent mt-1">
                    <button
                        className=" bg-gray-300 text-gray-600 hover:text-gray-700 hover:bg-gray-400 h-full w-20 rounded-l cursor-pointer outline-none"
                        onClick={e => handleCoinChange(e, "DEC")}
                    >
                        <span className="m-auto text-2xl font-thin">−</span>
                    </button>
                    <input
                        type="number"
                        className="outline-none focus:outline-none text-center w-full bg-gray-300 font-semibold text-md hover:text-black focus:text-black  md:text-basecursor-default flex items-center text-gray-700  outline-none"
                        name="custom-input-number"
                        value={coinAmount}
                        onChange={e => handleCoinChange(e, "")}
                    />
                    <button
                        className="bg-gray-300 text-gray-600 hover:text-gray-700 hover:bg-gray-400 h-full w-20 rounded-r cursor-pointer"
                        onClick={e => handleCoinChange(e, "INC")}
                    >
                        <span className="m-auto text-2xl font-thin">+</span>
                    </button>
                </div>
                <button
                    type="button"
                    className="inline-block add-coins-btn px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded-full shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
                    disabled={btnDisabled}
                    onClick={e => refillVault(e)}
                >
                    Add Coins
                </button>
            </div>
    );
}

export default AddCoinsToVault;