import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { getCampaignLeaderboardAPI } from "../../api/rewards";
import { ICampaignLeaderboardElement } from "../../utils";
import { useAccounts } from "../accounts/context";
import { CommunityTableContainer, TableHeaders } from "../communities/engaging";
import Loading from "../Loading";

const CampaignLeaderBoard = ({campaignId} : {campaignId: number}) => {
    const [loading, setLoading] = useState(false);
    const [leaderBoardList, setLeaderBoardList] = useState<Array<ICampaignLeaderboardElement>>([]);
    const {
        state: { curEnv }
    } = useAccounts();

    useEffect(() => {        
        try {
            setLoading(true);
            const getLeaderboardList = async () => {
                const result = await getCampaignLeaderboardAPI(curEnv, campaignId);
                setLeaderBoardList([]);
                result.forEach((element: any) => {
                    const leaderBoardItem = {} as ICampaignLeaderboardElement;
                    leaderBoardItem.userId = element["referaluserid"];
                    leaderBoardItem.name = element["firstname"] + " " + element["lastname"];
                    leaderBoardItem.converts = element["conversions"];
                    setLeaderBoardList(oldData => [...oldData, leaderBoardItem]);
                });
            }
            getLeaderboardList();
        } catch (err) {
            toast.error("Error loading campaign leaderboard");
        } finally {
            setLoading(false);
        }                
    }, [curEnv]);

    const fillTable = () => {
        return leaderBoardList.map((leaderBoardItem, index) => {
            return <tbody key = {index} >
                <tr className="border-b">
                <td className="px-6 py-2 text-sm font-light text-gray-900 whitespace-nowrap">
                    {leaderBoardItem.name}
                </td>
                <td className="px-6 py-2 text-sm font-light text-gray-900 whitespace-nowrap">
                    {leaderBoardItem.converts}
                </td>
                </tr>
            </tbody>
        });
    }
    

    return (
        <div>
            {
                loading
                ? <Loading />
                :
                <div className="p-2 border-2 rounded">
                    <h4 className="font-bold text-center">Leaderboard</h4>
                    <CommunityTableContainer>
                        <TableHeaders cols={[
                            "User",
                            "Total Converts"
                        ]} />
                        {
                            fillTable()
                        }                    
                    </CommunityTableContainer>
                </div>
            }
        </div>
    );
}

export default CampaignLeaderBoard;