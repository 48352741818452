import { formatTime } from "../../../utils";
import { MsgUser } from "../feedbackView";
import Logo from '../../../assets/logo.svg';
import { IFeedbackCardType } from "../feedbackCard";

export interface IReplytype {
  from?: string;
  to?: string;
  body?: string;
  sent?: number;
}
const RepliesBox = ({ replies, user }: { replies: Array<IReplytype>; user: IFeedbackCardType }) => {
  // if (replies.length === 0) return null;  

  return (
    <div>      
      {replies.map(el => <RenderReply key={el.sent} reply={el} user={user} />)}
    </div>
  );
}

export default RepliesBox;

const RenderReply = ({ reply, user }: { reply: IReplytype; user: IFeedbackCardType }) => {
  if ("from" in reply) {
    return <RenderUserReply reply={reply} user={user} />
  }
  return <RenderAdminReply reply={reply} />
}
const RenderAdminReply = ({ reply }: { reply: IReplytype }) => (
  <div className="m-3">
    <MsgUser avatar={Logo} name="Admin" time={formatTime(reply?.sent ?? Date.now())} />
    <p className="">{reply?.body}</p>
  </div>
);

const RenderUserReply = ({ reply, user }: { reply: IReplytype; user: IFeedbackCardType }) => (
  <div className="m-3">
    <MsgUser avatar={user?.thumbimage ?? Logo} name={user?.firstname + " " + user?.lastname} time={formatTime(reply?.sent ?? new Date())} />
    <p className="">{reply?.body}</p>
  </div>
)
