import { faEye, faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge, Card, Table } from 'flowbite-react'
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { endCampaignAPI, getAllCampaignDetailsAPI } from '../../api/rewards';
import { useAccounts } from '../../components/accounts/context';
import { ICampaignType, isAdminUser } from '../../utils';
import {Auth} from '@aws-amplify/auth';
import {Modal} from "flowbite-react";

// Change the design to the table format
const CampaignList = () => {

    const [campaigns, setCampaigns] = useState<Array<ICampaignType>>([]);
    const [inProgress, setInProgress] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [campaignId, setCampaignId] = useState(0);
    const navigate = useNavigate();

    const {
        state: { curEnv }
    } = useAccounts();
    
    useEffect(() => {
        async function getAllCampaigns() {
            const all_campaigns = await getAllCampaignDetailsAPI(curEnv);
            setCampaigns([]);
            all_campaigns.map((item: any) => {
                const campaign = {} as ICampaignType;
                campaign.campaignId = item.campaignid;
                campaign.campaignName = item.campaignname;
                campaign.campaignDescription = item.campaigndescription;                
                campaign.createdBy = item.creatoremail;
                campaign.campaignType = item.campaingtype;
                campaign.created = item.created.split('T')[0];
                campaign.endDate = item.expirydate.split('T')[0];
                campaign.refreeCoin = item.refreecoin ?? 0;
                campaign.referralCoin = item.refrerralcoin ?? 0;
                campaign.rewardCoin = item.rewardcoins ?? 0;
                campaign.status = item.status;
                setCampaigns(oldData => [...oldData, campaign]);
                return campaign;
            });
            console.log(all_campaigns);
        }
        getAllCampaigns();
    }, [curEnv]);

    useEffect(() => {
        const onLoad = () => {
            isAdminUser()
                .then(status => {
                    if (status === true) {
                        setIsAdmin(true);
                    } else {
                        setIsAdmin(false);
                    }
                }).catch(err => {
                    setIsAdmin(false);
                    console.log(err);
                });            
        }

        onLoad();
    });

    const openEndCampaignModal = (e: any, campaignId: number) => {
        e.preventDefault();
        // navigate("/dashboard/createcampaign");
        // endCampaignAPI(1, campaignId).then((data: any) => {
        //     toast.success("Campaign has been ended");
        // }).catch(err => {
        //     toast.error("Error occurred while ending the campaign");
        //     console.log(err);
        // }).finally(() => {
        //     setInProgress(false);
        // });
        if (campaignId !== 0 && campaignId > 0) {
            setCampaignId(campaignId);
            setShowModal(true);            
        }        
    }

    const endCampaign = () => {
        if (campaignId !== 0 && campaignId > 0) {
            setCampaignId(0);
            setShowModal(false);
            endCampaignAPI(1, campaignId).then((data: any) => {
                toast.success("Campaign has been ended");
            }).catch(err => {
                toast.error("Error occurred while ending the campaign");
                console.log(err);
                console.log("campaign ended");
            }).finally(() => {
                setInProgress(false);
            });            
        } else {
            console.log("Invalid Campaign Id");
            toast.error("Invalid campaign Id");
            setShowModal(false);
        }        
    }

    return (
        <Table hoverable={true}>
            <Table.Head>
                <Table.HeadCell>Campaign Name</Table.HeadCell>
                <Table.HeadCell>Description</Table.HeadCell>
                <Table.HeadCell>Created By</Table.HeadCell>
                <Table.HeadCell>Status</Table.HeadCell>
                <Table.HeadCell>Rewards</Table.HeadCell>
                <Table.HeadCell>Invitee Coin</Table.HeadCell>
                <Table.HeadCell>Joinee Coin</Table.HeadCell>
                <Table.HeadCell>Created</Table.HeadCell>
                <Table.HeadCell>End Date</Table.HeadCell>
                <Table.HeadCell>Actions</Table.HeadCell>
                <Table.HeadCell>
                    <span className="sr-only">
                        Edit
                    </span>
                </Table.HeadCell>
            </Table.Head>

            <Table.Body className="divide-y">
                {
                    campaigns.sort((a, b) => {
                        return b.status - a.status;
                    }).map(element => {
                        return (
                            <Table.Row key={element.campaignId} className="bg-white dark:border-gray-700 dark:bg-gray-800">
                                <Table.Cell className="font-small text-gray-900 dark:text-white">
                                    {element.campaignName}
                                </Table.Cell>
                                <Table.Cell className="font-small text-gray-900 dark:text-white">
                                    {element.campaignDescription}
                                </Table.Cell>
                                <Table.Cell className="font-small text-gray-900 dark:text-white">
                                    {element.createdBy}
                                </Table.Cell>
                                <Table.Cell className="font-small text-gray-900 dark:text-white">
                                    {element.status === 0 ? <Badge color="gray">Inactive</Badge> : <Badge color="success">Active</Badge>}
                                </Table.Cell>                                
                                <Table.Cell className="font-small text-gray-900 dark:text-white">
                                    {element.rewardCoin}
                                </Table.Cell>
                                <Table.Cell className="font-small text-gray-900 dark:text-white">
                                    {element.referralCoin}
                                </Table.Cell>
                                <Table.Cell className="font-small text-gray-900 dark:text-white">
                                    {element.refreeCoin}
                                </Table.Cell>
                                <Table.Cell className="whitespace-nowrap font-small text-gray-900 dark:text-white">
                                    {element.created}
                                </Table.Cell>
                                <Table.Cell className="whitespace-nowrap font-small text-gray-900 dark:text-white">
                                    {element.endDate}
                                </Table.Cell>
                                <Table.Cell className="font-small text-gray-900 dark:text-white">                                    
                                    {
                                        isAdmin ?
                                        <>
                                            <FontAwesomeIcon 
                                                icon={faPenToSquare}
                                                onClick={() => navigate(`/dashboard/editcampaign/${element.campaignId}`)}                                        
                                            />&nbsp;&nbsp;&nbsp;
                                        </> : ''                   
                                    }                                    
                                    <FontAwesomeIcon 
                                        icon={faEye}
                                        onClick={() => navigate(`/dashboard/campaigndetails/${element.campaignId}`)}
                                    />
                                </Table.Cell>
                                {
                                    isAdmin ?
                                    <Table.Cell>
                                        {
                                            element.status === 1
                                            ? 
                                            <a
                                                href="#"
                                                onClick={e => openEndCampaignModal(e, element.campaignId)}
                                                // onClick={() => setShowModal(true)}
                                                className="font-small text-blue-600 hover:underline dark:text-blue-500"
                                                aria-disabled={inProgress}
                                            >
                                            End Campaign
                                            </a>
                                            : ""
                                        }                                                                                                       
                                    </Table.Cell> : ''
                                }                                                                
                            </Table.Row>
                        )
                    })
                }
            </Table.Body>
            <Modal
                show={showModal}
                size="md"
                popup={true}
                onClose={() => setShowModal(false)}
            >
                <Modal.Header
                style={{backgroundColor: "#f3f3f3"}}                 
                >
                    Confirm End Campaign
                </Modal.Header>
                <Modal.Body>
                    <div className='flex flex-col mt-2'>
                        <h2>Are you sure you want to end this campaign?</h2>
                        <div className='flex flex-row mt-8 justify-end'>
                            <button 
                                className="mr-2 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                                onClick={() => endCampaign()}
                                >
                                End Campaign
                            </button>
                            <button 
                                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                                onClick={() => setShowModal(false)}
                                >
                                Cancel
                            </button>
                        </div>
                    </div>                    
                </Modal.Body>
            </Modal>
        </Table>        
    );
}

export default CampaignList;