export interface ICardInfoType {
  title: string | number; subtitle: string
}
const StatNumber = ({ stat }: { stat: number | string }) => {
  return (
    <p className="text-2xl font-extrabold">{stat}</p>
  );
}

export const OneCard = ({ title, subtitle }: ICardInfoType) => {
  return (
    <div className="flex mr-3">
      <div className="p-5 text-center rounded-lg bg-rose">
        <StatNumber stat={title} />
        <p>{subtitle}</p>
      </div>
    </div>
  );
}

export const JoinedCards = ({ items }: { items: Array<ICardInfoType> }) => (
  <div className="grid grid-cols-3 gap-0 rounded-lg bg-rose">
    {items.map((el, index) => (
      <div className="flex" key={index}>
        {index ? <div className="my-3 border-r-2"></div> : ''}
        <div className="w-full p-5 m-3 text-center">
          <StatNumber stat={el.title} />
          <p>{el.subtitle}</p>
        </div>
      </div>
    ))}
  </div>
)
