import { ReactNode, useEffect, useState } from "react";
import { getCommunitiesListAPI } from "../../api/community";
import { useAccounts } from "../accounts/context";
import Loading from "../Loading";

const COLUMNS = ['Community Name', 'Total Members', 'Engagement Rate']
const EngagingCommunties = () => {
  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const {
    state: { curEnv }
  } = useAccounts();

  useEffect(() => {
    const onLoad = async () => {
      try {
        const data = await getCommunitiesListAPI(5, 0, true, curEnv);
        setTableData(data);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    }
    onLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [curEnv]);

  return (
    <div className="p-2 border-2 rounded">
      <h4 className="font-bold text-center">Engaging Communties</h4>
      {loading ? <Loading /> : <CommunityTableContainer>
        <TableHeaders cols={COLUMNS} />
        <TableRows rows={tableData} />
      </CommunityTableContainer>}
    </div>
  );
}

export default EngagingCommunties;

export const CommunityTableContainer = ({ children }: { children: ReactNode }) => {
  return (
    <div className="flex flex-col">
      <div className="overflow-x-auto sm:-mx-3 lg:-mx-5">
        <div className="inline-block min-w-full py-2 sm:px-4 lg:px-5">
          <div className="overflow-hidden">
            <table className="min-w-full">
              {children}
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export const TableHeaders = ({ cols }: { cols: Array<string> }) => (
  <thead className="sticky bg-gray-100 border-b">
    <tr>
      {cols.map(el => <th key={el} scope="col" className="px-4 py-4 text-sm font-medium text-left text-gray-900">{el}</th>)}
    </tr>
  </thead>
)

const TableRows = ({ rows }: { rows: any }) => (
  <tbody>
    {rows.map((el: any) => (<TableRow key={el.communityid} row={el} />))}
  </tbody>
)

const TableRow = ({ row }: { row: any }) => (
  <tr className="border-b">
    <td className="px-6 py-2 text-sm font-light text-gray-900 whitespace-nowrap">
      {row.communityname}
    </td>
    <td className="px-6 py-2 text-sm font-light text-gray-900 whitespace-nowrap">
      {row.members}
    </td>
    <td className="px-6 py-2 text-sm font-light text-gray-900 whitespace-nowrap">
      {row.result}
    </td>
  </tr>
)
