import {PageContainer} from "../../pages/dashboard/feedback";
import {useEffect, useState} from "react";
import {useAccounts} from "../accounts/context";
import {getReportListAPI, getReportListNewAPI} from "../../api/report";
import ReportItem from "./reportItem";
import {IReportType} from "../../utils";
import {useNavigate} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFilter} from "@fortawesome/free-solid-svg-icons";
import { TextInput } from "flowbite-react";
import { IreportType } from "../../redux/report/reducer";
import { act } from "react-dom/test-utils";

const PostReports = () => {

    const [reportList, setReportList] = useState<Array<IReportType>>([]);
    const [reportFilter, setReportFilter] = useState(0);
    const [reportTypeFilter, setReportTypeFilter] = useState(0);
    const [showReportFilterDropdown, setShowReportFilterDropdown] = useState(false);
    const [startDateFilter, setStartDateFilter] = useState("");
    const [endDateFilter, setEndDateFilter] = useState("");
    // 0: All
    // 1: Spam
    // Misleading
    // Abusive/Hate Speech
    // Copyright
    // Obscene
    // Others

    const {
        state: {curEnv}
    } = useAccounts();

    useEffect(() => {
        try {
            const onLoad = async () => {
                //            const reportedPosts = await getReportListAPI(curEnv, 0, "", "", false);
                //            console.log(reportedPosts);
                const reportList = await getReportListNewAPI(curEnv);
//                console.log(reportList);
                const homeFeedReports = getReportArr(reportList[0], "HMF");
                const homeFeedCommentReports = getReportArr(reportList[1], "HMC");
                const communityFeedReports = getReportArr(reportList[2], "CMF");
//                console.log("Home Feed Reports: ", homeFeedReports);
//                console.log("Home Feed Comment Reports: ", homeFeedCommentReports);
//                console.log("Community Feed Reports: ", communityFeedReports);

                const combinedReport = [...homeFeedReports, ...homeFeedCommentReports, ...communityFeedReports];
                setReportList([]);
                setReportList([...combinedReport]);
            }
            onLoad();
        } catch (e) {
            console.log(e);
        }
    }, [curEnv]);

    const handleStartDate = (e: any) => {
        e.preventDefault();
        setStartDateFilter(e.target.value);
    }

    const handleEndDate = (e: any) => {
        e.preventDefault();
        setEndDateFilter(e.target.value);
    }

    const getReportArr = (reportArr: Array<any>, initialId: string) => {
        return reportArr.map((item: any) => {
            const tempObj = {} as IReportType;
            if (initialId === "HMF") {
                tempObj.id = "HMF" + item["postid"];
                tempObj.category = "Homefeed Post";
            } else if (initialId === "HMC") {
                tempObj.id = "HMC" + item["postid"];
                tempObj.category = "Homefeed Comment";
            } else if (initialId === "CMF") {
                tempObj.id = "CMF" + item["postid"];
                tempObj.category = "Community Post";
            }
            tempObj.userId = item["userid"];
            tempObj.name = item["name"];
            tempObj.profileImage = item["thumbimage"];
            tempObj.reportCount = item["report_count"];
            tempObj.warningCount = item["total_warnings"];
            tempObj.reportType = item["reportname"] === undefined || item["reportname"] === "" ? "Unknown" : item["reportname"];
            tempObj.status = item["status"];
            tempObj.content = item["content"];
            tempObj.timestamp = item["created"];

            return tempObj;
        });
    }

    const filterByReportType = (item: IReportType) => {
        const category = item.category.split(' ')[0];
        switch (reportTypeFilter) {
            case 0:
                return true;
            case 1:
                return category === "Homefeed";
            case 2:
                return category === "Community";
            default: return true;
        }
    }

    const filterByReportDate = (item: IReportType) => {        
        if (
            startDateFilter === undefined || startDateFilter === "" ||
            endDateFilter === undefined || endDateFilter === ""
            ) {
                return true;
        } else {
            const timestamp = item.timestamp;
            const startDate = new Date(startDateFilter);
            const endDate = new Date(endDateFilter);

            const itemDate = new Date(timestamp);

            return itemDate >= startDate && itemDate <= endDate;
        }
    }

    const isFilteredItem = (item: IReportType) => {
        // 0: All
        // 1: Spam
        // Misleading
        // Abusive/Hate Speech
        // Copyright
        // Obscene
        // Others
        switch (reportFilter) {
            case 0:
                return true;
            case 1:
                // reportname
                if (item.reportType === "Spam") {
                    return true;
                } else {
                    return false;
                }
            case 2:
                // reportname
                if (item.reportType === "Misleading") {
                    return true;
                } else {
                    return false;
                }
            case 3:
                // reportname
                if (item.reportType === "Abusive/Hate Speech") {
                    return true;
                } else {
                    return false;
                }
            case 4:
                // reportname
                if (item.reportType === "Copyright") {
                    return true;
                } else {
                    return false;
                }
            case 5:
                // reportname
                if (item.reportType === "Obscene") {
                    return true;
                } else {
                    return false;
                }
            case 6:
                // reportname
                if (item.reportType === "Other") {
                    return true;
                } else {
                    return false;
                }
            case 7:
                // reportname
                if (item.reportType === "Unknown") {
                    return true;
                } else {
                    return false;
                }
            default:
                return true;
        }
    }

    return (
            <div className="ml-6">
                <PageContainer>
                    <div className="flex flex-col">
                        {/*<div>
                            <button
                                className="text-white bg-purple-700 hover:bg-purple-800 focus:outline-none focus:ring-4 focus:ring-purple-300 font-medium rounded-full text-sm px-5 py-2.5 text-center mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900"
                                >All</button>
                            <button
                                className="ml-2 text-white bg-purple-700 hover:bg-purple-800 focus:outline-none focus:ring-4 focus:ring-purple-300 font-medium rounded-full text-sm px-5 py-2.5 text-center mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900"
                                >New</button>
                            <button
                                className="ml-2 text-white bg-purple-700 hover:bg-purple-800 focus:outline-none focus:ring-4 focus:ring-purple-300 font-medium rounded-full text-sm px-5 py-2.5 text-center mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900"
                                >Deleted</button>
                            <button
                                className="ml-2 text-white bg-purple-700 hover:bg-purple-800 focus:outline-none focus:ring-4 focus:ring-purple-300 font-medium rounded-full text-sm px-5 py-2.5 text-center mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900"
                                >Removed from App</button>
                            <button
                                className="ml-2 text-white bg-purple-700 hover:bg-purple-800 focus:outline-none focus:ring-4 focus:ring-purple-300 font-medium rounded-full text-sm px-5 py-2.5 text-center mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900"
                                >Warned</button>
                        </div>*/}
                        <div className="mt-2">
                            <div className="flex flex-row">
                                <div>
                                    <select className="rounded-full" onChange={(e) => setReportFilter(Number(e.target.value))}>
                                        <option value={0}>All</option>
                                        <option value={1}>Spam</option>
                                        <option value={2}>Misleading</option>
                                        <option value={3}>Abusive / Hate Speech</option>
                                        <option value={4}>Copyright</option>
                                        <option value={5}>Obscene</option>
                                        <option value={6}>Others</option>
                                        <option value={7}>Unknown</option>
                                    </select>
                                </div>
                                
                                <div className="flex flex-col">
                                    <div className="ml-2">
                                        <button
                                            id="dropdownDefault"
                                            data-dropdown-toggle="dropdown"
                                            onClick={() => setShowReportFilterDropdown(!showReportFilterDropdown)}
                                            className="ml-6 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" type="button">
                                            Filter
                                            <FontAwesomeIcon icon={faFilter} className="pl-2" />
                                        </button>
                                        <div
                                            id="dropdown"
                                            className={`${showReportFilterDropdown ? '' : 'hidden'} absolute ml-6 z-10 w-44 bg-white rounded divide-y divide-gray-100 shadow dark:bg-gray-700`}>
                                            <ul className="w-48 text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 dark:bg-gray-700 dark:border-gray-600 dark:text-white">
                                                <li
                                                    onClick={() => {setReportTypeFilter(0); setShowReportFilterDropdown(false)}}
                                                    className="w-full rounded-t-lg border-b border-gray-200 dark:border-gray-600">
                                                    <div className="flex items-center pl-3">
                                                        <input  type="radio" checked={reportTypeFilter === 0} value="" name="list-radio" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500" />
                                                        <label
                                                            className="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
                                                            >All
                                                        </label>
                                                    </div>
                                                </li>
                                                <li
                                                    onClick={() => {setReportTypeFilter(1); setShowReportFilterDropdown(false)}}
                                                    className="w-full rounded-t-lg border-b border-gray-200 dark:border-gray-600">
                                                    <div className="flex items-center pl-3">
                                                        <input type="radio" checked={reportTypeFilter === 1} value="" name="list-radio" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500" />
                                                        <label className="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300">Home Feed</label>
                                                    </div>
                                                </li>
                                                <li
                                                    onClick={() => {setReportTypeFilter(2); setShowReportFilterDropdown(false)}}
                                                    className="w-full rounded-t-lg border-b border-gray-200 dark:border-gray-600">
                                                    <div className="flex items-center pl-3">
                                                        <input type="radio" checked={reportTypeFilter === 2} value="" name="list-radio" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500" />
                                                        <label className="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300">Community Feed</label>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>                                        
                                    </div>
                                </div>

                                {/**
                                 * Date Filter
                                 */
                                }
                                <div className="flex flex-row ml-2">
                                    <label className="mr-2">From:</label>
                                    <TextInput type="date" onChange={e => handleStartDate(e)} />
                                    <label className="mr-2 ml-2">To:</label>
                                    <TextInput type="date" onChange={e => handleEndDate(e)} />
                                </div>

                            </div>
                        </div>
                        <div className="mt-4">
                            {
                                reportList.filter((item: IReportType) => {
                                    return isFilteredItem(item);
                                }).filter((item: IReportType) => {
                                    return filterByReportDate(item);
                                }).filter((item: IReportType) => {
                                    return filterByReportType(item);
                                }).sort((a: IReportType, b: IReportType) => {
                                    return (
                                        new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()                                        
                                    );
                                }).map(item => {
                                    return <ReportItem item={item} key={item.id} />
                                })
                            }
                        </div>
                    </div>
                </PageContainer>
            </div>
    );
}

export default PostReports;