import {PageContainer} from "../../pages/dashboard/feedback";
import {useEffect, useState} from "react";
import {useAccounts} from "../accounts/context";
import {getRawCohortDataAPI} from "../../api/users";
import {
    getNextDates,
    getPercentage,
    percentageToOpacity,
    setupUsersMap
} from "../../utils";

const UserRetention = () => {

    const {
        state: {curEnv, user}
    } = useAccounts();

    const [newUsersMap, setNewUsersMap] = useState<Map<string, Array<number>>>();
    const [sessionUserMap, setSessionUserMap] = useState<Map<string, Array<number>>>();


    useEffect(() => {
        const onLoad = async () => {
            try {
                const cohortData = await getRawCohortDataAPI(curEnv);

                const cleanedNewUserData = cleanCohortData(cohortData[0]);
                const cleanedSessionData = cleanCohortData(cohortData[1]);

                setNewUsersMap(setupUsersMap(cleanedNewUserData));
                setSessionUserMap(setupUsersMap(cleanedSessionData));

            } catch (e) {
                console.log(e);
            }
        }

        onLoad();
    }, [curEnv]);

    const cleanCohortData = (data: Array<any>) => {
        return data.filter((item: any) =>{
            return item['created'] !== undefined
        }).map((item: any) => {
            return {
                ...item,
                "created": item["created"].split('T')[0]
            }
        });
    }

    const getCommonCount = (sessionArr: any, userArr: any) => {
        if (sessionArr && userArr) {
            const filteredArray = sessionArr.filter((val: any) => userArr.includes(val));
            return (filteredArray.length ? filteredArray.length : 0)
        } else {
            return 0;
        }
    }



    const fillRetentionTableNumbers = () => {
        if (newUsersMap?.entries() !== undefined) {
            const rowData = [];
            for (const entry of newUsersMap?.entries()) {
                const nextDates = getNextDates(entry[0]);
                const newUsers = entry[1];
                const tempObj:any = {};
                tempObj.date = entry[0];
                tempObj.D0 = entry[1].length;
                tempObj.D1 = getCommonCount(
                        sessionUserMap?.get(nextDates[0]),
                        newUsers
                        );
                tempObj.D2 = getCommonCount(
                        sessionUserMap?.get(nextDates[1]),
                        newUsers
                        );
                tempObj.D3 = getCommonCount(
                        sessionUserMap?.get(nextDates[2]),
                        newUsers
                        );
                tempObj.D4 = getCommonCount(
                        sessionUserMap?.get(nextDates[3]),
                        newUsers
                        );
                tempObj.D5 = getCommonCount(
                        sessionUserMap?.get(nextDates[4]),
                        newUsers
                        );
                tempObj.D6 = getCommonCount(
                        sessionUserMap?.get(nextDates[5]),
                        newUsers
                        )
                tempObj.D7 = getCommonCount(
                        sessionUserMap?.get(nextDates[6]),
                        newUsers
                        );
                rowData.push(tempObj);

            }
            return (
                    <tbody>
                        {
                            rowData.map((item: any) => {
                                return <tr className="border-b" key={item["date"]}>
                                    <td className="px-6 py-2 text-sm font-light text-gray-900 whitespace-nowrap">
                                        {item["date"]}
                                    </td>
                                    <td
                                        style={{backgroundColor: `rgba(100, 21, 255, ${percentageToOpacity(Number(getPercentage(item["D0"], item["D0"])))})`}}
                                        className="px-6 py-2 text-sm font-light text-gray-900 whitespace-nowrap">
                                        {item["D0"]}
                                    </td>
                                    <td
                                        style={{backgroundColor: `rgba(100, 21, 255, ${percentageToOpacity(Number(getPercentage(item["D0"], item["D1"])))})`}}
                                        className="px-6 py-2 text-sm font-light text-gray-900 whitespace-nowrap">
                                        {item["D1"]}
                                    </td>
                                    <td
                                        style={{backgroundColor: `rgba(100, 21, 255, ${percentageToOpacity(Number(getPercentage(item["D0"], item["D2"])))})`}}
                                        className="px-6 py-2 text-sm font-light text-gray-900 whitespace-nowrap">
                                        {item["D2"]}
                                    </td>
                                    <td
                                        style={{backgroundColor: `rgba(100, 21, 255, ${percentageToOpacity(Number(getPercentage(item["D0"], item["D3"])))})`}}
                                        className="px-6 py-2 text-sm font-light text-gray-900 whitespace-nowrap">
                                        {item["D3"]}
                                    </td>
                                    <td
                                        style={{backgroundColor: `rgba(100, 21, 255, ${percentageToOpacity(Number(getPercentage(item["D0"], item["D4"])))})`}}
                                        className="px-6 py-2 text-sm font-light text-gray-900 whitespace-nowrap">
                                        {item["D4"]}
                                    </td>
                                    <td
                                        style={{backgroundColor: `rgba(100, 21, 255, ${percentageToOpacity(Number(getPercentage(item["D0"], item["D5"])))})`}}
                                        className="px-6 py-2 text-sm font-light text-gray-900 whitespace-nowrap">
                                        {item["D5"]}
                                    </td>
                                    <td
                                        style={{backgroundColor: `rgba(100, 21, 255, ${percentageToOpacity(Number(getPercentage(item["D0"], item["D6"])))})`}}
                                        className="px-6 py-2 text-sm font-light text-gray-900 whitespace-nowrap">
                                        {item["D6"]}
                                    </td>
                                    <td
                                        style={{backgroundColor: `rgba(100, 21, 255, ${percentageToOpacity(Number(getPercentage(item["D0"], item["D7"])))})`}}
                                        className="px-6 py-2 text-sm font-light text-gray-900 whitespace-nowrap">
                                        {item["D7"]}
                                    </td>
                                </tr>
                            })
                        }
                    </tbody>
                    );
        } else {
            return (
                    <tbody></tbody>
                    );
        }
    }

    const fillRetentionTable = () => {
        if (newUsersMap?.entries() !== undefined) {
            const rowData = [];
            for (const entry of newUsersMap?.entries()) {
                const nextDates = getNextDates(entry[0]);
                const newUsers = entry[1];
                const tempObj:any = {};
                tempObj.date = entry[0];
                tempObj.D0 = entry[1].length;
                tempObj.D1 = getCommonCount(
                        sessionUserMap?.get(nextDates[0]),
                        newUsers
                        );
                tempObj.D2 = getCommonCount(
                        sessionUserMap?.get(nextDates[1]),
                        newUsers
                        );
                tempObj.D3 = getCommonCount(
                        sessionUserMap?.get(nextDates[2]),
                        newUsers
                        );
                tempObj.D4 = getCommonCount(
                        sessionUserMap?.get(nextDates[3]),
                        newUsers
                        );
                tempObj.D5 = getCommonCount(
                        sessionUserMap?.get(nextDates[4]),
                        newUsers
                        );
                tempObj.D6 = getCommonCount(
                        sessionUserMap?.get(nextDates[5]),
                        newUsers
                        )
                tempObj.D7 = getCommonCount(
                        sessionUserMap?.get(nextDates[6]),
                        newUsers
                        );
                tempObj.D15 = getCommonCount(
                        sessionUserMap?.get(nextDates[7]),
                        newUsers
                );
                tempObj.D30 = getCommonCount(
                        sessionUserMap?.get(nextDates[8]),
                        newUsers
                );
                rowData.push(tempObj);

            }
            return (
                    <tbody>
                        {
                            rowData.map((item: any) => {
                                return <tr className="border-b" key={item["date"]}>
                                    <td className="px-6 py-2 text-sm font-light text-gray-900 whitespace-nowrap">
                                        {item["date"]}
                                    </td>
                                    <td
                                        style={{backgroundColor: `rgba(100, 21, 255, ${percentageToOpacity(Number(getPercentage(item["D0"], item["D0"])))})`}}
                                        className="px-6 py-2 text-sm font-light text-gray-900 whitespace-nowrap">
                                        {getPercentage(item["D0"], item["D0"]) + "%"}
                                    </td>
                                    <td
                                        style={{backgroundColor: `rgba(100, 21, 255, ${percentageToOpacity(Number(getPercentage(item["D0"], item["D1"])))})`}}
                                        className="px-6 py-2 text-sm font-light text-gray-900 whitespace-nowrap">
                                        {getPercentage(item["D0"], item["D1"]) + "%"}
                                    </td>
                                    <td
                                        style={{backgroundColor: `rgba(100, 21, 255, ${percentageToOpacity(Number(getPercentage(item["D0"], item["D2"])))})`}}
                                        className="px-6 py-2 text-sm font-light text-gray-900 whitespace-nowrap">
                                        {getPercentage(item["D0"], item["D2"]) + "%"}
                                    </td>
                                    <td
                                        style={{backgroundColor: `rgba(100, 21, 255, ${percentageToOpacity(Number(getPercentage(item["D0"], item["D3"])))})`}}
                                        className="px-6 py-2 text-sm font-light text-gray-900 whitespace-nowrap">
                                        {getPercentage(item["D0"], item["D3"]) + "%"}
                                    </td>
                                    <td
                                        style={{backgroundColor: `rgba(100, 21, 255, ${percentageToOpacity(Number(getPercentage(item["D0"], item["D4"])))})`}}
                                        className="px-6 py-2 text-sm font-light text-gray-900 whitespace-nowrap">
                                        {getPercentage(item["D0"], item["D4"]) + "%"}
                                    </td>
                                    <td
                                        style={{backgroundColor: `rgba(100, 21, 255, ${percentageToOpacity(Number(getPercentage(item["D0"], item["D5"])))})`}}
                                        className="px-6 py-2 text-sm font-light text-gray-900 whitespace-nowrap">
                                        {getPercentage(item["D0"], item["D5"]) + "%"}
                                    </td>
                                    <td
                                        style={{backgroundColor: `rgba(100, 21, 255, ${percentageToOpacity(Number(getPercentage(item["D0"], item["D6"])))})`}}
                                        className="px-6 py-2 text-sm font-light text-gray-900 whitespace-nowrap">
                                        {getPercentage(item["D0"], item["D6"]) + "%"}
                                    </td>
                                    <td
                                        style={{backgroundColor: `rgba(100, 21, 255, ${percentageToOpacity(Number(getPercentage(item["D0"], item["D7"])))})`}}
                                        className="px-6 py-2 text-sm font-light text-gray-900 whitespace-nowrap">
                                        {getPercentage(item["D0"], item["D7"]) + "%"}
                                    </td>
                                </tr>
                            })
                        }
                    </tbody>
                    );
        } else {
            return (
                    <tbody></tbody>
                    );
        }
    }

    return (
            <div>
                <PageContainer>
                    <div className="overflow-x-auto relative ml-6">
                        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                            <thead className="text-xs text-gray-900 uppercase dark:text-gray-400">
                                <th scope="col" className="py-3 px-6">Date</th>
                                <th scope="col" className="py-3 px-6">D0</th>
                                <th scope="col" className="py-3 px-6">D1</th>
                                <th scope="col" className="py-3 px-6">D2</th>
                                <th scope="col" className="py-3 px-6">D3</th>
                                <th scope="col" className="py-3 px-6">D4</th>
                                <th scope="col" className="py-3 px-6">D5</th>
                                <th scope="col" className="py-3 px-6">D6</th>
                                <th scope="col" className="py-3 px-6">D7</th>
                                <th scope="col" className="py-3 px-6">D15</th>
                                <th scope="col" className="py-3 px-6">D30</th>
                            </thead>
                            {
                                fillRetentionTable()
                            }
                        </table>
                        <br /><br /><br />
                    </div>
                </PageContainer>
                <PageContainer>
                    <div className="overflow-x-auto relative ml-6">
                        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                            <thead className="text-xs text-gray-900 uppercase dark:text-gray-400">
                                <th scope="col" className="py-3 px-6">Date</th>
                                <th scope="col" className="py-3 px-6">D0</th>
                                <th scope="col" className="py-3 px-6">D1</th>
                                <th scope="col" className="py-3 px-6">D2</th>
                                <th scope="col" className="py-3 px-6">D3</th>
                                <th scope="col" className="py-3 px-6">D4</th>
                                <th scope="col" className="py-3 px-6">D5</th>
                                <th scope="col" className="py-3 px-6">D6</th>
                                <th scope="col" className="py-3 px-6">D7</th>
                                <th scope="col" className="py-3 px-6">D15</th>
                                <th scope="col" className="py-3 px-6">D30</th>
                            </thead>
                            {
                                fillRetentionTableNumbers()
                            }
                        </table>
                        <br /><br /><br />
                    </div>
                </PageContainer>
            </div>
    );
}

export default UserRetention;