import API from "@aws-amplify/api";
import { getEnvApi } from "../utils";

export function getAppConfigsAPI(env: number) {
    return API.post(getEnvApi(env),'/admin/admin_ops/app_configs/all',{
        body: {

        }
    }); 
}

export function setSocialSignInStatusAPI(env: number, status: number) {
    return API.post(getEnvApi(env), "/admin/admin_ops/set_social_sign_in_status/all", {
        body: {
            "status": status
        }
    });
}

export function setRedeemStatusAPI(env: number, status: number) {
    return API.post(getEnvApi(env), "/admin/admin_ops/set_redeem_status/all", {
        body: {
            "status": status
        }
    });
}

export function getCurrentWithdrawalSettingsAPI(env: number) {
    return API.post(getEnvApi(env), "/admin/admin_ops/current_withdraw_settings/all", {
        body: {
            
        }
    });
}

export function updateWithdrawalSettingsAPI(
    env: number,
    withdrawalId: number,
    withdrawalLimit: number,
    thresholdLimit: number,
    alertMessage: string,
    emailList: string,
    startDate: string,
    endDate: string,
    updatedBy: string
) {
    return API.post(getEnvApi(env), "/admin/admin_ops/update_withdraw_settings/all", {
        body: {
            "withdrawalId": withdrawalId,
            "withdrawalLimit": withdrawalLimit,
            "thresholdLimit": thresholdLimit,
            "alertMessage": alertMessage,
            "emailList": emailList,
            "startDate": startDate,
            "endDate": endDate,
            "updatedBy": updatedBy
        }
    });
}

export function getWithdrawalSettingsHistoryAPI(env: number) {
    return API.post(getEnvApi(env), "/admin/admin_ops/withdraw_settings_history/all", {
        body: {
            
        }
    });
}

export function updateUserAuthenticatedStatusAPI(
    env: number,
    userId: number,
    authenticatedStatus: boolean,
    updatedBy: string
) {
    // userId: number,
    // authenticatedStatus: boolean,
    // updatedBy: string
    return API.post(getEnvApi(env), "/admin/admin_ops/toggle_auth_status/all", {
        body: {
            "userId": userId,
            "authenticatedStatus": authenticatedStatus,
            "updatedBy": updatedBy
        }
    });
}

export function getAuthenticatedCommunitiesAPI(env: number) {
    return API.post(getEnvApi(env), "/admin/admin_ops/authenticated_communities_list/all", {
        body: {
            
        }
    });
}

export function createWithdrawalSettingsAPI(
    env: number,
    withdrawalLimit: number,
    thresholdLimit: number,
    alertMessage: string,
    emailList: string,
    startDate: string,
    endDate: string,
    createdBy: string
    ) {
    return API.post(getEnvApi(env), "/admin/admin_ops/create_withdraw_settings/all", {
        body: {
            "withdrawalLimit": withdrawalLimit,
            "thresholdLimit": thresholdLimit,
            "alertMessage": alertMessage,
            "emailList": emailList,
            "startDate": startDate,
            "endDate": endDate,
            "createdBy": createdBy
        }
    });
}