const config = {
  s3: {
    REGION: '',
    BUCKET: '',
  },
  apiGateway: {
    REGION: 'ap-south-1',
    URL: 'https://v1-adminapi.one.investmates.io',
  },
  devapiGateway: {
    REGION: 'ap-south-1',
    URL: 'https://dev-adminapi.one.investmates.io',
  },
  stageapiGateway: {
    REGION: 'ap-south-1',
    URL: 'https://stage-adminapi.one.investmates.io',
  },
  cognito: {
    REGION: 'ap-south-1',
    USER_POOL_ID: 'ap-south-1_qQwqdsxQN',
    APP_CLIENT_ID: 'mjvich6pu2ta31mt4dbej3g7e',
    IDENTITY_POOL_ID: 'ap-south-1:d412c98e-5d2e-4dcb-9804-baeb1c64fbff',
    // oauth: {
    //   domain: 'accounts.investmates.io',
    //   scope: ['email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
    //   redirectSignIn: window.location.origin + '?signedin=true',
    //   redirectSignOut: window.location.origin + '/',
    //   responseType: 'code',
    // },
  },
}

export default config;
