import clsx from "clsx";

const TabTitle = ({ title, selected }: { title: string; selected: boolean }) => {
    return (
        <div className="flex flex-col items-center m-3">
            <div className={`text-lg font-bold ${selected ? 'text-white' : 'text-gray-800'}`}>
                {title}
            </div>
                           
        </div>
    );
}

export default TabTitle;