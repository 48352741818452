import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal, TextInput } from "flowbite-react";
import { title } from "process";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { getContestHistoryAPI, getQuizDetailsAPI, updateContestTaskAPI } from "../../api/contest";
import { getLocalDateTime, IContestItem, IOption, IQuiz, IQuizOptions, IRank, PRIMARY_BUTTON_STYLE } from "../../utils";
import { useAccounts } from "../accounts/context";

const TaskHistory = () => {
    
    const [historyItems, setHistoryItems] = useState<Array<IContestItem>>([]);
    const [showModal, setShowModal] = useState(false);
    const [editingItem, setEditingItem] = useState({} as IContestItem);
    // IQuiz Array<IOption> Array<IRank>
    const [editingQuiz, setEditingQuiz] = useState({} as IQuiz);
    const [editingOps, setEditingOps] = useState<Array<IOption>>([]);
    const [editingRanks, setEditingRanks] = useState<Array<IRank>>([]);
    const [updateCounter, setUpdateCounter] = useState(0);
    const [isEditableStartDate, setIsEditableStartDate] = useState(false);

    const {
        state: {curEnv, user}
    } = useAccounts();

    const toggleModal = () => {
        if (showModal) {
            setEditingItem({} as IContestItem);
            setEditingQuiz({} as IQuiz);
            setEditingOps([]);
            setEditingRanks([]);
        }
        setShowModal(!showModal);
    }

    useEffect(() => {
        const onLoad = () => {
            getContestHistoryAPI(
                curEnv
            ).then(data => {
                // console.log(data);
                if (Array.isArray(data) && data.length > 0) {
                    setHistoryItems([]);
                    const currentDate = new Date(new Date().toUTCString());
                    data.forEach(item => {
                        // console.log(item["enddate"]);
                        const tempItem = {} as IContestItem;
                        tempItem.actionKey = item["actionkey"];
                        tempItem.contestId = item["contestid"];
                        tempItem.title = item["title"];
                        tempItem.description = item["sdesc"];
                        tempItem.coins = item["coins"];
                        tempItem.rewardType = item["rewardtype"];
                        tempItem.status = currentDate > new Date(item["enddate"]) ? 0 : item["isactive"];
                        tempItem.startDate = item["startdate"];
                        tempItem.endDate = item["enddate"];
                        tempItem.createdBy = item["createdby"];
                        setIsEditableStartDate(new Date(tempItem.startDate) > new Date());

                        setHistoryItems(oldData => [...oldData, tempItem]);
                    });
                }
            }).catch(err => {
                console.log(err);
                toast.error("Error while getting the contest history");
            });
        }

        onLoad();
    }, [curEnv, updateCounter]);

    const handleEditClick = (e: any, item: IContestItem) => {
        e.preventDefault();
        if (item.actionKey === "quiz") {
            getQuizDetailsAPI(
                curEnv, 
                item.contestId
            ).then(data => {
                if (
                    Array.isArray(data) && 
                    data.length > 0 && 
                    Array.isArray(data[1]) && 
                    data[1].length > 0 &&
                    Array.isArray(data[2]) &&
                    data[2].length > 0
                ) {
                    const quizId = data[0][0]["quizid"];
                    const questionId = data[0][0]["questionid"];
                    const isPoll = data[0][0]["ispoll"] === 1 ? true : false;
                    const answerExplanation = data[0][0]["qexplain"];
                    const duration = data[0][0]["duration"];
                    const questionTitle = data[0][0]["title"];
                    
                    const quiz = {} as IQuiz;
                    quiz.quizId = quizId;
                    quiz.questionId = questionId;
                    quiz.isPoll = isPoll;
                    quiz.answerExplanation = answerExplanation;
                    quiz.quizQuestion = questionTitle;
                    quiz.duration = duration;

                    const optionsArray: Array<IOption> = []

                    data[1].forEach(option => {
                        const tempOption = {} as IOption;
                        tempOption.optionId = option["optionid"];
                        tempOption.optionText = option["title"];
                        tempOption.isRight = option["isright"] === 1 ? true : false;
                        optionsArray.push(tempOption);
                    });

                    const ranks: Array<IRank> = [];
                    data[2].forEach(rank => {
                        const tempRank = {} as IRank;
                        tempRank.rankId = rank["rewardid"];
                        tempRank.coins = rank["amount"];
                        tempRank.startRank = rank["startrange"];
                        tempRank.endRank = rank["endrange"];
                        ranks.push(tempRank);
                    });

                    setEditingQuiz(quiz);
                    setEditingOps(optionsArray);
                    setEditingRanks(ranks);
                    setEditingItem({
                        ...item,
                        startDate: item.startDate.split('.')[0],
                        endDate: item.endDate.split('.')[0]
                    });
                    setShowModal(true);
                } else {
                    toast.error("The quiz details could not be found for this campaign");
                    setEditingItem({} as IContestItem);
                    setShowModal(false);
                }             
            }).catch(err => {
                toast.error("Error while getting the quiz details");
            });
        } else {
            // console.log("Local Start Date: ", getLocalDateTime(item.startDate));
            // console.log("Local End Date: ", getLocalDateTime(item.endDate));
            // setEditingItem({
            //     ...item,
            //     startDate: item.startDate.split('.')[0],
            //     endDate: item.endDate.split('.')[0]
            // });

            setEditingItem({
                ...item,
                startDate: getLocalDateTime(item.startDate),
                endDate: getLocalDateTime(item.endDate)
            });
            setShowModal(true);
        }        
    }

    const updateContestItem = (e: any) => {
        e.preventDefault();   
        
        if (checkValidity()) {
            const optionsArray = {} as IQuizOptions;
            optionsArray.options = editingOps;
            updateContestTaskAPI(
                curEnv,
                editingItem.contestId,
                editingItem.actionKey,
                editingItem.title,
                editingItem.description,
                editingItem.startDate,
                editingItem.endDate,
                editingItem.status,
                editingItem.coins,
                editingQuiz,
                optionsArray,
                editingRanks,
                user?.email ? user?.email : 'feedback@investmates.io'
            ).then(result => {
                // console.log(result);
                toast.success("Updated the contest item successfully");
                setUpdateCounter(updateCounter + 1);
            }).catch(err => {
                console.log(err);
                toast.error("Error while updating the contest item");            
            }).finally(() => {
                setEditingItem({} as IContestItem);
                setEditingQuiz({} as IQuiz);
                setEditingOps([]);
                setEditingRanks([]);
                setShowModal(false);
                setIsEditableStartDate(false);
            });           
        }      
    }

    const checkValidity = () => {
        if (editingItem.title === undefined || editingItem.title === null || editingItem.title === "") {
            toast.error("Please set a proper contest title");
            return false;
        }

        if (editingItem.description === undefined || editingItem.description === null || editingItem.description === "") {
            toast.error("Please set a proper contest description");
            return false;
        }

        if (editingItem.actionKey !== "quiz" && editingItem.coins <= 0) {
            toast.error("Please set proper reward coins");
            return false;
        }

        if (isEditableStartDate && new Date(editingItem.startDate) <= new Date()) {
            toast.error("Please set start date time greater than the current date time");
            return false;
        }

        if (new Date(editingItem.endDate) <= new Date(editingItem.startDate)) {
            toast.error("Please set the end date greater than the start date");
            return false;
        }

        if (
            editingItem.actionKey === "quiz" && 
            (
                editingQuiz.quizQuestion === undefined || 
                editingQuiz.quizQuestion === null ||
                editingQuiz.quizQuestion === ""
            )
        ) {
            toast.error("Please set proper quiz question");
            return false;
        }

        // Validating the first option        
        if (
            editingItem.actionKey === "quiz" && 
            (editingOps[0].optionText === undefined ||
            editingOps[0].optionText === null ||
            editingOps[0].optionText === "")
        ) {
            toast.error("Please set proper first option");
            return false;
        }

        // Validating the second option        
        if (
            editingItem.actionKey === "quiz" && 
            (editingOps[1].optionText === undefined ||
            editingOps[1].optionText === null ||
            editingOps[1].optionText === "")
        ) {
            toast.error("Please set proper second option");
            return false;
        }

        // Validating the third option        
        if (
            editingItem.actionKey === "quiz" && 
            (editingOps[2].optionText === undefined ||
            editingOps[2].optionText === null ||
            editingOps[2].optionText === "")
        ) {
            toast.error("Please set proper third option");
            return false;
        }

        // Validating the fourth option        
        if (
            editingItem.actionKey === "quiz" && 
            (editingOps[3].optionText === undefined ||
            editingOps[3].optionText === null ||
            editingOps[3].optionText === "")
        ) {
            toast.error("Please set proper fourth option");
            return false;
        }

        if (editingItem.actionKey === "quiz" && !editingRanks.reduce((acc, item, index) => {
            return acc && item.coins > 0 && index !== 0 ? editingRanks[index - 1].coins > item.coins : true;
        }, true)) {
            toast.error("Please set proper reward coins for ranks");
            return false;
        }

        return true;
    }

    const getQuizDetails = () => {
        getQuizDetailsAPI(
            curEnv, 
            editingItem.contestId
        ).then(data => {
            // console.log(data);
        }).catch(err => {
            console.log(err);
            toast.error("Error while getting the quiz details");
        });
    }


    const fillTable = () => {
        return historyItems.sort((a, b) =>{
            return b.status - a.status;
        }).map((item, index) => {
            return (
                <tr className="border-b border-slate-200" key={"".concat(index.toString(), item.contestId.toString())}>
                    <td className="px-2 py-2 text-sm font-light text-gray-900">
                        {item.contestId}
                    </td>
                    <td className="px-2 py-2 text-sm font-light text-gray-900">
                        {item.title}
                    </td>
                    <td className="px-2 py-2 text-sm font-light text-gray-900">
                        {item.actionKey}
                    </td>
                    <td className="px-2 py-2 text-sm font-light text-gray-900">
                        {item.description}
                    </td>
                    <td className="px-2 py-2 text-sm font-light text-gray-900">
                        {item.coins}
                    </td>
                    <td className="px-2 py-2 text-sm font-light text-gray-900">
                        {item.rewardType}
                    </td>
                    <td className={`${item.status === 1 ? 'text-green-600' : 'text-red-600' }`}>
                        {item.status === 1 ? "Active" : "Inactive"}
                    </td>
                    <td className="px-2 py-2 text-sm font-light text-gray-900">
                        {new Date(item.startDate).toLocaleString()}
                    </td>
                    <td className="px-2 py-2 text-sm font-light text-gray-900">
                        {new Date(item.endDate).toLocaleString()}
                    </td>
                    <td className="px-2 py-2 text-sm font-light text-gray-900">
                        {item.createdBy}
                    </td>
                    <td className="border-b border-slate-200">
                        <button onClick={e => handleEditClick(e, item)}>
                            <FontAwesomeIcon icon={faEdit} />
                        </button>
                    </td>
                </tr>
            );
        });
    }    

    return (
        <div className="relative overflow-auto mr-4 ml-4 mt-4">
            <table className="border w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-100 dark:text-gray-400">
                    <tr>
                        <th className="p-2">
                            Contest Id
                        </th>
                        <th className="p-2">
                            Title
                        </th>
                        <th className="p-2">
                            Key
                        </th>
                        <th className="p-2">
                            Description
                        </th>
                        <th className="p-2">
                            Reward Coins
                        </th>
                        <th className="p-2">
                            Reward Type
                        </th>
                        <th className="p-2">
                            Status
                        </th>
                        <th className="p-2">
                            Start Date
                        </th>
                        <th className="p-2">
                            End Date
                        </th>
                        <th className="p-2">
                            Created By
                        </th>  
                        <th className="p-2">
                            Actions
                        </th>                  
                    </tr>                    
                </thead>
                <tbody>
                    {
                        fillTable()
                    }
                </tbody>
            </table>
            <Modal
                show={showModal}
                size="xxl"
                popup={true}
                onClose={() => toggleModal()}
            >
                <Modal.Header>
                    Edit Contest Item
                </Modal.Header>
                <Modal.Body>
                    <div className="mt-4 mb-4 max-h-96 overflow-y-scroll">
                        <table>
                            <tbody>
                                <tr>
                                    <td><b>Contest Id: </b></td>
                                    <td>{editingItem.contestId}</td>
                                </tr>
                                <tr>
                                    <td>
                                        <b>Title</b>
                                    </td>
                                    <td>
                                        <input 
                                            type="text" 
                                            value={editingItem.title}
                                            onChange={e => setEditingItem({
                                                ...editingItem,
                                                title: e.target.value
                                            } as IContestItem)}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <b>Action Key</b>                                    
                                    </td>
                                    <td>{editingItem.actionKey}</td>
                                </tr>
                                <tr>
                                    <td>
                                        <b>Description</b>
                                    </td>
                                    <td>
                                        <input 
                                            type="text"
                                            value={editingItem.description}
                                            onChange={e => setEditingItem({
                                                ...editingItem,
                                                description: e.target.value
                                            } as IContestItem)}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <b>Reward Coins: </b>
                                    </td>
                                    <td>
                                        <input 
                                            type="number" 
                                            value={editingItem.coins}
                                            disabled={editingItem.actionKey === "quiz"}
                                            onChange={e => setEditingItem({
                                                ...editingItem,
                                                coins: Number(e.target.value)
                                            } as IContestItem)}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <b>Status</b>
                                    </td>
                                    <td>
                                        <select value={editingItem.status} onChange={e => setEditingItem({
                                            ...editingItem,
                                            status: Number(e.target.value)
                                        } as IContestItem)}>
                                            <option value={1}>Active</option>
                                            <option value={0}>Inactive</option>
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <b>Start Date</b>                                    
                                    </td>
                                    <td>
                                        <TextInput 
                                            type="datetime-local"
                                            value={editingItem.startDate}
                                            disabled={!isEditableStartDate}
                                            onChange={e => setEditingItem({
                                                ...editingItem,
                                                startDate: e.target.value
                                            } as IContestItem)}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <b>End Date</b>                                    
                                    </td>
                                    <td>
                                        <TextInput 
                                            type="datetime-local"
                                            value={editingItem.endDate}
                                            onChange={e => setEditingItem({
                                                ...editingItem,
                                                endDate: e.target.value
                                            } as IContestItem)}
                                        />
                                    </td>
                                </tr>
                                {
                                    editingItem.actionKey === 'quiz'
                                    ? <>
                                    <tr>
                                        <td>
                                            <b>Question Title</b>
                                        </td>
                                        <td>
                                            <textarea
                                                className="w-full"                                             
                                                value={editingQuiz.quizQuestion}
                                                onChange={e => setEditingQuiz({
                                                    ...editingQuiz,
                                                    quizQuestion: e.target.value
                                                } as IQuiz)}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <b>Options: </b>
                                        </td>
                                    </tr>
                                    {
                                        editingOps.map((option, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>
                                                        <input 
                                                            type="text"
                                                            value={option.optionText}
                                                            onChange={e => setEditingOps([
                                                                ...editingOps.map(item => {
                                                                    if (item.optionId === option.optionId) {
                                                                        return {
                                                                            ...item,
                                                                            optionText: e.target.value
                                                                        }
                                                                    } else {
                                                                        return item;
                                                                    }
                                                                })
                                                            ])}
                                                        />
                                                    </td>
                                                    <td>
                                                        <input 
                                                            type="checkbox"
                                                            checked={option.isRight}
                                                            onChange={e => setEditingOps([
                                                                ...editingOps.map(item => {
                                                                    if (item.optionId === option.optionId) {
                                                                        return {
                                                                            ...item,
                                                                            isRight: item.isRight ? true : e.target.checked
                                                                        }
                                                                    } else {
                                                                        return {
                                                                            ...item,
                                                                            isRight: e.target.checked ? false : item.isRight
                                                                        };
                                                                    }
                                                                })
                                                            ])}
                                                        />
                                                    </td>
                                                </tr>
                                            );
                                        })
                                    }
                                    <tr>
                                        <td>
                                            <b>Ranks: </b>
                                        </td>
                                    </tr>
                                    {
                                        editingRanks.map((rank, index) => {
                                            return (
                                                <tr>
                                                    <td>
                                                        Start Rank: {rank.startRank}
                                                        {/* <input 
                                                            type="number"
                                                            value={rank.startRank}
                                                            onChange={e => setEditingRanks([
                                                                ...editingRanks.map(item => {
                                                                    if (item.rankId === rank.rankId) {
                                                                        return {
                                                                            ...item,
                                                                            startRank: Number(e.target.value)
                                                                        }
                                                                    } else {
                                                                        return item;
                                                                    }
                                                                })
                                                            ])}
                                                        /> */}
                                                    </td>
                                                    <td>
                                                        End Rank: {rank.endRank}
                                                        {/* <input 
                                                            type="number"
                                                            value={rank.endRank}
                                                            onChange={e => setEditingRanks([
                                                                ...editingRanks.map(item => {
                                                                    if (item.rankId === rank.rankId) {
                                                                        return {
                                                                            ...item,
                                                                            endRank: Number(e.target.value)
                                                                        };
                                                                    } else {
                                                                        return item;
                                                                    }
                                                                })
                                                            ])}
                                                        /> */}
                                                    </td>
                                                    <td>
                                                        <input 
                                                            type="number"
                                                            value={rank.coins}
                                                            onChange={e => setEditingRanks([
                                                                ...editingRanks.map(item => {
                                                                    if (item.rankId === rank.rankId) {
                                                                        return {
                                                                            ...item,
                                                                            coins: Number(e.target.value)
                                                                        }
                                                                    } else {
                                                                        return item;
                                                                    }
                                                                })
                                                            ])}
                                                        />
                                                    </td>
                                                </tr>
                                            );
                                        })
                                    }
                                    </>
                                    : ''
                                }                           
                            </tbody>
                        </table>
                    </div>                    
                </Modal.Body>
                <Modal.Footer>
                    <button 
                        className={`${PRIMARY_BUTTON_STYLE}`}
                        onClick={e => updateContestItem(e)}
                    >
                        Update
                    </button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default TaskHistory;