import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, Title } from 'chart.js';
import { useAppState } from '../../../../../redux/context';
import { useMemo } from 'react';
import Loading from '../../../../Loading';

ChartJS.register(ArcElement, Tooltip, Legend, Title);

const TagCharts = ({ src }: { src: string | number }) => {
  const { state } = useAppState();

  const data1 = useMemo(() => {
    return {
      labels: ["Others", "Help", "Suggestion", "Grievances"],
      datasets: [{
        data: state?.FeedB?.tagCharts[Number(src)] ?? [0, 13000, 63000, 14000, 10000],
        backgroundColor: [
          'rgba(175, 192, 192, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
        ]
      }]
    }
  }, [state?.FeedB?.tagCharts, src]);

  if (!state.FeedB.tagCharts) {
    return <Loading />;
  }
  return (
    <div className="flex flex-col items-center">
      <div className="max-w-xs">
        <Pie data={data1} options={{
          plugins: {
            legend: {
              position: 'right',
            },
            title: {
              display: true,
              text: 'Feedback Types',
              position: 'top'
            }
          },
        }} />
      </div>
    </div>
  );
}

export default TagCharts;
