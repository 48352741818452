import clsx from "clsx";
import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { searchUserAPI } from "../../api/users";
import { ISearchType, IUserSearchResultType } from "../../utils";
import { useAccounts } from "../accounts/context";

const UserSearch = ({
    searchQuery = 0,
    onSubmit
} : {
    searchQuery: number | string;
    onSubmit: (data: IUserSearchResultType) => void
}) => {

    const {
        register,
        handleSubmit,
        formState: {errors, isSubmitting}
    } = useForm<IUserSearchResultType>({
        defaultValues: {
            userId: 0                              
        }
    });

    const {
        state: {curEnv}
    } = useAccounts();

    const [changeCount, setChangeCount] = useState(0);
    const [hideResults, setHideResults] = useState(true);
    const [suggestionList, setSuggestionList] = useState<Array<IUserSearchResultType>>([]);

    const handleSearchChange = (e: any) => {
        e.preventDefault();
        if (changeCount >= 3) {
            searchUserAPI(curEnv, e.target.value)
                .then(data => {
                    if (Array.isArray(data)) {
                        setSuggestionList([]);
                        if (data.length > 0) {
                            data.forEach(dataItem => {
                                const tempItem = {} as IUserSearchResultType;
                                tempItem.userId = dataItem["userid"];
                                tempItem.userName = dataItem["user_name"];
                                tempItem.profilePicture = dataItem["smallimage"];
                                tempItem.userHandle = dataItem["userhandle"];
                                tempItem.contactNo = dataItem["contactno"];
                                tempItem.email = dataItem["email"];
                                tempItem.joinDate = dataItem["created"];
                                tempItem.communityOwned = dataItem["community_count"];
                                tempItem.authenticated = dataItem["byimates"] === 1;

                                setSuggestionList(oldData => [...oldData, tempItem]);
                            });
                            setChangeCount(0);
                            setHideResults(false);
                            setTimeout(() => {
                                setHideResults(true);
                            }, 4000);
                        }
                    }
                }).catch(err => {
                    console.log(err);
                })
        } else {
            setChangeCount(changeCount + 1);
        }
    }

    return (
        <div>
            <form className="flex items-center px-3" onSubmit={handleSubmit(onSubmit)}>
                <label htmlFor="simple-search" className="sr-only">Search</label>
                <div className="relative w-full">
                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <svg className="w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M61,30.3H33.8V3c0-1-0.8-1.8-1.8-1.8S30.3,2,30.3,3v27.3H3c-1,0-1.8,0.8-1.8,1.8S2,33.8,3,33.8h27.3V61c0,1,0.8,1.8,1.8,1.8
            s1.8-0.8,1.8-1.8V33.8H61c1,0,1.8-0.8,1.8-1.8S62,30.3,61,30.3z" clipRule="evenodd"></path></svg>
                </div>
                <input
                    {...register('userId', { required: true })}
                    type="text" min={1}
                    id="simple-search"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Enter User Id / Name"
                    onChange={e => handleSearchChange(e)}
                    required
                />
                
                </div>
                <button type="submit"
                disabled={isSubmitting}
                className="p-2.5 ml-2 text-sm font-medium text-white bg-blue-700 rounded-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                {isSubmitting ?
                    (<div className="w-5 h-5 border-b-2 border-gray-100 rounded-full animate-spin" />) : (<svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>)}
                </button>
            </form>            
            <div 
                className="z-10 w-84 bg-white rounded divide-y divide-gray-100 shadow dark:bg-gray-700" 
                hidden={hideResults || !(suggestionList.length > 0)}
            >
                <ul className="py-1 text-sm text-gray-700 dark:text-gray-200">
                {
                    suggestionList.map((item) => {
                        return <SearchItem item={item} submitHandler={onSubmit} />
                    })
                }
                </ul>
            </div>
        </div>
    );
}

const SearchItem = ({
    item,
    submitHandler
} : {
    item: IUserSearchResultType,
    submitHandler: SubmitHandler<IUserSearchResultType>
}) => {
    const resultType = {
        ...item
    } as IUserSearchResultType;

    return (
        <li
            key={item.userId}
            className="p-2 flex flex-row items-center"
            onClick={() => submitHandler(resultType)}
        >
            <span>
                <img className="h-10" src={item.profilePicture} alt="profile picture" />
            </span>
            <span className="ml-2">
                {item.userName}
            </span>
        </li>
    );
}

export default UserSearch;

