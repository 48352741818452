import API from '@aws-amplify/api';
import { getEnvApi } from '../utils';

export function getReportListAPI(env: number, tag: number, from?: string, to?: string, deleted = false) {
  return API.post(getEnvApi(env), `/user/reports/ls/all`, {
    body: {
      tag, from, to, deleted
    }
  });
}

export function countReportsAPI(env: number) {
  return API.post(getEnvApi(env), `/user/reports/count/all`, {
    body: {

    }
  });
}

export function getReportAPI(env: number, communityreportid = 0, postreportid = 0, tradereportid = 0) {
  if (communityreportid + postreportid + tradereportid === 0) return;
  return API.post(getEnvApi(env), `/user/reports/get/all`, {
    body: {
      communityreportid,
      postreportid,
      tradereportid
    }
  });
}

export function deleteReportAPI(env: number, communityreportid = 0, postreportid = 0, tradereportid = 0) {
  if (communityreportid + postreportid + tradereportid === 0) return;
  return API.post(getEnvApi(env), `/admin/reports/rm/all`, {
    body: {
      communityreportid,
      postreportid,
      tradereportid
    }
  });
}

export function updateReportAPI(env: number, communityreportid = 0, postreportid = 0, tradereportid = 0, status?: number) {
  if (communityreportid + postreportid + tradereportid === 0) return;
  return API.post(getEnvApi(env), `/admin/reports/upd/one`, {
    body: {
      communityreportid,
      postreportid,
      tradereportid,
      status
    }
  });
}

export function reportActionAPI(env: number, communityreportid = 0, postreportid = 0, tradereportid = 0, status = true, warn = true, to = '', sub = 'Regarding your post', body = 'Regarding reports on your post') {
  if (communityreportid + postreportid + tradereportid === 0) return;
  return API.post(getEnvApi(env), `/admin/reports/act/one`, {
    body: {
      communityreportid,
      postreportid,
      tradereportid,
      status,
      warn, to, sub, body
    }
  });
}

export function getReportListNewAPI(env: number) {
    return API.post(getEnvApi(env), `/user/reports/report_list/all`, {

    });
}

export function getReportDetailsAPI(env: number, postId: number, postType: string) {
    return API.post(getEnvApi(env), `/user/reports/report_details/all`, {
        body: {
            "postId": postId,
            "postType": postType
        }
    });
}
// userId suspendType actionBy
export function suspendUserAPI(env: number, userId: number, suspendType: string, actionBy: string) {
  return API.post(getEnvApi(env), `/admin/reports/suspend_user/all`, {
      body: {
          "userId": userId,
          "suspendType": suspendType,
          "actionBy": actionBy
      }
  });
}

export function ignorePostReportAPI(env: number, postId: number, postType: string, actionBy: string) {
    return API.post(getEnvApi(env), `/admin/reports/ignore_post_report/all`,  {
        body: {
            "postId": postId,
            "postType": postType,
            "actionBy": actionBy
        }
    });
}

export function deleteReportedPostAPI(env: number, postId: number, postType: string, actionBy: string) {
    return API.post(getEnvApi(env), `/admin/reports/delete_report/all`, {
        body: {
            "postId": postId,
            "postType": postType,
            "actionBy": actionBy
        }
    });
}

export function warnUserAPI(
  env: number,
  postId: number,
  postType: string,
  actionBy: string,
  warnType: string,
  userId: number
  ) {
    return API.post(getEnvApi(env), `/admin/reports/send_warning/all`, {
        body: {
            "postId": postId,
            "postType": postType,
            "actionBy": actionBy,
            "warnType": warnType,
            "userId": userId
        }
    });
}

export function getUserReportListAPI(env: number) {
  return API.post(getEnvApi(env), '/admin/reports/user_report_list/all', {
    body: {

    }
  });
}

export function getUserReportDetailsAPI(env: number, userId: number) {
  return API.post(getEnvApi(env), '/admin/reports/reported_user_details/all', {
    body: {
      "userId": userId
    }
  });
}

