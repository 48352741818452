export const keyword = '@@FB';

export const ADD_FB_COUNT = keyword + '_COUNT';
export const ADD_FB_TRASH_COUNT = keyword + '_TSH_COUNT';

export function AddFBCountAction(payload: Array<any>) {
  let count = new Array(4).fill(0);
  let charts = Array(4).fill(undefined).map(() => Array(4).fill(0))
  let tagCharts = Array(4).fill(undefined).map(() => Array(4).fill(0));

  for (let el of payload) {
    count[0] = count[0] + el.number;
    count[el.src] = count[el.src] + el.number;
    charts[0][el.status] = charts[0][el.status] + el.number;
    charts[el.src][el.status] = charts[el.src][el.status] + el.number;
    tagCharts[0][el.tag] = tagCharts[0][el.tag] + el.number;
    tagCharts[el.src][el.tag] = tagCharts[el.src][el.tag] + el.number;
  }
  return {
    type: ADD_FB_COUNT,
    payload: {
      count: count[0],
      inC: count[1],
      playC: count[2],
      appC: count[3],
      charts,
      tagCharts
    }
  }
}

export function AddTrashedAction(payload: Array<any>) {
  return {
    type: ADD_FB_TRASH_COUNT,
    payload: {
      trash: payload[0].number
    }
  }
}
