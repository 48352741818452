import API from '@aws-amplify/api';
import { getEnvApi } from '../utils';

export function getFBListAPI(env: number, src: number, tag: number, status: number, from?: string, to?: string, deleted = false) {
  return API.post(getEnvApi(env), `/user/feedbacks/ls/all`, {
    body: {
      src, tag, status, from, to, deleted
    }
  });
}

export function getInAppFeedback(env: number, src: number, tag: number, status: number, from?: string, to?: string, deleted = false) {
  return API.post(getEnvApi(env), `/user/feedbacks/inapp/all`, {
    body: {
      src, tag, status, from, to, deleted
    }
  });
}

export function getPlayStoreAPI() {
  return API.post(getEnvApi(0), `/user/feedbacks/playstore/all`, {
    body: {

    }
  });
}

export function getAppStoreAPI() {
  return API.post(getEnvApi(0), `/user/feedbacks/appstore/all`, {
    body: {

    }
  });
}

export function getFBbyIdAPI(fbid: string | number, env: number) {
  return API.post(getEnvApi(env), `/user/feedbacks/get/${fbid}`, {
    body: {

    }
  });
}

export function getFBRatingsAPI(env: number) {
  return API.post(getEnvApi(env) , `/user/feedbacks/rate/rate`, {
    body: {

    }
  });
}

export function deleteFBbyIdAPI(env: number, fbid?: string | number) {
  if (!fbid) return;
  return API.post(getEnvApi(env) , `/admin/feedbacks/rm/${fbid}`, {
    body: {

    }
  });
}

export function undeleteFBbyIdAPI(env: number, fbid?: string | number) {
  if (!fbid) return;
  return API.post(getEnvApi(env), `/admin/feedbacks/recover/${fbid}`, {
    body: {

    }
  });
}

export function updateFBStatusAPI(fbid: string | number, status: string | number, env: number) {
  return API.post(getEnvApi(env), `/admin/feedbacks/upd/${fbid}`, {
    body: {
      status
    }
  });
}

export function sendFBbyIdAPI(fbid: string | number, to: string, sub: string, body: string, env: number) {
  return API.post(getEnvApi(env) , `/admin/feedbacks/send/${fbid}`, {
    body: {
      to, sub, body
    }
  });
}
