import {useAccounts} from "../accounts/context";
import {useEffect, useState} from "react";
import {ICampaignType, isValidEmail} from "../../utils";
import {generalCoinRequestAPI, getCampaignsByEmailAPI, requestCoinsAPI} from "../../api/rewards";
import toast from "react-hot-toast";
import {CommunityTableContainer, TableHeaders} from "../communities/engaging";
import {Modal} from "flowbite-react";


const RequestCoins = () => {

    const [emptyCampaigns, setEmptyCampaigns] = useState(false);
    const [campaignsList, setCampaignsList] = useState<Array<ICampaignType>>([]);
    const [showRequestModal, setShowRequestModal] = useState(false);
    const [coinReason, setCoinReason] = useState("");
    const [coinDescription, setCoinDescription] = useState("");
    const [requiredCoins, setRequiredCoins] = useState(0);

    const {
        state: {curEnv, user}
    } = useAccounts();

    useEffect(() => {
        const getCampaignList = () => {
            if (isValidEmail(user?.email)) {
                getCampaignsByEmailAPI(curEnv, user?.email)
                    .then((campaigns: any) => {
                        if (campaigns !== undefined && campaigns.length > 0 ) {
                            toast.success("Found few campaigns for the user");
                            setEmptyCampaigns(false);
                            setCampaignsList([]);
                            campaigns.forEach((campaign: any) => {
                                const tempCampaign = {} as ICampaignType;
                                tempCampaign.campaignDescription = campaign["campaigndescription"];
                                tempCampaign.campaignId = campaign["campaignid"];
                                tempCampaign.campaignName = campaign["campaignname"];
                                tempCampaign.campaignType = campaign["campaingtype"];
                                tempCampaign.created = campaign["created"];
                                tempCampaign.endDate = campaign["expirydate"];
                                tempCampaign.status = campaign["status"];
                                // using the reward coin property to allocate the coins (a jugad)
                                tempCampaign.rewardCoin = 0;
                                setCampaignsList(oldData => [...oldData, tempCampaign]);
                            });

                        } else {
                            setEmptyCampaigns(true);
                        }
                    }).catch(err => {
                        console.log(err);
                        if (err !== undefined && err.error_code !== undefined && err.error_code === "NOT_FOUND") {
                            toast.error("No campaigns found for the user");
                        } else {
                            toast.error("Error while getting the lsit of campaigns for the current user");
                        }
                        
                    })
            } else {
                toast.error("The current user has invalid email id, cannot find associated campaigns for the current user");
            }
        }
        getCampaignList();
    }, [curEnv]);

    const handleCoinAmountChange = (e: any, campaign: ICampaignType) => {
        e.preventDefault();
        if (Number(e.target.value) > 0) {
            campaign.rewardCoin = Number(e.target.value);
        } else if (Number(e.target.value) < 0) {
            e.target.value = Number(e.target.value) * -1;
            campaign.rewardCoin = Number(e.target.value);
        }
    }

    const requestCoins = (e: any, campaign: ICampaignType) => {
        e.preventDefault();
        if (campaign.rewardCoin > 0) {
//            campaignId: number,
//            campaignName: string,
//            campaignDescription: string,
//            campaignDate: string,
//            requiredCoins: number,
//            requestedBy: string,
//            message: string
//            requestCoinsAPI(
//                    curEnv,
//                    campaign.campaignId,
//                    campaign.campaignName,
//                    campaign.campaignDescription,
//                    campaign.created.split('T')[0],
//                    campaign.rewardCoin,
//                    user?.email,
//                    ""
//                ).then(data => {
//                    toast.success("Successfully made request for the coins");
//                }).catch(err => {
//                    toast.error("Error while making requests for coins");
//                    console.log(err);
//                });
        }
    }

    const fillTable = () => {
        return campaignsList.map((campaign, index) => {
            return <tbody key={index}>
                <tr className="border-b">
                    <td className="px-6 py-2 text-sm font-light text-gray-900">
                        {campaign.campaignName}
                    </td>
                    <td className="px-6 py-2 text-sm font-light text-gray-900">
                        {campaign.campaignDescription}
                    </td>
                    <td className="px-6 py-2 text-sm font-light text-gray-900">
                        {campaign.campaignType === 1 ? "Invites Campaign" : "Community Campaign"}
                    </td>
                    <td className="px-6 py-2 text-sm font-light text-gray-900">
                        {campaign.status === 0 ? "Inactive" : "Active"}
                    </td>
                    <td className="px-6 py-2 text-sm font-light text-gray-900">
                        {campaign.created.split("T")[0]}
                    </td>
                    <td className="px-6 py-2 text-sm font-light text-gray-900">
                        {campaign.endDate.split('T')[0]}
                    </td>
                    <td className="px-6 py-2 text-sm font-light text-gray-900 whitespace-nowrap">
                        <span>
                            <input
                                className="w-1/2"
                                type="number"
                                placeholder="Enter coins"
                                onChange={e => handleCoinAmountChange(e, campaign)}
                            />
                            <button
                                className="bg-blue-500 ml-4 text-white active:bg-blue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button"
                                onClick={e => requestCoins(e, campaign)}
                                >
                                Request Coins
                            </button>
                        </span>
                    </td>

                </tr>
            </tbody>
        });
    }

    const newCoinRequest = (e: any) => {
        e.preventDefault();
        setShowRequestModal(true);
    }

    const makeCoinRequest = (e: any) => {
        e.preventDefault();
//        requestFrom: string,
//        requestName: string,
//        message: string,
//        amount: number
        generalCoinRequestAPI(
                1,
                user?.email ? user?.email : "feedback@investmates.io",
                coinReason,
                coinDescription,
                requiredCoins
        ).then(data => {
            toast.success("Coin request made successfully");
        }).catch(err => {
            console.log(err);
            toast.error("Errror while making the general coin request");
        }).finally(() => {
            setShowRequestModal(false);
        });

    }

    return (
            <div className="m-8">
                <div className="mb-2">
                    <button
                        className="bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 border border-blue-700 rounded"
                        onClick={e => newCoinRequest(e)}
                        >
                        + New Request
                    </button>
                </div>
                {/* Visible when there are no campaings for the current user  */}
                <div className={`flex ${!emptyCampaigns ? 'hidden' : ''}`}>
                    <div className="bg-teal-100 w-3/4 m-8 border-t-4 border-teal-500 rounded-b text-teal-900 px-4 py-3 shadow-md" role="alert">
                        <div className="flex">
                            <div className="py-1"><svg className="fill-current h-6 w-6 text-teal-500 mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"/></svg></div>
                            <div>
                                <p className="font-bold">Sorry, No Campaigns :(</p>
                                <p className="text-sm">We couldn't find the associated campaigns for the email <b>{user?.email}</b></p>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Table showing the list of campaigns for the current user */}
                <div className={`${emptyCampaigns ? 'hidden' : ''} p-2 border-2 rounded`}>
                    <h4 className="font-bold text-center">List of your campaigns</h4>
                    <CommunityTableContainer>
                        <TableHeaders cols={[
                            "Campaign Name",
                            "Campaign Description",
                            "Campaign Type",
                            "Status",
                            "Date Created",
                            "End Date"
                        ]} />
                        {
                            fillTable()
                        }
                    </CommunityTableContainer>
                </div>

                <Modal
                    show={showRequestModal}
                    size="md"
                    popup={true}
                    onClose={() => setShowRequestModal(false)}
                >
                    <Modal.Header>
                        New Coin Request
                    </Modal.Header>
                    <Modal.Body>
                        <table>
                            <tr>
                                <td>Requesting For:</td>
                                <td>
                                    <input
                                        type="text"
                                        value={coinReason}
                                        onChange={e => setCoinReason(e.target.value)}
                                        placeholder="Requesting For" />
                                </td>
                            </tr>
                            <tr>
                                <td>Description:</td>
                                <td>
                                    <textarea
                                        rows={4}
                                        value={coinDescription}
                                        onChange={e => setCoinDescription(e.target.value)}
                                        placeholder="Description"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>Required Coins:</td>
                                <td>
                                    <input
                                        type="number"
                                        value={requiredCoins}
                                        onChange={e => setRequiredCoins(Number(e.target.value))}
                                        placeholder="Required Coins" />
                                </td>
                            </tr>
                            <tr className="mt-2">
                                <td>
                                    <button
                                        className="mt-2 text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900"
                                        onClick={e => makeCoinRequest(e)}
                                        >
                                        Make Request
                                    </button>
                                </td>
                                <td>
                                    <button
                                        className="mt-2 ml-2 text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900"
                                        onClick={() => setShowRequestModal(false)}>Cancel</button>
                                </td>
                            </tr>
                        </table>
                    </Modal.Body>
                </Modal>
            </div>
    );
}

export default RequestCoins;