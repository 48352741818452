import { faBackspace, faBackwardStep, faLeftLong, faLeftRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import {useNavigate, useParams} from "react-router-dom";
import { getUserReportDetailsAPI, suspendUserAPI, warnUserAPI } from "../../api/report";
import { IReporterdetails, isAdminUser, isValidEmail, IUserReportDetails, WRN_UR } from "../../utils";
import { useAccounts } from "../accounts/context";

const UserReportDetails = () => {
    
    const {
        state: {curEnv, user}
    } = useAccounts();
    const {id} = useParams();
    const [reportDetails, setReportDetails] = useState({} as IUserReportDetails);
    const [reporterList, setReporterList] = useState<Array<IReporterdetails>>([]);
    const [isAdmin, setIsAdmin] = useState(false);
    const [toggleDropdown, setToggleDropdown] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const onLoad = () => {
            isAdminUser()
                .then(status => {
                    if (status === true) {
                        setIsAdmin(true);
                    } else {
                        setIsAdmin(false);
                    }
                }).catch(err => {
                    setIsAdmin(false);
                    console.log(err);
                });            
        }

        onLoad();
    });

    useEffect(() => {
        const onLoad = () => {
            if (id !== undefined) {
                // TODO: make it for all env
                getUserReportDetailsAPI(curEnv, Number(id))
                    .then(result => {                        
                        const data = result[0];
                        const reporterData = result[1];
                        // console.log(result);
                        if (reporterData !== undefined && reporterData.length > 0) {
                            setReporterList([]);
                            reporterData.forEach((item: any) => {
                                const tempReporter = {} as IReporterdetails;                                
                                tempReporter.userId = item["userid"];
                                tempReporter.name = item["reporter_name"];

                                setReporterList(oldData => [...oldData, tempReporter]);
                            });
                        }
                        const tempObj = {} as IUserReportDetails;
                        // General Details
                        tempObj.userId = data[0]["userid"];
                        tempObj.name = data[0]["user_name"];
                        tempObj.contactNo = data[0]["contactno"];
                        tempObj.created = data[0]["join_date"];
                        tempObj.status = data[0]["status"];

                        // Activity Details
                        tempObj.lastActiveDate = data[0]["last_active_date"];
                        tempObj.lastPostCreated = data[0]["last_post_date"];
                        tempObj.lastActiveCommunityDate = data[0]["last_active_community_date"];
                        tempObj.lastActiveCommunityId = data[0]["last_seen_community_id"];
                        tempObj.lastActiveCommunityName = data[0]["last_seen_community_name"];
                        tempObj.paidCommunitiesOwned = data[0]["paid_communities_owned"];
                        tempObj.communitiesJoined = data[0]["communities_joined"];
                        tempObj.communitiesOwned = data[0]["communities_owned"];

                        // paid channel details
                        tempObj.totalPaidChannelsJoined = data[0]["channels_joined"];
                        tempObj.totalPaidChannelsOwned = data[0]["channels_owned"];
                        tempObj.totalPaidChannelMembers = data[0]["channel_members"];

                        // Reward Details
                        tempObj.balance = data[0]["balance"];                        
                        tempObj.communityReward = data[0]["community_reward"];                                        
                        tempObj.joiningReward = data[0]["joining_reward"];
                        tempObj.redeemCoins = data[0]["redeem_coins"];
                        tempObj.referralReward = data[0]["referral_reward"];
                        tempObj.rewardsEarned = data[0]["total_rewards_earned"];
                        tempObj.trendingUserReward = data[0]["user_reward"];
                                                
                        // report details                                                                        
                        tempObj.postReportsCount = data[0]["total_post_report"];                        
                        tempObj.totalSuspensions = data[0]["total_suspensions"];
                        tempObj.totalWarnings = data[0]["total_warnings"];                        
                        tempObj.userReportsCount = data[0]["user_report_count"];
                        tempObj.lastReportedDate = data[0]["last_reported_date"];
                        
                        setReportDetails(tempObj);                        
                    }).catch(err => {
                        console.log(err);
                        setReportDetails({} as IUserReportDetails);
                    });
            } else {
                toast.error("Invalid User Id");
            }
        }

        onLoad();
    }, [curEnv, id]);

    const sendWarning = (e: any) => {
        e.preventDefault();
        setToggleDropdown(false);
        // TODO: Make it available for all the environments
        warnUserAPI(
            curEnv,
            0,
            "",
            isValidEmail(user?.email) ? user?.email : "feedback@investmates.io",
            WRN_UR,
            Number(id)
        ).then(res => {
            toast.success("Warning sent to the user");
        }).catch(err => {
            toast.error("Error sending the warning to the user");
            console.log(err);
        });
    }

    const suspendUser = (e: any, suspendType: string) => {
        e.preventDefault();
        
        setToggleDropdown(false);
        if (user && user.email !== undefined) {
            let email = user?.email;
            if (email === undefined || email === "") {
                email = "feedback@investmates.io";            
            }
            // TODO: make it available for all env
            suspendUserAPI(
                curEnv,
                Number(id), 
                suspendType,
                isValidEmail(email) ? email : "feedback@investmates.io"
            ).then(res => {
                toast.success("User suspended");
            }).catch(err => {
                console.log(err);
                toast.error("Error suspending user");
            }).finally(() =>{
                setToggleDropdown(false);
            });
        }
    }

    const handleToggleClick = (e: any) => {
        e.preventDefault();
        setToggleDropdown(!toggleDropdown);
        setTimeout(() => {
            setToggleDropdown(false);
        }, 5000);
    }

    const goBack = (e: any) => {
        e.preventDefault();
        navigate("/dashboard/report?selectedindex=1");
    }

    return (
        <div className="mt-4 ml-4 mr-4">            
            <div className="flex flex-col">
                <div className="flex flex-row">
                    <span className="pr-4">
                        <a href="" onClick={e => goBack(e)}>
                            <FontAwesomeIcon 
                                icon={faLeftLong}
                                size="lg"                          
                                className="mt-[50%] mb-[50%]"
                            />
                        </a>
                    </span>                    
                    <span>
                        <h2 className="text-3xl font-bold mb-4">Reported User Details</h2>                    
                    </span>
                    
                </div>
                <div>
                            {
                                isAdmin ?
                                <>
                                    <button
                                        id="dropdownDefault"
                                        data-dropdown-toggle="dropdown"
                                        onClick={(e) => handleToggleClick(e)}
                                        className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" type="button">
                                        Actions
                                        <svg className="ml-2 w-4 h-4" aria-hidden="true" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
                                        </svg>
                                    </button>

                                    <div
                                        id="dropdown"
                                        className={`${toggleDropdown ? '' : 'hidden'} absolute z-10 w-44 bg-white rounded divide-y divide-gray-100 shadow dark:bg-gray-700`}>
                                        <ul className="py-1 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownDefault">
                                            <li>
                                                <a
                                                    href=""                                            
                                                    className="block py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                                                    >Ignore</a>
                                            </li>                                    
                                            <li>
                                                <a
                                                    href=""                                            
                                                    className="block py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                                                    onClick={e => sendWarning(e)}
                                                    >Send Warning</a>
                                            </li>
                                            <li>
                                                <a
                                                    href=""
                                                    onClick={(e) => suspendUser(e, "TEMP")}
                                                    className="block py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                                                    >Suspend User - Temporary</a>
                                            </li>
                                            <li>
                                                <a
                                                    href=""
                                                    onClick={(e) => suspendUser(e, "PERM")}
                                                    className="block py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                                                    >Suspend User - Permanent</a>
                                            </li>
                                        </ul>
                                    </div>  
                                </>                                 

                                : ''
                            }
                        </div>
                <div>                    
                </div>
                <div className="mt-2">
                    <span><b>Reported By:&nbsp;</b></span>
                    {
                        reporterList.map((reporter, index) => {
                            return (
                                <span key={index}>
                                    {reporter.name}
                                    {index === reporterList.length -1 ? '' : ','}
                                </span>
                            )
                        })
                    }
                </div>
                <div>
                    <h3 className="text-2xl font-bold mb-8 mt-2">
                        General Details
                    </h3>
                </div>
                <div className="flex flex-row">
                    <div className="basis-1/3">
                        <span className="font-normal text-md"><b>User Id:</b></span>&nbsp;
                        <span className="text-xl">
                            {reportDetails.userId}
                        </span>
                    </div>
                    <div className="basis-1/3">
                        <span className="font-normal text-md"><b>Name:</b></span>&nbsp;
                        <span className="text-xl">{reportDetails.name}</span>
                    </div>
                    <div className="basis-1/3">
                    <span className="font-normal text-md"><b>Contact:</b></span>&nbsp;
                    <span className="text-xl">{reportDetails.contactNo}</span>
                    </div>
                </div>
                <div className="flex flex-row">
                    <div className="basis-1/3">
                        <span className="font-normal text-md"><b>Join Date:</b></span>&nbsp;
                        <span className="text-xl">
                            {
                                (reportDetails.created === undefined || reportDetails.created === "" || reportDetails.created === null)
                                ? ""
                                : reportDetails.created.split("T")[0]
                            }
                        </span>
                    </div>
                    <div className="basis-1/3">
                        <span className="font-normal text-md"><b>Status:</b></span>&nbsp;
                        <span className="text-xl">
                            {
                                reportDetails.status
                            }
                        </span>
                    </div>                    
                </div>

                <div className="mt-2">
                    <hr />
                </div>                            

                <div>
                    <h3 className="text-2xl font-bold mt-4 mb-8">
                        Activity Details
                    </h3>
                </div>
                <div className="flex flex-row">
                    <div className="basis-1/3">
                        <span className="font-normal text-md"><b>Last Active Date:</b></span>&nbsp;
                        <span className="text-xl">
                            {
                                (reportDetails.lastActiveDate === undefined || reportDetails.lastActiveDate === "" || reportDetails.lastActiveDate === null)
                                ? ""
                                : reportDetails.lastActiveDate.split("T")[0]
                            }
                        </span>
                    </div>
                    <div className="basis-1/3">
                        <span className="font-normal text-md"><b>Last Post created:</b></span>&nbsp;
                        <span className="text-xl">
                            {
                                (reportDetails.lastPostCreated === undefined || reportDetails.lastPostCreated === "" || reportDetails.lastPostCreated === null)
                                ? ""
                                : reportDetails.lastPostCreated.split("T")[0]
                            }
                        </span>
                    </div>
                    <div className="basis-1/3">
                        <span className="font-normal text-md"><b>Last Active Community Date:</b></span>&nbsp;
                        <span className="text-xl">
                            {
                                (reportDetails.lastActiveCommunityDate === undefined || reportDetails.lastActiveCommunityDate === "" || reportDetails.lastActiveCommunityDate === null)
                                ? ""
                                : reportDetails.lastActiveCommunityDate.split("T")[0]
                            }
                        </span>
                    </div>
                </div>
                <div className="flex flex-row">
                    <div className="basis-1/3">
                        <span className="font-normal text-md"><b>Last Active Community Name:</b></span>&nbsp;
                        <span className="text-xl">{reportDetails.lastActiveCommunityName}</span>
                    </div>
                    <div className="basis-1/3">
                        <span className="font-normal text-md"><b>Communities Owned:</b></span>&nbsp;
                        <span className="text-xl">{reportDetails.communitiesOwned}</span>
                    </div>
                    <div className="basis-1/3">
                        <span className="font-normal text-md"><b>Paid Communities Owned:</b></span>&nbsp;
                        <span className="text-xl">{reportDetails.paidCommunitiesOwned}</span>
                    </div>                    
                </div>
                <div className="flex flex-row">
                    <div className="basis-1/3">
                        <span className="font-normal text-md"><b>Communities Joined:</b></span>&nbsp;
                        <span className="text-xl">{reportDetails.communitiesJoined}</span>
                    </div>
                    <div className="basis-1/3">
                        <span className="font-normal text-md"><b>Paid Channels Joined:</b></span>&nbsp;
                        <span className="text-xl">{reportDetails.totalPaidChannelsJoined}</span>
                    </div>
                    <div className="basis-1/3">
                        <span className="font-normal text-md"><b>Paid Channels Owned:</b></span>&nbsp;
                        <span className="text-xl">{reportDetails.totalPaidChannelsOwned}</span>
                    </div>                                  
                </div>
                <div className="flex flex-row">
                    <div className="basis-1/3">
                        <span className="font-normal text-md"><b>Paid Channel Members:</b></span>&nbsp;
                        <span className="text-xl">{reportDetails.totalPaidChannelMembers}</span>
                    </div>                                                     
                </div>

                <div className="mt-2">
                    <hr />
                </div>   
                <div>
                    <h3 className="text-2xl font-bold mt-4 mb-8">
                        Reward Details
                    </h3>
                </div>
                <div className="flex flex-row">
                    <div className="basis-1/3">
                        <span className="font-normal text-md"><b>Wallet Balance:</b></span>&nbsp;
                        <span className="text-xl">{reportDetails.balance}</span>
                    </div>
                    <div className="basis-1/3">
                        <span className="font-normal text-md"><b>Community Reward:</b></span>&nbsp;
                        <span className="text-xl">{reportDetails.communityReward}</span>
                    </div>
                    <div className="basis-1/3">
                        <span className="font-normal text-md"><b>Joining reward:</b></span>&nbsp;
                        <span className="text-xl">{reportDetails.joiningReward}</span>
                    </div>
                </div>
                <div className="flex flex-row">
                    <div className="basis-1/3">
                        <span className="font-normal text-md"><b>Coins Redeemed:</b></span>&nbsp;
                        <span className="text-xl">{reportDetails.redeemCoins}</span>
                    </div>
                    <div className="basis-1/3">
                        <span className="font-normal text-md"><b>Referral Reward:</b></span>&nbsp;
                        <span className="text-xl">{reportDetails.referralReward}</span>
                    </div>
                    <div className="basis-1/3">
                        <span className="font-normal text-md"><b>Total Reward Earned:</b></span>&nbsp;
                        <span className="text-xl">{reportDetails.rewardsEarned}</span>
                    </div>
                </div>
                <div className="flex flex-row">
                    <div className="basis-1/3">
                        <span className="font-normal text-md"><b>Trending User Reward:</b></span>&nbsp;
                        <span className="text-xl">{reportDetails.trendingUserReward}</span>
                    </div>                    
                </div>
                <div className="mt-2">
                    <hr />
                </div>                
                <div>
                    <h3 className="text-2xl font-bold mt-4 mb-8">
                        Report Details
                    </h3>
                </div>
                <div className="flex flex-row">
                    <div className="basis-1/3">
                        <span className="font-normal text-md"><b>Total Post Reports:</b></span>&nbsp;
                        <span className="text-xl">{reportDetails.postReportsCount}</span>
                    </div>
                    <div className="basis-1/3">
                        <span className="font-normal text-md"><b>Total Suspensions:</b></span>&nbsp;
                        <span className="text-xl">{reportDetails.totalSuspensions}</span>
                    </div>
                    <div className="basis-1/3">
                        <span className="font-normal text-md"><b>Total Warnings:</b></span>&nbsp;
                        <span className="text-xl">{reportDetails.totalWarnings}</span>
                    </div>             
                </div>
                <div className="flex flex-row mb-4">
                    <div className="basis-1/3">
                        <span className="font-normal text-md"><b>Total User Reports:</b></span>&nbsp;
                        <span className="text-xl">{reportDetails.userReportsCount}</span>
                    </div> 
                    <div className="basis-1/3">
                        <span className="font-normal text-md"><b>Last Reported Date:</b></span>&nbsp;
                        <span className="text-xl">
                            {
                                (reportDetails.lastReportedDate === undefined || reportDetails.lastReportedDate === "" || reportDetails.lastReportedDate === null)
                                ? "NA"
                                : reportDetails.lastReportedDate.split("T")[0]
                            }
                        </span>
                    </div>                               
                </div>
            </div>
            {/* <div className="flex flex-col">
                    <div>
                        <select 
                            className="rounded-full"                     
                            >
                                <option>Action</option>
                                <option>Ignore</option>
                                <option>Suspend</option>
                        </select>
                    </div>
                    <div>
                        <table className="mt-4">
                            <tr>
                                <td className="p-4 bg-[#F3F3F3] font-medium">Username</td>
                                <td className="p-4 whitespace-nowrap">{reportDetails.name}</td>
                                <td className="p-4 bg-[#F3F3F3]	font-medium">Phone Number</td>
                                <td className="p-4">{reportDetails.contactNo}</td>
                                <td className="p-4 bg-[#F3F3F3]	font-medium">Number of paid members in users community</td>
                                <td className="p-4">12</td>
                            </tr>
                            <tr>
                                <td className="p-4 bg-[#F3F3F3]	font-medium">User Id</td>
                                <td className="p-4">{reportDetails.userId}</td>
                                <td className="p-4 bg-[#F3F3F3]	font-medium">Acquisation date</td>
                                <td className="p-4">
                                    {
                                        reportDetails.created !== undefined
                                        ? reportDetails.created.split('T')[0]
                                        : ""
                                    }
                                </td>
                                <td className="p-4 bg-[#F3F3F3]	font-medium">Number of paid channels owned</td>
                                <td className="p-4">1</td>
                            </tr>
                            <tr>
                                <td className="p-4 bg-[#F3F3F3]	font-medium">Status</td>
                                <td className="p-4">{reportDetails.status}</td>
                                <td className="p-4 bg-[#F3F3F3]	font-medium">Last active date in app</td>
                                <td className="p-4">
                                    {   
                                        reportDetails.lastActiveDate !== undefined 
                                        ? reportDetails.lastActiveDate.split('T')[0]
                                        : ""
                                    }
                                </td>
                                <td className="p-4 bg-[#F3F3F3]	font-medium">Last home feed posted</td>
                                <td className="p-4">
                                    {
                                        reportDetails.lastPostCreated !== undefined
                                        ? reportDetails.lastPostCreated.split('T')[0]
                                        : ""
                                    }
                                </td>
                            </tr>
                            <tr>
                                <td className="p-4 bg-[#F3F3F3]	font-medium">Number of User reports</td>
                                <td className="p-4">{reportDetails.userReportsCount}</td>
                                <td className="p-4 bg-[#F3F3F3]	font-medium">Number of communities joined</td>
                                <td className="p-4">{reportDetails.communitiesJoined}</td>
                                <td className="p-4 bg-[#F3F3F3]	font-medium">Last active date in community</td>
                                <td className="p-4">
                                    {
                                        reportDetails.lastActiveCommunityDate !== undefined
                                        ? reportDetails.lastActiveCommunityDate.split('T')[0]
                                        : ""
                                    }
                                </td>
                            </tr>
                            <tr>
                                <td className="p-4 bg-[#F3F3F3]	font-medium">Number of warnings</td>
                                <td className="p-4">{reportDetails.totalWarnings}</td>
                                <td className="p-4 bg-[#F3F3F3]	font-medium">Number of paid channels joined</td>
                                <td className="p-4">{reportDetails.totalPaidCommunitiesJoined}</td>
                                <td className="p-4 bg-[#F3F3F3] font-medium">Wallet balance</td>
                                <td className="p-4">{reportDetails.balance}</td>
                            </tr>
                            <tr>
                                <td className="p-4 bg-[#F3F3F3]	font-medium">Number of times suspended</td>
                                <td className="p-4">{reportDetails.totalSuspensions}</td>
                            </tr>
                        </table>
                    </div>
            </div>          */}
        </div>
    );
}

export default UserReportDetails;