import React, { lazy, useEffect, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import { RequireAuth } from '../components/layout/AuthenticatedRoute';
import { CheckAuth } from '../components/layout/UnauthenticatedRoute';
import NotFound from '../pages/notFound';
import CampaignDetails from '../pages/rewards/campaignDetails';
import CreateCampaign from '../pages/rewards/createCampaign';
import EditCampaign from '../pages/rewards/editCampaign';
import CampaignList from '../pages/rewards/viewCampaignList';
import RewardManagement from "../pages/rewards/rewardManagement";
import RequestCoins from "../components/rewards/RequestCoins";
import Contest from "../pages/dashboard/contest";
import Cohorts from "../pages/cohorts";
import Report from "../pages/report";
import ReportDetails from "../components/report/reportDetails";
import Incentivize from '../pages/incentivize';
import UserReportDetails from '../components/report/userReportDetails';
import { isAdminUser } from '../utils';
import Create from '../pages/create/createIndex';
import ExploreIndex from '../pages/explore/exploreIndex';
import Admin from '../pages/admin';

// Public components
const Login = lazy(() => import('../pages/login'));
const Signup = lazy(() => import('../pages/signup'));
const Logout = lazy(() => import('../pages/logout'));
const Forgot = lazy(() => import('../pages/forgot'));

// Public & Private components
const Home = lazy(() => import('../pages/home'));

// Private components
const DashboardHome = lazy(() => import('../pages/dashboard/home'));
const Community = lazy(() => import('../pages/dashboard/community'));
const FeedbackLayout = lazy(() => import('../pages/dashboard/feedback'));
const ReportLayout = lazy(() => import('../pages/dashboard/report'));
const Rewards = lazy(() => import('../pages/dashboard/rewards'));
const Dashboard = lazy(() => import('../pages/dashboard'));
const Users = lazy(() => import('../pages/dashboard/users'));
const Posts = lazy(() => import('../pages/dashboard/posts'));

// Public Routes
export const pubLinksMap = new Map([
  ['Login', { path: '/login', src: Login }],
  ['Signup', { path: '/signup/*', src: Signup }],
  ['Forgot', { path: '/forgot', src: Forgot }],
]);
// Mixed Routes
// Private Routes
export const pvtLinksMap = new Map([
  ['Dashboard', { path: '/dashboard/*', src: Dashboard }],
]);


const RootRoutes = () => {
  const [isAdmin, setIsAdmin] = useState(false);
  useEffect(() => {
    const onLoad = () => {
      isAdminUser()
        .then(status => {
            if (status === true) {
                setIsAdmin(true);
            } else {
                setIsAdmin(false);
            }
        }).catch(err => {
            setIsAdmin(false);
            console.log(err);
        });        
    }

    onLoad();
  });

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/logout" element={<Logout />} />
      {[...pubLinksMap].map(([k, v]) => (
        <Route
          key={k}
          path={v.path}
          element={
            <CheckAuth>
              <v.src />
            </CheckAuth>
          }
        />
      ))}
      <Route path="dashboard" element={<RequireAuth><Dashboard /></RequireAuth>}>
        <Route index element={<DashboardHome />} />
        <Route path={'users'} element={<Users />}></Route>
        <Route path={'community'} element={<Community />}></Route>
        <Route path={'feedbacks/*'} element={<FeedbackLayout />}>
        </Route>
        {/* <Route path={'reports/*'} element={<ReportLayout />}>
        </Route> */}
        <Route path={'rewards'} element={<Rewards />}></Route>
        <Route path={'posts'} element={<Posts />}></Route>               
        <Route path={'createcampaign'} element={<CreateCampaign />}></Route>
        <Route path={'allcampaigns'} element={<CampaignList />}></Route>
        <Route path={'campaigndetails/:id'} element={<CampaignDetails />}></Route>
        <Route path={'rewardmanagement'} element={<RewardManagement />}></Route>
        <Route path={'requestcoins'} element={<RequestCoins />}></Route>
        <Route path={'contestmanagement'} element={<Contest />}></Route>
        <Route path={'cohorts'} element={<Cohorts />}></Route>        
        <Route path={'create'} element={<Create />}></Route>
        <Route path={'user_report_details/:id'} element={<UserReportDetails />}></Route>        
        <Route path={'report/details/:id'} element={<ReportDetails />}></Route>
        <Route path={'explore'} element={<ExploreIndex />}></Route>
        {
          // Adding the authorized routes
          isAdmin ?
          <>
            <Route path={'editcampaign/:id'} element={<EditCampaign />} />
            <Route path={'report'} element={<Report />} />
            <Route path={'admin'} element={<Admin />} /> 
          </>
          : <></>
        }
        <Route path={'incentivize'} element={<Incentivize />}></Route>
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default RootRoutes;
