import React, { Suspense, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Hub } from '@aws-amplify/core';
import { Auth } from '@aws-amplify/auth';

import { AccountContext } from '../components/accounts/context';
import Loading from '../components/Loading';
import ErrorBoundary from '../pages/ErrorBoundary';
import { AppStateProvider } from '../redux/context';
import Routes from './Routes';
import 'setimmediate';

const AppLayout = () => {
  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const [curEnv, setCurEnv] = useState(1);
  const [user, setUser] = useState(undefined);
  const navigate = useNavigate();

  useEffect(() => {
    async function onLoad() {
      Hub.listen('auth', async ({ payload: { event } }) => {
        switch (event) {
          case 'signIn':
            // console.log('Hub SignIn');
            setImmediate(() => onSignin());
            break;
          case 'signOut':
            console.log('Hub signout');
            break;
          case 'customOAuthState':
            console.log('Hub Oauth');
        }
      });
    }
    async function onSignin() {
      try {
        const { attributes, signInUserSession } =
          await Auth.currentAuthenticatedUser();
        const { identityId } = await Auth.currentUserCredentials();
        setUser({ ...attributes, identityId });
        userHasAuthenticated(true);
        // dispatch(refreshUserData());
        // await Amplify.PubSub.publish('real-time-hub', { msg: 'Hello to all subscribers!' });
      } catch (e) {
        if (e !== 'No current user') {
          console.error(e);
        }
      }
      setIsAuthenticating(false);
      console.log('dispatch done for user data refresh');
    }
    onLoad();
    onSignin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLogout = useCallback(async () => {
    await Auth.signOut();
    userHasAuthenticated(false);
    setUser(undefined);
    // dispatch(onLogout());
    navigate('/login');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const value = {
    state: { isAuthenticated, isAuthenticating, user, curEnv },
    actions: { handleLogout, userHasAuthenticated, setCurEnv },
  };

  if (isAuthenticating) {
    return <Loading />;
  }

  return (
    <ErrorBoundary>
      <AppStateProvider isLoggedIn={isAuthenticated} currentStage={"DEV"}>
        <AccountContext.Provider value={value}>
          <Suspense fallback={<Loading />}>
            <Routes />
          </Suspense>
        </AccountContext.Provider>
      </AppStateProvider>
    </ErrorBoundary>
  );
}

export default AppLayout;
