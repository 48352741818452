import {S3} from '@aws-sdk/client-s3';

const s3Client = new S3({
    forcePathStyle: false,
    endpoint: "https://sgp1.digitaloceanspaces.com",
    region: "sgp1",    
    credentials: {
        accessKeyId: process.env.REACT_APP_SPACES_KEY,
        secretAccessKey: process.env.REACT_APP_SPACES_SECRET
      }
});

export { s3Client };
