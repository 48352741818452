import API from '@aws-amplify/api';
import { getEnvApi } from '../utils';
import {number} from "yup/lib/locale";

export function getRewardsCount(env: number) {
    return API.post(getEnvApi(env), `/user/rewards/count/all`, {

    });
}

export function getUserRegistrationsPerReferrer(env: number) {
    return API.post(getEnvApi(env), `/user/rewards/reg_per_ref/all`, {

    });
}

// Ending the campaign
export function endCampaignAPI(env: number, campaignId: number) {
    return API.post(getEnvApi(env), `/admin/rewards/end_campaign/all`, {
      body: {
        "campaignId": campaignId
      }
    });
  }

export function getCampaignByIdAPI(env: number, campaignId: number) {
    return API.post(getEnvApi(env), `/user/rewards/get_campaign_by_id/all`, {
        body: {
            "campaignId": campaignId
        }
    });
}

// Updating the campaign API
export function updateCampaignAPI(
    env: number, 
    campaignId: number,
    campaignName: string,
    campaignDesc: string,
    campaignType: number,
    rewardCoins: number,
    status: number,
    endDate: string,
    refreeCoin: number,
    referralCoin: number
    ) {
        return API.post(getEnvApi(env), `/admin/rewards/edit_campaign/all`, {
            body: {
                "campaignId": campaignId,
                "campaignName": campaignName,
                "campaignDesc": campaignDesc,
                "campaignType": campaignType,
                "rewardCoins": rewardCoins,
                "status": status,
                "endDate": endDate,
                "refreeCoin": refreeCoin,
                "referralCoin": referralCoin
            }
        });
}

// getting the all campaign details
export function getAllCampaignDetailsAPI(env: number) {
    return API.post(getEnvApi(env), `/user/rewards/all_campaigns/all`, {
        body: {

        }
    });
}

// creating the campaign
export function createCampaignAPI(
    env: number,
    campaignName: string,
    campaignDescription: string,
    campaignImage: string,
    campaignType: number,
    rewardCoins: number,
    status: number,
    expiryDate: string,
    userId: number,
    refreeCoin: number,
    referralCoin: number
) {
    return API.post(getEnvApi(env), `/admin/rewards/create_campaign/all`, {
        body: {
            "campaignName": campaignName,
            "campaignDesc": campaignDescription,
            "campaignImage": campaignImage,
            "campaignType": campaignType,
            "rewardCoins": rewardCoins,
            "status": status,
            "endDate": expiryDate,
            "userId": userId,
            "refreeCoin": refreeCoin,
            "referralCoin": referralCoin,
        }
    });
}

// get the conversion list by campaign id
export function getConversionListByCampaignId(env: number ,campaignId: number) {
    return API.post(getEnvApi(env), `/user/rewards/conversion_list_by_campaign/all`, {
        body : {
            "campaignId": campaignId
        }
    });
}

// get the conversion count by campaign id
export function getConversionCountByCampaignIdAPI(env: number ,campaignId: number) {
    return API.post(getEnvApi(env), `/user/rewards/conversion_count_by_campaign/all`, {
        body : {
            "campaignId": campaignId
        }
    });
}

// get coins earned by campaign Id
export function getCoinsEarnedByCampaignIdAPI(env: number, campaignId: number) {
    return API.post(getEnvApi(env), `/user/rewards/coins_earned_by_campaign/all`, {
        body : {
            "campaignId": campaignId
        }
    });
}

// get the leaderboard for campaign
export function getCampaignLeaderboardAPI(env: number, campaignId: number) {
    return API.post(getEnvApi(env), `/user/rewards/campaign_leaderboard/all`, {
        body: {
            "campaignId": campaignId
        }
    });
}

// get the user details by email id
export function getUserDetailsByEmailAPI(env: number, email: string) {
    return API.post(getEnvApi(env), `/user/rewards/user_by_email/all`, {
        body: {
            "email": email
        }
    });
}

// Reward Management API's
// for getting the vault balance
export function getVaultBalanceAPI(env: number) {
    return API.post(getEnvApi(env), `/user/rewards/get_vault_balance/all`,  {
        body: {

        }
    });
}

// Refilling the vault
export function refillVaultAPI(env: number, alloactedBy: string, amount: number) {
    return API.post(getEnvApi(env), `/user/rewards/refill_vault/all`,  {
        body: {
            "alloactedBy": alloactedBy,
            "amount": amount
        }
    });
}

// Approving the request
export function approveCoinsRequestAPI(env: number, requestId: number, approvedBy: string) {
    return API.post(getEnvApi(env), `/user/rewards/approve_coin/all`,  {
        body: {
            "requestId": requestId,
            "approvedBy": approvedBy
        }
    });
}

// keeping the coin request on hold
export function holdCoinRequestAPI(env: number, requestId: number) {
    return API.post(getEnvApi(env), `/user/rewards/hold_coin/all`,  {
        body: {
            "requestId": requestId
        }
    });
}

// rejecting the coin request
export function rejectCoinRequestAPI(env: number, requestId: number, responseMessage: string) {
    return API.post(getEnvApi(env), `/user/rewards/reject_coin/all`,  {
        body: {
            "requestId": requestId,
            "responseMessage": responseMessage
        }
    });
}

// making the coin allocation request for campaign
//const campaignId = JSON.parse(event?.body ?? '')["campaignId"];
//const contestId = JSON.parse(event?.body ?? '')["contestId"];
//const campaignName = JSON.parse(event?.body ?? '')["campaignName"];
//const campaignDescription = JSON.parse(event?.body ?? '')["campaignDescription"];
//const campaignDate = JSON.parse(event?.body ?? '')["campaignDate"];
//const expiryDate = JSON.parse(event?.body ?? '')["expiryDate"];
//const requiredCoins = JSON.parse(event?.body ?? '')["requiredCoins"];
//const requestedBy = JSON.parse(event?.body ?? '')["requestedBy"];
//const message = JSON.parse(event?.body ?? '')["message"];

export function requestCoinsAPI(
        env: number,
        campaignId: number,
        contestId: number,
        campaignName: string,
        campaignDescription: string,
        campaignDate: string,
        expiryDate: string,
        requiredCoins: number,
        requestedBy: string,
        message: string
) {
    return API.post(getEnvApi(env), `/user/rewards/request_coins/all`,  {
        body: {
            "campaignId": campaignId,
            "contestId": contestId,
            "campaignName": campaignName,
            "campaignDescription": campaignDescription,
            "campaignDate": campaignDate,
            "expiryDate": expiryDate,
            "requiredCoins": requiredCoins,
            "requestedBy": requestedBy,
            "message": message
        }
    });
}

// getting the history of coin allocation
export function getAllocatedCoinsHistoryAPI(env: number) {
    return API.post(getEnvApi(env), `/user/rewards/coin_allocation_history/all`,  {
        body: {

        }
    });
}

// get the list of active coin requests
export function getActiveCoinRequests(env: number) {
    return API.post(getEnvApi(env), `/user/rewards/coin_request_list/all`, {
        body: {

        }
    });
}

// getting the list of campaigns by user email
export function getCampaignsByEmailAPI(env: number, email: string) {
    return API.post(getEnvApi(env), `/user/rewards/campaigns_by_email/all`, {
        body: {
            "email": email
        }
    });
}

// getting the total coins spent
export function getTotalCoinsSpentAPI(env: number) {
    return API.post(getEnvApi(env), `/user/rewards/total_coins_spent/all`, {
        body: {

        }
    });
}

// get the vault manager auth
export function getVaultManagerAuthAPI(env: number, email: string) {
    return API.post(getEnvApi(env), `/user/rewards/get_vm_auth/all`, {
        body: {
            "email": email
        }
    });
}

// general coin request
//requestFrom: string,
//requestName: string,
//message: string,
//amount: number

//const requestFrom = JSON.parse(event?.body ?? '')["requestFrom"];
//const requestName = JSON.parse(event?.body ?? '')["requestName"];
//const message = JSON.parse(event?.body ?? '')["message"];
//const amount = Number(JSON.parse(event?.body ?? '')["amount"]);
export function generalCoinRequestAPI(
        env: number,
        requestFrom: string,
        requestName: string,
        message: string,
        amount: number
        ) {
    return API.post(getEnvApi(env), '/user/rewards/gen_coin_request/all', {
        body: {
            "requestFrom": requestFrom,
            "requestName": requestName,
            "message": message,
            "amount": amount
        }
    });
}

export function getUserCommunitiesAPI(env: number, userId: number, userHandle: string) {
    return API.post(getEnvApi(env), '/admin/rewards/search_user_communities/all', {   
        body: {
            "userId": userId,
            "userHandle": userHandle
        }     
    });
}

export function addSpecialRewardAPI(
    env: number,    
    userId: number,
    rewardedBy: string,
    rewardCoins: number    
    ) {
        return API.post(getEnvApi(env), '/admin/rewards/add_special_reward/all', {
            body: {                
                "userId": userId,
                "rewardedBy": rewardedBy,
                "rewardCoins": rewardCoins                
            }
        });
}

export function getSpecialRewardsHistoryAPI(env: number) {
    return API.post(getEnvApi(env), '/admin/rewards/special_reward_history/all', {
        body: {
        }
    });
}

// auth token for matesreferrals: eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE2OTUxMTUzNzcsInVzZXJpZCI6MX0.KdoJ7Aa6CUkikwIj1lFfzETVZf3RwpZmCsf6b4acIE4
