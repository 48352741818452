import { Interests } from "../../constants";

export const keyword = '@@COMM';
export const ADD_COM_COUNT = keyword + '_COUNT';
export const ADD_COM_TYPES = keyword + '_TYPES';
export const ADD_NOT_PART_OF_COMM = keyword + '_NOT_PART_OF_COMM';

export function AddCommCountAction(payload: any) {
  let count = 0;
  for (let el of payload) {
    count = count + el.num;
  }
  return {
    type: ADD_COM_COUNT,
    payload: {
      count,
    }
  }
}

export function AddActiveCommAction(payload: any) {
  return {
    type: ADD_COM_COUNT,
    payload: {
      active: payload[0].count
    }
  }
}
export function AddCommChartData(payload: any) {
  let chart = Array(1 + (Object.keys(Interests).length / 2)).fill(0);
  for (let el of payload) {
    chart[el.interestid] = el.num;
  }
  return {
    type: ADD_COM_TYPES,
    payload: {
      chart,
    }
  }
}

export function AddNotPartOfCommunity(payload: any) {
  return {
    type: ADD_NOT_PART_OF_COMM,
    payload: {
      not_part_of_community: payload[0].no_community_members
    }
  }
}

export function AddCommPageStats(dispatch: any, payload: Array<any>) {
  dispatch(AddCommCountAction(payload[0]));
  dispatch(AddCommChartData(payload[1]));
  dispatch(AddActiveCommAction(payload[2]));
  dispatch(AddNotPartOfCommunity(payload[3]));
}
