import CoinAllocationHistory from "../../components/rewards/coinAllocationHistory";
import ActiveCoinRequests from "../../components/rewards/activeCoinRequests";
import AddCoinsToVault from "../../components/rewards/addCoinsToVault";
import VaultBalance from "../../components/rewards/vaultBalance";
import ErrorBoundary from "../ErrorBoundary";
import {PageContainer} from "../dashboard/feedback";
import {EqualWidthContainer} from "../dashboard/community/all";
import CoinsSpent from "../../components/rewards/coinsSpent";
import {useEffect, useState} from "react";
import {useAccounts} from "../../components/accounts/context";
import {isAdminUser, isValidEmail} from "../../utils";
import {getVaultManagerAuthAPI} from "../../api/rewards";
import toast from "react-hot-toast";
import {Tab} from "@headlessui/react";
import clsx from "clsx";
import {AddActiveUsers} from "../../redux/user/actions";
import {Auth} from '@aws-amplify/auth';

const RewardManagement = () => {

    const {
        state: {curEnv, user}
    } = useAccounts();

    const [isVaultManager, setIsVaultManager] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [isAdmin, setIsAdmin] = useState(false);

    useEffect(() => {
        const getVaultManagerAuthentication = () => {
            if (user?.email !== undefined && user?.email !== '' && isValidEmail(user?.email)) {
                getVaultManagerAuthAPI(1, user?.email)
                    .then(data => {
                        if (data !== undefined) {
                            const isAuthenticated = data["auth_status"];
                            console.log("Is Authenticated: ", isAuthenticated);
                            if (typeof isAuthenticated === "boolean") {
                                setIsVaultManager(isAuthenticated);
                            }
                        }
                    }).catch(err => {
                        console.log(err);
                        toast.error("Error while getting the auth status for the vault manager");
                    });
            }
        }

        getVaultManagerAuthentication();
    }, [curEnv]);

    useEffect(() => {
        const onLoad = () => {
            isAdminUser()
                .then(status => {
                    if (status === true) {
                        setIsAdmin(true);
                    } else {
                        setIsAdmin(false);
                    }
                }).catch(err => {
                    setIsAdmin(false);
                    console.log(err);
                });            
        }

        onLoad();
    });

    return (
        <ErrorBoundary>
            <PageContainer>
                <EqualWidthContainer>
                    <div className="flex flex-row" >
                        <VaultBalance />
                        <CoinsSpent />
                        {isVaultManager ? <AddCoinsToVault /> : ''}
                    </div>
                    <Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
                        <Tab.List>
                            <Tab>
                                <TabTitle title="Coin Allocation History" selected={selectedIndex === 0} />
                            </Tab>
                            {
                                isAdmin ?
                                <Tab>
                                    <TabTitle title="Active Coin Requests" selected={selectedIndex === 1} />
                                </Tab> : ''
                            }                            
                        </Tab.List>
                        <Tab.Panels>
                            <Tab.Panel>
                                <CoinAllocationHistory /><br />
                            </Tab.Panel>
                            {
                                isAdmin
                                ? <Tab.Panel>
                                    <ActiveCoinRequests />
                                </Tab.Panel>
                                : ''
                            }
                        </Tab.Panels>
                    </Tab.Group>
                    {/*<br />
                    {isVaultManager ? <ActiveCoinRequests /> : ''}
                    <br />
                    <CoinAllocationHistory />
                    <br /> */}
                </EqualWidthContainer>
                <EqualWidthContainer>

                </EqualWidthContainer>
            </PageContainer>
        </ErrorBoundary>
    );
}

const TabTitle = ({ title, selected }: { title: string; selected: boolean }) => {
    return (
            <div className="flex flex-col items-center m-3">
                <div className={clsx(" text-lg font-bold text-gray-500", {
                    'text-black': selected
                })}>{title}</div>
                {selected ? <div className="w-2 h-2 bg-black rounded-full"></div> : null}
            </div>
            );
}

export default RewardManagement;

// {/*<div>
// <VaultBalance />
// {/* <AddCoinsToVault /> */}
// {/*<ActiveCoinRequests /> */}
// {/*<CoinAllocationHistory /> */}
// /* </div> */}