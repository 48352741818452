import {useEffect, useState} from "react";
import { useAccounts } from "../accounts/context";
import {getAllocatedCoinsHistoryAPI} from "../../api/rewards";
import {IAllocationHistoryType} from "../../utils";
import {CommunityTableContainer, TableHeaders} from "../communities/engaging";

const CoinAllocationHistory = () => {

    const [allocationList, setAllocationList] = useState<Array<IAllocationHistoryType>>([]);

    const {
        state: { curEnv }
    } = useAccounts();

    useEffect(() => {
        const loadData = () => {
            getAllocatedCoinsHistoryAPI(1)
                .then((result: any) => {
                    // console.log(result);
                    if (result !== undefined && result.length > 0) {
                        setAllocationList([]);
                        result.forEach((item: any) => {
                            const historyItem = {} as IAllocationHistoryType;
                            historyItem.allocatedBy = item["allocatedby"];
                            historyItem.allocatedOn = item["allocatedon"];
                            historyItem.amount = item["amount"];
                            historyItem.campaignCreated = item["campaigncreated"];
                            historyItem.campaignDescription = item["campaigndescription"];
                            historyItem.campaignExpiry = item["campaignexpiry"];
                            historyItem.campaignId = item["campaignid"];
                            historyItem.campaignName = item["campaignname"];
                            historyItem.campaignOwner = item["campaignowner"];
                            historyItem.campaignStatus = item["campaignstatus"];
                            historyItem.campaignType = item["campaingtype"];
                            historyItem.ownerId = item["ownerid"];
                            historyItem.transactionId = item["transactionid"];
                            setAllocationList(oldItems => [...oldItems, historyItem]);
                        });
                        console.log("allocation list: ", allocationList);
                    }
                });
        }
        loadData();
    }, [curEnv]);

    const fillTable = () => {
        return allocationList.map((item: any, index: number) => {
            return <tbody key={index} >
                <tr className="border-b">
                    <td className="px-6 py-2 text-sm font-light text-gray-900">
                        {item.campaignName}
                    </td>
                    <td className="px-6 py-2 text-sm font-light text-gray-900">
                        {item.campaignDescription}
                    </td>
                    <td className="px-6 py-2 text-sm font-light text-gray-900">
                        {item.amount}
                    </td>
                    <td className="px-6 py-2 text-sm font-light text-gray-900">
                        {item.allocatedBy}
                    </td>
                    <td className="px-6 py-2 text-sm font-light text-gray-900">
                        {item.campaignExpiry.split('T')[0]}
                    </td>
                    <td className="px-6 py-2 text-sm font-light text-gray-900">
                        {item.campaignStatus === 1 ? 'Active' : 'Inactive'}
                    </td>
                    <td className="px-6 py-2 text-sm font-light text-gray-900">
                        {item.campaignOwner}
                    </td>
                    <td className="px-6 py-2 text-sm font-light text-gray-900">
                        {item.allocatedOn.split('T')[0]}
                    </td>
                </tr>
            </tbody>
        });
    }

    return (
            <div>
                <h4 className="font-bold text-center">Coin Allocation History</h4>
                <CommunityTableContainer>
                    <TableHeaders cols={[
                        "Campaign Name",
                        "Campaign Description",
                        "Amount",
                        "Allocated By",
                        "Campaign Expiry",
                        "Campaign Status",
                        "Campaign Owner",
                        "Allocated On"
                    ]} />
                    {fillTable()}
                </CommunityTableContainer>
            </div>
    );
}

export default CoinAllocationHistory;