import { useEffect, useState } from "react";
import { getSpecialRewardsHistoryAPI } from "../../api/rewards";
import { ISpecialRewardsUser } from "../../utils";
import { useAccounts } from "../accounts/context";

const SpecialRewardsHistory = () => {    

    const [historyList, setHistoryList] = useState<Array<ISpecialRewardsUser>>([]);

    const {
        state: {curEnv}
    } = useAccounts();

    useEffect(() => {
        const onLoad = () => {
            getSpecialRewardsHistoryAPI(curEnv)
                .then(res => {
                    console.log(res);
                    if (res !== undefined && Array.isArray(res)) {
                        setHistoryList([]);
                        res.forEach(rewardItem => {
                            const tempItem = {} as ISpecialRewardsUser;
                            tempItem.rewardId = rewardItem.specialrewardid;
                            tempItem.userId = rewardItem.userid;
                            tempItem.name = rewardItem.name;
                            tempItem.communityId = rewardItem.communityid;
                            tempItem.communityName = rewardItem.communityname;
                            tempItem.rewardAmount = rewardItem.reward;
                            tempItem.rewardedBy = rewardItem.rewardedby;
                            tempItem.rewardDate = rewardItem.created;

                            setHistoryList(oldData => [...oldData, tempItem]);
                        });
                    }
                }).catch(err => {
                    console.log(err);
                });
        }
        onLoad();
    }, [curEnv]);

    const fillTable = () => {
        return historyList.map((historyItem, index) => {
            return (
                <tr key={index}>
                    <td className="px-2 py-2 text-center">
                        {historyItem.rewardId}
                    </td>
                    <td className="px-2 py-2 text-center">
                        {historyItem.userId}
                    </td>
                    <td className="px-2 py-2 text-center">
                        {historyItem.name}
                    </td>
                    <td className="px-2 py-2 text-center">
                        {historyItem.communityId}
                    </td>
                    <td className="px-2 py-2 text-center">
                        {historyItem.communityName}
                    </td>
                    <td className="px-2 py-2 text-center">
                        {historyItem.rewardAmount}
                    </td>
                    <td className="px-2 py-2 text-center">
                        {historyItem.rewardedBy}
                    </td>
                    <td className="px-2 py-2 text-center">
                        {
                            historyItem.rewardDate.includes('T') 
                            ? historyItem.rewardDate.split('T')[0]
                            : historyItem.rewardDate
                        }
                    </td>
                </tr>
            );
        });
    }

    return (
        <div className="mt-2 ml-6">
            <table>
                <thead>
                    <tr className="bg-[#F3F3F3]">
                        <th className="p-4">Id</th>
                        <th className="p-4">User Id</th>
                        <th className="p-4">Name</th>
                        <th className="p-4">Community Id</th>
                        <th className="p-4">Community Name</th>
                        <th className="p-4">Reward Amount</th>
                        <th className="p-4">Rewarded By</th>
                        <th className="p-4">Rewarded On</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        fillTable()
                    }
                </tbody>
            </table>
        </div>
    );
}

export default SpecialRewardsHistory;