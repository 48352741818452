import {useState} from "react";
import {Tab} from "@headlessui/react";
import clsx from "clsx";
import UserRetention from "../components/cohorts/userRetention";
import ChurnData from "../components/cohorts/churnData";
import TabTitle from "../components/common/tabTitle";

const Cohorts = () => {
    const [selectedIndex, setSelectedIndex] = useState(0);

    return (
            <Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
                <Tab.List>
                    <Tab
                        className={`
                        ${selectedIndex === 0 ? 'bg-[#6415ff]' : ''}
                        ml-2
                        rounded-full border-solid border-2 border-[#6415ff]`}
                    >
                        <TabTitle
                            title="User Retention" selected={selectedIndex === 0} />
                    </Tab>
                    <Tab
                        className={`
                        ${selectedIndex === 1 ? 'bg-[#6415ff]' : ''}
                        ml-2
                        rounded-full border-solid border-2 border-[#6415ff]`}
                    >
                        <TabTitle                            
                            title="Churn Table" selected={selectedIndex === 1} />
                    </Tab>
                </Tab.List>
                <Tab.Panels>
                    <Tab.Panel><UserRetention /></Tab.Panel>
                    <Tab.Panel><ChurnData /></Tab.Panel>
                </Tab.Panels>
            </Tab.Group>
    );
}

export default Cohorts;