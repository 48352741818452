import API from '@aws-amplify/api';
import {getEnvApi, IContestRank, IQuiz, IQuizOptions, IQuizTimings, IRank} from "../utils";

export function createContestAPI(
        env: number,
        contestName: string,
        contestDesc: string,
        contestImage: string,
        createdBy: string,
        startDate: string,
        endDate: string,
        contestType: string,
        rewardCoins: number,
        tnc: string,
        rewardType: string,
        rankList: Array<IContestRank>
        ) {    
    return API.post(getEnvApi(env), "/admin/contest/create_contest/all", {
        body: {
            "contestName": contestName,
            "contestDesc": contestDesc,
            "contestImage": contestImage,
            "createdBy": createdBy,
            "startDate": startDate,
            "endDate": endDate,
            "contestType": contestType,
            "rewardCoins": rewardCoins,
            "tnc": tnc,
            "rewardType": rewardType,
            "rankList": rankList
        }
    });
}

export function getAllContestAPI(env: number) {
    return API.post(getEnvApi(env), "/user/contest/all_contest/all", {
        body: {

        }
    });
}

export function endContestAPI(env: number, contestId: number) {
    return API.post(getEnvApi(env), "/admin/contest/end_contest/all", {
        body: {
            "contestId": contestId
        }
    });
}

export function cloneContestAPI(env: number, contestId: number, startDate: string, endDate: string) {
    return API.post(getEnvApi(env), "/admin/contest/clone_contest/all",  {
        body: {
            "contestId": contestId,
            "startDate": startDate,
            "endDate": endDate
        }
    });
}

// bannerTitle: string,
// bannerImage: string,
// bannerKey: string,
// buttonText: string,
// minBuild: number,
// startDate: string,
// endDate: string,
// sequence: number,
// metaData: string,
// reward: number,
// createdBy: string

export function addBannerAPI(
    env:number,
    bannerTitle: string,
    bannerImage: string,
    bannerKey: string,
    buttonText: string,
    minBuild: number,
    startDate: string,
    endDate: string,    
    sequence: number,
    metaData: string,
    reward: number,
    createdBy: string
    ) {
        return API.post(getEnvApi(env), "/admin/contest/add_banner/all", {
            body: {
                "bannerTitle": bannerTitle,
                "bannerImage": bannerImage,
                "bannerKey": bannerKey,
                "buttonText": buttonText,
                "minBuild": minBuild,
                "startDate": startDate,
                "endDate": endDate,
                "sequence": sequence,
                "metaData": metaData,
                "reward": reward,
                "createdBy": createdBy
            }
        });
}

export function getBannerHistoryAPI(env: number) {
    return API.post(getEnvApi(env), "/admin/contest/banner_history/all", {
        body: {
            
        }
    });
}

export function updateBannerAPI(
    env: number,
    bannerId: number,
    title: string,
    bannerImage:string,
    bannerKey: string,
    buttonText: string,
    minBuild: number,
    expiry: string,
    metadata: string,
    sequence: number,
    status: number
) {
    return API.post(getEnvApi(env), "/admin/contest/update_banner/all", {
        body: {
            "bannerId": bannerId,
            "title": title,
            "bannerImage": bannerImage,
            "bannerKey": bannerKey,
            "buttonText": buttonText,
            "minBuild": minBuild,
            "expiry": expiry,
            "metadata": metadata,
            "sequence": sequence,
            "status": status
        }
    });
}

export function createQuizTaskAPI(
    env: number,
    quizKey: string,
    quiz: IQuiz,
    quizOptions: IQuizOptions,
    rankings: Array<IRank>,
    timings: IQuizTimings,
    tnc: string,
    timer: number,
    createdBy: string
    ) {
        return API.post(getEnvApi(env), '/admin/contest/create_quiz/all', {
            body: {
                "quizKey": quizKey,
                "quiz": quiz,
                "quizOptions": quizOptions,
                "rankings": rankings,
                "timings": timings,
                "tnc": tnc,
                "timer": timer,
                "createdBy": createdBy
            }
        });
}

export function createOtherTask(
    env: number,
    title: string,
    description: string,
    actionKey: string,
    activeImage: string,
    inActiveImage: string,    
    coins: number,
    startDate: string,
    endDate: string,
    createdBy: string,
    tnc: string
) {
    return API.post(getEnvApi(env), '/admin/contest/create_portfolio/all', {
        body: {
            "title": title,
            "description": description,
            "actionKey": actionKey,
            "activeImage": activeImage,
            "inActiveImage": inActiveImage,
            "coins": coins,
            "startDate": startDate,
            "endDate": endDate,
            "createdBy": createdBy,
            "tnc": tnc
        }
    });
}

export function getContestHistoryAPI(env: number) {
    return API.post(getEnvApi(env), '/admin/contest/contest_history/all', {
        body: {

        }
    });
}

export function getQuizDetailsAPI(env: number, contestId: number) {
    return API.post(getEnvApi(env), '/admin/contest/quiz_details/all', {
        body: {
            "contestId": contestId
        }
    });
}

export function updateContestTaskAPI(
    env: number,
    contestId: number,
    taskKey: string,
    contestTitle: string,
    contestDescription: string,
    startDate: string,
    endDate: string,
    status: number,
    coins: number,
    quiz: IQuiz,
    options: IQuizOptions,
    ranks: Array<IRank>,
    updatedBy: string
    ) {
        return API.post(getEnvApi(env), '/admin/contest/update_contest_task/all', {
            body: {
                "contestId": contestId,
                "taskKey": taskKey,
                "contestTitle": contestTitle,
                "contestDescription": contestDescription,
                "startDate": startDate,
                "endDate": endDate,
                "status": status,
                "coins": coins,
                "quiz":quiz,
                "options": options,
                "ranks": ranks,
                "updatedBy": updatedBy
            }
        });
}