import { useEffect, useState } from "react";
import { getCommunitiesListAPI } from "../../api/community";
import Loading from "../Loading";
import { CommunityTableContainer, TableHeaders } from "./engaging";
import { useAccounts } from "../accounts/context";

const COLUMNS = ['Community Name', 'Total Members']

const InactiveCommunities = () => {
  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const {
    state: { curEnv }
  } = useAccounts();

  useEffect(() => {
    const onLoad = async () => {
      try {
        const data = await getCommunitiesListAPI(5, 0, false, curEnv);
        setTableData(data);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    }
    onLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [curEnv]);

  return (
    <div className="p-2 border-2 rounded">
      <h4 className="font-bold text-center">Least Engaging Communties</h4>
      {loading ? <Loading /> : <CommunityTableContainer>
        <TableHeaders cols={COLUMNS} />
        <TableRows rows={tableData} />
      </CommunityTableContainer>}
    </div>
  );
}

export default InactiveCommunities;

const TableRows = ({ rows }: { rows: any }) => (
  <tbody>
    {rows.map((el: any) => (<TableRow key={el.communityid} row={el} />))}
  </tbody>
)

const TableRow = ({ row }: { row: any }) => (
  <tr className="border-b">
    <td className="px-6 py-2 text-sm font-light text-gray-900 whitespace-nowrap">
      {row.communityname}
    </td>
    <td className="px-6 py-2 text-sm font-light text-gray-900 whitespace-nowrap">
      {row.members}
    </td>
  </tr>
)
