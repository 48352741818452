import { AddActiveCommAction, AddCommChartData, AddCommCountAction, AddNotPartOfCommunity } from "../communities/actions";
import { AddFBCountAction, AddTrashedAction } from "../feedback/actions";
import { AddActiveUsers, AddUserCountAction, AddUserGrowth } from "../user/actions";

export const keyword = '@@DASH';

export function AddDashCountAction(dispatch: any, payload: Array<any>) {
  dispatch(AddUserCountAction(payload[0][0]));
  dispatch(AddCommCountAction(payload[1]));
  dispatch(AddCommChartData(payload[2]));
  dispatch(AddFBCountAction(payload[3]));
  dispatch(AddTrashedAction(payload[4]));
  dispatch(AddActiveCommAction(payload[5]));
  dispatch(AddActiveUsers(payload[6][0]));
  dispatch(AddNotPartOfCommunity(payload[7]));
  dispatch(AddUserGrowth(payload[8][0]));
}
