import { createContext, useContext } from 'react';

type IAccountState = {
  isAuthenticated: boolean;
  isAuthenticating: boolean;
  user: { [x: string]: any } | undefined;
  curEnv: number;
};

type IAccountActions = {
  handleLogout?: () => void;
  userHasAuthenticated?: (x: boolean) => void;
  handleGoogleLogin?: () => void;
  setCurEnv?: (x: number) => void;
};

type IAccountContextType = {
  state: IAccountState;
  actions: IAccountActions;
};
// https://stackoverflow.com/questions/57285906/how-to-set-node-env-from-package-json-for-react
export const AccountContext = createContext<IAccountContextType>({
  state: { isAuthenticated: false, isAuthenticating: true, user: {}, curEnv: (process.env.REACT_APP_STAGE === 'dev' ? 1 : 0 ) },
  actions: {},
});

export const useAccounts = () => useContext(AccountContext);
