import { ON_LOGOUT } from "../context";
import { ADD_COM_COUNT, ADD_COM_TYPES, ADD_NOT_PART_OF_COMM } from "./actions";

export interface ICommunityType {
  communityid: number;
  approval: number;
  communitydesc: string;
  communityimg: string;
  communityname: string;
  created: Date | string;
  paid: number;
  post: number;
  referencename: string;
  status: string;
  updated: Date | string;
  userid: number;
}
export interface ICommType {
  count?: number;
  active?: number;
  not_part_of_community?: number;
}
const initialState = {

};

export function commReducer(state: ICommType = initialState, action: any) {
  switch (action.type) {
    case ADD_COM_COUNT:
      return { ...state, ...action.payload }
    case ADD_COM_TYPES:
      return { ...state, ...action.payload }
    case ADD_NOT_PART_OF_COMM:
      return {...state, ...action.payload }
    case ON_LOGOUT:
      return initialState;
    default:
      return state;
  }
}
