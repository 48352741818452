import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, LegendItem, Title } from 'chart.js';
import { Interests } from '../../../../../constants';
import { useAppState } from '../../../../../redux/context';
import Loading from '../../../../Loading';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { toHex } from '../../../../../utils';
import { ChartJSOrUndefined } from 'react-chartjs-2/dist/types';

ChartJS.register(ArcElement, Tooltip, Legend, Title);


const CommunityCharts = () => {
  const { state } = useAppState();
  const [legend, setLegentItems] = useState<Array<LegendItem>>([]);
  const chartRef = useRef<ChartJSOrUndefined<"pie", any, string>>(null);

  useEffect(() => {
    const chart = chartRef.current;
    setLegentItems(chart?.legend?.legendItems ?? []);
  }, []);

  const data1 = useMemo(() => {
    const labels = Object.values(Interests).filter(el => typeof el === 'string').map(String);
    const colors = labels.map((_, index) => toHex(index));
    return {
      labels: labels,
      datasets: [{
        data: state?.Comms?.chart ?? [13000, 63000, 14000, 10000],
        backgroundColor: colors
      }]
    }
  }, [state?.Comms?.chart]);

  const onLegendClick = useCallback((index: number) => {
    const chart = chartRef.current;
    chart?.toggleDataVisibility(index);
    chart?.update();
    setLegentItems(chart?.legend?.legendItems ?? []);
  }, []);

  if (!state.Comms.chart) {
    return <Loading />;
  }
  return (
    <div className="flex items-center justify-center">
      <div className="max-w-[10rem]">
        <Pie ref={chartRef} data={data1} options={{
          plugins: {
            legend: {
              display: false,
              position: 'bottom',
            },
            title: {
              display: true,
              text: 'Types of Communities'
            }
          },
        }} />
      </div>
      <div id="legend-container" className="flex justify-center p-2">
        <ul className="grid grid-cols-2 p-2 space-y-2">
          {legend.map((el, index) => <li key={index} className="flex items-center ml-3 cursor-pointer" onClick={() => onLegendClick(index)}>
            <ColorBox item={el} />
            <TextContainer item={el} />
          </li>)}
        </ul>
      </div>
    </div>
  );
}

export default CommunityCharts;

// https://github.com/chartjs/Chart.js/blob/master/docs/samples/legend/html.md
export const ColorBox = ({ item }: { item: LegendItem }) => (
  <span className="inline-block w-5 h-3 mr-2"
    style={{
      background: String(item.fillStyle),
      borderColor: String(item.strokeStyle),
      borderWidth: `${item.lineWidth} px`
    }}>
  </span>
)

export const TextContainer = ({ item }: { item: LegendItem }) => (
  <p className="p-0 m-0 text-xs text-gray-700" style={{
    color: String(item.fontColor),
    textDecoration: item.hidden ? 'line-through' : ''
  }}>{item.text}</p>
)
