import { Tab } from "@headlessui/react";
import clsx from "clsx";
import { useState } from "react";
import Misc from "../components/admin/misc";
import TabTitle from "../components/common/tabTitle";
import IncentivizeUser from "../components/rewards/incentivizeUser";
import SpecialRewardsHistory from "../components/rewards/specialRewardsHistory";

const Incentivize = () => {
    const [selectedIndex, setSelectedIndex] = useState(0);    
    return (
        <Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
            <Tab.List>
                <Tab
                    className={`
                    ${selectedIndex === 0 ? 'bg-[#6415ff]' : ''}
                    ml-2
                    rounded-full border-solid border-2 border-[#6415ff]`}
                >
                    <TabTitle
                        title="Misc" selected={selectedIndex === 0} />
                </Tab>
                <Tab
                    className={`
                    ${selectedIndex === 1 ? 'bg-[#6415ff]' : ''}
                    ml-2
                    rounded-full border-solid border-2 border-[#6415ff]`}
                >
                    <TabTitle
                        title="Incentivize" selected={selectedIndex === 1} />
                </Tab>
                <Tab
                    className={`
                    ${selectedIndex === 2 ? 'bg-[#6415ff]' : ''}
                    ml-2
                    rounded-full border-solid border-2 border-[#6415ff]`}
                >
                    <TabTitle
                        title="History" selected={selectedIndex === 2} />
                </Tab>
            </Tab.List>
            <Tab.Panels>
                <Tab.Panel>
                    <Misc />
                </Tab.Panel>
                <Tab.Panel>
                    <IncentivizeUser />
                </Tab.Panel>
                <Tab.Panel>
                    <SpecialRewardsHistory />
                </Tab.Panel>
            </Tab.Panels>
        </Tab.Group>
    );
}

export default Incentivize;