import { useEffect, useState } from 'react';
import { useAccounts } from '../accounts/context';
import {getVaultBalanceAPI} from "../../api/rewards";

const VaultBalance = () => {
    const [vaultBalance, setVaultBalance] = useState(0);

    const {
        state: { curEnv }
    } = useAccounts();

    useEffect(() => {
        const getVaultBalance = () => {
            getVaultBalanceAPI(
                    curEnv
            ).then((result: any) => {
                    if (result !== undefined && result.length > 0) {
                        setVaultBalance(result[0]["vault_balance"]);
                    }
                }).catch(err => {
                    console.log("Error while getting the vault balance");
                    console.log(err);
                });
        }

        getVaultBalance();
    }, [curEnv]);

    return (
            <div className="flex mr-3">
                <div className="p-5 text-center rounded-lg bg-rose">
                    <p className="text-2xl font-extrabold">{vaultBalance}</p>
                    <p>Vault Balance</p>
                </div>
            </div>
    );
}

export default VaultBalance;