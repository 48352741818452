import { ReactNode, useEffect, useState } from "react";
import { getCountsAPI } from "../../../api/dash";
import { useAppState } from "../../../redux/context";
import { AddDashCountAction } from "../../../redux/dash/actions";
import { IfeedbackType } from "../../../redux/feedback/reducer";
import { useAccounts } from "../../accounts/context";
import { JoinedCards, OneCard } from "../../common/cards";
import FBRatings from "../../dashboard/panels/feedback/components/ratings";
import FeedbackCharts from "../../dashboard/panels/feedback/components/srccharts";
import TagCharts from "../../dashboard/panels/feedback/components/tagCharts";
import { FormSelect } from "../../forms/selects";
import Loading from "../../Loading";
import { useFeedbackContext } from "../context";


const FeedbackStatics = () => {
  const [src, setSrc] = useState<string | number>('0');
  const { dispatch, state } = useAppState();
  const { actions } = useFeedbackContext();

  const [loading, setLoading] = useState(true);
  const {
    state: { curEnv }
  } = useAccounts();

  useEffect(() => {
    const onLoad = async () => {
      try {
        const data = await getCountsAPI(curEnv);
        AddDashCountAction(dispatch, data);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };
    actions.setSelected && actions.setSelected(-1);
    onLoad();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [curEnv]);
  return (
    <ReportStatContainer>
      <ReportStatHeader title="Report" />
      <ReportStatCards data={state?.FeedB} />
      <ReportStatFilter src={src} setSrc={setSrc} />
      {loading ? <Loading /> : <div className="flex justify-around">
        <FeedbackCharts src={src} />
        <TagCharts src={src} />
      </div>}
      <FBRatings />
    </ReportStatContainer>
  );
}

export default FeedbackStatics;

export const ReportStatContainer = ({ children }: { children: ReactNode }) => (
  <div className="min-h-0 overflow-y-auto grow shrink basis-auto">
    <div className="flex flex-col w-full h-auto px-5 pt-12 space-y-5">
      {children}
    </div>
  </div>
)

export const ReportStatHeader = ({ title }: { title: string }) => (
  <h1 className="text-2xl font-bold">{title}</h1>
)
export const ReportStatCards = ({ data, title = "Feedbacks" }: { data?: IfeedbackType; title?: string; }) => (
  <div className="flex justify-center w-full">
    <OneCard title={data?.count ?? '-'} subtitle={`Total Number of ${title}`} />
    <JoinedCards items={[{ title: data?.inC ?? 0, subtitle: 'InApp' }, { title: data?.playC ?? 0, subtitle: 'Playstore' }, { title: data?.appC ?? 0, subtitle: 'Appstore' }]} />
  </div>
)

export const ReportStatFilter = ({ src, setSrc }: { src: string | number; setSrc: (x: string | number) => void }) => (
  <div className="flex justify-center ">
    <FormSelect label="" name="feed" value={src} onChange={(evt) => setSrc(evt.target.value)}>
      <option value="0">All feeds</option>
      <option value="1">Inapp feed</option>
      <option value="2">Playstore feed</option>
      <option value="3">Appstore feed</option>
    </FormSelect>
  </div>
)
