import { ON_LOGOUT } from "../context";
import { ADD_ACTIVE_USERS, ADD_USER_COUNT, ADD_USER_GROWTH } from "./actions";

export interface IUserType {
  count?: number;
  active?: number;
  newUsers?: number;
}
const initialState = {

};

export function usersReducer(state: IUserType = initialState, action: any) {
  switch (action.type) {
    case ADD_USER_COUNT:
      return { ...state, count: action.payload['userCount'] }
    case ADD_ACTIVE_USERS:
      return { ...state, active: action.payload['count'] }
    case ADD_USER_GROWTH:
      return { ...state, newUsers: action.payload['user_growth'] };
    case ON_LOGOUT:
      return initialState;
    default:
      return state;
  }
}
