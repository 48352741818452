import { Switch } from "@headlessui/react";
import { useEffect, useState } from "react";
import { SubmitHandler } from "react-hook-form";
import toast from "react-hot-toast";
import { 
    getAppConfigsAPI, 
    setRedeemStatusAPI, 
    setSocialSignInStatusAPI 
} from "../../api/admin_ops";
import { getUserRedeemStatusAPI, updateUserRedeemStatusAPI } from "../../api/users";
import { ISearchType, IUserSearchResultType } from "../../utils";
import { useAccounts } from "../accounts/context";
import UserSearch from "../common/userSearch";
import Loading from "../Loading";
import WithdrawalSettings from "./withdrawalSettings";

const Misc = () => {

    const [socialSignInStatus, setSocialSignInStatus] = useState<number>(0);
    const [redeemStatus, setRedeemStatus] = useState<number>(0);
    const [isAppConfigLoading, setIsAppConfigLoading] = useState(true);  
    const [searchQuery, setSearchQuery] = useState(0);
    const [currentUser, setCurrentUser] = useState<IUserSearchResultType>({} as IUserSearchResultType);
    const [userRedeemStatus, setUserRedeemStatus] = useState(false);    
    const {
        state: {curEnv, user}
    } = useAccounts();

    useEffect(() => {        
        const onLoad = () => {
            setIsAppConfigLoading(true);
            getAppConfigsAPI(curEnv)
                .then(data => {                    
                    if (Array.isArray(data) && data.length > 0) {
                        data.forEach(item => {
                            if (item["configid"] === 2) {
                                setSocialSignInStatus(item["val"]);
                            } else if (item["configid"] === 3) {
                                setRedeemStatus(item["val"]);
                            }                            
                        });
                    }                  
                }).catch(err => {
                    console.log(err);
                }).finally(() => {
                    setIsAppConfigLoading(false);
                });
        }

        onLoad();
    }, [curEnv]);

    const toggleSocialSignInStatus = () => {        
        setIsAppConfigLoading(true);
        setSocialSignInStatusAPI(
            curEnv,
            socialSignInStatus === 1 ? 0 : 1
        ).then(data => {
            toast.success("Social Sign In Status modified successfully");
            setSocialSignInStatus(socialSignInStatus === 1 ? 0 : 1);
        }).catch(err => {
            console.log(err);
            toast.error("Error setting the social sign in status");
        }).finally(() => {
            setIsAppConfigLoading(false);
        });
    }

    const toggleRedeemStatus = () => {
        setIsAppConfigLoading(true);
        setRedeemStatusAPI(
            curEnv,
            redeemStatus === 1 ? 0 : 1
        ).then(data => {
            toast.success("Redeem Status modified successfully");
            setRedeemStatus(redeemStatus === 1 ? 0 : 1);
        }).catch(err => {
            console.log(err);
            toast.error("Error setting the redeem status");
        }).finally(() => {
            setIsAppConfigLoading(false);
        });
    }

    const toggleUserRedeemStatus = () => {
        if (currentUser.userId !== undefined && currentUser.userId !== null && currentUser.userId !== 0) {
            updateUserRedeemStatusAPI(
                curEnv,
                currentUser.userId,
                userRedeemStatus ? 0 : 1,
                user?.email ? user?.email : "feedback@investmates.io"
            ).then(data => {
                toast.success("User redeem status updated successfully");
                setUserRedeemStatus(!userRedeemStatus);
            }).catch(err => {
                console.log(err);
                toast.error("Error while updating the user redeem status");
            });
        }        
    }

    const onSubmitSearch: SubmitHandler<IUserSearchResultType> = (data) => {
        const {userId} = data;        
        setCurrentUser({
            userId: userId,
            userName: data.userName,
            userHandle: data.userHandle,
            profilePicture: data.profilePicture,
            email: data.email,
            contactNo: data.contactNo,
            authenticated: data.authenticated
        } as IUserSearchResultType);
        getUserRedeemStatusAPI(
            curEnv,
            userId
        ).then(data => {            
            if (Array.isArray(data)) {
                if (data.length === 0) {
                    setUserRedeemStatus(false);
                } else {
                    setUserRedeemStatus(data[0]["canredeem"] === 1);
                }
            }
        }).catch(err => {
            console.log(err);
        });
    }

    return (
        <div className="mt-2 ml-4 mr-4">
            <h2 className="text-xl font-bold">
                Enable / Disable 
            </h2>
            {
                isAppConfigLoading 
                ? <Loading />
                :<div className="mt-2">
                    <span className="mr-2">
                        Enable / Disable Social Sign In
                    </span>
                    <Switch 
                        checked={socialSignInStatus === 1 ? true : false}
                        onChange={() => toggleSocialSignInStatus()}
                        className={`${
                            socialSignInStatus === 1 ? 'bg-blue-600' : 'bg-gray-200'
                        } relative inline-flex h-6 w-11 items-center rounded-full`}
                        >
                        <span className="sr-only">Enable notifications</span>
                        <span
                            className={`${
                                socialSignInStatus === 1 ? 'translate-x-1' : 'translate-x-6'
                            } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                        />
                    </Switch>

                    <span className="ml-2 mr-2">
                        Enable / Disable Reward Redeem
                    </span>
                    <Switch 
                        checked={redeemStatus === 1 ? true : false}
                        onChange={() => toggleRedeemStatus()}
                        className={`${
                            redeemStatus === 1 ? 'bg-blue-600' : 'bg-gray-200'
                        } relative inline-flex h-6 w-11 items-center rounded-full`}
                        >
                        <span className="sr-only">Enable Redeem</span>
                        <span
                            className={`${
                                redeemStatus === 1 ? 'translate-x-1' : 'translate-x-6'
                            } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                        />
                    </Switch>
                </div>                
            }            
            <div className="mt-8">
                <hr />
                <h2 className="text-xl mt-2 mb-2 font-bold">
                    Enable / Disable User Redeem
                </h2>
                <div className="w-1/2">
                    <UserSearch searchQuery={searchQuery} onSubmit={onSubmitSearch} />
                    <div className={`${currentUser.userId === 0 ? 'hidden' : 'visible'} m-4`}>
                        <img className="w-10" src={currentUser.profilePicture} />
                        <span>{currentUser.userName}</span>
                        <span className="ml-2 mr-2">
                            Enable / Disable User Redeem
                        </span>
                        <Switch 
                            checked={userRedeemStatus}
                            onChange={() => toggleUserRedeemStatus()}
                            className={`${
                                userRedeemStatus ? 'bg-blue-600' : 'bg-gray-200'
                            } relative inline-flex h-6 w-11 items-center rounded-full`}
                            >
                            <span className="sr-only">Enable Redeem</span>
                            <span
                                className={`${
                                    userRedeemStatus ? 'translate-x-1' : 'translate-x-6'
                                } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                            />
                        </Switch>
                    </div>
                </div>
                <hr />
            </div> 
            <div className="mt-2">
                <WithdrawalSettings />                                
            </div>         
        </div>
    );
}

export default Misc;