import CSS from 'csstype';
import {IReportType} from "../../utils";
import {useNavigate} from "react-router-dom";

const ReportItem = ({item} : {item: IReportType}) => {

    const navigate = useNavigate();

    const handleReportClick = (reportId: string) => {
        navigate("/dashboard/report/details/" + reportId);
    }

    return (
            <div
                className="grid grid-cols-10 gap-3 mr-4 mb-6 rounded-lg border border-[#C7CBD0] w-9/12"
                onClick={() => handleReportClick(item.id)}>
                <div className="col-span-3">
                    <div className="flex flex-col ml-2 mt-2 mb-2">
                        <div className="flex flex-row">
                            <img
                                className="flex-shrink-0 w-10 h-10 bg-gray-300 rounded-full"
                                src={item.profileImage} />
                            <div className="flex flex-col">
                                <h2 className="ml-2 whitespace-nowrap">{item.name}</h2>
                                <h2 className="ml-2 whitespace-nowrap">User Id: {item.userId}</h2>
                            </div>
                        </div>
                        <div>
                            Report count: {item.reportCount}
                        </div>
                        <div>
                            Warning count: {item.warningCount}
                        </div>
                        <div className="mt-2 mb-2">
                            <button className="outline outline-1 rounded px-2 pb-0.5">{item.reportType}</button>
                            <button className="ml-2 outline outline-1 rounded px-2 pb-0.5">{item.status}</button>
                        </div>
                    </div>
                </div>
                <div className="col-span-7 pt-2 pb-2 pl-2 mr-2 mt-2 mb-2 bg-[#F7F6FF] rounded-md">
                    <div className="flex flex-col">
                        <div>
                            <span><b>{item.category}</b></span>
                            <span className="float-right px-8">{item.timestamp}</span>
                        </div>
                        <div>
                            {item.content}
                        </div>
                    </div>
                </div>
            </div>
    );
}

export default ReportItem;