export enum Interests {
  Unknown = 0,
  Stock,
  Commodity,
  'MF/ETF',
  'Real Estate',
  Crypto,
  'Future & Options',
  Forex,
  Indices,
  Bonds,
  Insurance,
  'Bank Desposits',
  Retirement
}
