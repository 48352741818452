import { ON_LOGOUT } from "../context";

export interface IrewardType {
  id?: string
}
const initialState = {};

export function rewardReducer(state: IrewardType = initialState, action: any) {
  switch (action.type) {
    case ON_LOGOUT:
      return initialState;
    default:
      return state;
  }
}
