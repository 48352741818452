import { createContext, ReactNode, useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getAppStoreAPI, getFBListAPI, getInAppFeedback, getPlayStoreAPI } from "../../api/feedback";
import { IFeedbackCardType } from "./feedbackCard";
import {useAccounts} from '../../components/accounts/context';
import { getPagesCount, isNumber, parseAppStoreReview, parsePlayStoreReviews, sortedFeedback } from "../../utils";

type IFeedbackContextState = {
  loading: boolean;
  srcFilter: number;
  tagFilter: number;
  statusFilter: number;
  fromDate: string;
  toDate: string;
  selected: number;
  userFilter: any;
  currentPage: number;
  totalPages: number;
  list: Array<IFeedbackCardType>;
  showDeleted: boolean;
}

type IFeedbackContextActions = {
  setSrcFilter?: (x: number) => void;
  setTagFilter?: (x: number) => void;
  setStatusFilter?: (x: number) => void;
  setFromDate?: (x?: any) => void;
  setToDate?: (x?: any) => void;
  setSelected?: (x: number) => void;
  setUserFilter?: (x?:any) => void;
  setList?: (x: Array<any>) => void;
  setTotalPages?: (x: number) => void;
  setCurrentPage?: (x: number) => void;
  UpdateListItem?: (index: number, data: any) => void;
  DeleteListItem?: (index: number) => void;
  setShowDeleted?: (x: boolean) => void;
}

type IFeedbackContextType = {
  state: IFeedbackContextState,
  actions: IFeedbackContextActions
}
const FeedbackContext = createContext<IFeedbackContextType>({
  state: {
    loading: true,
    srcFilter: 0,
    tagFilter: 0,
    statusFilter: 0,
    fromDate: '',
    toDate: '',
    selected: -1,
    userFilter: "",
    list: [],    
    showDeleted: false,
    currentPage: 0,
    totalPages: 0
  },
  actions: {}
});

// context consumer hook
export const useFeedbackContext = () => {
  // get the context
  const context = useContext(FeedbackContext);

  // if `undefined`, throw an error
  if (context === undefined) {
    throw new Error("useReportContext was used outside of its Provider");
  }

  return context;
};

export const FeedbackContextProvider = ({ children }: { children: ReactNode }) => {
  const [loading, setLoading] = useState(true);
  const [srcFilter, setSrcFilter] = useState(0);
  const [tagFilter, setTagFilter] = useState(0);
  const [statusFilter, setStatusFilter] = useState(0);
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [selected, setSelected] = useState(-1);
  const [showDeleted, setShowDeleted] = useState(false);
  const [userFilter, setUserFilter] = useState("");
  const [list, setList] = useState<Array<IFeedbackCardType>>([]);
  const [parentList, setParentList] = useState<Array<IFeedbackCardType>>([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const navigate = useNavigate();
  const {
    state: {curEnv}
  } = useAccounts(); 
  
  const pageSize = 10;

  // useEffect(() => {
  //   setList(parentList.slice(currentPage - 1, (currentPage + pageSize) - 1));
  // }, [currentPage]);

  useEffect(() => {
    const onChange = () => {
      if (userFilter !== undefined && userFilter !== null && userFilter !== '' && Number(userFilter) !== 0) {
        if (isNumber(userFilter)) {       
          setList([
            ...parentList.filter(item => {
              return item.userid === Number(userFilter)
            })
          ]);                 
        } else {
          setList([
            ...parentList.filter(item => {
              return item.firstname?.toLocaleLowerCase().includes(userFilter.toLowerCase()) 
                || item.lastname?.toLowerCase().includes(userFilter.toLowerCase())
            })
          ]);
        }        
      } else {
        setList([...parentList]);
      }
      setCurrentPage(1);
      setTotalPages(getPagesCount(list.length, pageSize));
    }

    onChange();
  }, [userFilter]);

  useEffect(() => {
    const onLoad = async () => {      
      try { 
        setLoading(true);       
        switch (srcFilter) {
          case 0:            
            const data = await getFBListAPI(curEnv, srcFilter, tagFilter, statusFilter, fromDate, toDate, showDeleted);            
            setParentList(sortedFeedback(data));
            setList(sortedFeedback(data));
            setTotalPages(getPagesCount(Number(data.length), pageSize));
            // console.log(data);            
            break;
          case 1:            
            const inappData = await getInAppFeedback(curEnv, srcFilter, tagFilter, statusFilter, fromDate, toDate, showDeleted);            
            setParentList(sortedFeedback(inappData));
            setList(sortedFeedback(inappData));
            setTotalPages(getPagesCount(Number(inappData.length), pageSize));
            break;
          case 2:
            const playStoreData = await getPlayStoreAPI();
            setParentList(parsePlayStoreReviews(playStoreData));
            setList(parsePlayStoreReviews(playStoreData));
            setTotalPages(getPagesCount(Number(playStoreData.length), pageSize));
            break
          case 3:
            const appStoreReviews = await getAppStoreAPI();
            setParentList(parseAppStoreReview(appStoreReviews));
            setList(parseAppStoreReview(appStoreReviews));
            setTotalPages(getPagesCount(Number(appStoreReviews.length), pageSize));
            break;          
        }
        setCurrentPage(1);        
        console.log("Current Page: ", currentPage);
        console.log("Total Pages: ", totalPages);
        // dispatch(AddDashCountAction(formatSQLResult(data)));
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };
    setSelected(-1);
    navigate('/dashboard/feedbacks');
    onLoad();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [srcFilter, tagFilter, statusFilter, fromDate, toDate, showDeleted, curEnv]);

  const UpdateListItem = useCallback((index: number, data: any) => {
    const newList = list.map((item, ind) => {
      if (ind !== index) {
        // This isn't the item we care about - keep it as-is
        return item
      }

      // Otherwise, this is the one we want - return an updated value
      return {
        ...item,
        ...data
      }
    });
    setList(newList);
  }, [list]);

  const DeleteListItem = useCallback((fbid: number) => {
    const newList = list.filter((item, ind) => item.fbid !== fbid);
    setList(newList);
  }, [list]);

  return (
    <FeedbackContext.Provider value={{
      state: {
        loading, srcFilter, tagFilter, statusFilter, fromDate, toDate, selected, 
        showDeleted, userFilter, list, currentPage, totalPages
      }, actions: {
        setSrcFilter, setTagFilter, setStatusFilter, setFromDate, 
        setToDate, setSelected, setShowDeleted, setUserFilter, setList, 
        UpdateListItem, DeleteListItem, setCurrentPage, setTotalPages
      }
    }}>
      {children}
    </FeedbackContext.Provider>
  );
}
