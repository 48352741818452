import { Spinner, TextInput } from "flowbite-react";
import { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { addBannerAPI } from "../../api/contest";
import {uploadImage} from "../../utils";
import { useAccounts } from "../accounts/context";

const CreateBanner = () => {

    const {
        state: {curEnv, user}
    } = useAccounts();

    const [bannerTitle, setBannerTitle] = useState("");
    const [bannerKey, setBannerKey] = useState("");
    const [urlMeta, setUrlMeta] = useState("");
    const [bannerImage, setBannerImage] = useState("");
    const [buttonText, setButtonText] = useState("");
    const [minBuild, setMinBuild] = useState(138);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [sequence, setSequence] = useState(0);
    const [reward, setReward] = useState(0);
    const inputFile = useRef<HTMLInputElement | null>(null);
    const [selectedFile, setSelectedFile] = useState();
    const [preview, setPreview] = useState("");
    const [loading, setLoading] = useState(false);
    const [hasMetadata, setHasMetadata] = useState(false);

    const clearData = () => {
        setBannerTitle("");
        setBannerKey("profile_complete");
        setUrlMeta("");
        setBannerImage("");
        setButtonText("");
        setMinBuild(138);
        setEndDate("");
        setSequence(0);        
        setPreview("");
        setSelectedFile(undefined);
        setLoading(false);
    }

    const isValidData = () => {
        if (sequence <= 0) {
            console.log("No banner sequence");
            return false;            
        }

        if (bannerTitle === undefined || bannerTitle === null || bannerTitle === "") {
            console.log("No banner title");
            return false;
        }

        if (bannerKey === undefined || bannerKey === null || bannerKey === "") {
            console.log("No banner key");
            return false;
        }        

        if (buttonText === undefined || buttonText === null || buttonText === "") {
            console.log("No button text");
            return false;
        }

        if (bannerKey === 'url' && (urlMeta === undefined || urlMeta === null || urlMeta === '')) {
            console.log("no url metadata provided for the url key");
            return false;
        }
        return true;
    }

    useEffect(() => {
        if (!selectedFile) {
            setPreview("")
            return
        }
        const objectUrl = URL.createObjectURL(selectedFile)
        setPreview(objectUrl)

        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl)
    }, [selectedFile]);

    const handleFileUploadClick = (e: any) => {
        e.preventDefault();
        inputFile.current?.click();
    }

    const handleFileInputChange = (e: any) => {
        if (!e.target.files || e.target.files.length === 0) {
            setSelectedFile(undefined);
            return;
        }
        setSelectedFile(e.target.files[0]);
    }

    useEffect(() => {
        // calls the add banner api whenever the file is uploaded
        if (bannerImage === undefined || bannerImage === null || bannerImage === "") {
            setLoading(false);
        } else {
            // bannerTitle: string,
            // bannerImage: string,
            // bannerKey: string,
            // buttonText: string,
            // minBuild: number,
            // startDate: string,
            // endDate: string,
            // sequence: number,
            // metaData: string,
            // reward: number,
            // createdBy: string
            addBannerAPI(
                curEnv,
                bannerTitle,
                bannerImage,
                bannerKey,
                buttonText,
                minBuild,
                startDate,
                endDate,
                sequence,
                urlMeta,
                reward,
                user?.email ? user?.email : 'feedback@investmates.io'
            ).then(data => {                        
                clearData();
                toast.success("Banner Added");
            }).catch(err => {
                setLoading(false);
                console.log(err);
                toast.error("Error adding the banner");
            });
        }
    }, [bannerImage]);

    const uploadBannerImage = () => {
        return new Promise((resolve, reject) => {
            if (inputFile.current?.files && inputFile.current?.files?.length > 0) {
                const fileName = "banner_" + Date.now() + ".jpg";
                uploadImage(fileName, "banners", inputFile.current?.files[0])
                    .then(data => {                        
                        setBannerImage("https://discovery.investmates.tech/Investmates/banners/" + fileName);
                        return resolve(data);
                    }).catch(err => {       
                        return reject(err);
                    });
            }
        });        
    }

    const addBanner = (e: any) => {
        e.preventDefault();        
        if (isValidData()) {          
            setLoading(true);
            uploadBannerImage()
                .then(data => {
                    toast.success("Banner Image added");                    
                }).catch(err => {
                    setLoading(false);
                    console.log(err);
                    toast.error("Error uploading banner image");
                });
        } else {            
            toast.error("Complete the necessary fields");
        }                
    }    

    return (
        <div className="ml-4 mt-4">
            <h2 className="font-medium leading-tight text-2xl mt-0 mb-2">
                Create Banner for Homepage
            </h2>
            <div className={`object-cover h-48 w-96 bg-indigo-50 flex justify-center items-center`}>
                {
                    selectedFile 
                    ?  <img className="h-48 w-96" src={preview} /> 
                    : <span className="font-medium leading-tight text-2xl mt-0 mb-2">
                        No Image selected
                      </span>
                }
            </div>
            <table>
                <tbody>
                    <tr>
                        <td>
                            <a 
                                href="#"
                                onClick={e => handleFileUploadClick(e)}
                                className="hover:underline"
                                >
                                {selectedFile ? 'Change Banner Image' : 'Upload Banner Image'}
                            </a>
                        </td>
                        <td className="hidden">
                            <input
                                onChange={handleFileInputChange}
                                ref={inputFile}
                                type="file" hidden={true} 
                            />
                        </td>
                        {/* <td>
                            <button 
                                type="button"
                                className={`${
                                    (inputFile.current?.value === undefined ||
                                    inputFile.current?.value === null ||
                                    inputFile.current?.value === '') ? 'hidden' : ''
                                }`}
                                onClick={e => uploadBannerImage(e)}
                            >
                                Upload Image
                            </button>
                        </td> */}
                    </tr>
                    <tr>
                        <td className="py-2">Banner Title</td>
                        <td className="py-2">
                            <input 
                                type="text" 
                                onChange={e => setBannerTitle(e.target.value)} 
                                value={bannerTitle}
                                placeholder="Banner Title" 
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className="py-2">Button title</td>
                        <td className="py-2">
                            <input 
                                type="text" 
                                onChange={e => setButtonText(e.target.value)}
                                value={buttonText}
                                placeholder="Button title" 
                                />
                        </td>
                    </tr>
                    <tr>
                        <td className="py-2">Sequence</td>
                        <td className="py-2">
                            <input 
                                type="number" 
                                onChange={e => setSequence(Number(e.target.value))}
                                value={sequence}
                                placeholder="Banner sequence" 
                                />
                        </td>
                    </tr>
                    <tr>
                        <td className="py-2">Banner Key</td>
                        {/* profile_complete
                        earn_reward
                        portfolio_complete
                        url : requires metadata i.e url link
                        */}
                        <td className="py-2">
                            <input type="text" placeholder="banner key" value={bannerKey} onChange={e => setBannerKey(e.target.value)} />                            
                        </td>
                        <td className="px-2">has metadata? </td>
                        <td className="px-2">
                            <input type="checkbox" onChange={e => setHasMetadata(e.target.checked)} />
                        </td>
                        <td className={`py-2 ${hasMetadata ? 'block' : 'hidden'}`}>
                            <input 
                                type="text" 
                                value={urlMeta} 
                                onChange={e => setUrlMeta(e.target.value)}
                                placeholder="Url Metadata" 
                            />
                        </td>                      
                    </tr>
                    <tr>
                        <td className="py-2">Min App Version</td>
                        <td className="py-2">
                            <input type="text" value={minBuild} onChange={e => setMinBuild(Number(e.target.value))} />                            
                        </td>
                    </tr>
                    <tr className={`${bannerKey === 'user_survey' ? 'visible' : 'hidden'}`}>
                        <td className="py-2">Start Date</td>
                        <td className="py-2">
                            <TextInput
                                type="date"
                                placeholder="Expiry Date"
                                onChange={e => setStartDate(e.target.value)}
                                />
                        </td>
                    </tr>  
                    <tr>
                        <td className="py-2">End Date</td>
                        <td className="py-2">
                            <TextInput
                                type="date"
                                placeholder="Expiry Date"
                                onChange={e => setEndDate(e.target.value)}
                                />
                        </td>
                    </tr> 
                    <tr className={`${bannerKey === 'user_survey' ? 'visible' : 'hidden'}`}>
                        <td className="py-2">Reward: </td>
                        <td className="py-2">
                            <input 
                                type="number"
                                value={reward}
                                onChange={e => setReward(Number(e.target.value))}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className="py-2">
                            {
                                loading 
                                ? <Spinner size="xl" />
                                : <button 
                                        className="bg-[#6415ff] hover:bg-[#5500fb] text-white font-bold py-2 px-4 rounded-full"
                                        onClick={e => addBanner(e)}
                                    >
                                        Add Banner
                                    </button>
                            }                            
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}

export default CreateBanner;