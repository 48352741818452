import React, { useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import clsx from 'clsx';
import toast from 'react-hot-toast';
import { sendFBbyIdAPI } from '../../../api/feedback';
import { IFeedbackCardType } from '../feedbackCard';
import { getFBMailSubject } from '../../../utils';
import { IReplytype } from './box';
import { useAccounts } from '../../accounts/context';

type IPropsType = {
  email: string;
  body: string;
}
const schema = yup.object().shape({
  email: yup.string().trim().email().required(),
  body: yup
    .string()
    .required('Please Enter your Message')
    .notOneOf([yup.ref('email')])
});

const ReplyForm = ({ data, onReply }: { data: IFeedbackCardType; onReply: (x: IReplytype) => void }) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset
  } = useForm<IPropsType>({
    resolver: yupResolver(schema),
  });
  const {
    state: { curEnv }
  } = useAccounts();

  useEffect(() => {
    const newvals = { email: data?.email ?? ''};
    reset(newvals);
  }, [data, reset]);

  const onSubmit: SubmitHandler<IPropsType> = async (ins) => {
    const { email, body } = ins;
    try {
      await sendFBbyIdAPI(data.fbid, email, getFBMailSubject(data?.fbid, data.title), body, curEnv);
      onReply({ to: email, body: body, sent: Date.now() });
      toast.success('Message Sent')
      reset({ email: data.email, body: '' });
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <form className="w-full mt-12 space-y-4" onSubmit={handleSubmit(onSubmit)}>
      <input
        {...register('email', { required: true })}
        className="w-full px-8 py-4 mt-5 text-sm font-medium placeholder-gray-500 bg-gray-100 border border-gray-200 rounded-lg focus:outline-none focus:border-gray-400 focus:bg-white first:mt-0"
        type="email"
        placeholder="Email"
        disabled={!!data.email}
      />
      <span
        className={clsx('text-xs text-red-500', {
          hidden: !errors?.email?.message,
        })}
      >
        {errors?.email?.message}
      </span>

      <textarea
        {...register('body', { required: true })}
        className="block w-full px-4 py-2 text-gray-700 bg-white border rounded-md h-36 dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 dark:focus:border-blue-300 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40">
      </textarea>
      <button
        className="flex items-center justify-center float-right p-3 mt-5 font-semibold tracking-wide text-gray-100 transition-all duration-300 ease-in-out rounded-lg bg-primary-500 hover:bg-primary-900 focus:shadow-outline focus:outline-none"
        type="submit"
        disabled={isSubmitting}
      >
        {isSubmitting ? (
          <>
            <div className="w-6 h-6 -ml-2 border-b-2 border-gray-100 rounded-full animate-spin" />
            <span className="ml-3">Working</span>
          </>
        ) : (
          <>
            <span className="mx-3">Send Email</span>
          </>
        )}
      </button>
    </form>
  );
}

export default ReplyForm;
