import { faCancel, faDeleteLeft, faEdit, faSave, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Spinner } from "flowbite-react";
import { useState } from "react";
import { useEffect } from "react";
import toast from "react-hot-toast";
import { getExploreHistoryAPI, updateExploreItemAPI } from "../../api/explore";
import { IExploreHistoryItem } from "../../utils";
import { useAccounts } from "../accounts/context";
import Loading from "../Loading";

const HistoryExplore = () => {

    const [exploreHistoryList, setExploreHistoryList] = useState<Array<IExploreHistoryItem>>([]);
    const [editingItem, setEditingItem] = useState<IExploreHistoryItem>({} as IExploreHistoryItem);
    const [loading, setLoading] = useState(false);
    const [editCount, setEditCount] = useState(0);
    const [editingMode, setEditingMode] = useState(false);

    const {
        state: {curEnv, user}
    } = useAccounts();

    useEffect(() => {
        const onLoad = () => {
            getExploreHistoryAPI(curEnv)
                .then(data => {                    
                    if (data !== undefined && Array.isArray(data) && data.length > 0) {
                        setExploreHistoryList([]);
                        data.forEach(item => {
                            const tempItem = {} as IExploreHistoryItem;
                            tempItem.created = item["created"].split('T')[0];
                            tempItem.createdBy = item["createdby"];
                            tempItem.exploreId = item["exploreid"];
                            tempItem.key = item["key"];
                            tempItem.listLength = item["list_length"];
                            tempItem.sequence = item["sequence"];
                            tempItem.status = item["status"];
                            tempItem.title = item["title"];
                            tempItem.description = item["description"] === null ? "" : item["description"];
                            tempItem.updated = item["updated"].split('T')[0];
                            tempItem.updatedBy = item["updatedby"] === null ? "" : item["updatedby"];
                            tempItem.editing = false;
                            setExploreHistoryList(oldData => [...oldData, tempItem]);
                        });
                    }
                }).catch(err => {
                    console.log(err);
                })
        }

        onLoad()
    }, [editCount, curEnv]);

    const toggleEdit = (e: any, index: number) => {
        e.preventDefault();
        const tempExploreItem = exploreHistoryList[index];
        if (tempExploreItem !== undefined || tempExploreItem !== null) {
            tempExploreItem.editing = !tempExploreItem.editing;
            setExploreHistoryList(
                exploreHistoryList.map((item, itemIndex) => {
                    if (itemIndex === index) {
                        return tempExploreItem;
                    } else {
                        return item;
                    }
                })
            );
            setEditingMode(tempExploreItem.editing);

            if (tempExploreItem.editing) {
                
                setEditingItem({
                    ...tempExploreItem
                });
            } else {
                setEditingItem({} as IExploreHistoryItem);
            }
        }        
    }

    const updateExploreItem = (e: any) => {
        e.preventDefault();
        // env: number,
        // exploreId: number,
        // title: string,
        // key:string,
        // sequence: number,
        // listLength: number,
        // status: number,
        // updatedBy: string

        // Validation Checks
        if (editingItem.title === "" || editingItem.title === undefined || editingItem.title === null) {
            toast.error("Invalid title");
            return;
        }

        if (editingItem.sequence === undefined || editingItem.sequence <= 0) {
            toast.error("Invalid sequence");
            return;            
        }

        if (editingItem.status < 0 || editingItem.status > 1) {
            toast.error("Invalid status");
            return;
        }

        // Done with the validation checks
        setLoading(true);
        updateExploreItemAPI(
            curEnv,
            editingItem.exploreId,
            editingItem.title,
            editingItem.description,
            editingItem.key,
            editingItem.sequence,
            editingItem.listLength,
            editingItem.status,
            user?.email !== undefined ? user?.email : "feedback@investmates.io"
        ).then(data => {                        
            toast.success("Updated explore item");
            setExploreHistoryList(exploreHistoryList.map(item => {
                if (item.exploreId === editingItem.exploreId)
                    return {
                        ...editingItem,
                        editing: false
                    }
                return item;
            }));
            setEditCount(editCount + 1);
        }).catch(err => {
            console.log(err);
            toast.error("Error while updating explore item");
            setExploreHistoryList(exploreHistoryList.map(item => {
                if (item.exploreId === editingItem.exploreId) {
                    return {
                        ...item,
                        editing: false
                    } as IExploreHistoryItem;
                }                    
                return item;
            }));
        }).finally(() => {
            setLoading(false);
        });
    }

    const fillTable = () => {
        return exploreHistoryList.sort((a, b) => {
            return b.status - a.status;
        }).map((item, index) => {
            return (
                loading && item.exploreId === editingItem.exploreId ? <Loading />
                :
                <tr key={item.exploreId} className="border-b">
                    <td className="px-2 py-2 text-sm font-light text-gray-900">
                        {item.editing ? "" : item.title}
                        <input 
                            type="text" 
                            placeholder="Edit title"
                            value={editingItem.title}
                            onChange={e => setEditingItem({
                                ...editingItem,
                                title: e.target.value
                            })}
                            className={`text-xs rounded-full ${item.editing ? '' : 'hidden'}`}
                        />
                    </td>
                    <td className="px-2 py-2 text-sm font-light text-gray-900">
                        {item.editing ? "" : item.description}
                        <input 
                            type="text" 
                            placeholder="Edit description"
                            value={editingItem.description}
                            onChange={e => setEditingItem({
                                ...editingItem,
                                description: e.target.value
                            })}
                            className={`text-xs rounded-full ${item.editing ? '' : 'hidden'}`}
                        />
                    </td>
                    <td>
                        {item.key}
                        {/* <select 
                            className={`text-xs rounded-full ${item.editing ? "visible" : "hidden"}`} value={editingItem.key}
                            onChange={e => setEditingItem({
                                ...editingItem,
                                key: e.target.value
                            })}
                        >
                            <option value="im_recommended">im_recommended</option>
                            <option value="comm_suggestion">comm_suggestion</option>
                            <option value="hashtag_trend">hashtag_trend</option>
                            <option value="investor_top">investor_top</option>
                            <option value="asset_trend">asset_trend</option>
                            <option value="asset_topholding">asset_topholding</option>
                            <option value="investor_new">investor_new</option>
                            <option value="comm_top">comm_top</option>
                            <option value="comm_recent">comm_recent</option>
                        </select> */}
                    </td>
                    <td>
                        {item.editing ? "" : item.sequence}
                        <input 
                            type="number"                            
                            className={`text-xs rounded-full ${item.editing ? "visible" : "hidden"}`}
                            placeholder="Set sequence"
                            value={editingItem.sequence}
                            onChange={e => setEditingItem({
                                ...editingItem,
                                sequence: Number(e.target.value)
                            })}
                        />
                    </td>
                    <td>
                        {item.editing ? "" : item.listLength}
                        <input
                            type="number"
                            className={`text-xs rounded-full ${item.editing ? "visible" : "hidden"}`}
                            placeholder="Set list length"
                            value={editingItem.listLength}
                            onChange={e => setEditingItem({
                                ...editingItem,
                                listLength: Number(e.target.value)
                            })}
                        />
                    </td>
                    <td className={`${item.status === 1 ? 'text-green-600' : 'text-red-600' }`}>
                        {item.editing ? "" : item.status === 1 ? "Active": "Inactive"}
                        <select 
                            className={`rounded-full text-xs ${item.editing ? "visible" : "hidden"}`} 
                            value={editingItem.status}
                            onChange={e => setEditingItem({
                                ...editingItem,
                                status: Number(e.target.value)
                            })}
                        >
                            <option value={1}>Active</option>
                            <option value={0}>Inactive</option>
                        </select>
                    </td>
                    <td className={`py-2 px-2 ${editingMode ? 'hidden' : 'visible'}`}>
                        {item.created}
                    </td>
                    <td className={`py-2 px-2 ${editingMode ? 'hidden' : 'visible'}`}>
                        {item.createdBy}
                    </td>
                    <td className={`py-2 px-2 ${editingMode ? 'hidden' : 'visible'}`}>
                        {item.updated}
                    </td>
                    <td>
                        <button onClick={e => toggleEdit(e, index)}>
                            <FontAwesomeIcon className={`${item.editing ? 'hidden' : 'visible'}`} icon={faEdit} />
                            <FontAwesomeIcon className={`${item.editing ? 'visible' : 'hidden'}`} icon={faCancel} />
                        </button>
                        <button 
                            className={`ml-2 ${item.editing ? "visible" : "hidden"}`}
                            onClick={e => updateExploreItem(e)}
                        >
                            <FontAwesomeIcon icon={faSave} />
                            
                        </button>                                              
                    </td>
                </tr>                
            );
        });
    }

    return (        
        <div className="relative overflow-auto mr-4 ml-4 mt-2">            
            <table className="border w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-100 dark:text-gray-400">
                    <th scope="col" className="py-2 px-2">
                        Title
                    </th>
                    <th scope="col" className="py-2 px-2">
                        Description
                    </th>
                    <th scope="col" className="py-2 px-2">
                        Key
                    </th>
                    <th scope="col" className="py-2 px-2">
                        Sequence
                    </th>
                    <th scope="col" className="py-2 px-2">
                        List Length
                    </th>
                    <th scope="col" className="py-2 px-2">
                        Status
                    </th>
                    <th scope="col" className={`py-2 px-2 ${editingMode ? 'hidden' : 'visible'}`}>
                        Created On
                    </th>
                    <th scope="col" className={`py-2 px-2 ${editingMode ? 'hidden' : 'visible'}`}>
                        Created By
                    </th>
                    <th scope="col" className={`py-2 px-2 ${editingMode ? 'hidden' : 'visible'}`}>
                        Updated On
                    </th>
                    <th scope="col" className="py-2 px-2">
                        Actions
                    </th>
                </thead>
                <tbody>
                    {
                        fillTable()
                    }
                </tbody>
            </table>
        </div>
    );
}

export default HistoryExplore;