import {useAccounts} from "../accounts/context";
import {useEffect, useState} from "react";
import {cloneContestAPI, endContestAPI, getAllContestAPI} from "../../api/contest";
import {IContestType, isAdminUser} from "../../utils";
import toast from "react-hot-toast";
import {Auth} from '@aws-amplify/auth';

const ContestList = () => {

    const [contestList, setContestList] = useState<Array<IContestType>>([]);
    const [contestFilter, setContestFilter] = useState("ALL");
    const [subFilter, setSubFilter] = useState("MAX_ENG");
    const [subFilterList, setSubFilterList] = useState<Array<any>>(["ALL"]);
    const [contestTypeFilter, setContestTypeFilter] = useState("ALL");
    const [statusFilter, setStatusFilter] = useState("ALL");
    const [isAdmin, setIsAdmin] = useState(false);

    const {
        state: {curEnv}
    } = useAccounts();



    useEffect(() => {
        const getContestList = () => {
            getAllContestAPI(curEnv)
                .then((data: any) => {                    
                    setContestList([]);
                    data.forEach((contest: any) => {
                        const tempContest = {} as IContestType;
                        tempContest.contestId = contest["contestid"];
                        tempContest.contestName = contest["contestname"];
                        tempContest.contestDesc = contest["contestdesc"];
                        const startDate = contest["start_date"].split("T")[0];
                        const endDate = contest["end_date"].split("T")[0];
                        tempContest.contestStatus = getContestStatus(startDate, endDate);
                        tempContest.duration = startDate + " to " + endDate;
                        tempContest.contestType = contest["contesttype"];
                        tempContest.createdBy = contest["createdby"];
                        tempContest.totalRewards = contest["total_reward"];
                        tempContest.rewardsGiven = 0;
                        tempContest.usersJoined = 0;
                        tempContest.usersWon = 0;
                        tempContest.tnc = contest["tnc"];
                        setContestList(oldData => [...oldData, tempContest]);
                    });
                }).catch(err => {
                    toast.error("Error while loading the contest list");
                });
        }
        getContestList();
    }, [curEnv]);

    useEffect(() => {
        const onLoad = () => {
            isAdminUser()
                .then(status => {
                    if (status === true) {
                        setIsAdmin(true);
                    } else {
                        setIsAdmin(false);
                    }
                }).catch(err => {
                    setIsAdmin(false);
                    console.log(err);
                });            
        }

        onLoad();
    });

    const fillTable = () => {
        return contestList.filter((data) => {
            // Filtering the contest type
            if (contestFilter === "CONTEST_TYPE") {
                switch (subFilter) {
                    case "ALL":
                        return true;
                    case "MAX_POSTS":
                        return data.contestType === "MAX_POSTS";
                    case "MAX_ENG":
                        return data.contestType === "MAX_ENG";
                    default:
                        return true;
                }
            } else if (contestFilter === "STATUS") {
                switch (subFilter) {
                    case "ALL":
                        return true;
                    case "ENDED":
                        return data.contestStatus === "ENDED";
                    case "LIVE":
                        return data.contestStatus === "LIVE";
                    case "UPCOMING":
                        return data.contestStatus === "UPCOMING";
                    default: return true;
                }
            }
            return true;
        }).map((contest, index) => {
            return <tr key={index} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                <td className="py-4 px-6">
                    {contest.contestName}
                </td>
                <td className="py-4 px-3">
                    {contest.contestDesc}
                </td>
                <td className="py-4 px-3">
                    {contest.contestStatus}
                </td>
                <td className="py-4 px-1">
                    {contest.duration}
                </td>
                <td className="py-4 px-3">
                    {contest.contestType}
                </td>
                <td className="py-4 px-3">
                    {contest.createdBy}
                </td>
                <td className="py-4 px-3">
                    {contest.totalRewards}
                </td>
                <td className="py-4 px-3">
                    {contest.rewardsGiven}
                </td>
                <td className="py-4 px-3">
                    {contest.usersJoined}
                </td>
                <td className="py-4 px-3">
                    {contest.usersWon}
                </td>
                <td className="py-4 px-3">
                    {contest.tnc}
                </td>
                <td className="py-4 px-3">
                    <ul>
                        <li>
                            <a href="#">Download leaderboard</a>
                        </li>
                        {
                            isAdmin ? 
                            <li>
                                <a href="#" onClick={e => endContest(e, contest.contestId)}>End</a>
                            </li>
                             : ''
                        }
                        {
                            isAdmin ?
                            <li>
                                <a href="#" onClick={e => cloneContest(e, contest.contestId)}>Clone</a>
                            </li> : ''
                        }                                        
                    </ul>
                </td>
            </tr>
        })
    }

    const getContestStatus = (startDate: string, endDate: string) => {
        const today = new Date();
        let dd = today.getDate().toString();
        let mm = (today.getMonth() + 1).toString();
        const year = today.getFullYear();

        if (Number(dd) < 10) {
            dd = '0' + dd;
        }

        if(Number(mm) < 10) {
            mm = '0' + mm;
        }

        const todayDate = year + '-' + mm + '-' + dd;        

        if (todayDate > endDate) {
            return "ENDED";
        }

        if (startDate <= todayDate && endDate >= todayDate) {
            return "LIVE"
        }

        return "UPCOMING";
    }

    // Ending the contest function
    const endContest = (e: any, contestId: number) => {
        e.preventDefault();
        endContestAPI(1, contestId)
            .then(data => {                
                toast.success("The contest was ended");
            }).catch(err => {
                console.log(err);
                toast.error("Error ending the contest");
            })
    }

    const cloneContest = (e: any, contestId: number) => {
        e.preventDefault();
        cloneContestAPI(1, contestId, "2022-11-08", "2022-11-11")
            .then(data => {
                toast.success("Contest was cloned");
            }).catch(err => {
                console.log(err);
                toast.error("Error while cloning the contest");
            });
    }

    const handleFilterChange = (e: any) => {
        e.preventDefault();
        switch (e.target.value) {
            case "ALL":
                setContestFilter("ALL");
                setSubFilter("ALL");
                setSubFilterList(["ALL"]);
                break;
            case "CONTEST_TYPE":
                setContestFilter("CONTEST_TYPE");
                setSubFilter("ALL");
                setSubFilterList([]);
                setSubFilterList(oldValue => [...oldValue, ...getContestTypeList()]);
                break;
            case "STATUS":
                setContestFilter("STATUS");
                setSubFilter("ALL");
                setSubFilterList([]);
                setSubFilterList(oldValue => [...oldValue, ...getStatusList()]);
                break;
            default:
                setContestFilter("ALL");
                setSubFilter("ALL");
                setSubFilterList([]);
                break;
        }
    }

    const handleSubFilterChange = (e: any) => {
        e.preventDefault();
    }

    const getContestTypeList = () => {
        return [
            "ALL",
            "MAX_POSTS",
            "MAX_ENG"
        ];
    }

    const getStatusList = () => {
        return [
            "ALL",
            "ENDED",
            "LIVE",
            "UPCOMING"
        ];
    }

    return (
            <div>
                <div className="overflow-x-auto relative mt-4">
                    <div>
                        <span className="mr-2">Filter:</span>
                        <select value={contestFilter} onChange={e => handleFilterChange(e)}>
                            <option value="ALL">All</option>
                            <option value="CONTEST_TYPE">Contest Type</option>
                            <option value="STATUS">Status</option>
                        </select>

                        <select value={subFilter} onChange={e => setSubFilter(e.target.value)}>
                            {
                                subFilterList.map(item => {
                                    return <option value={item}>{item}</option>
                                })
                            }
                        </select>
                    </div>
                    <table className="text-sm text-left text-gray-500 dark:text-gray-400">
                        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                            <tr>
                                <th scope="col" className="py-3 px-3">
                                    Contest Name
                                </th>
                                <th scope="col" className="py-3 px-3">
                                    Contest Description
                                </th>
                                <th scope="col" className="py-3 px-3">
                                    Status
                                </th>
                                <th scope="col" className="py-3 px-3">
                                    Duration
                                </th>
                                <th scope="col" className="py-3 px-3">
                                    Contest Type
                                </th>
                                <th scope="col" className="py-3 px-3">
                                    Created By
                                </th>
                                <th scope="col" className="py-3 px-3">
                                    Total Rewards
                                </th>
                                <th scope="col" className="py-3 px-3">
                                    Rewards Given
                                </th>
                                <th scope="col" className="py-3 px-3">
                                    Users Joined
                                </th>
                                <th scope="col" className="py-3 px-3">
                                    Users Won
                                </th>
                                <th scope="col" className="py-3 px-3">
                                    TnC
                                </th>
                                <th scope="col" className="py-3 px-3">
                                    Actions
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                fillTable()
                            }
                        </tbody>
                    </table>
                </div>
            </div>
    );
}

export default ContestList;