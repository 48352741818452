import { Tab } from "@headlessui/react";
import { useState } from "react";
import TabTitle from "../../components/common/tabTitle";
import BannerHistory from "../../components/create/bannerHistory";
import CreateBanner from "../../components/create/createBanner";

const Create = () => {
    const [selectedIndex, setSelectedIndex] = useState(0);
    return (
        <Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
            <Tab.List>
                <Tab
                    className={`
                    ${selectedIndex === 0 ? 'bg-[#6415ff]' : ''}
                    ml-2
                    rounded-full border-solid border-2 border-[#6415ff]`}
                >
                    <TabTitle
                        title="Create Banner" selected={selectedIndex === 0} />
                </Tab>
                <Tab
                    className={`
                    ${selectedIndex === 1 ? 'bg-[#6415ff]' : ''}
                    ml-2
                    rounded-full border-solid border-2 border-[#6415ff]`}
                >
                    <TabTitle
                        title="Banner History" selected={selectedIndex === 1} />
                </Tab>
            </Tab.List>
            <Tab.Panels>
                <Tab.Panel>
                    <CreateBanner />
                </Tab.Panel>
                <Tab.Panel>
                    <BannerHistory />
                </Tab.Panel>
            </Tab.Panels>
        </Tab.Group>
    )
}

export default Create;
