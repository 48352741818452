import { useCallback } from "react";
import { isNumber } from "../../utils";
import { FormSelect } from "../forms/selects";
import { TextInput } from "../forms/text-inputs";
import QueryFilter from "../report/reportFilter/components/queryFilter";
import { useFeedbackContext } from "./context";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";


const FeedbackFilter = () => {
  const { state, actions } = useFeedbackContext();
  const minStart = new Date().toISOString().split('T')[0];

  function handleFilterChange(e: any) {
    state.srcFilter = e.target.value;
    actions.setSrcFilter && actions.setSrcFilter(Number(e.target.value));
  }

  function handleTagsFilter(e: any) {
    state.tagFilter = e.target.value;
    actions.setTagFilter && actions.setTagFilter(Number(e.target.value))
  }

  function handleStatusfilter(e: any) {
    state.statusFilter = e.target.value;
    actions.setStatusFilter && actions.setStatusFilter(Number(e.target.value))
  }

  function handleUserSearch(e: any) {
    e.preventDefault();
    state.userFilter = e.target.value;
    actions.setUserFilter && actions.setUserFilter(e.target.value);
  }

  const renderPageLinks = useCallback(() => {
    if(state.totalPages === 0) return null;
    const visiblePageButtonCount = 5;
    let numberOfButtons = state.totalPages < visiblePageButtonCount ? state.totalPages : visiblePageButtonCount;
    const pageIndices = [state.currentPage-1];
    numberOfButtons--;
    [...Array(numberOfButtons)].forEach((_item, itemIndex) => {
      const pageNumberBefore = pageIndices[0] - 1;
      const pageNumberAfter = pageIndices[pageIndices.length - 1] + 1;
      if (
        pageNumberBefore >= 0 &&
        (itemIndex < numberOfButtons / 2 || pageNumberAfter > state.totalPages - 1)
      ) {
        pageIndices.unshift(pageNumberBefore);
      } else {
        pageIndices.push(pageNumberAfter);
      }
    });
    return pageIndices.map((pageIndexToMap) => (
      <button
      key={pageIndexToMap}
        className={`${state.currentPage === (pageIndexToMap + 1) ? "z-10 bg-indigo-600 text-white" : "text-gray-900"} relative inline-flex items-center px-4 py-2 text-sm font-semibold  ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0`}
        onClick={() => actions.setCurrentPage && actions.setCurrentPage(pageIndexToMap+1)}>
        {pageIndexToMap+1}
      </button>
    ));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.currentPage, state.totalPages]);
  return (
    <div className="flex flex-col">
      <div className="flex flex-wrap items-center justify-between pb-5 pl-5">
        <FormSelect name="filter" label="" value={String(state.srcFilter)} onChange={handleFilterChange}>
          <option value="0">All feeds</option>
          <option value="1">Inapp feed</option>
          <option value="2">Playstore feed</option>
          <option value="3">Appstore feed</option>
        </FormSelect>

        <FormSelect name="tags" label="" value={String(state.tagFilter)} onChange={handleTagsFilter}>
          <option value="0">All Tags</option>
          <option value="1">suggestion</option>
          <option value="2">grievance</option>
          <option value="3">help</option>
          <option value="4">suspend</option>
          <option value="5">CommunityExit</option>
          <option value="6">unknown</option>
        </FormSelect>
        <FormSelect name="status" label="" value={String(state.statusFilter)} onChange={handleStatusfilter}>
          <option value="0">All Status</option>
          <option value="1">Unattended</option>
          <option value="2">In Review</option>
          <option value="3">Closed</option>
        </FormSelect>
        <QueryFilter value={state.showDeleted} onChange={actions?.setShowDeleted} />
        <div className="flex items-center mt-3 justify-evenly">
          <TextInput
              type="date"
              name="from"
              label=""
              max={minStart}
              value={state.fromDate}
              onChange={(evt) => actions.setFromDate && actions.setFromDate(evt.target.value)}
          />
          <span className="mx-2">To</span>
          <TextInput
              type="date"
              name="to"
              label=""
              max={minStart}
              value={state.toDate}
              onChange={(evt) => actions.setToDate && actions.setToDate(evt.target.value)}
          />
        </div>
        <div className="flex flex-row mt-2 items-center justify-center">
          <div>
            <input
              type="text"
              className="w-full rounded-full"
              placeholder="search user"
              onChange={e => handleUserSearch(e)}
            />
          </div>
          <div className="ml-2">
            <span>{state.list.length}</span>
            <span><b>&nbsp;feedbacks</b></span>
          </div>
        </div>
      </div>

      <div className="px-2 pb-2 mx-auto">
        <nav className="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
          <button
            className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
            onClick={() => actions.setCurrentPage && actions.setCurrentPage(state.currentPage - 1)}
            disabled={state.currentPage <= 1}
          >
            <span className="sr-only">Previous</span>
            <FaChevronLeft className="h-5 w-5" aria-hidden="true" />
          </button>
          {renderPageLinks()}
          <button
            className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
            onClick={() => actions.setCurrentPage && actions.setCurrentPage(state.currentPage + 1)}
            disabled={state.currentPage >= state.totalPages}
          >
            <span className="sr-only">Next</span>
            <FaChevronRight className="h-5 w-5" aria-hidden="true" />
          </button>
        </nav>
      </div>
    </div>
  );
}


export default FeedbackFilter;
