import {useEffect, useState} from "react";
import {Tab} from "@headlessui/react";
import PostReports from "../components/report/postReports";
import clsx from "clsx";
import UserReports from "../components/report/userReports";
import TabTitle from "../components/common/tabTitle";
import { useSearchParams } from "react-router-dom";
import toast from "react-hot-toast";

const Report = () => {
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [params, setParams] = useSearchParams();

    useEffect(() => {
        const onLoad = () => {
            const curIndex = params.get("selectedindex");
            if (curIndex !== undefined && curIndex !== null && curIndex !== "") {
                Number(curIndex) >= 0 && Number(curIndex) <= 1 ? setSelectedIndex(Number(curIndex)) : setSelectedIndex(0);
            }
        }
        onLoad();
    }, []);

    return (
            <Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
                <Tab.List>
                    <Tab
                        className={`
                        ${selectedIndex === 0 ? 'bg-[#6415ff]' : ''}
                        ml-2
                        rounded-full border-solid border-2 border-[#6415ff]`}
                    >
                        <TabTitle
                            title="Post Reports" selected={selectedIndex === 0} />                        
                    </Tab>
                    <Tab
                        className={`
                        ${selectedIndex === 1 ? 'bg-[#6415ff]' : ''}
                        ml-2
                        rounded-full border-solid border-2 border-[#6415ff]`}
                    >
                        <TabTitle
                                title="User Reports" selected={selectedIndex === 1} />
                    </Tab>
                </Tab.List>
                <Tab.Panels>
                    <Tab.Panel><PostReports /> </Tab.Panel>
                    <Tab.Panel><UserReports /> </Tab.Panel>
                </Tab.Panels>
            </Tab.Group>
    );
}


export default Report;