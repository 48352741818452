import { ON_LOGOUT } from "../context";
import { ADD_FB_COUNT, ADD_FB_TRASH_COUNT } from "./actions";

export interface IfeedbackType {
  count?: number;
  inC?: number;
  playC?: number;
  appC?: number;
  charts?: Array<Array<number>>;
  tagCharts?: Array<number>;
}
const initialState = {

};

export function feedbackReducer(state: IfeedbackType = initialState, action: any) {
  switch (action.type) {
    case ADD_FB_COUNT:
      return { ...state, ...action.payload }
    case ADD_FB_TRASH_COUNT:
      return { ...state, ...action.payload }
    case ON_LOGOUT:
      return initialState;
    default:
      return state;
  }
}
