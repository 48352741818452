import { TextInput } from "flowbite-react";
import { useState } from "react";
import toast from "react-hot-toast";
import { copyToClipboard, isValidUrl, PRIMARY_BUTTON_STYLE } from "../../utils";

const NewsNotification = () => {

    const [newsTitle, setNewsTitle] = useState("");
    const [newsUrl, setNewsUrl] = useState("");
    const [description, setDescription] = useState("");
    const [imageUrl, setImageUrl] = useState("");
    const [newsSource, setNewsSource] = useState("");
    const [publishDate, setPublishDate] = useState("");
    const [payload, setPayload] = useState("");

    const isValid = () => {
        if (newsTitle === undefined || newsTitle === null || newsTitle === '') {
            toast.error("Please add a news title");
            return false;
        }

        if (newsUrl === undefined || newsUrl === null || newsUrl === '') {
            toast.error("Please add a news URL");
            return false;
        }

        if (!isValidUrl(newsUrl)) {
            toast.error("Please enter a valid news URL");
            return false;
        }

        if (description === undefined || description === null || description === '') {
            toast.error("Please add a description");
            return false;
        }

        if (imageUrl === undefined || imageUrl === null || imageUrl === "") {
            toast.error("Please add a image url");
            return false;
        }

        if (!isValidUrl(imageUrl)) {
            toast.error("Please enter a valid image url");
            return false;
        }

        if (newsSource === undefined || newsSource === null || newsSource === '') {
            toast.error("Please enter a news source");
            return false;
        }

        if (publishDate === undefined || publishDate === null || publishDate === '') {
            toast.error("Please enter a publish date");
            return false;
        }

        return true;        

    }

    const generatePayload = (e: any) => {
        e.preventDefault();        
        if (isValid()) {
            const tempObject = {
                "newstitle": newsTitle,
                "newsurl": newsUrl,
                "description": description,
                "imageurl": imageUrl,
                "source": newsSource,
                "publishTime": new Date(publishDate).toISOString()
            }
    
            const jsonString = JSON.stringify(tempObject);        
            setPayload(btoa(jsonString));
        }        
    }

    const handleCopyClick = (e: any) => {
        e.preventDefault();
        copyToClipboard(payload);
        toast.success("copied to clipboard");
    }    

    return (
        <div className="m-4">
            <div className="flex flex-row">
                <div>
                    <table>
                        <tbody>
                            <tr>
                                <td>News Title</td>
                                <td>
                                    <input 
                                        type="text" 
                                        placeholder="News Title"
                                        value={newsTitle}
                                        onChange={e => setNewsTitle(e.target.value)}
                                    />
                                </td>
                            </tr>

                            <tr>
                                <td>News Url</td>
                                <td>
                                    <input 
                                        type="url" 
                                        placeholder="News Url" 
                                        value={newsUrl}
                                        onChange={e => setNewsUrl(e.target.value)}
                                    />
                                </td>
                            </tr>

                            <tr>
                                <td>Description</td>
                                <td>
                                    <textarea 
                                        placeholder="Description"
                                        value={description}
                                        onChange={e => setDescription(e.target.value)}
                                    />
                                </td>
                            </tr>

                            <tr>
                                <td>Image Url</td>
                                <td>
                                    <input 
                                        type="text" 
                                        placeholder="Image Url" 
                                        value={imageUrl}
                                        onChange={e => setImageUrl(e.target.value)}
                                    />
                                </td>
                            </tr>

                            <tr>
                                <td>Source</td>
                                <td>
                                    <input 
                                        type="text" 
                                        placeholder="News Source" 
                                        value={newsSource}
                                        onChange={e => setNewsSource(e.target.value)}
                                    />
                                </td>
                            </tr>

                            <tr>
                                <td>Publish Time</td>
                                <td>
                                    <TextInput
                                        type={"datetime-local"} 
                                        value={publishDate}
                                        onChange={e => setPublishDate(e.target.value)}
                                    />
                                </td>
                            </tr>

                            <tr>
                                <td></td>
                                <td className="pt-4">
                                    <button
                                        className={`${PRIMARY_BUTTON_STYLE}`}
                                        onClick={e => generatePayload(e)}
                                    >
                                        Generate Payload
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className={`${payload === '' ? 'hidden' : 'visible'} w-48 ml-4 break-all`}>
                    <div>
                        {payload}
                    </div>
                    <div className="mt-2">
                        <button onClick={e => handleCopyClick(e)}>Copy to clipboard</button>
                    </div>
                </div>
            </div>            
        </div>
    );
}

export default NewsNotification;