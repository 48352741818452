import {useNavigate, useParams} from "react-router-dom";
import { useEffect, useState} from "react";
import {useAccounts} from "../accounts/context";
import {
    deleteReportedPostAPI,
    getReportDetailsAPI,
    ignorePostReportAPI,
    suspendUserAPI,
    warnUserAPI
} from "../../api/report";
import toast from "react-hot-toast";
import { IReporterdetails, isAdminUser, isValidEmail, WRN_PR} from "../../utils";
import {Auth} from '@aws-amplify/auth';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLeftLong } from "@fortawesome/free-solid-svg-icons";

const ReportDetails = () => {
    const {
        state: {curEnv, user}
    } = useAccounts();
    const {id} = useParams();
    const [name, setName] = useState("");
    const [userId, setUserId] = useState(0);
    const [postId, setPostId] = useState(0);
    const [postType, setPostType] = useState("");
    const [userImage, setUserImage] = useState("");
    const [userHandle, setUserHandle] = useState("");
    const [reportCount, setReportCount] = useState(0);
    const [reportsOnPost, setReportsOnPost] = useState(0);
    const [acquisitionDate, setAcquisitionDate] = useState("");
    const [lastActiveDate, setLastActiveDate] = useState("");
    const [warningCount, setWarningCount] = useState(0);
    const [content, setContent] = useState("");
    const [postTimestamp, setPostTimestamp] = useState("");
    const [toggleDropdown, setToggleDropdown] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const [reporters, setReporters] = useState<Array<IReporterdetails>>([]);
    const navigate = useNavigate();

    useEffect(() => {
        const onLoad = () => {
            isAdminUser()
                .then(status => {
                    if (status === true) {
                        setIsAdmin(true);
                    } else {
                        setIsAdmin(false);
                    }
                }).catch(err => {
                    setIsAdmin(false);
                    console.log(err);
                });            
        }

        onLoad();
    });


    useEffect(() => {
        const onLoad = async () => {
            console.log("Report Id: ", id);
            if (id !== undefined) {
                const reportCategory = id.substring(0, 3);
                const postid = id.substring(3, id.length);
                setPostType(reportCategory);
                setPostId(Number(id.substring(3, id.length)));
                const result = await getReportDetailsAPI(curEnv, Number(postid), reportCategory);
                console.log(result);
                clearData();
                setName(result[0][0].name);
                setUserImage(result[0][0].thumbimage);
                setUserHandle(result[0][0].userhandle);
                setPostTimestamp(result[0][0].created);
                setUserId(result[0][0].userid);
                setReportsOnPost(result[0][0].report_count);
                setAcquisitionDate(result[0][0].acquisition_date);
                setReportCount(result[0][0].total_reported);
                setLastActiveDate(result[0][0].last_active);
                setContent(result[0][0].content);
                setWarningCount(result[0][0].total_warnings);

                // setting up the reporters array
                if (result[2] !== undefined && result[2].length > 0) {
                    result[2].forEach((item: any) => {
                        const tempReporter = {} as IReporterdetails;
                        tempReporter.userId = item["userid"];
                        tempReporter.name = item["reporter_name"];

                        setReporters(oldData => [...oldData, tempReporter]);
                    });
                }
            } else {
                console.log("undefined report id");
            }
        }
        onLoad();

    }, [curEnv, id]);

    const clearData = () => {
        setName("");
        setUserId(0);
        setUserHandle("");
        setReportCount(0);
        setReportsOnPost(0);
        setAcquisitionDate("");
        setLastActiveDate("");
        setContent("");
        setUserImage("");
        setPostTimestamp("");
        setReporters([]);
    }

    const suspendUser = (e: any, suspendType: string) => {
        e.preventDefault();
        if (isAdmin) {
            let email = user?.email;
            if (email === undefined || email === "") {
                email = "feedback@investmates.io";
            }

            setToggleDropdown(false);
            console.log("userid: ", userId);
            console.log("suspend type: ", suspendType);
            console.log("email: ", email);

            if (isValidEmail(email) && userId > 0 && (suspendType === "TEMP" || suspendType === "PERM" )) {
                suspendUserAPI(curEnv, userId, suspendType, email.toString())
                .then(data => {
                    console.log("user suspended");
                    toast.success("User was suspended");
                }).catch(err => {
                    console.log(err);
                    toast.error("Error while suspending error");
                });
            }
        } else {
            toast.error("You need to be admin to suspend the user");
        }        
    }

    const ignorePostReport = (e: any) => {
        e.preventDefault();        
        if (isAdmin) {
            let email = user?.email;
            if (email === undefined || email === "") {
                email = "feedback@investmates.io";
            }
            setToggleDropdown(false);
            if (isValidEmail(email) && postId > 0 && (postType === "HMF" || postType === "HMC" || postType === "CMF")) {
                ignorePostReportAPI(curEnv, postId, postType, email)
                .then(data => {
                    toast.success("Post report was ignored");
                }).catch(err => {
                    console.log(err);
                    toast.error("Error while ignoring post report");
                });
            } else {
                toast.error("Invalid input data");
            }
        } else {
            toast.error("You need to be admin to ignore the post report");
        }        
    }

    const removePost = (e: any) => {
        e.preventDefault();
        if (isAdmin) {
            let email = user?.email;
            if (email === undefined || email === "") {
                email = "feedback@investmates.io";
            }

            setToggleDropdown(false);
            if (isValidEmail(email) && postId > 0 && (postType === "HMF" || postType === "HMC" || postType === "CMF")) {
                deleteReportedPostAPI(curEnv, postId, postType, email)
                    .then(data => {
                        toast.success("Deleted the post successfully");
                    }).catch(err => {
                        toast.error("Error deleting the post");
                    });
            } else {
                toast.error("Invalid input data");
            }
        } else {
            toast.error("You need to be admin to remove the post");
        }        
    }

    const warnUser = (e: any) => {
        e.preventDefault();
        if (isAdmin) {
            let email = user?.email;
            if (email === undefined || email === "") {
                email = "feedback@investmates.io";
            }
            setToggleDropdown(false);
            if (isValidEmail(email) && postId > 0 && (postType === "HMF" || postType === "HMC" || postType === "CMF")) {
                // env: number,
                // postId: number, 
                // postType: string, 
                // actionBy: string,
                // warnType: string,
                // userId: number
                warnUserAPI(
                    curEnv, 
                    postId, 
                    postType, 
                    email,
                    WRN_PR,
                    0)
                    .then(data => {
                        toast.success("Warning sent to the user");
                    }).catch(err => {
                        console.log(err);
                        toast.error("Error sending warning to the user");
                    })
            }
        } else {
            toast.error("You need to be admin to send warning to the user");
        }
    }

    const handleToggleClick = (e: any) => {
        e.preventDefault();
        setToggleDropdown(!toggleDropdown);
        setTimeout(() => {
            setToggleDropdown(false);
        }, 5000);
    } 
    
    const goBack = (e: any) => {
        e.preventDefault();
        navigate("/dashboard/report?selectedindex=0");
    }

    return (
            <div className="flex flex-col mt-6 ml-6 mr-4">
                <div className="flex flex-row w-full">
                    <span className="pr-4">
                        <a 
                            href=""
                            onClick={e => goBack(e)}
                            >
                            <FontAwesomeIcon
                                icon={faLeftLong}
                                size="lg"                          
                                className="mt-[50%] mb-[50%]"
                            />
                        </a>
                    </span>
                    <img src={userImage} className="flex-shrink-0 w-10 h-10 bg-gray-300 rounded-full" />
                    <span className="ml-2">{name} |</span>
                    <span className="ml-2">@{userHandle}</span>
                    <span className="ml-4">{postTimestamp}</span>
                    {
                        isAdmin ?
                        <div className="float-right ml-2">
                            {
                                isAdmin ? 
                                <button
                                    id="dropdownDefault"
                                    data-dropdown-toggle="dropdown"
                                    onClick={(e) => handleToggleClick(e)}
                                    className="ml-6 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" type="button">
                                    Actions
                                    <svg className="ml-2 w-4 h-4" aria-hidden="true" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
                                    </svg>
                                </button>
                                : ''
                            }                                                  
                            <div
                                id="dropdown"
                                className={`${toggleDropdown ? '' : 'hidden'} absolute ml-6 z-10 w-44 bg-white rounded divide-y divide-gray-100 shadow dark:bg-gray-700`}>
                                <ul className="py-1 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownDefault">
                                    <li>
                                        <a
                                            href=""
                                            onClick={(e) => ignorePostReport(e)}
                                            className="block py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                                            >Ignore</a>
                                    </li>
                                    <li>
                                        <a
                                            href=""
                                            onClick={(e) => removePost(e)}
                                            className="block py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                                            >Delete Post</a>
                                    </li>
                                    <li>
                                        <a
                                            href=""
                                            onClick={(e) => warnUser(e)}
                                            className="block py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                                            >Send Warning</a>
                                    </li>
                                    <li>
                                        <a
                                            href=""
                                            onClick={(e) => suspendUser(e, "TEMP")}
                                            className="block py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                                            >Suspend User - Temporary</a>
                                    </li>
                                    <li>
                                        <a
                                            href=""
                                            onClick={(e) => suspendUser(e, "PERM")}
                                            className="block py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                                            >Suspend User - Permanent</a>
                                    </li>
                                </ul>
                            </div>                                        
                        </div> : ''
                    }                    
                </div>
                <hr className="mt-4" />
                <div className="mt-2">
                    <span><b>Reported By: </b></span>
                    {
                        reporters.map((reporter, index) => {
                            return (
                                <span key={index}>
                                    {index === reporters.length - 1 ? reporter.name : reporter.name + ","}
                                </span>
                            )
                        })
                    }
                </div>
                <div className="flex mt-2">                    
                    <div className="w-1/2">
                        <span><b>User Id:</b> {userId}</span>
                    </div>
                    <div className="w-1/2">
                        <b>Report on this post: </b>{reportsOnPost}
                    </div>
                </div>
                <div className="flex mt-2">
                    <div className="w-1/2">
                        <span><b>Acquisition Date:</b> {acquisitionDate}</span>
                    </div>
                    <div className="w-1/2">
                        <b>Total No. of reports for this user: </b> {reportCount}
                    </div>
                </div>
                <div className="flex mt-2">
                    <div className="w-1/2">
                        <b>Last Active Date:</b> {lastActiveDate}
                    </div>
                    <div className="w-1/2">
                        <b>Warnings sent: </b> {warningCount}
                    </div>                
                </div>
                <div className="mt-2">
                    <b>Post Id:</b>&nbsp;{postId}
                </div>
                <div className="mt-2">
                    <b>Category: </b>Misleading : 1, Abusive / Hate speech : 10, Obscene : 1
                </div>
                <div className="mt-2">
                    {content}
                </div>
                <div className="flex flex-row">

                </div>
            </div>
    );
}

export default ReportDetails;