import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartData,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import ChartSelect from './chartSelect';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const GrowthChart = ({ title, data, chartType, setChart, skip = 0 }: { skip?: number; data: ChartData<"line", any>; chartType: number; setChart: (x: number) => void; title: string }) => {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: true,
        text: title,
      }
    },
  };
  return (
    <div className="p-3 my-3 border-2 rounded">
      <Line options={options} data={data} />
      <ChartSelect value={chartType} onChange={setChart} name={title} skip={skip} />
    </div>
  );
}

export default GrowthChart;
