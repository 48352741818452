import API from '@aws-amplify/api';
import { getEnvApi } from '../utils';

// title: string,
// key: string,
// sequence: number,
// createdBy: string,
// listLength: number
export function addExploreAPI(
    env: number,
    title: string,
    key: string,
    sequence: number,
    createdBy: string,
    listLength: number,
    description: string
    ) {
        return API.post(getEnvApi(env), '/admin/explore/create/all', {
            body: {
                "title": title,
                "key": key,
                "sequence": sequence,
                "createdBy": createdBy,
                "listLength": listLength,
                "description": description
            }
        });
}

export function getExploreHistoryAPI(env: number) {
    return API.post(getEnvApi(env), '/admin/explore/explore_history/all', {
        body: {

        }
    });
}

export function updateExploreItemAPI(
    env: number,
    exploreId: number,
    title: string,
    description: string,
    key:string,
    sequence: number,
    listLength: number,
    status: number,
    updatedBy: string
) {
    return API.post(getEnvApi(env), '/admin/explore/update_explore_item/all', {
        body: {
            "exploreId": exploreId,
            "title": title,
            "description": description,
            "key": key,
            "sequence": sequence,
            "listLength": listLength,
            "status": status,
            "updatedBy": updatedBy
        }
    });
}