import {PageContainer} from "../../pages/dashboard/feedback";
import {useAccounts} from "../accounts/context";
import {useEffect, useState} from "react";
import {getBasicChurnDataAPI} from "../../api/users";
import {getNextDate, getPercentage, percentageToOpacity} from "../../utils";

const ChurnData = () => {

    const {
        state: {curEnv, user}
    } = useAccounts();

    const [churnUserIdMap, setChurnUserIdMap] = useState<Map<number, any>>();
    const [dateMap, setDateMap] = useState<Map<string, Array<number>>>();

    useEffect(() => {
        const onLoad = async () => {
            const churnResult = await getBasicChurnDataAPI(curEnv);            
            const cleanedChurnData = cleanDates(churnResult);            
            churnUserIdMap?.clear();
            dateMap?.clear();
            setChurnUserIdMap(generateMap(cleanedChurnData));
            setDateMap(generateDateMap(cleanedChurnData));            
        }
        onLoad();
    }, [curEnv]);

    const cleanDates = (arr: Array<any>) => {
        return arr.filter((item: any) => {
            return (item["userid"] !== null && item["userid"] !== undefined && item["userid"] !== '') &&
            (item["created_date"] !== null && item["created_date"] !== undefined && item["created_date"] !== '') &&
            (item["created_hour"] !== null && item["created_hour"] !== undefined && item["created_hour"] !== '')
        }).map((item: any) => {            
            const tempObj: any = {};            
            tempObj.userid = item["userid"];
            tempObj.created_date = item["created_date"].split('T')[0];
            tempObj.created_hour = item["created_hour"];
            tempObj.latest_session = item["latest_session"].split('T')[0];
            tempObj.session_hour = item["session_hour"];
            return tempObj;
        });
    }

    const generateMap = (churnArr: Array<any>) => {
        const tempMap = new Map<number, any>();
        churnArr.forEach((item: any) => {
            const tempObj: any = {};
            tempObj.created_date = item["created_date"];
            tempObj.created_hour = item["created_hour"];
            tempObj.latest_session = item["latest_session"];
            tempObj.session_hour = item["session_hour"];
            tempMap.set(item["userid"], tempObj);
        });
        return tempMap;
    }

    const generateDateMap = (churnArr: Array<any>) => {
        const datesMap = new Map<string, Array<number>>();
        churnArr.forEach((item: any) => {
            if (datesMap.has(item["created_date"])) {
                datesMap.get(item["created_date"])?.push(item["userid"]);
            } else {
                datesMap.set(item["created_date"], [item["userid"]]);
            }
        });
        return datesMap;
    }

    const fillChurnTablePercentage = () => {
        const rowData = [];
        if (dateMap?.entries() !== undefined) {
            for (const entry of dateMap?.entries()) {
                const tempObj: any = {};
                tempObj.date = entry[0];
                tempObj.newUsers= entry[1].length;
                tempObj.D1 = getChurnValueByDayCount(entry[0], 1);
                tempObj.D3 = getChurnValueByDayCount(entry[0], 3);
                tempObj.D7 = getChurnValueByDayCount(entry[0], 7);
                tempObj.D15 = getChurnValueByDayCount(entry[0], 15);
                tempObj.D30 = getChurnValueByDayCount(entry[0], 30);
                tempObj.D40 = getChurnValueByDayCount(entry[0], 40);
                rowData.push(tempObj);
            }            
            return (
                    <tbody>
                        {
                            rowData.map((item: any) => {
                                return <tr className="border-b" key={item["date"]}>
                                    <td className="px-6 py-2 text-sm font-light text-gray-900 whitespace-nowrap">
                                        {item["date"]}
                                    </td>
                                    <td
                                        style={{backgroundColor: `rgba(100, 21, 255, ${percentageToOpacity(Number(getPercentage(item["newUsers"], item["newUsers"])))})`}}
                                        className="px-6 py-2 text-sm font-light text-gray-900 whitespace-nowrap">
                                        {getPercentage(item["newUsers"], item["newUsers"]) + "%"}
                                    </td>
                                    <td
                                        style={{backgroundColor: `rgba(100, 21, 255, ${percentageToOpacity(Number(getPercentage(item["newUsers"], item["D1"])))})`}}
                                        className="px-6 py-2 text-sm font-light text-gray-900 whitespace-nowrap">
                                        {getPercentage(item["newUsers"], item["D1"]) + "%"}
                                    </td>
                                    <td
                                        style={{backgroundColor: `rgba(100, 21, 255, ${percentageToOpacity(Number(getPercentage(item["newUsers"], item["D3"])))})`}}
                                        className="px-6 py-2 text-sm font-light text-gray-900 whitespace-nowrap">
                                        { getPercentage(item["newUsers"], item["D3"]) + "%"}
                                    </td>
                                    <td
                                        style={{backgroundColor: `rgba(100, 21, 255, ${percentageToOpacity(Number(getPercentage(item["newUsers"], item["D7"])))})`}}
                                        className="px-6 py-2 text-sm font-light text-gray-900 whitespace-nowrap">
                                        { getPercentage(item["newUsers"], item["D7"]) + "%"}
                                    </td>
                                    <td
                                        style={{backgroundColor: `rgba(100, 21, 255, ${percentageToOpacity(Number(getPercentage(item["newUsers"], item["D15"])))})`}}
                                        className="px-6 py-2 text-sm font-light text-gray-900 whitespace-nowrap">
                                        { getPercentage(item["newUsers"], item["D15"]) + "%"}
                                    </td>
                                    <td
                                        style={{backgroundColor: `rgba(100, 21, 255, ${percentageToOpacity(Number(getPercentage(item["newUsers"], item["D30"])))})`}}
                                        className="px-6 py-2 text-sm font-light text-gray-900 whitespace-nowrap">
                                        { getPercentage(item["newUsers"], item["D30"]) + "%"}
                                    </td>
                                    <td
                                        style={{backgroundColor: `rgba(100, 21, 255, ${percentageToOpacity(Number(getPercentage(item["newUsers"], item["D40"])))})`}}
                                        className="px-6 py-2 text-sm font-light text-gray-900 whitespace-nowrap">
                                        { getPercentage(item["newUsers"], item["D40"]) + "%"}
                                    </td>
                                </tr>
                            })
                        }
                    </tbody>
                    );
        } else {
            return (
                    <tbody></tbody>
                    );
        }
    }

    const fillChurnTable = () => {
        const rowData = [];
        if (dateMap?.entries() !== undefined) {
            for (const entry of dateMap?.entries()) {
                const tempObj: any = {};
                tempObj.date = entry[0];
                tempObj.newUsers= entry[1].length;
                tempObj.D1 = getChurnValueByDayCount(entry[0], 1);
                tempObj.D3 = getChurnValueByDayCount(entry[0], 3);
                tempObj.D7 = getChurnValueByDayCount(entry[0], 7);
                tempObj.D15 = getChurnValueByDayCount(entry[0], 15);
                tempObj.D30 = getChurnValueByDayCount(entry[0], 30);
                tempObj.D40 = getChurnValueByDayCount(entry[0], 40);
                rowData.push(tempObj);
            }            
            return (
                    <tbody>
                        {
                            rowData.map((item: any) => {
                                return <tr className="border-b" key={item["date"]}>
                                    <td className="px-6 py-2 text-sm font-light text-gray-900 whitespace-nowrap">
                                        {item["date"]}
                                    </td>
                                    <td
                                        style={{backgroundColor: `rgba(100, 21, 255, ${percentageToOpacity(Number(getPercentage(item["newUsers"], item["newUsers"])))})`}}
                                        className="px-6 py-2 text-sm font-light text-gray-900 whitespace-nowrap">
                                        {item["newUsers"]}
                                    </td>
                                    <td
                                        style={{backgroundColor: `rgba(100, 21, 255, ${percentageToOpacity(Number(getPercentage(item["newUsers"], item["D1"])))})`}}
                                        className="px-6 py-2 text-sm font-light text-gray-900 whitespace-nowrap">
                                        {item["D1"]}
                                    </td>
                                    <td
                                        style={{backgroundColor: `rgba(100, 21, 255, ${percentageToOpacity(Number(getPercentage(item["newUsers"], item["D3"])))})`}}
                                        className="px-6 py-2 text-sm font-light text-gray-900 whitespace-nowrap">
                                        {item["D3"]}
                                    </td>
                                    <td
                                        style={{backgroundColor: `rgba(100, 21, 255, ${percentageToOpacity(Number(getPercentage(item["newUsers"], item["D7"])))})`}}
                                        className="px-6 py-2 text-sm font-light text-gray-900 whitespace-nowrap">
                                        {item["D7"]}
                                    </td>
                                    <td
                                        style={{backgroundColor: `rgba(100, 21, 255, ${percentageToOpacity(Number(getPercentage(item["newUsers"], item["D15"])))})`}}
                                        className="px-6 py-2 text-sm font-light text-gray-900 whitespace-nowrap">
                                        {item["D15"]}
                                    </td>
                                    <td
                                        style={{backgroundColor: `rgba(100, 21, 255, ${percentageToOpacity(Number(getPercentage(item["newUsers"], item["D30"])))})`}}
                                        className="px-6 py-2 text-sm font-light text-gray-900 whitespace-nowrap">
                                        {item["D30"]}
                                    </td>
                                    <td
                                        style={{backgroundColor: `rgba(100, 21, 255, ${percentageToOpacity(Number(getPercentage(item["newUsers"], item["D40"])))})`}}
                                        className="px-6 py-2 text-sm font-light text-gray-900 whitespace-nowrap">
                                        {item["D40"]}
                                    </td>
                                </tr>
                            })
                        }
                    </tbody>
            );
        } else {
            return (
                    <tbody></tbody>
                );
        }
    }

    const getChurnValueByDayCount = (curDate: string, next: number) => {
        // get the next date
        const nextDate = getNextDate(curDate, next);
        let churnCount = 0;
        // get the userIds array from the dates map
        const userIds = dateMap?.get(curDate);
        if (userIds !== undefined) {
            userIds.forEach((userId: number) => {
                // for every user check if the last session date is eq.to next date
                if (churnUserIdMap?.get(userId) !== undefined) {
                    const userData = churnUserIdMap.get(userId);
                    if (userData["latest_session"] === nextDate) {
                        churnCount++;
                    }
                }
            });
            return churnCount
        } else {
            return 0;
        }
    }

    return (
            <div>
                <PageContainer>
                    <div className="overflow-x-auto relative ml-6">
                        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                            <thead className="text-xs text-gray-900 uppercase dark:text-gray-400">
                                <th scope="col" className="py-3 px-6">Date</th>
                                <th scope="col" className="py-3 px-6">New Users</th>
                                <th scope="col" className="py-3 px-6">D1</th>
                                <th scope="col" className="py-3 px-6">D3</th>
                                <th scope="col" className="py-3 px-6">D7</th>
                                <th scope="col" className="py-3 px-6">D15</th>
                                <th scope="col" className="py-3 px-6">D30</th>
                                <th scope="col" className="py-3 px-6">D40</th>
                            </thead>
                            {
                                fillChurnTablePercentage()
                            }
                        </table>
                        <br /><br /><br />
                    </div>
                </PageContainer>
                <PageContainer>
                    <div className="overflow-x-auto relative ml-6">
                        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                            <thead className="text-xs text-gray-900 uppercase dark:text-gray-400">
                                <th scope="col" className="py-3 px-6">Date</th>
                                <th scope="col" className="py-3 px-6">New Users</th>
                                <th scope="col" className="py-3 px-6">D1</th>
                                <th scope="col" className="py-3 px-6">D3</th>
                                <th scope="col" className="py-3 px-6">D7</th>
                                <th scope="col" className="py-3 px-6">D15</th>
                                <th scope="col" className="py-3 px-6">D30</th>
                                <th scope="col" className="py-3 px-6">D40</th>
                            </thead>
                            {
                                fillChurnTable()
                            }
                        </table>
                        <br /><br /><br />
                    </div>
                </PageContainer>
            </div>
    );
}

export default ChurnData;