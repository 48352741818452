import React from 'react';
import { Route, Navigate, useLocation } from 'react-router-dom';
import { useAccounts } from '../accounts/context';


export default function AuthenticatedRoute({
  component: C,
  path,
  ...rest
}: {
  path: string;
  component: any;
}) {
  return (
    <Route
      path={path}
      {...rest}
      element={
        <RequireAuth>
          <C />
        </RequireAuth>
      }
    ></Route>
  );
}

export function RequireAuth({ children }: { children: JSX.Element }) {
  const {
    state: { isAuthenticated },
  } = useAccounts();
  const location = useLocation();
  if (!isAuthenticated) {
    return (
      <Navigate
        to={`/login?redirect=${location.pathname}${location.search}`}
        replace
      />
    );
  }
  return children;
}
