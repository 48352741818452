import React, {
  createContext,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
} from 'react';

import { usersReducer } from './user/reducer';
import { commReducer } from './communities/reducer';
import { feedbackReducer } from './feedback/reducer';
import { reportReducer } from './report/reducer';
import { rewardReducer } from './reward/reducer';

export const ON_LOGOUT = { type: 'reset' };

type State = any;
type Dispatch = any;

const AppStateContext = createContext<
  { state: State; dispatch: Dispatch } | undefined
>(undefined);

const combineDispatch =
  (...dispatches: React.Dispatch<any>[]) =>
    (action: any) =>
      dispatches.forEach((dispatch) => dispatch(action));

export function AppStateProvider({
  isLoggedIn,
  currentStage,
  children,
}: {
  isLoggedIn: boolean;
  currentStage: string;
  children: ReactNode;
}) {

  const [Users, UsersDispatch] = useReducer(usersReducer, {});
  const [Comms, CommsDispatch] = useReducer(commReducer, {});
  const [FeedB, FeedBDispatch] = useReducer(feedbackReducer, {});
  const [Report, ReportDispatch] = useReducer(reportReducer, {});
  const [Reward, RewardDispatch] = useReducer(rewardReducer, {});

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const combinedDispatch = useCallback(
    combineDispatch(UsersDispatch, CommsDispatch, FeedBDispatch, ReportDispatch, RewardDispatch),
    [],
  );
  const combinedState = useMemo(
    () => ({ Users, Comms, FeedB, Report, Reward }),
    [Users, Comms, FeedB, Report, Reward],
  );

  useEffect(() => {
    if (!isLoggedIn) {
      const reset = () => combinedDispatch(ON_LOGOUT);
      reset();
    }
  }, [combinedDispatch, isLoggedIn]);

  const value = { state: combinedState, dispatch: combinedDispatch };
  return (
    <AppStateContext.Provider value={value}>
      {children}
    </AppStateContext.Provider>
  );
}

export function useAppState() {
  const context = React.useContext(AppStateContext);
  if (context === undefined) {
    throw new Error('useAppState must be used within a AppStateContext');
  }
  return context;
}
