import ErrorBoundary from "../ErrorBoundary";
import {PageContainer} from "./feedback";
import {EqualWidthContainer} from "./community/all";
import {Tab} from "@headlessui/react";
import clsx from "clsx";
import {useEffect, useState} from "react";
import CreateContest from "../../components/contests/createContest";
import ContestList from "../../components/contests/contestList";
import {Auth} from '@aws-amplify/auth';
import { isAdminUser } from "../../utils";

const Contest = () => {

    const [selectedIndex, setSelectedIndex] = useState(0);
    const [isAdmin, setIsAdmin] = useState(false);

    useEffect(() => {
        const onLoad = () => {
            isAdminUser()
                .then(status => {
                    if (status === true) {
                        setIsAdmin(true);
                    } else {
                        setIsAdmin(false);
                    }
                }).catch(err => {
                    setIsAdmin(false);
                    console.log(err);
                });            
        }

        onLoad();
    });

    return (
        <ErrorBoundary>
            <PageContainer>
                <EqualWidthContainer>
                    <Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
                        <Tab.List>                                                      
                            <Tab
                                className={`
                                ${selectedIndex === 0 ? 'bg-[#6415ff]' : ''}
                                ml-2
                                rounded-full border-solid border-2 border-[#6415ff]`}
                                >
                                <TabTitle title="All Contest" selected={selectedIndex === 0} />
                            </Tab>
                            {
                                isAdmin ? <Tab
                                className={`
                                ${selectedIndex === 1 ? 'bg-[#6415ff]' : ''}
                                ml-2
                                rounded-full border-solid border-2 border-[#6415ff]
                                rounded-full`}
                                >
                                <TabTitle
                                    title="Create Contest" selected={selectedIndex === 1} />
                                </Tab> : ''
                            }
                        </Tab.List>
                        <Tab.Panels className="my-5">                                                       
                            <Tab.Panel>
                                <ContestList />
                            </Tab.Panel>
                            {
                                isAdmin ?
                                <Tab.Panel>
                                    <CreateContest />
                                </Tab.Panel> : ''
                            }
                        </Tab.Panels>
                    </Tab.Group>
                </EqualWidthContainer>
            </PageContainer>
        </ErrorBoundary>
    );
}

const TabTitle = ({ title, selected }: { title: string; selected: boolean }) => {
    return (
            <div className="flex flex-col items-center m-3">
                <div className={clsx(" text-lg font-bold text-gray-600", {
                    'text-[#ffffff]': selected
                })}>{title}</div>

            </div>
        );
}

export default Contest;