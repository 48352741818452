export const keyword = '@@USER';

export const ADD_USER_COUNT = keyword + '_COUNT';
export const ADD_ACTIVE_USERS = keyword + '_ACT_COUNT';
export const ADD_USER_GROWTH = keyword + '_GROWTH';

export function AddUserCountAction(payload: any) {
  return {
    type: ADD_USER_COUNT,
    payload
  }
}

export function AddActiveUsers(payload: any) {
  return {
    type: ADD_ACTIVE_USERS,
    payload
  }
}

export function AddUserGrowth(payload: any) {
  return { 
    type: ADD_USER_GROWTH,
    payload
  }
}

export function AddUserPageStats(dispatch: any, payload: Array<any>) {
  dispatch(AddUserCountAction(payload[0][0]));
  dispatch(AddActiveUsers(payload[1][0]));
  dispatch(AddUserGrowth(payload[5][0]));
}
