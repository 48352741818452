import { TextInput } from "flowbite-react";
import { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { createOtherTask, createQuizTaskAPI } from "../../api/contest";
import { getUTCDateString, IOption, IQuiz, IQuizOptions, IQuizTimings, IRank, PRIMARY_BUTTON_STYLE, uploadImage } from "../../utils";
import { useAccounts } from "../accounts/context";

const CreateTask = () => {
    
    const [taskKey, setTaskKey] = useState("");
    const [activeImage, setActiveImage] = useState("");
    const [inactiveImage, setInactiveImage] = useState("");
    const [taskName, setTaskName] = useState("");
    const [quizQuestion, setQuizQuestion] = useState("");
    const [optionOne, setOptionOne] = useState("");
    const [optionTwo, setOptionTwo] = useState("");
    const [optionThree, setOptionThree] = useState("");
    const [optionFour, setOptionFour] = useState("");
    const [optionThreeHidden, setOptionThreeHidden] = useState(true);
    const [optionFourHidden, setOptionFourHidden] = useState(true);
    const [rightOption, setRightOption] = useState(0);
    const [answer, setAnswer] = useState("");
    const [answerExplanation, setAnswerExplanation] = useState("");
    const [description, setDescription] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [timer, setTimer] = useState(0);
    const [ranks, setRanks] = useState<Array<IRank>>([]);
    const [startRank, setStartRank] = useState(0);
    const [endRank, setEndRank] = useState(0);
    const [coins, setCoins] = useState(0);
    const [rewardCoins, setRewardCoins] = useState(0);
    const [flatReward, setFlatReward] = useState(false);
    const [flatRewardAmount, setFlatRewardAmount] = useState(0);
    const [tnc, setTnc] = useState("");
    const activeImageInputFile = useRef<HTMLInputElement | null>(null);
    const inActiveImageInputFile = useRef<HTMLInputElement | null>(null);
    const [activeImageSelectedFile, setActiveImageSelectedFile] = useState();
    const [inActiveImageSelectedFile, setInActiveImageSelectedFile] = useState();
    const [activeImagePreview, setActiveImagePreview] = useState("");
    const [inActiveImagePreview, setInActiveImagePreview] = useState("");


    const cleanUp = () => {
        setTaskKey("");
        setTaskName("");
        setQuizQuestion("");
        setOptionOne("");
        setOptionTwo("");
        setOptionThree("");
        setOptionFour("");
        setRightOption(0);
        setAnswerExplanation("");
        setDescription("");
        setStartDate("");
        setEndDate("");
        setTimer(0);
        setRanks([]);
        setStartRank(0);
        setEndRank(0);
        setCoins(0);
        setRewardCoins(0);
        setFlatReward(false);
        setFlatRewardAmount(0);
        setTnc("");        
        setActiveImageSelectedFile(undefined);
        setInActiveImageSelectedFile(undefined);
        setActiveImagePreview("");
        setInActiveImagePreview("");
        // const [activeImageSelectedFile, setActiveImageSelectedFile] = useState();
        // const [inActiveImageSelectedFile, setInActiveImageSelectedFile] = useState();
        // const [activeImagePreview, setActiveImagePreview] = useState("");
        // const [inActiveImagePreview, setInActiveImagePreview] = useState("");

    }

    const {
        state: {curEnv, user}
    } = useAccounts();

    useEffect(() => {
        if (!activeImageSelectedFile) {
            setActiveImagePreview("");
            return;
        }
        const objectUrl = URL.createObjectURL(activeImageSelectedFile);
        setActiveImagePreview(objectUrl);

        return () => URL.revokeObjectURL(objectUrl);
    }, [activeImageSelectedFile]);

    useEffect(() => {
        if (!inActiveImageSelectedFile) {
            setInActiveImagePreview("");
            return;
        }
        const objectUrl = URL.createObjectURL(inActiveImageSelectedFile);
        setInActiveImagePreview(objectUrl);

        return () => URL.revokeObjectURL(objectUrl);
    }, [inActiveImageSelectedFile])

    

    const addRank = (e: any) => {
        e.preventDefault();
        if (ranks.length === 0) {
            // This is the first entry for the ranks array
            if (startRank === 1 && endRank >= 1 && coins >= 1) {
                const rank: IRank = {} as IRank;
                rank.startRank = startRank;
                rank.endRank = endRank;
                rank.coins = coins;
                setRanks(oldData => [...oldData, rank]);
                setStartRank(0);
                setEndRank(0);
                setCoins(0);
                return;
            } else {
                if (startRank === 0 || startRank > 1) {
                    toast.error("The firts rank should begin with 1");
                    return;
                }
                if (endRank < startRank) {
                    toast.error("The end rank should be greater than or equal to start rank");
                    return;
                }
                if (coins <= 0) {
                    toast.error("The coins should be greater than 0");
                    return;
                }
                return;
            }            
        } else {
            // The start rank should be one more than the previous end rank
            // The end rank should be greater than or equal to the start rank
            // The coins should be greater than 0
            if (
                startRank > 1 &&
                startRank - ranks[ranks.length - 1].endRank === 1 &&
                endRank >= startRank &&
                coins > 0
            ) {
                const rank: IRank = {} as IRank;
                rank.startRank = startRank;
                rank.endRank = endRank;
                rank.coins = coins;
                setRanks(oldData => [...oldData, rank]);
                setStartRank(0);
                setEndRank(0);
                setCoins(0);
                return;
            } else {
                if (startRank <= ranks[ranks.length - 1].endRank) {
                    toast.error("Please enter value for start rank that is one more the last end rank");
                    return;
                }
                if (endRank < startRank) {
                    toast.error("Please enter the end rank that is greater than or equal to the start rank");
                    return;                    
                }
                if (coins <= 0) {
                    toast.error("Please enter coins that is greater than 0");
                    return;
                }
            }
        }                    
    }

    const handleActiveFileUploadClick = (e: any) => {
        e.preventDefault();
        activeImageInputFile.current?.click();
    }

    const handleInActiveFileUploadClick = (e: any) => {
        e.preventDefault();
        inActiveImageInputFile.current?.click();
    }

    const handleActiveFileInputChange = (e: any) => {
        if (!e.target.files || e.target.files.length === 0) {
            setActiveImageSelectedFile(undefined);
            return;
        }
        setActiveImageSelectedFile(e.target.files[0]);
    }

    const handleInActiveFileInputChange = (e: any) => {
        if (!e.target.files || e.target.files.length === 0) {
            setInActiveImageSelectedFile(undefined);
            return;
        }
        setInActiveImageSelectedFile(e.target.files[0]);
    }

    const uploadImages = (e: any) => {
        e.preventDefault();         
        if (checkValidity()) {
            return new Promise((resolve, reject) => {
                if (
                    activeImageInputFile.current?.files && inActiveImageInputFile.current?.files 
                    && inActiveImageInputFile.current?.files?.length > 0 && activeImageInputFile.current?.files?.length > 0) {
                        const activeImageFileName = "active_contest_" + Date.now() + ".png";
                        const inActiveImageFileName = "inactive_contest_" + Date.now() + ".png";
    
                        Promise.all([
                            uploadImage(activeImageFileName, "contest", activeImageInputFile.current?.files[0]),
                            uploadImage(inActiveImageFileName, "contest", inActiveImageInputFile.current?.files[0])
                        ]).then(values => {
                            const utcStartDate = getUTCDateString(startDate);
                            const utcEndDate = getUTCDateString(endDate);
                            if (shouldHide()) {
                                toast.success("Images added successfully");                            
    
                                createOtherTask(
                                    curEnv,
                                    taskName,
                                    description,
                                    taskKey,
                                    "https://discovery.investmates.tech/Investmates/contest/" + activeImageFileName,
                                    "https://discovery.investmates.tech/Investmates/contest/" + inActiveImageFileName,
                                    rewardCoins,
                                    getUTCDateString(startDate),
                                    getUTCDateString(endDate),
                                    user?.email ? user?.email : 'feedback@investmates.io',
                                    tnc
                                ).then(data => {
                                    toast.success("Created portfolio task successfully");
                                }).catch(err => {
                                    console.log(err);
                                    toast.error("Error while creating the portfolio task");
                                });
                            } else {
                                console.log(values);
                                console.log("Added the Images for quiz");
                                toast.success("Images added successfully");
                                // setActiveImage("https://discovery.investmates.tech/Investmates/contest/" + activeImageFileName);
                                // setInactiveImage("https://discovery.investmates.tech/Investmates/contest/" + inActiveImageFileName);
                                // createQuizTask();
                                const quizKey = "quiz";
                                const quiz: IQuiz = {} as IQuiz;
                                console.log("Active Image Url: ", activeImage);
                                console.log("Inactive Image Url: ", inactiveImage);
                                quiz.quizActiveImage = "https://discovery.investmates.tech/Investmates/contest/" + activeImageFileName;
                                quiz.quizInactiveImage = "https://discovery.investmates.tech/Investmates/contest/" + inActiveImageFileName;
                                quiz.quizName = taskName;
                                quiz.quizQuestion = quizQuestion;
                                quiz.answer = answer;
                                quiz.answerExplanation = answerExplanation;
                                quiz.description = description;
                                quiz.isPoll = false;
    
                                const quizOptionsArray: Array<IOption> = [];
                                const one: IOption = {} as IOption;
                                one.optionText = optionOne;
                                one.isRight = rightOption === 1;
                                // Option Two
                                const two: IOption = {} as IOption;
                                two.optionText = optionTwo;
                                two.isRight = rightOption === 2;
                                // Option Three
                                const three: IOption = {} as IOption;
                                three.optionText = optionThree;
                                three.isRight = rightOption === 3;
                                // Option Four
                                const four: IOption = {} as IOption;
                                four.optionText = optionFour;
                                four.isRight = rightOption === 4;
                                if (!optionThreeHidden) {
                                    if (!optionFourHidden) {
                                        quizOptionsArray.push(one, two, three, four);
                                    } else {
                                        quizOptionsArray.push(one, two, three);
                                    }
                                } else {
                                    quizOptionsArray.push(one, two);
                                }                               
    
                                const quizOptions: IQuizOptions = {} as IQuizOptions;
                                quizOptions.options = quizOptionsArray;
    
                                const quizTimings: IQuizTimings = {} as IQuizTimings;
                                
                                quizTimings.startDate = getUTCDateString(startDate);
                                quizTimings.endDate = getUTCDateString(endDate);
    
                                const rankArray: Array<IRank> = [];
    
                                if (flatReward) {
                                    const rankItem = {} as IRank;
                                    rankItem.startRank = 1;
                                    rankItem.endRank = 1;
                                    rankItem.coins = flatRewardAmount;
                                    rankArray.push(rankItem);
                                } else {
                                    ranks.forEach(rankItem => {
                                        rankArray.push(rankItem);
                                    });
                                }                            
    
                                createQuizTaskAPI(
                                    curEnv,
                                    quizKey,
                                    quiz,
                                    quizOptions,
                                    rankArray,
                                    quizTimings,
                                    tnc,
                                    timer,
                                    user?.email ? user?.email : "feedback@investmates.io"
                                ).then(data => {
                                    console.log(data);
                                    toast.success("Added Contest Quiz Successfully");
                                }).catch(err => {
                                    console.log(err);
                                    toast.error("Error while creating the contest quiz");
                                });
                            }                        
                        }).catch(err => {
                            toast.error("Error while uploading quiz images");
                        }).finally(() => {
                            cleanUp();
                        });
                    }            
            });
        }        
    }

    const shouldHide = () => {
        if (
            taskKey === "action_portfolio" || 
            taskKey === "action_referral" || 
            taskKey === "contest_trending_investor" ||            
            taskKey === "contest_trending_community"
        ) {
            return true;
        }
        return false;
    } 
    
    const checkValidity = () => {         
        // Image Validity Check
        if (
            activeImagePreview === undefined || 
            activeImagePreview === null ||
            activeImagePreview === "" ||
            inActiveImagePreview === undefined ||
            inActiveImagePreview === null ||
            inActiveImagePreview === ""
        ) {
            toast.error("Please select proper active contest images");
            return false;
        }

        // Task type validity check
        if (taskKey === undefined || taskKey === null || taskKey === "") {
            toast.error("Please enter proper task type");
            return false;
        }

        // Task Name validity check
        if (taskName === undefined || taskName === null || taskName === "") {
            toast.error("Please enter proper task name");
            return false;
        }

        // Quiz Validity Check
        if (taskKey === "quiz") {
            // Quiz Question validity check
            if (quizQuestion === undefined || quizQuestion === null || quizQuestion === "" ){
                toast.error("Enter proper quiz question");
                return false;            
            }

            // Quiz options validity check
            if (optionOne === undefined || optionOne === null || optionOne === "") {
                toast.error("Enter proper value for option one");
                return false;
            }
            if (optionTwo === undefined || optionTwo === null || optionTwo === ""){
                toast.error("Enter proper value for option two");
                return false;
            }
            if (!optionThreeHidden && optionThree === undefined || optionThree === null || optionThree === "") {
                toast.error("Enter proper value for option three");
                return false;
            }
            if (!optionFourHidden && optionFour === undefined || optionFour === null || optionFour === "") {
                toast.error("Enter proper value for option four");
                return false;
            }
            if (rightOption === 0) {
                toast.error("Please select the right option");
                return false;
            }

            // Reward table validity check
            if (flatReward && flatRewardAmount <= 0) {
                toast.error("Please select some reward value for the flat reward");
                return false;
            }

            // Ranks validity check
            if (!flatReward && ranks.length <= 0) {
                toast.error("Please define ranks for the quiz contest");
                return false;
            }

            // Timer validity check
            if (timer <= 4) {
                toast.error("Please set the timer for at least 5 seconds");
                return false;
            }

            // Answer validity check
            if (answerExplanation === undefined || answerExplanation === null || answerExplanation === "") {
                toast.error("Please enter proper answer explanation");
                return false;
            }                        

        }
        
        // Description validty check
        if (description === undefined || description === null || description === "") {
            toast.error("Please enter proper value for the description");
            return false;
        }

        // TnC validity check
        if (tnc === undefined || tnc === null || tnc === "") {
            toast.error("Please enter proper value for the TnC");
            return false;
        }

        // Start Date validity check
        if (startDate === undefined || startDate === null || startDate === "") {
            toast.error("Please enter proper value for the start date");
            return false;
        } else if (
            new Date(startDate) < new Date()
        ) {
            toast.error("The start date time must be greater than then current date time");
            return false;
        }

        // End Date validity check
        if (endDate === undefined || endDate === null || endDate === "") {
            toast.error("Please enter proper value for the end date");
            return false;
        } else if (
            new Date(endDate) <= new Date(startDate)
        ) {
            toast.error("The end date time must be greater than start date time");
            return false;
        }

        if (taskKey !== "quiz" && rewardCoins <= 0) {
            toast.error("Please enter proper value for the reward coins");
            return false;
        }


        return true;
    }  
    
    const addNewOption = (e: any) => {
        e.preventDefault();
        if (optionThreeHidden) {
            setOptionThreeHidden(false);            
            return;
        } else if (optionFourHidden){
            setOptionFourHidden(false);
            return;
        }
    }

    const removeOption = (e: any) => { 
        e.preventDefault();
        if (!optionFourHidden) {
            setOptionFourHidden(true);
            setOptionFour("");
            if (rightOption === 4) {
                setRightOption(0);
            }
        } else if (!optionThreeHidden) {
            setOptionThreeHidden(true);
            setOptionThree("");
            if (rightOption === 3) {
                setRightOption(0);
            }
        }
    }

    return (
        <div className="mt-2">
            <hr />
            <div className="grid grid-cols-2 gap-4 m-4">
                {
                    /**
                     * First Column
                     */
                }
                <div>
                    <div>Upload Active Image</div>
                    <div className="mt-2 object-cover h-48 w-96 bg-indigo-50 flex justify-center items-center">
                    {
                        activeImageSelectedFile 
                        ?  <img className="h-48 w-96" src={activeImagePreview} /> 
                        : <span className="font-medium leading-tight text-2xl mt-0 mb-2">
                            No Image selected
                        </span>
                    }
                    </div>
                    <div>
                        <a 
                            href="#" 
                            onClick={e => handleActiveFileUploadClick(e)}
                            className="hover:underline"
                        >
                            {activeImageSelectedFile ? 'Change Active Image' : 'Choose Active Image'}
                        </a>
                        <input
                            onChange={handleActiveFileInputChange}
                            ref={activeImageInputFile}
                            type="file"
                            hidden={true}
                         />
                    </div>
                    <hr />

                    <div className="mt-2">Upload Inactive Image</div>
                    <div className="mt-2 object-cover h-48 w-96 bg-indigo-50 flex justify-center items-center">
                    {
                        inActiveImageSelectedFile 
                        ?  <img className="h-48 w-96" src={inActiveImagePreview} /> 
                        : <span className="font-medium leading-tight text-2xl mt-0 mb-2">
                            No Image selected
                        </span>
                    }
                    </div>
                    <div>
                        <a 
                            href="#"
                            onClick={e => handleInActiveFileUploadClick(e)}
                            className="hover:underline"
                        >
                            {inActiveImageSelectedFile ? 'Change Inactive Image' : 'Choose Inactive Image'}
                        </a>
                        <input
                            onChange={handleInActiveFileInputChange}
                            ref={inActiveImageInputFile}
                            type="file"
                            hidden={true}
                         />
                    </div>

                    <div className="mt-2">
                        <hr className="mb-2" />
                        <span><b>Task Type</b></span>
                    </div>
                    <div>
                        <input 
                            className="w-full rounded" 
                            type="text" 
                            placeholder="Task Type" 
                            value={taskKey}
                            onChange={e => setTaskKey(e.target.value)}
                        />
                    </div>

                    <div className="mt-2">
                        <span><b>Task Name</b></span>
                    </div>
                    <div>
                        <input 
                            className="w-full rounded" 
                            type="text" 
                            placeholder="Task Name" 
                            value={taskName}
                            onChange={e => setTaskName(e.target.value)}
                        />
                    </div>

                    <div className={`${shouldHide() ? 'hidden' : 'visible'}`}>
                        <div className="mt-2">
                            <span><b>Quiz Question</b></span>
                        </div>
                        <div>
                            <input 
                                className="w-full rounded" 
                                type="text" 
                                placeholder="Quiz Question" 
                                value={quizQuestion}
                                onChange={e => setQuizQuestion(e.target.value)}
                            />
                        </div>
                    </div>  

                    <div className={`${shouldHide() ? 'hidden' : 'visible'}`}>
                        <div className="mt-2">
                            <hr />
                            <b>Quiz Options</b>
                        </div>
                        <div className="mt-2">
                            <span>Option 1: </span>
                            <span>
                                <input 
                                    className="w-96 rounded" 
                                    type="text" 
                                    placeholder="Option 1" 
                                    value={optionOne}
                                    onChange={e => setOptionOne(e.target.value)}
                                />
                            </span>
                            <span className="ml-2 mr-2">Is Right</span>
                            <input 
                                type="checkbox" 
                                checked={rightOption === 1} 
                                onClick={e => setRightOption(rightOption === 1 ? 0 : 1)} 
                            />
                        </div>
                        <div className="mt-2">
                            <span>Option 2: </span>
                            <span>
                                <input 
                                    className="w-96 rounded" 
                                    type="text" 
                                    placeholder="Option 2" 
                                    value={optionTwo}
                                    onChange={e => setOptionTwo(e.target.value)}
                                />
                            </span>
                            <span className="ml-2 mr-2">Is Right</span>
                            <input type="checkbox" checked={rightOption === 2} onClick={e => setRightOption(rightOption === 2 ? 0 : 2)} />
                        </div>
                        <div className={`${optionThreeHidden ? 'hidden' : 'visible'} mt-2`}>
                            <span>Option 3: </span>
                            <span>
                                <input 
                                    className="w-96 rounded" 
                                    type="text" 
                                    placeholder="Option 3" 
                                    value={optionThree}
                                    onChange={e => setOptionThree(e.target.value)}
                                />
                            </span>
                            <span className="ml-2 mr-2">Is Right</span>
                            <input type="checkbox" checked={rightOption === 3} onClick={e => setRightOption(rightOption === 3 ? 0 : 3)} />
                        </div>
                        <div className={`${optionFourHidden ? 'hidden' : 'visible'} mt-2`}>
                            <span>Option 4: </span>
                            <span>
                                <input 
                                    className="w-96 rounded" 
                                    type="text" 
                                    placeholder="Option 4" 
                                    value={optionFour}
                                    onChange={e => setOptionFour(e.target.value)}
                                />
                            </span>
                            <span className="ml-2 mr-2">Is Right</span>
                            <input type="checkbox" checked={rightOption === 4} onClick={e => setRightOption(rightOption === 4 ? 0 : 4)} />
                        </div>
                    </div>   
                    <div className={`${shouldHide() ? 'hidden' : 'visible'} mt-2`}>
                        <button 
                            className="hover:underline"
                            onClick={e => addNewOption(e)}
                        >
                            + New Option
                        </button>
                        <button
                            className="hover:underline ml-2"
                            onClick={e => removeOption(e)}
                        >
                            - Remove Option
                        </button>
                    </div>                 
                    
                    <div className={`${shouldHide() ? 'hidden' : 'visible'} mt-3`}>
                        <hr />
                        <span><b>Rewards Table</b></span>
                    </div>
                    <div className={`${shouldHide() ? 'hidden' : 'visible'} mt-3`}>
                        <span>Flat Reward: </span>
                        <span>
                            <input 
                                type="checkbox"
                                checked={flatReward}
                                onChange={e => setFlatReward(!flatReward)}
                            />
                        </span>
                    </div>
                    <div className={`${flatReward ? 'visible' : 'hidden'}`}>
                        <input 
                            type="number" 
                            value={flatRewardAmount}
                            onChange={e => setFlatRewardAmount(Number(e.target.value))}
                        />
                    </div>
                    <div className={`${shouldHide() || flatReward ? 'hidden' : 'visible'} flex flex-row`}>                        
                        <div className="flex flex-col">
                            <div>Start Rank</div>
                            <div>
                                <input 
                                    type="number" 
                                    placeholder="start rank"
                                    value={startRank}                                    
                                    onChange={e => setStartRank(Number(e.target.value))}
                                />
                            </div>
                        </div>

                        <div className="flex flex-col ml-2">
                            <div>End Rank</div>
                            <div>
                                <input 
                                    type="number" 
                                    placeholder="end rank"
                                    value={endRank}                                    
                                    onChange={e => setEndRank(Number(e.target.value))}
                                />
                            </div>
                        </div>

                        <div className="flex flex-col ml-2">
                            <div>Coins</div>
                            <div>
                                <input 
                                    type="number" 
                                    placeholder="coins" 
                                    value={coins}
                                    onChange={e => setCoins(Number(e.target.value))}
                                />
                            </div>
                        </div>                        
                    </div>
                    <button 
                        className={`${shouldHide() || flatReward ? 'hidden' : 'visible'} ${PRIMARY_BUTTON_STYLE} mt-2`}                        
                        onClick={e => addRank(e)}
                    >
                        Add Rank
                    </button>
                    <table className={`${shouldHide() || flatReward ? 'hidden' : 'visible'}`}>                        
                        <thead>
                            <tr>
                                <th>Start Rank</th>
                                <th>End Rank</th>
                                <th>Coins</th>
                            </tr>                                
                        </thead>                        
                        <tbody>
                            {
                                ranks.map(rank => {
                                    return (
                                        <tr>
                                            <td>{rank.startRank}</td>
                                            <td>{rank.endRank}</td>
                                            <td>{rank.coins}</td>
                                        </tr>
                                    );
                                })
                            }
                        </tbody>
                    </table>
                </div>
                {
                    /**
                     * Second Column
                     */
                }
                <div>
                    <div className="grid grid-cols-2 gap-2">
                        <div>
                            <div>Start Date</div>
                            <TextInput 
                                type={"datetime-local"} 
                                value={startDate} 
                                onChange={e => setStartDate(e.target.value)}
                            />
                        </div>
                        <div>
                            <div>
                                End Date
                            </div>
                            <TextInput 
                                type={"datetime-local"} 
                                value={endDate}
                                onChange={e => setEndDate(e.target.value)}
                            />
                        </div>
                    </div>

                    <div className="mt-2 grid grid-cols-2 gap-2">
                        <div className={`${flatReward ? 'hidden' : 'visible'}`}>
                            <div>Total Reward Coins</div>
                            <input 
                                className="w-full rounded" 
                                type="number" 
                                placeholder="reward coins"
                                disabled={!shouldHide()} 
                                value={shouldHide() ? rewardCoins : ranks.reduce((acc, val) => acc + ((val.endRank - val.startRank + 1) * val.coins) ,0)}
                                onChange={e => shouldHide() && setRewardCoins(Number(e.target.value))}
                            />
                        </div>

                        <div className={`${shouldHide() ? 'hidden' : 'visible'}`}>
                            <div>Timer (seconds)</div>
                            <input 
                                className="w-full rounded" 
                                type="number" 
                                placeholder="time in seconds" 
                                value={timer}
                                onChange={e => setTimer(Number(e.target.value))}
                            />
                        </div>
                    </div>                    

                    <div className={`${shouldHide() ? 'hidden' : 'visible'}`}>
                        <div className="mt-2">
                            Answer Explanation
                        </div>
                        <div>
                            <textarea 
                                className="w-full rounded" 
                                placeholder="Answer Explanation" 
                                value={answerExplanation}
                                onChange={e => setAnswerExplanation(e.target.value)}
                            />
                        </div>
                    </div>                    

                    <div>
                        <div className="mt-2">
                            Description
                        </div>
                        <div>
                            <textarea 
                                className="w-full rounded" 
                                placeholder="Description"
                                value={description}
                                onChange={e => setDescription(e.target.value)}
                            />
                        </div>
                    </div>                    

                    <div>
                        <div className="mt-2">
                            TnC
                        </div>
                        <div>
                            <textarea 
                                className="w-full rounded" 
                                placeholder="Terms and Conditions" 
                                value={tnc}
                                onChange={e => setTnc(e.target.value)}
                            />
                        </div>
                    </div>                    
                </div>
            </div>
            <button 
                className={`${PRIMARY_BUTTON_STYLE} mb-8`}
                onClick={e => uploadImages(e)}
            >
                Create Contest
            </button>
        </div>
    );
}

export default CreateTask;