import { Tab } from '@headlessui/react';
import { useState } from 'react';
import TabTitle from '../../components/common/tabTitle';
import CreateExplore from '../../components/explore/createExplore';
import HistoryExplore from '../../components/explore/historyExplore';

const ExploreIndex = () => {

    const [selectedIndex, setSelectedIndex] = useState(0);

    return (
        <div>
            <Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
                <Tab.List>
                    <Tab
                        className={`
                        ${selectedIndex === 0 ? 'bg-[#6415ff]' : ''}
                        ml-2
                        rounded-full border-solid border-2 border-[#6415ff]`}
                    >
                        <TabTitle
                            title='Create' selected={selectedIndex === 0} />
                    </Tab>
                    <Tab
                        className={`
                        ${selectedIndex === 1 ? 'bg-[#6415ff]' : ''}
                        ml-2
                        rounded-full border-solid border-2 border-[#6415ff]`}
                    >
                        <TabTitle
                            title='History' selected={selectedIndex === 1} />
                    </Tab>
                </Tab.List>
                <Tab.Panels>
                    <Tab.Panel>
                        <CreateExplore />
                    </Tab.Panel>
                    <Tab.Panel>
                        <HistoryExplore />
                    </Tab.Panel>
                </Tab.Panels>
            </Tab.Group>
        </div>
    );
}

export default ExploreIndex;