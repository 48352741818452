const buttons = ['24hrs', '1 W', '1 M', '1 Y', 'All', '3 M', '6 M'];

const ChartSelect = ({ name, value, onChange, skip = 0 }: { skip?: number; name: string; value: number; onChange: (x: number) => void }) => {
  return (
    <div>
      {buttons.slice(skip).map((el, index) =>
        <label key={el} className="text-gray-700">
          <input type="radio" name={name} className="hidden peer" value={index} checked={value === index} onChange={evt => onChange(Number(evt.currentTarget.value))} />
          <span className="inline-flex p-2 m-2 text-xs font-bold text-gray-500 rounded shadow cursor-pointer peer-checked:bg-green-300 hover:bg-green-500 bg-green-50">
            {el}
          </span>
        </label>)}
    </div>
  );
}

export default ChartSelect;
