import React from 'react';
import { Route, Navigate } from 'react-router-dom';
import { useAccounts } from '../accounts/context';

function querystring(name: string, url = window.location.href) {
  name = name.replace(/[[]]/g, '\\$&');

  const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)', 'i');
  const results = regex.exec(url);

  if (!results) {
    return null;
  }
  if (!results[2]) {
    return '';
  }

  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export default function UnauthenticatedRoute({
  component: C,
  path,
  ...rest
}: {
  path: string;
  component: any;
}) {
  return (
    <Route
      path={path}
      {...rest}
      element={
        <CheckAuth>
          <C />
        </CheckAuth>
      }
    ></Route>
  );
}

export function CheckAuth({ children }: { children: JSX.Element }) {
  const {
    state: { isAuthenticated },
  } = useAccounts();
  const redirect = querystring('redirect');

  if (isAuthenticated) {
    return (
      <Navigate to={redirect === '' || redirect === null ? '/' : redirect} />
    );
  }
  return children;
}
