import { Tab } from "@headlessui/react";
import { useState } from "react";
import Misc from "../components/admin/misc";
import NewsNotification from "../components/admin/newsNotification";
import TabTitle from "../components/common/tabTitle";
import CreateTask from "../components/tasks/createTask";
import TaskHistory from "../components/tasks/taskHistory";
import { PRIMARY_BUTTON_STYLE } from "../utils";

const Admin = () => {
    const [selectedIndex, setSelectedIndex] = useState(0);
    return (
        <div>
            <Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
                <Tab.List>                    
                    <Tab
                        className={`
                        ${selectedIndex === 0 ? 'bg-[#6415ff]' : ''}
                        ml-2
                        rounded-full border-solid border-2 border-[#6415ff]`}
                    >
                        <TabTitle
                            title="Create Task" selected={selectedIndex === 0}
                            />
                    </Tab>
                    <Tab
                        className={`
                        ${selectedIndex === 1 ? 'bg-[#6415ff]' : ''}
                        ml-2
                        rounded-full border-solid border-2 border-[#6415ff]`}
                    >
                        <TabTitle
                            title="Task History" selected={selectedIndex === 1}
                            />
                    </Tab>
                    <Tab
                        className={`
                        ${selectedIndex === 2 ? 'bg-[#6415ff]' : ''}
                        ml-2
                        rounded-full border-solid border-2 border-[#6415ff]`}
                    >
                        <TabTitle
                            title="News Notification" selected={selectedIndex === 2}
                            />
                    </Tab>
                </Tab.List>
                <Tab.Panels>                    
                    <Tab.Panel>
                        <CreateTask />
                    </Tab.Panel>
                    <Tab.Panel>
                        <TaskHistory />
                    </Tab.Panel>
                    <Tab.Panel>
                        <NewsNotification />
                    </Tab.Panel>
                </Tab.Panels>
            </Tab.Group>
        </div>
    );
}

export default Admin;