import {TextInput} from "flowbite-react";
import {ReactNode, useEffect, useRef, useState} from "react";
import {IContestRank} from "../../utils";
import {useAccounts} from "../accounts/context";
import {createContestAPI} from "../../api/contest";
import toast from "react-hot-toast";
import { s3Client } from "../../s3Creds";
// import * as fs from '';

const CreateContest = () => {
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [startTimeHH, setStartTimeHH] = useState(0);
    const [startTimeMM, setStartTimeMM] = useState(0);
    const [endTimeHH, setEndTimeHH] = useState(0);
    const [endTimeMM, setEndTimeMM] = useState(0);
    const [contestImage, setContestImage] = useState("");
    const [contestName, setContestName] = useState("");
    const [contestDesc, setContestDesc] = useState("");
    const [contestType, setContestType] = useState("MAX_POSTS");
    const [rewardCoins, setRewardCoins] = useState(0);
    const [tnc, setTnc] = useState("");
    const [contestRanks, setContestRanks] = useState<Array<IContestRank>>([]);
    const [startRank, setStartRank] = useState(1);
    const [endRank, setEndRank] = useState(1);
    const [rankCoins, setRankCoins] = useState(0);
    const inputFile = useRef<HTMLInputElement | null>(null);
    const [selectedFile, setSelectedFile] = useState();
    const [preview, setPreview] = useState("");    


    const {
        state: {curEnv, user}
    } = useAccounts();

    // validation states
    const [startDateErr, setStartDateErr] = useState(false);
    const [endDateErr, setEndDateErr] = useState(false);
    const [contestNameErr, setContestNameErr] = useState(false);
    const [contestDescErr, setContestDescErr] = useState(false);
    const [tncErr, setTncErr] = useState(false);
    const [rankErr, setRankErr] = useState(false);

    useEffect(() => {
        if (!selectedFile) {
            setPreview("")
            return
        }

        const objectUrl = URL.createObjectURL(selectedFile)
        setPreview(objectUrl)

        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl)
    }, [selectedFile]);

    const checkForValidations = () => {
        let hasErrors = false;
        if (!validStartAndEndDate()) {
            setStartDateErr(true);
            setEndDateErr(true);
            hasErrors = true;
        } else {
            setStartDateErr(false);
            setEndDateErr(false);
        }

        if (contestDesc === "") {
            setContestDescErr(true);
            hasErrors = true;
        } else {
            setContestDescErr(false);
        }

        if (contestName === "") {
            setContestNameErr(true);
            hasErrors = true;
        } else {
            setContestNameErr(false);
        }

        if (tnc === "") {
            setTncErr(true);
            hasErrors = true;
        } else {
            setTncErr(false);
        }

        if (contestRanks.length > 0) {
            setRankErr(false);
        } else {
            setRankErr(true);
            hasErrors = true;
        }

        return hasErrors;
    }

    const validStartAndEndDate = () => {
        if (startDate === "") {
            return false;
        }
        if (endDate === "") {
            return false;
        }

        const today = new Date();
        let dd = today.getDate().toString();
        let mm = (today.getMonth() + 1).toString();
        const year = today.getFullYear();

        if (Number(dd) < 10) {
            dd = '0' + dd;
        }

        if(Number(mm) < 10) {
            mm = '0' + mm;
        }

        const todayDate = year + '-' + mm + '-' + dd;

        if (Date.parse(todayDate) <= Date.parse(startDate)) {
            return true;
        } else {
            return false;
        }        
    }

    const handleRankCoinsChange = (e: any) => {
        e.preventDefault();
        if (Number(e.target.value) > 0) {
            setRankCoins(Number(e.target.value));
        }
    }

    const handleEndRankChange = (e: any) => {
        e.preventDefault();
        if (Number(e.target.value) > 0) {
            setEndRank(Number(e.target.value));
        }
    }

    const addRank = (e: any) => {
        e.preventDefault();
        if (startRank <= endRank && rankCoins > 0) {
            const tempRank = {} as IContestRank;
            tempRank.startRank = startRank;
            tempRank.endRank = endRank;
            tempRank.coins = rankCoins;
            setContestRanks(oldData => [...oldData, tempRank]);
            calculateCoins(startRank, endRank, rankCoins);
            setStartRank(endRank + 1);
            setEndRank(endRank + 1);
            setRankCoins(0);
            setRankErr(false);
        } else {
            setRankErr(true);
        }
    }

    const handleStartDate = (e: any) => {
        e.preventDefault();
        setStartDate(e.target.value);
    }

    const handleEndDate = (e: any) => {
        e.preventDefault();
        setEndDate(e.target.value);
    }

    const handleContestName = (e: any) => {
        e.preventDefault();
        setContestName(e.target.value);
    }

    const handleContestDesc = (e: any) => {
        e.preventDefault();
        setContestDesc(e.target.value);
    }

    const handleTnc = (e: any) => {
        e.preventDefault();
        setTnc(e.target.value);
    }

    const calculateCoins = (startRank: number, endRank: number, coins: number) => {
        if (startRank - endRank === 0) {
            setRewardCoins(rewardCoins + coins);
        } else {
            // count the number of ranks i the given range
            const totalRanks = (endRank - startRank) + 1;
            setRewardCoins(rewardCoins + (totalRanks * coins));
        }
    }

    const fillRanksTable = () => {
        return contestRanks.map((rank, index) => {
            return <tr key={index} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                <th scope="row" className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                    {rank.startRank}
                </th>
                <td className="py-4 px-6">
                    {rank.endRank}
                </td>
                <td className="py-4 px-6">
                    {rank.coins}
                </td>
            </tr>
        });
    }

    const createContest = (e: any) => {
        e.preventDefault();
        if (!checkForValidations()) {            
            const fullStartDate = startDate + 
                " " + 
                (startTimeHH < 10 ? "0" + startTimeHH : startTimeHH) + ":" +
                (startTimeMM < 10 ? "0" + startTimeMM : startTimeMM) + ":" + "00.000";
            const fullEndDate = endDate + 
                " " + 
                (endTimeHH < 10 ? "0" + endTimeHH : endTimeHH) + ":" +
                (endTimeMM < 10 ? "0" + endTimeMM : endTimeMM) + ":" + "00.000";
            createContestAPI(
                    1,
                    contestName,
                    contestDesc,
                    contestImage,
                    user?.email !== undefined ? user?.email : "feedback@investmates.io",
                    fullStartDate,
                    fullEndDate,
                    "MAX_POSTS",
                    rewardCoins,
                    tnc,
                    "IND",
                    contestRanks
                ).then(data => {                    
                    toast.success("Contest Created");
                }).catch(err => {
                    console.log(err);
                    toast.error("Error creating the contest");
                });            
        }        
    }
    
    const handleFileUploadClick = (e: any) => {
        // e.preventDefault();
        inputFile.current?.click();
    }

    const uploadContestImage = (e: any) => {
        e.preventDefault();           
        const fileName = "contest_" + Date.now() + ".jpg";
        
        if (inputFile.current?.files && inputFile.current?.files?.length > 0) {
            s3Client.putObject({
                Bucket: process.env.REACT_APP_SPACES_BUCKET,
                Key: process.env.REACT_APP_CONTEST_FOLDER + fileName,
                Body: inputFile.current?.files[0],
                ACL: "public-read"
            }).then(data => {
                setContestImage("https://discovery.investmates.tech/Investmates/contest/" + fileName);
            }).catch(err => {
                console.log(err);
            });
        }
    }

    const handleFileInputChange = (e: any) => {
        if (!e.target.files || e.target.files.length === 0) {
            setSelectedFile(undefined)
            return
        }        
        setSelectedFile(e.target.files[0])
    }

    return (
            <div>
                <div className="mb-2 text-2xl">Contest created by: {user?.email !== undefined ? user?.email : "feedack@investmates.io"}</div>
                <div className={`object-cover h-48 w-96 bg-indigo-50`}>   
                {
                    selectedFile &&  <img className="h-48 w-96" src={preview} />
                }              
                </div>
                <div className="my-4 underline"> 
                    <input onChange={handleFileInputChange} type="file" id="file_upload" ref={inputFile} style={{display: "none"}} />
                    <a onClick={e => handleFileUploadClick(e)} href="#">Choose Image</a>
                    <button
                        className={`${
                            (inputFile.current?.value === undefined ||
                            inputFile.current?.value === null ||
                            inputFile.current?.value === '') ? 'hidden' : ''
                        }`}
                        onClick={e => uploadContestImage(e)}
                        >
                            Upload Image
                    </button>
                </div>
                <span className="text-sm text-red-700" hidden={!startDateErr || !endDateErr}>
                    Invalid Duration
                    <ul className="mb-2">
                        <li>The Start Date and End Date should have a valid value</li>
                        <li>The Start Date should be before the End Date</li>
                        <li>The Start Date should be greater than or equal to today's date</li>
                    </ul>
                </span>
                <div className="flex flex-row">
                    <span className="mr-2">Duration: </span>
                    <span>
                        <TextInput
                            type="date"
                            required={true}
                            onChange={e => handleStartDate(e)}
                        />
                    </span>
                    <span className="ml-2">
                        Start Time:&nbsp;
                        <select onChange={(e) => setStartTimeHH(Number(e.target.value))}>
                            {(() => {
                                const options = [];

                                for (let i = 0; i <= 23; i++) {
                                    options.push(<option value={i} key={i}>{i}</option>);
                                }

                                return options;
                            })()}
                        </select>
                        <select onChange={e => setStartTimeMM(Number(e.target.value))}>
                            {(() => {
                                const options = [];

                                for (let i = 0; i <= 59; i++) {
                                    options.push(<option value={i} key={i}>{i}</option>);
                                }

                                return options;
                            })()}
                        </select>
                    </span>
                    <span className="mx-2">To</span>
                    <span className="mx-4">
                        <TextInput
                            type="date"
                            required={true}
                            placeholder="End Date"
                            onChange={e => handleEndDate(e)}
                        />
                    </span>
                    <span className="ml-2">
                        End Time:&nbsp;
                        <select onChange={e => setEndTimeHH(Number(e.target.value))}>
                            {(() => {
                                const options = [];

                                for (let i = 0; i <= 23; i++) {
                                    options.push(<option value={i} key={i}>{i}</option>);
                                }

                                return options;
                            })()}
                        </select>
                        <select onChange={e => setEndTimeMM(Number(e.target.value))}>
                            {(() => {
                                const options = [];

                                for (let i = 0; i <= 59; i++) {
                                    options.push(<option value={i} key={i}>{i}</option>);
                                }

                                return options;
                            })()}
                        </select>
                    </span>
                </div>
                <div className="flex flex-row mt-5">
                    <div className="basis-1/2">
                        <div>
                            <span className="text-sm text-red-700" hidden={!contestNameErr}>
                                The Contest name should not be empty
                            </span>
                        </div>
                        <div>Contest Name</div>
                        <div>
                            <input
                                className="w-full mr-5"
                                type="text"
                                placeholder="Contest Name"
                                value={contestName}
                                onChange={e => handleContestName(e)}
                            />
                        </div>
                    </div>
                    <div className="basis-1/2 ml-5">
                        <div className="flex flex-row">
                            <div className="basis-1/2">
                                <div>Contest Type</div>
                                <div>
                                    <select
                                        className="w-full"
                                        placeholder="Select contest type"
                                        value={contestType}
                                        onChange={e => setContestType(e.target.value)}
                                    >
                                        <option value="MAX_POSTS">Maximum Posts</option>
                                        <option value="MAX_ENG">Maximum Engagement</option>
                                    </select>
                                </div>
                            </div>
                            <div className="basis-1/2 ml-5">
                                <div>Reward Coins</div>
                                <input
                                    type="number"
                                    placeholder="Reward coins"
                                    value={rewardCoins}
                                    disabled={true}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="flex flex-row mt-4">
                    <div className="basis-1/2 w-full">
                        <div>
                            <span className="text-sm text-red-700" hidden={!contestDescErr}>
                                The Contest description should not be empty
                            </span>
                        </div>
                        <div>Description</div>
                        <div>
                            <textarea
                                className="w-full"
                                rows={4}
                                placeholder="Description"
                                value={contestDesc}
                                onChange={e => handleContestDesc(e)}
                            />
                        </div>
                    </div>
                    <div className="basis-1/2 ml-5">
                        <div>
                            <span className="text-sm text-red-700" hidden={!tncErr}>
                                The Terms and Conditions should not be empty
                            </span>
                        </div>
                        <div>Terms and Conditions</div>
                        <textarea
                            className="w-full"
                            rows={4}
                            value={tnc}
                            onChange={e => handleTnc(e)}
                            placeholder="Terms and Conditions" />
                    </div>
                </div>
                <div>
                    <span className="text-sm text-red-700" hidden={!rankErr}>
                        Please enter valid rank and coins
                    </span>
                </div>
                <div className="flex flex-row mt-2">
                    <div className="mr-2">
                        <div>Start Rank</div>
                        <input
                            type="number"
                            placeholder="Start Rank"
                            value={startRank}
                            disabled={true}
                        />
                    </div>
                    <div className="mr-2">
                        <div>End Rank</div>
                        <input
                            type="number"
                            placeholder="End Rank"
                            value={endRank}
                            onChange={e => handleEndRankChange(e)}
                        />
                    </div>
                    <div className="mr-2">
                        <div>Coins</div>
                        <input
                            type="number"
                            value={rankCoins}
                            onChange={e => handleRankCoinsChange(e)}
                            placeholder="Coins"
                        />
                    </div>
                    <div className="basis-1/4">
                        <br />
                        <button
                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                            onClick={e => addRank(e)}
                        >
                            +Add Rank
                        </button>
                    </div>
                </div>
                {
                    contestRanks.length > 0
                    ?
                    <div className="overflow-x-auto relative mt-4">
                        <table className="w-1/2 text-sm text-left text-gray-500 dark:text-gray-400">
                            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                <tr>
                                    <th scope="col" className="py-3 px-6">
                                        Start Rank
                                    </th>
                                    <th scope="col" className="py-3 px-6">
                                        End Rank
                                    </th>
                                    <th scope="col" className="py-3 px-6">
                                        Coins
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    fillRanksTable()
                                }
                            </tbody>
                        </table>
                    </div>
                    : ""
                }
                <br /><br />
                <div className="flex justify-center items-center mb-10">
                    <button
                        className="w-1/3 justify-center bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                        onClick={e => createContest(e)}
                    >
                        Create Contest
                    </button>
                </div>
            </div>
    );
}

export default CreateContest;