import { IReportCardType } from "../components/report/reportCard";
import dayjs from 'dayjs'
import weekOfYear from 'dayjs/plugin/weekOfYear';
import { IFeedbackCardType } from "../components/feedback/feedbackCard";
import {Auth} from '@aws-amplify/auth';
import { useNavigate } from "react-router-dom";
import { s3Client } from "../s3Creds";

dayjs.extend(weekOfYear);


// Warining codes
export const WRN_PR = "WRN_PR"; // for post report
export const WRN_UR = "WRN_UR"; // for user report

export type ISearchType = {
  code: any
}

export type IUserSearchResultType = {
  userId: number,
  userName: string,
  userHandle: string,
  profilePicture: string,
  contactNo: string,
  email: string,
  authenticated: boolean,
  joinDate: string,
  communityOwned: number,
  sebi: boolean,
  sebiBadge: string
}

export interface IBannerItem {
  bannerId: number;
  bannerKey: string;
  bannerSequence: number;
  buttonText: string;
  created: string;
  expiry: string;
  bannerImage: string;
  metadata: string;
  minBuild: number;
  status: number;
  title: string;
  updated: string;
}

export interface IExploreHistoryItem {
  exploreId: number;
  title: string;
  description: string;
  key: string;
  status: number;
  sequence: number; 
  listLength: number;  
  createdBy: string;
  updatedBy: string;
  created: string;
  updated: string;
  editing: boolean;
}

export interface IRecommendHistoryItem {
  id: number;  
  batchNo: number;
  communityId: number;
  communityName: string;
  communityDescription: string;
  communityReference: string;
  communityImage: string;
  created: string;
  adminName: string;
  adminUserId: number;
  rank: number;
  recommendedBy: string;
  status: string;
}

export interface IUserReportListType {
  id: number;
  name: string;
  status: string;
  userReportCount: number;
  postReportCount: number;
  warningCount: number;
  suspendCount: number;
  lastReportedDate: string;
}

export interface IUserReportDetails {
  userId: number;
  name: string;
  status: string;
  contactNo: string;
  created: string;
  lastActiveDate: string;
  lastPostCreated: string;
  lastActiveCommunityDate: string;
  lastActiveCommunityName: string;
  lastActiveCommunityId: number;
  communitiesJoined: number;
  communitiesOwned: number;
  paidCommunitiesOwned: number;
  totalPaidChannelsOwned: number;
  totalPaidChannelsJoined: number;
  totalPaidChannelMembers: number;
  joiningReward: number;
  referralReward: number;
  communityReward: number;
  trendingUserReward: number;
  rewardsEarned: number; 
  redeemCoins: number;
  balance: number;               
  totalSuspensions: number;  
  totalWarnings: number;
  userReportsCount: number; 
  postReportsCount: number;
  lastReportedDate: string;
}

export interface IReporterdetails {
  userId: number;
  name: string;
}

export interface ISpecialRewardsUser {
  rewardId: number;
  userId: number;
  name: string;
  communityId: number;
  communityName: string;
  rewardAmount: number;
  rewardedBy: string;
  rewardDate: string;
}

export interface IReportType {
    id: string;
    name: string;
    userId: number;
    profileImage: string;
    reportCount: number;
    warningCount: number;
    status: string;
    reportType: string;
    category: string;
    content: string;
    timestamp: string;
}

export interface ICampaignType {
  campaignId: number;
  campaignName: string;
  campaignDescription: string;
  campaignType: number;
  createdBy: string;
  refreeCoin: number;
  referralCoin: number;
  rewardCoin: number;
  status: number;
  created: string;
  endDate: string;
}

export interface IAllocationHistoryType {
    allocatedBy: string;
    allocatedOn: string;
    amount: number;
    campaignCreated: string;
    campaignDescription: string;
    campaignExpiry: string;
    campaignId: number;
    campaignName: string;
    campaignOwner: string;
    campaignStatus: number;
    campaignType: number;
    ownerId: number;
    transactionId: number;
}

export interface ICoinRequestType {
    approvalStatus: string;
    campaignDate: string;
    campaignDescription: string;
    campaignName: string;
    campaignType: number;
    message: string;
    requestedBy: string;
    requestedOn: string;
    requestId: number;
    requiredCoins: number;
}

export interface IContestType {
    contestId: number;
    contestName: string;
    contestDesc: string;
    contestStatus: string;
    duration: string;
    contestType: string;
    createdBy: string;
    totalRewards: number;
    rewardsGiven: number;
    usersJoined: number;
    usersWon: number;
    tnc: string;
}

export interface IConversionListElement {
  Id: number;
  campaignId: number;
  created: string;
  joinedUser: string;
  joinedUserId: number
  referredBy: string;
  referredUserId: number;  
}

export interface IUserCommunityResultItem {
  userId: number;
  name: string;
  userImage: string;
  userHandle: string;
  communityId: number;
  communityName: string;
  communityImage: string;
  communityCreatedDate: string;
  communityMemberCount: number;

}

export interface ICampaignLeaderboardElement {
  userId: number;
  name: string;
  converts: number;
}

export interface IQuiz {
  quizId: number;
  quizActiveImage: string;
  quizInactiveImage: string;
  quizImage: string;
  quizName: string;
  questionId: number;
  quizQuestion: string;
  answer: string;
  answerExplanation: string;
  description: string;
  isPoll: boolean;
  duration: number;
}

export interface IQuizTimings {
  startDate: string;
  endDate: string;  
}

export interface IQuizOptions {
  options: Array<IOption>
}

export interface IOption {
  optionId: number;
  optionText: string;
  isRight: boolean;
}

export interface IRank {
  rankId: number;
  startRank: number;
  endRank: number;
  coins: number;
}

export interface IContestItem {
  contestId: number;
  actionKey: string;
  title: string;
  coins: number;
  description: string;
  rewardType: string;
  status: number;
  startDate: string;
  endDate: string;
  createdBy: string;  
}


export const PRIMARY_BUTTON_STYLE = "ml-2 bg-[#6415ff] hover:bg-[#5500fb] text-white font-bold py-2 px-4 rounded-full";
export const PRIMARY_BUTTON_STYLE_ALTERNATE = "ml-2 bg-[#f2f2f2] hover:bg-[#e6e6e6] text-[#5500fb] font-bold py-2 px-4 rounded-full";
export const INPUT_TEXT_STYLE = "w-96 rounded-full";
export const MODAL_CONTAINER_STYLE = "max-h-96 overflow-scroll";
export const TABLE_STYLE = "text-center";

export const DEFAULT_COMMUNITY_IMAGE = "https://discovery.investmates.tech/Investmates/assets/community.png";
export const DEFAULT_EMAIL = "feedback@investmates.io";

export const MONTHS = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"]
export const formatSQLResult = (arr: Array<any>) => {
  let result = {};
  arr.forEach(el => {
    result = { ...result, ...el[0] }
  })
  return result;
}

// https://www.finetricks.com/use-of-timeago-function-in-react-js/
export const formatTime = (timeCreated: string | number | Date) => {
  //console.log(Date.now())
  if (typeof timeCreated === 'number') {
    // console.log(timeCreated)
    if (String(timeCreated).length < 13) {
      // handle if millis are missing
      timeCreated = timeCreated * 1000;
    }
  }
  var diff = Math.floor((Date.now() - (new Date(timeCreated)).getTime()) / 1000);
  var interval = Math.floor(diff / 31536000);

  if (interval >= 1) {
    return interval + " years ago";
  }
  interval = Math.floor(diff / 2592000);
  if (interval >= 1) {
    return interval + " months ago";
  }
  interval = Math.floor(diff / 604800);
  if (interval >= 1) {
    return interval + " weeks ago";
  }
  interval = Math.floor(diff / 86400);
  if (interval >= 1) {
    return interval + " days ago";
  }
  interval = Math.floor(diff / 3600);
  if (interval >= 1) {
    return interval + " hours ago";
  }
  interval = Math.floor(diff / 60);
  if (interval >= 1) {
    return interval + " min ago";
  }
  return "Just Now";
}

export const getReportClassification = (rep: IReportCardType["rep"]) => {
  if ("postid" in rep) {
    if (rep["postcommentid"] > 0) return "Post Comment"
    return "Post"
  }
  if ("tradeid" in rep) {
    if (rep["tradecommentid"] > 0) return "Trade Comment"
    return "Trade"
  }
  if ("communityid" in rep) {
    if (rep["communitycommentid"] > 0) return "Community Post Comment";
    if (rep["communitypostid"] > 0) return "Community Post";
    return "Community"
  }
  return '';
}

export const getReportIds = (rep: IReportCardType["rep"]) => {
  let communityreportid = 0, postreportid = 0, tradereportid = 0;
  if ("communityreportid" in rep) {
    communityreportid = rep.communityreportid;
  }
  if ("postreportid" in rep) {
    postreportid = rep.postreportid;
  }
  if ("tradereportid" in rep) {
    tradereportid = rep.tradereportid;
  }
  return [communityreportid, postreportid, tradereportid];
}

export const getReportRoutePrefix = (rep: IReportCardType["rep"]) => {
  if ("communityreportid" in rep) {
    return "community/" + rep.communityreportid;
  }
  if ("postreportid" in rep) {
    return "post/" + rep.postreportid;
  }
  if ("tradereportid" in rep) {
    return "trade/" + rep.tradereportid
  }
  return "report"
}

export const getReportPathArgs = ({ id, item }: any) => {
  let communityreportid = 0, postreportid = 0, tradereportid = 0;
  if (item === "community") {
    communityreportid = Number(id);
  }
  if (item === "post") {
    postreportid = Number(id);
  }
  if (item === "trade") {
    tradereportid = Number(id);
  }
  return [communityreportid, postreportid, tradereportid];
}

export const getFBMailSubject = (id: string | number, title: string) => {
  return `[IM][FB${id}] ${title}`
}

export const toHex = (index: number) => {
  var colors = ["#b86495", "#12fa5c", "#a7c5d7", "#871ed8", "#92136c", "#c9d61b", "#25debf", "#c542c1", "#0e81ea", "#292df4", "#ff9800", "#ff5722", "#795548", "#607d8b"]
  return colors[index];
}

// https://stackoverflow.com/a/63006702
export const timeout = (delay: number) => {
  return new Promise(res => setTimeout(res, delay));
}

export const normalizeData = (hours: Array<{ hour: number; count: number }>, isPlain = false) => {
  // get 24 hours
  const newArr: number[] = [];
  const last = hours.length - 1;
  let base = isPlain ? 0 : hours[last].count;
  for (let i = last - 1; i >= 0; i--) {
    let el = hours[i];
    newArr[i] = el.count - base;
    if (!isPlain)
      base = el.count;
  }

  return newArr.reverse();
}

export const get24hrLabels = () => {
  const newArr = [];
  let currentdate = new Date();
  for (let i = 23; i >= 0; i--) {
    newArr[i] = currentdate.getHours();
    currentdate.setHours(currentdate.getHours() - 1);
  }
  return newArr;
}

export const get1WLabels = () => {
  const newArr = [];
  let currentdate = new Date();
  for (let i = 6; i >= 0; i--) {
    newArr[i] = currentdate.getDate();
    currentdate.setDate(currentdate.getDate() - 1);
  }
  return newArr;
}

export const get1MLabels = () => {
  const newArr = [];
  let currentdate = dayjs(new Date());
  for (let i = 4; i >= 0; i--) {
    newArr[i] = `Week ${currentdate.week() - 1}`
    currentdate = currentdate.subtract(1, 'week')
  }
  return newArr;
}

export const get1YLabels = () => {
  let newArr = [];
  let currentdate = new Date();
  for (let i = 11; i >= 0; i--) {
    newArr[i] = currentdate.toLocaleString('default', { month: 'long' });
    currentdate.setMonth(currentdate.getMonth() - 1);
  }
  return newArr;
}

export const get3MLabels = () => {
  let newArr = [];
  let currentdate = new Date();
  for (let i = 2; i >= 0; i--) {
    newArr[i] = currentdate.toLocaleString('default', { month: 'long' });
    currentdate.setMonth(currentdate.getMonth() - 1);
  }
  return newArr;
}

export const get6MLabels = () => {
  let newArr = [];
  let currentdate = new Date();
  for (let i = 5; i >= 0; i--) {
    newArr[i] = currentdate.toLocaleString('default', { month: 'long' });
    currentdate.setMonth(currentdate.getMonth() - 1);
  }
  return newArr;
}

export const getAllYearsLabels = () => {
  let newArr = [];
  let currentdate = new Date();
  for (let i = 4; i >= 0; i--) {
    newArr[i] = currentdate.getFullYear();
    currentdate.setFullYear(currentdate.getFullYear() - 1);
  }
  return newArr;
}

export const normalizeDays = (days: Array<any>) => {
  const labels = get24hrLabels();
  // const timezonedResult = getTimeZonedResults(days);
  const tempMap = new Map<number, number>();
  days.forEach((e: any) => {
    tempMap.set(e.hour, e.count);
  });
  return labels.map((data: any) => {
    return tempMap.get(data) ?? 0;
  });
}

const getTimeZonedResults = (results: any) => {
  return results.map((data: any) => {
    data.hour = ((data.hour + 5) % 24) + 1;
    return data;
  });
  // for (let i = 0; i < results.length; i++) {
  //   console.log(results[i], (((results[i].hour + 5) % 24)));
  // }
}

export const normalizeWeek = (week: Array<any>) => {
  const weekLabels = get1WLabels();
  const weekArr = new Array<number>();
  const weekMap: Map<number, number> = new Map<number, number>();

  week.forEach((data: any) => {
    weekMap.set(data.day, data.count);
  });

  weekLabels.forEach((data: number) => {
    if (!weekMap.has(data)) {
      weekArr.push(0);
    } else {
      weekArr.push(Number(weekMap.get(data)));
    }
  });

  return weekArr;
}

export const normalizeYear = (year: Array<any>) => {
  const yearLabels = get1YLabels();
  const yearArr = new Array<number>();
  const yearMap: Map<number, number> = new Map<number, number>();
  year.forEach((data: any) => {
    yearMap.set(data.month, data.count);
  });
  yearLabels.forEach((data: string) => {
    if (yearMap.has(getMonthNumberByName(data))) {
      yearArr.push(yearMap.get(getMonthNumberByName(data)) ?? 0);
    } else {
      yearArr.push(0);
    }   
  });
  return yearArr;
  // return year.map((data: any) => {
  //   return data.count;
  // })
}

// normalizing the last 3 month data
export const normalize3Month = (data: Array<any>) => {
  const monthLabels = get3MLabels();
  const monthArr = new Array<number>();
  const monthMap: Map<number, number> = new Map<number, number>();
  data.forEach((month: any) => {
    monthMap.set(month["month"], month["count"]);    
  });
  monthLabels.forEach((month: string) => {
    if (monthMap.has(getMonthNumberByName(month))) {
      monthArr.push(monthMap.get(getMonthNumberByName(month)) ?? 0);
    } else {
      monthArr.push(0);
    }
  });
  return monthArr;
}

// normalizing the last 6 month data
export const normalize6Month = (data: Array<any>) => {
  const monthLabels = get6MLabels();
  const monthArr = new Array<number>();
  const monthMap: Map<number, number> = new Map<number, number>();
  data.forEach((month: any) => {
    monthMap.set(month["month"], month["count"]);    
  });
  monthLabels.forEach((month: string) => {
    if (monthMap.has(getMonthNumberByName(month))) {
      monthArr.push(monthMap.get(getMonthNumberByName(month)) ?? 0);
    } else {
      monthArr.push(0);
    }
  });
  return monthArr;
}

// normalizing the last 5 years data
export const normalizeLast5Years = (data: Array<any>) => {
  const yearsLabels = getAllYearsLabels();
  const yearsArr = new Array<number>();
  const yearsMap: Map<number, number> = new Map<number, number>();
  data.forEach((year: any) => {
    yearsMap.set(year["year"], year["count"]);
  });
  yearsLabels.forEach((year: number) => {
    yearsMap.has(year) 
      ? yearsArr.push(Number(yearsMap.get(year))) 
      : yearsArr.push(0);
  });

  return yearsArr;
}

export const normalizeMonth = (month: Array<any>) => {
  const monthLabels = get1MLabels();
  const monthArr = new Array<number>();
  const monthMap = new Map<number, number>();
  month.forEach((data: any) => {
    monthMap.set(data.week, data.count);
  });
  monthLabels.forEach((data: string) => {
    monthArr.push(monthMap.get(parseInt( data.substring(5,) )) ?? 0);
  });
  return monthArr;
  // return month.map((data: any) => {
  //   return data.count;
  // });
}

const getMonthNumberByName = (name: string) => {
  switch (name) {
    case "January":
      return 1;
    case "February":
      return 2;
    case "March":
      return 3;
    case "April":
      return 4;
    case "May":
      return 5;
    case "June":
      return 6;
    case "July":
      return 7;
    case "August":
      return 8;
    case "September":
      return 9;
    case "October":
      return 10;
    case "November":
      return 11;
    case "December":
      return 12;
    default:
      return 0;
  }
}



export const getLabels = (chart: number) => {
  switch (chart) {
    case 0: return get24hrLabels();
    case 1: return get1WLabels();
    case 2: return get1MLabels();
    case 3: return get1YLabels();
    case 4: return getAllYearsLabels();
    case 5: return get3MLabels();
    case 6: return get6MLabels();
    default:
      return get24hrLabels();
  }
}

export const parseCommunityPostGrowth = (items: Array<{ hours: number; commposts: number; dashposts: number }>, isPlain = false) => {
  const comArr: number[] = [];
  const dashArr: number[] = [];
  const last = items.length - 1;
  let base1 = isPlain ? 0 : items[last].commposts;
  let base2 = isPlain ? 0 : items[last].dashposts;
  for (let i = last - 1; i >= 0; i--) {
    let el = items[i];
    comArr[i] = el.commposts - base1;
    dashArr[i] = el.dashposts - base2;
    if (!isPlain) {
      base1 = el.commposts;
      base2 = el.dashposts;
    }

  }
  return [comArr.reverse(), dashArr.reverse()]
}

export const parseEngagement = (items: Array<{ hours: number; result: number }>) => {
  const newArr: number[] = [];
  const last = items.length - 1;
  for (let i = last - 1; i >= 0; i--) {
    let el = items[i];
    newArr[i] = el.result;
  }

  return newArr.reverse();
}

export const parseChurn = (items: Array<{ hour: number; total: number; inactive: number }>, isPlain = false) => {
  const churns: number[] = [];
  const last = items.length - 1;
  let prev = items[last];
  for (let i = last - 1; i >= 0; i--) {
    let el = items[i];
    if ((prev.total - prev.inactive) === 0) churns[i] = 0;
    else {
      // remove previous inactive for numerator and remove total inactive for total members
      churns[i] = Math.round((el.inactive - prev.inactive) * 10000 / (prev.total - prev.inactive)) / 100; // for two digit precision
    }

    prev = el;
  }
  return churns.reverse();
}

export const parseMembersActive = (items: Array<{ hour: number; total: number; inactive: number }>, isPlain = false) => {
  const actArr: number[] = [];
  const inactArr: number[] = [];
  const last = items.length - 1;

  for (let i = last - 1; i >= 0; i--) {
    let el = items[i];
    actArr[i] = el.total - el.inactive;
    inactArr[i] = el.inactive;
  }
  return [actArr.reverse(), inactArr.reverse()]
}

export const getEnvApi = (env: number) => {
  switch (env) {
    case 0:
      return 'baseUrl';
    case 1:
      return 'devbaseUrl';
    case 2:
      return 'stagebaseUrl';
    default:
      return 'baseUrl';
  }
}

/**
 * Downloads the given csv data to a csv file
 * @param data given csv data
 * @param filename filename with a .csv extension
 *  
 */
export const downloadCsv = function(data: any, filename: string) {
  const blob = new Blob([data], {type: 'text/csv'});
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.setAttribute('hidden', '');
  a.setAttribute('href', url);
  a.setAttribute('download', filename + '.csv');
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}

export const jsonToCsv = (jsonData: any) => {
	const csvRows = [];

  // getting the headers
  const headers = Object.keys(jsonData[0]);
  csvRows.push(headers.join(','));

  // loop over the rows
  for (const row of jsonData) {
    const values = headers.map((header: any) => {
      const escaped = ('' + row[header]).replace(/"/g, '\\"');
      return `"${escaped}"`;
    });
    csvRows.push(values.join(','));
  }
 	return csvRows.join('\n');
}

export const parseAppStoreReview = (data: any) => {
  // - authorName: "ankit dhiman65"
  // - reviewId: "8850258161"
  // - starRating: "5"
  // - text: "Enjoyed the experience of using this app, its really helpful to connect with investors and learn from their experiences."
  // - timestamp: "2022-07-07T08:51:14-07:00"
  // - title: "Amazing communities to learn more about investing."
  const feedbackData = [] as Array<IFeedbackCardType>;
  if (data.constructor === Array) {
    data.map((item: any) => {
      const tempData: IFeedbackCardType = {} as IFeedbackCardType;
      tempData.firstname = item.authorName;
      tempData.lastname = "";
      tempData.fbid = item.reviewId;
      tempData.rating = parseInt(item.starRating);
      tempData.src = 3;
      tempData.title = item.title;
      tempData.msg = item.text;
      tempData.created = item.timestamp;
      feedbackData.push(tempData);
      return item;
    });
  } else {
    const tempData: IFeedbackCardType = {} as IFeedbackCardType;
    tempData.firstname = data.authorName;
    tempData.lastname = "";
    tempData.fbid = data.reviewId;
    tempData.rating = parseInt(data.starRating);
    tempData.src = 3;
    tempData.title = data.title;
    tempData.msg = data.text;
    tempData.created = data.timestamp;
    feedbackData.push(tempData)
  }
  return feedbackData;
}

export const parsePlayStoreReviews = (data: any) => {
  // - authorName: "gaurav garg"
  // - reviewId: "69d0f033-7817-41ee-aa5f-4ff7fd0a666a"
  // - starRating: 5
  // - text: "\tThis is the best app regarding financial education. Easy to use. Once should have this app in their mobile phone."
  // - timestamp: {seconds: '1658231357', nanos: 585000000}
  const feedbackData = [] as Array<IFeedbackCardType>;
  data.forEach((item: any) => {
    const tempData: IFeedbackCardType = {} as IFeedbackCardType;
    tempData.firstname = item.authorName;
    tempData.lastname = "";
    tempData.fbid = item.reviewId;
    tempData.rating = item.starRating;
    tempData.src = 2;
    tempData.msg = item.text;
    tempData.created = getDateString(parseInt(item.timestamp.seconds));
    feedbackData.push(tempData);
  });
  return feedbackData;
}

// created: "2022-07-09T15:04:29.000Z"
// deleted: 0
// email: "ratan.product@gmail.com"
// fbid: 275
// firstname: "Ratan"
// lastname: "D"
// msg: "hshsjs"
// rating: 0
// src: 1
// status: 1
// tag: 1
// thumbimage: "https://images.nearle.app/Investmates/Profile/265/profile-1657238238712-spdmV4.jpg"
// title: "jsjsjs"
// updated: "2022-07-09T15:04:29.000Z"
// userhandle: "ratan"
// userid: 265


export const sortedFeedback = (feedback: any[]) => {
  return feedback.sort((d1, d2) => {
    return (new Date(d2.created).valueOf() - new Date(d1.created).valueOf());
  });
}

// Checking is the email id is valid
export const isValidEmail = (email: string) => {
    const emailPattern = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
    return emailPattern.test(email);
}

// Contest Rank Interface
export interface IContestRank {
    startRank: number;
    endRank: number;
    coins: number;
}

// getting the percentage
export const getPercentage = (base: number, value: number) => {
    if (base <= 0) {
        return 0;
    }
    return ((value / base) * 100).toFixed(2)
}

export const percentageToOpacity = (percentage: number) => {
    const opacityVal = (percentage / 100) < 0.8 ? (percentage / 100) : 0.8
    return opacityVal.toFixed(2)
}

export const setupUsersMap = (newUsersArr: Array<any>) => {
    const newUserMap = new Map<string, Array<number>>();
    newUsersArr.forEach((item: any) => {
        if (newUserMap.get(item['created'])) {
            newUserMap.get(item['created'])?.push(item['userid']);
        } else {
            newUserMap.set(item['created'], [item['userid']]);
        }
    });
    return newUserMap;
}

export const getNextDates = (curDate: string) => {
    const d = new Date(curDate);
    const d1 = new Date(d.setDate(d.getDate() + 1));
    const D1 = (d1.getFullYear() + "-" + ("0" + (Number(d1.getMonth()) + 1)).slice(-2) + "-" + ("0" + d1.getDate()).slice(-2))

    const d2 = new Date(d.setDate(d.getDate() + 1));
    const D2 = (d2.getFullYear() + "-" + ("0" + (Number(d2.getMonth()) + 1)).slice(-2) + "-" + ("0" + d2.getDate()).slice(-2));

    const d3 = new Date(d.setDate(d.getDate() + 1));
    const D3 = (d3.getFullYear() + "-" + ("0" + (Number(d3.getMonth()) + 1)).slice(-2) + "-" + ("0" + d3.getDate()).slice(-2));

    const d4 = new Date(d.setDate(d.getDate() + 1));
    const D4 = (d4.getFullYear() + "-" + ("0" + (Number(d4.getMonth()) + 1)).slice(-2) + "-" + ("0" + d4.getDate()).slice(-2));

    const d5 = new Date(d.setDate(d.getDate() + 1));
    const D5 = (d5.getFullYear() + "-" + ("0" + (Number(d5.getMonth()) + 1)).slice(-2) + "-" + ("0" + d5.getDate()).slice(-2));

    const d6 = new Date(d.setDate(d.getDate() + 1));
    const D6 = (d6.getFullYear() + "-" + ("0" + (Number(d6.getMonth()) + 1)).slice(-2) + "-" + ("0" + d6.getDate()).slice(-2));

    const d7 = new Date(d.setDate(d.getDate() + 1));
    const D7 = (d7.getFullYear() + "-" + ("0" + (Number(d7.getMonth()) + 1)).slice(-2) + "-" + ("0" + d7.getDate()).slice(-2));

    const d15 = new Date(d.setDate(d.getDate() + 8));
    const D15 = (d15.getFullYear() + "-" + ("0" + (Number(d15.getMonth()) + 1)).slice(-2) + "-" + ("0" + d15.getDate()).slice(-2));

    const d30 = new Date(d.setDate(d.getDate() + 15));
    const D30 = (d30.getFullYear() + "-" + ("0" + (Number(d30.getMonth()) + 1)).slice(-2) + "-" + ("0" + d30.getDate()).slice(-2));

    return [D1, D2, D3, D4, D5, D6, D7, D15, D30];
}

export const getNextDate = (curDate: string, next: number) => {
    const d = new Date(curDate);
    const d1 = new Date(d.setDate(d.getDate() + next));
    const D1 = (d1.getFullYear() + "-" + ("0" + (Number(d1.getMonth()) + 1)).slice(-2) + "-" + ("0" + d1.getDate()).slice(-2));

    return D1;
}

export const isNumber = (num: string) => {
  return /^\d+$/.test(num);
}

export const isValidDate = (date: string) => {
  return !isNaN(new Date(date).getDate());
}

export const isAdminUser = () => {
  return new Promise((resolve, reject) => {
    Auth.currentAuthenticatedUser()
      .then(data => {
        const userGroups = data.signInUserSession.accessToken.payload['cognito:groups'];
        if (
          userGroups !== undefined && 
          userGroups.length > 0 &&
          (userGroups.includes("imadmin") || userGroups.includes("tester"))) {
            return resolve(true);
          } else {
            return resolve(false);
          }
      }).catch(err => {
        return reject(err);
      });
  });
}

// Uploading the image
export const uploadImage = (imageName: string, folderName: string, file: any) => {
  return new Promise((resolve, reject) => {
    s3Client.putObject({
      Bucket: process.env.REACT_APP_SPACES_BUCKET,
      Key: getFolderName(folderName, imageName),
      Body: file,
      ACL: "public-read"
    }).then(data => {
      return resolve(data);
    }).catch(err => {
      return reject(err);
    });
  });
}

const getFolderName = (folderName: string, imageName: string) => {
  switch (folderName) {
    case "contest":
      return process.env.REACT_APP_CONTEST_FOLDER + imageName;
    case "banners":
      return process.env.REACT_APP_BANNER_FOLDER + imageName;
  }
}

export function getUTCDateString(dateString: string) {
  const date = new Date(dateString);
  const isoString = date.toISOString();

  return isoString.split('T')[0] + ' ' + isoString.slice(11, 19).split(' ')[0];
  // return date.toISOString().split('T')[0] + ' '
  // + date.toTimeString().split(' ')[0];
}

export function getLocalDateTime(inputDate: string){
  // console.log(dateString);
  const dateString = new Date(inputDate).toLocaleString();  
  const [date, time] = dateString.split(", ");
  const [day, month, year] = date.split("/");
  const [hours, minutes, seconds] = time.split(":");
  const formattedDate = `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")} ${hours}:${minutes}:${seconds}`;
  // console.log(formattedDate);
  return formattedDate;
}

export const getFeedbackTagById = (id: number) => {
  switch (id) {
    case 1: 
      return "Help";
    case 2:
      return "Suggestion";
    case 3:
      return "Grievance";
    default: return "Unknown";
  }
}

export const copyToClipboard = (data: string) => {
  navigator.clipboard.writeText(data);
}

export const isValidUrl = (url: string) => {
  return /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i.test(url);
}

const getDateString = (timestamp: number) => {
  const date = new Date(timestamp * 1000);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
}

export const getPagesCount = (listLength: number, pageSize: number) => {
  if (listLength <= 0 || pageSize <= 0) {
    return 0;
  }
  return Math.ceil(listLength / pageSize);
}

export const getPages = (currentPage: number, totalPages: number, pageWindow: number) => {  
  // List of pages
  const pages = [];  
  if (totalPages < pageWindow)  {
    for (let i = 1; i <= totalPages; i++) {
      pages.push(i);      
    }
    return pages;
  }
  
  if (totalPages - currentPage >= pageWindow) {
    // from current page till currentPage + pageWindow add the pages
    for (let i = currentPage; i < currentPage + pageWindow; i++) {
      pages.push(i);
    }

    return pages;
  }

  for (let i = 1; i <= pageWindow; i++) {
    pages.unshift(totalPages);
    totalPages--;
  }
  return pages;  
}