import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from "react-router-dom";
import { Toaster } from 'react-hot-toast';
import ReactTooltip from 'react-tooltip';
import { Amplify } from '@aws-amplify/core';
import './styles/index.css';
import AppLayout from './layout/AppLayout';
import config from './config';
// import reportWebVitals from './utils/reportWebVitals';

const updatedAwsConfig = {
  Auth: {
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
  },
  API: {
    endpoints: [
      {
        name: 'baseUrl',
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION
      },
      {
        name: 'devbaseUrl',
        endpoint: config.devapiGateway.URL,
        region: config.devapiGateway.REGION
      },
      {
        name: 'stagebaseUrl',
        endpoint: config.stageapiGateway.URL,
        region: config.stageapiGateway.REGION
      },
    ],
  },
};

Amplify.configure(updatedAwsConfig);

const container = document.getElementById('root');
const root = createRoot(container!);

const App = () => (
  <>
    <Router>
      <AppLayout />
    </Router>
    <Toaster />
    <ReactTooltip effect="solid" type="dark" />
  </>
)
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
