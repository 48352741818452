import { faDownload, faSort, faSortAlphaDesc, faSortAmountUp, faSortAsc, faSortDesc, faSortDown, faUnsorted } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getUserReportListAPI } from "../../api/report";
import { downloadCsv, isValidDate, IUserReportListType } from "../../utils";
import { useAccounts } from "../accounts/context";

const UserReports = () => {

    const {
        state: {curEnv}
    } = useAccounts();
    const navigate = useNavigate();

    const [userFilter, setUserFilter] = useState<number | string>("");    
    const [isNumericalFilter, setIsNumericalFilter] = useState(false);
    const [reportUserData, setReportUserData] = useState<Array<IUserReportListType>>([]);
    const [sortCode, setSortCode] = useState("RDD");
    // sort codes:
    // URA: User Report Ascending
    // URD: User Report Descending
    // PRA: Post Report Ascending
    // PRD: Post Report Descending
    // UWA: User Warning Ascending
    // UWD: User Warning Descending
    // USA: User Suspension Ascending
    // USD: User Suspension Descending
    // RDA: Report Date Ascending
    // RDD: Report Date Descending

    useEffect(() => {
        const onLoad = () => {
            setIsNumericalFilter(/^\d+$/.test(userFilter.toString()));
        }
        onLoad();
    }, [userFilter]);

    const handleReportClick = (e: any, userId: number) => {
        e.preventDefault();
        navigate('/dashboard/user_report_details/' + userId);
    }

    const downloadData = (e: any) => {
        // e.preventDefault();
        downloadCsv(reportUserData, "reported_user_list");
    }

    useEffect(() => {
        const onLoad = () => {
            getUserReportListAPI(curEnv)
                .then(data => {
                    // console.log(data);
                    if (data !== undefined && data.length > 0) {
                        setReportUserData([]);
                        data.map((item: any) => {
                            // export interface IUserReportListType {
                            //     id: number;
                            //     name: string;
                            //     status: string;
                            //     userReportCount: number;
                            //     postReportCount: number;
                            //     warningCount: number;
                            //     suspendCount: number;
                            //   }
                            const tempObj = {} as IUserReportListType;
                            
                            tempObj.id = item["userid"];
                            tempObj.name = item["name"];
                            tempObj.status = item["status"];
                            tempObj.userReportCount = item["user_reports"];
                            tempObj.postReportCount = item["post_reports"];
                            tempObj.warningCount = item["total_warnings"];
                            tempObj.suspendCount = item["total_suspensions"];
                            tempObj.lastReportedDate = item["last_reported_date"];
                            // console.log(tempObj);
                            setReportUserData(oldData => [...oldData, tempObj]);                             
                        });
                    } else {
                        setReportUserData([]);
                    }
                }).catch(err => {
                    console.log(err);
                });
        }

        onLoad();
    }, [curEnv]);

    const changeSort = (code: string) => {
        // No filter: faUnsorted
        // Ascending filter: faSortAsc
        // Descending: faSortDesc
        // URA: User Report Ascending
        // URD: User Report Descending
        // PRA: Post Report Ascending
        // PRD: Post Report Descending
        // UWA: User Warning Ascending
        // UWD: User Warning Descending
        // USA: User Suspension Ascending
        // USD: User Suspension Descending
        // RDA: Report Date Ascending
        // RDD: Report Date Descending
        // sort change flow -> No Sort -> Descending -> Ascending -> NoSort
        switch (code) {
            case "UR":
                if (                    
                    sortCode === "PRA" ||
                    sortCode === "PRD" ||
                    sortCode === "UWA" ||
                    sortCode === "UWD" ||
                    sortCode === "USA" ||
                    sortCode === "USD" ||
                    sortCode === "RDA" ||
                    sortCode === "RDD" ||
                    sortCode === ""
                    ) {
                        setSortCode("URD");
                        return;
                    } else if (sortCode.charAt(2) === "D") {
                        setSortCode("URA");
                        return;
                    } else if (sortCode.charAt(2) === "A") {
                        setSortCode("");
                        return;
                    } else {
                        setSortCode("");
                        return;
                    }
            case "PR":
                if (                    
                    sortCode === "URA" ||
                    sortCode === "URD" ||
                    sortCode === "UWA" ||
                    sortCode === "UWD" ||
                    sortCode === "USA" ||
                    sortCode === "USD" ||
                    sortCode === "RDA" ||
                    sortCode === "RDD" ||
                    sortCode === ""
                    ) {
                        setSortCode("PRD");
                        return;
                    } else if (sortCode.charAt(2) === "D") {
                        setSortCode("PRA");
                        return;
                    } else if (sortCode.charAt(2) === "A") {
                        setSortCode("");
                        return;
                    } else {
                        setSortCode("");
                        return;
                    }
            case "UW":
                if (                    
                    sortCode === "URA" ||
                    sortCode === "URD" ||
                    sortCode === "PRA" ||
                    sortCode === "PRD" ||
                    sortCode === "USA" ||
                    sortCode === "USD" ||
                    sortCode === "RDA" ||
                    sortCode === "RDD" ||
                    sortCode === ""
                    ) {
                        setSortCode("UWD");
                        return;
                    } else if (sortCode.charAt(2) === "D") {
                        setSortCode("UWA");
                        return;
                    } else if (sortCode.charAt(2) === "A") {
                        setSortCode("");
                        return;
                    } else {
                        setSortCode("");
                        return;
                    }
            case "US":
                if (                    
                    sortCode === "URA" ||
                    sortCode === "URD" ||
                    sortCode === "PRA" ||
                    sortCode === "PRD" ||
                    sortCode === "UWA" ||
                    sortCode === "UWD" ||
                    sortCode === "RDA" ||
                    sortCode === "RDD" ||
                    sortCode === ""
                    ) {
                        setSortCode("USD");
                        return;
                    } else if (sortCode.charAt(2) === "D") {
                        setSortCode("USA");
                        return;
                    } else if (sortCode.charAt(2) === "A") {
                        setSortCode("");
                        return;
                    } else {
                        setSortCode("");
                        return;
                    }
                case "RD":
                    if (                    
                        sortCode === "URA" ||
                        sortCode === "URD" ||
                        sortCode === "PRA" ||
                        sortCode === "PRD" ||
                        sortCode === "UWA" ||
                        sortCode === "UWD" ||
                        sortCode === "USA" ||
                        sortCode === "USD" ||
                        sortCode === ""
                        ) {
                            setSortCode("RDD");
                            return;
                        } else if (sortCode.charAt(2) === "D") {
                            setSortCode("RDA");
                            return;
                        } else if (sortCode.charAt(2) === "A") {
                            setSortCode("");
                            return;
                        } else {
                            setSortCode("");
                            return;
                        }
            default:
                setSortCode("");
                return;
        }
    }    

    return (
        <div className="ml-6">
            <div className="flex flex-col">
                <UserReportTopBar filter={userFilter} setFilter={setUserFilter} downloadFunc={downloadData}  />

                <table className="mt-4 mr-4">
                    <thead className="bg-[#f3f3f3]">
                        <tr>
                            <th className="px-4 py-4">Username</th>
                            <th>User Id</th>
                            <th>Status</th>
                            <th>
                                Number of User Reports&nbsp;                                
                                <FontAwesomeIcon                                                                        
                                    icon={
                                        (sortCode === "" 
                                        || sortCode === "PRA"
                                        || sortCode === "PRD"
                                        || sortCode === "UWA"
                                        || sortCode === "UWD"
                                        || sortCode === "USA"
                                        || sortCode === "USD")
                                        ? faUnsorted
                                        : (sortCode === "URA"
                                          ? faSortAsc
                                          : faSortDesc)
                                    }
                                    onClick={() => changeSort("UR")}
                                />
                            </th>
                            <th>
                                Number of Post Reports&nbsp;
                                <FontAwesomeIcon                                                                        
                                    icon={
                                        (sortCode === "" 
                                        || sortCode === "URA"
                                        || sortCode === "URD"
                                        || sortCode === "UWA"
                                        || sortCode === "UWD"
                                        || sortCode === "USA"
                                        || sortCode === "USD"
                                        || sortCode === "RDA"
                                        || sortCode === "RDD"
                                        )
                                        ? faUnsorted
                                        : (sortCode === "PRA"
                                          ? faSortAsc
                                          : faSortDesc)
                                    }
                                    onClick={() => changeSort("PR")}
                                />
                            </th>
                            <th>
                                Number of Warnings&nbsp;
                                <FontAwesomeIcon                                                                        
                                    icon={
                                        (sortCode === "" 
                                        || sortCode === "URA"
                                        || sortCode === "URD"
                                        || sortCode === "PRA"
                                        || sortCode === "PRD"
                                        || sortCode === "USA"
                                        || sortCode === "USD"
                                        || sortCode === "RDA"
                                        || sortCode === "RDD"
                                        )
                                        ? faUnsorted
                                        : (sortCode === "UWA"
                                          ? faSortAsc
                                          : faSortDesc)
                                    }
                                    onClick={() => changeSort("UW")}
                                />
                            </th>
                            <th>
                                Number of times suspended&nbsp;
                                <FontAwesomeIcon                                                                        
                                    icon={
                                        (sortCode === "" 
                                        || sortCode === "URA"
                                        || sortCode === "URD"
                                        || sortCode === "UWA"
                                        || sortCode === "UWD"
                                        || sortCode === "PRA"
                                        || sortCode === "PRD"
                                        || sortCode === "RDA"
                                        || sortCode === "RDD"
                                        )
                                        ? faUnsorted
                                        : (sortCode === "USA"
                                          ? faSortAsc
                                          : faSortDesc)
                                    }
                                    onClick={() => changeSort("US")}
                                />
                            </th>
                            <th>
                                Last reported&nbsp;
                                <FontAwesomeIcon                                                                        
                                    icon={
                                        (sortCode === "" 
                                        || sortCode === "URA"
                                        || sortCode === "URD"
                                        || sortCode === "UWA"
                                        || sortCode === "UWD"
                                        || sortCode === "PRA"
                                        || sortCode === "PRD"
                                        || sortCode === "USA"
                                        || sortCode === "USD"
                                        )
                                        ? faUnsorted
                                        : (sortCode === "RDA"
                                          ? faSortAsc
                                          : faSortDesc)
                                    }
                                    onClick={() => changeSort("RD")}
                                />
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            reportUserData.filter((item) => {
                                // check if the filter is numerical
                                // check if the item has a valid date
                                if (isValidDate(item.lastReportedDate)) {
                                    if (userFilter === "" || userFilter === 0) {
                                        return true
                                    } else {
                                        if (isNumericalFilter) {
                                            return item.id.toString().includes(userFilter.toString());
                                        } else {
                                            return item.name.toLocaleLowerCase().includes(userFilter.toString().toLocaleLowerCase());
                                        }
                                    }
                                } else {
                                    return false;
                                }                               
                            }).sort((a, b) => {
                                switch (sortCode) {
                                    // URA: User Report Ascending
                                    // URD: User Report Descending
                                    // PRA: Post Report Ascending
                                    // PRD: Post Report Descending
                                    // UWA: User Warning Ascending
                                    // UWD: User Warning Descending
                                    // USA: User Suspension Ascending
                                    // USD: User Suspension Descending
                                    case "URA":
                                        return a.userReportCount - b.userReportCount;
                                    case "URD":
                                        return b.userReportCount - a.userReportCount;
                                    case "PRA":
                                        return a.postReportCount - b.postReportCount;
                                    case "PRD":
                                        return b.postReportCount - a.postReportCount;
                                    case "UWA":
                                        return a.warningCount - b.warningCount;
                                    case "UWD":
                                        return b.warningCount - a.warningCount;
                                    case "USA":
                                        return a.suspendCount - b.suspendCount;
                                    case "USD":
                                        return b.suspendCount - a.suspendCount;
                                    case "RDA":
                                        return new Date(a.lastReportedDate).getTime() - new Date(b.lastReportedDate).getTime();
                                    case "RDD":
                                        return new Date(b.lastReportedDate).getTime() - new Date(a.lastReportedDate).getTime();
                                    default:
                                        return 0;
                                }
                            }).map((item, index) => {
                                return <tr className="border-b" key={index}>
                                    <td className="px-4 py-2 text-sm font-light text-gray-900 text-center">
                                        <a href="#" 
                                            className="text-indigo-600 hover:text-indigo-900 hover:underline" 
                                            onClick={e => handleReportClick(e, item.id)}>{item.name}</a>
                                    </td>
                                    <td className="px-6 py-2 text-sm font-light text-gray-900 whitespace-nowrap text-center">
                                        {item.id}
                                    </td>
                                    <td className="px-6 py-2 text-sm font-light text-gray-900 whitespace-nowrap text-center">
                                        {
                                            item.status === "Active" ? "Suspended" : "Not Suspended"
                                        }
                                    </td>
                                    <td className="px-6 py-2 text-sm font-light text-gray-900 whitespace-nowrap text-center">
                                        {item.userReportCount}                                        
                                    </td>
                                    <td className="px-6 py-2 text-sm font-light text-gray-900 whitespace-nowrap text-center">
                                        {item.postReportCount}
                                    </td>
                                    <td className="px-6 py-2 text-sm font-light text-gray-900 whitespace-nowrap text-center">
                                        {item.warningCount}
                                    </td>
                                    <td className="px-6 py-2 text-sm font-light text-gray-900 whitespace-nowrap text-center">
                                        {item.suspendCount}
                                    </td>
                                    <td className="px-6 py-2 text-sm font-light text-gray-900 whitespace-nowrap text-center">
                                        {
                                            (item.lastReportedDate === undefined || item.lastReportedDate === "" || item.lastReportedDate === null )
                                            ? ""
                                            : item.lastReportedDate.split("T")[0]
                                        }
                                    </td>
                                </tr>
                            })
                        }
                    </tbody>
                </table>              
            </div>            
        </div>
    );
}

const UserReportTopBar = (
    {filter, setFilter, downloadFunc}: 
    {filter: number | string, setFilter: Dispatch<SetStateAction<number | string>>, downloadFunc: any}) => {
    return (        
        <div className="flex flex-row mt-2">            
            <div className="ml-4">
                <input 
                    className="w-96 rounded-full"
                    type="text"
                    onChange={e => setFilter(e.target.value)}
                    placeholder="Search by username / User Id" 
                    value={filter}
                />                
                <button                                                
                    className="ml-2 text-white bg-[#6415FF] hover:bg-[#5500fb] focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" type="button">
                    Download
                    <FontAwesomeIcon icon={faDownload} className="pl-2" onClick={e => downloadFunc(e)} />
                </button>
            </div>
        </div>
    );
}

export default UserReports;