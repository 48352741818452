import { ICommType } from "../../../../../redux/communities/reducer";
import { OneCard } from "../../../../common/cards";

const CommunityCards = ({ data }: { data: ICommType }) => {
  return (
    <div className="flex">
      <OneCard title={data?.count ?? "-"} subtitle="Total Number of Communities" />
      <OneCard title={data?.active ?? "-"} subtitle="Active Communities" />
      <OneCard title={(Number(data?.count) - Number(data?.active )) ?? "-"} subtitle="Inactive Communities" />
      <OneCard title={data?.not_part_of_community ?? "-"} subtitle="Users not part of Community" />
    </div>
  );
}

export default CommunityCards;
