export const keyword = '@@REP';

export const ADD_REP_COUNT = keyword + '_COUNT';

export function AddRepCountAction(payload: Array<any>) {
  let count = new Array(4).fill(0);
  let charts = Array(4).fill(undefined).map(() => Array(10).fill(0))
  let tagCharts = new Array(10).fill(0);

  for (let el of payload) {
    for (let i of el) {
      count[0] = count[0] + i.count;
      count[1] = count[1] + i.count;
      charts[0][i.reportid] = charts[0][i.reportid] + i.count;
      charts[1][i.reportid] = charts[1][i.reportid] + i.count
      tagCharts[i.reportid] = tagCharts[i.reportid] + i.count;
    }
  }
  return {
    type: ADD_REP_COUNT,
    payload: {
      count: count[0],
      inC: count[1],
      playC: count[2],
      appC: count[3],
      charts,
      tagCharts
    }
  }
}
