import {useEffect, useState} from "react";
import {useAccounts} from "../accounts/context";
import {getTotalCoinsSpentAPI} from "../../api/rewards";
import toast from "react-hot-toast";

const CoinsSpent = () => {
    const [totalCoinsSpent, setTotalCoinsSpent] = useState(0);

    const {
        state: {curEnv}
    } = useAccounts();

    useEffect(() => {
        const getCoinsSpent = () => {
            getTotalCoinsSpentAPI(1)
                .then(data => {
                    if (data !== undefined && data.length > 0) {
                        setTotalCoinsSpent(data[0]["total_debit"]);
                    }
                }).catch(err => {
                    console.log(err);
                    toast.error("Error fetching data for total coins spent")
                });
        }

        getCoinsSpent();
    }, [curEnv]);

    return<div className="flex mr-3">
        <div className="p-5 text-center rounded-lg bg-rose">
            <p className="text-2xl font-extrabold">{totalCoinsSpent}</p>
            <p>Total Coins Spent</p>
        </div>
    </div>
}

export default CoinsSpent;