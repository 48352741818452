import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal, TextInput } from "flowbite-react";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { createWithdrawalSettingsAPI, getCurrentWithdrawalSettingsAPI, getWithdrawalSettingsHistoryAPI, updateWithdrawalSettingsAPI } from "../../api/admin_ops";
import { getLocalDateTime, getUTCDateString, isValidEmail, PRIMARY_BUTTON_STYLE, PRIMARY_BUTTON_STYLE_ALTERNATE } from "../../utils";
import { useAccounts } from "../accounts/context";
import Loading from "../Loading";

const WithdrawalSettings = () => {

    interface IWithdrawSettings {
        withdrawalId: number;
        withdrawalLimit: number;
        totalWithdrawal: number;
        balance: number;
        alertThreshold: number;
        alertMessage: string;
        status: boolean;
        emailList: string;
        startDate: string;
        endDate: string;
        createdBy: string;
        updatedBy: string;        
    };

    const [withdrawSettings, setWithdrawSettings] = useState({} as IWithdrawSettings);
    const [editingObject, setEditingObject] = useState({} as IWithdrawSettings);    
    const [hasDataLoaded, setHasDataLoaded] = useState(false);
    const [hasWithdrawalSettings, setHasWithdrawalSettings] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showHistoryModal, setShowHistoryModal] = useState(false);
    const [isHistoryLoaded, setIsHistoryLoaded] = useState(false);
    const [histroyItems, setHistoryItems] = useState<Array<IWithdrawSettings>>([]);
    const [tempEmailList, setTempEmailList] = useState<Array<string>>([]);
    const [tempEmail, setTempEmail] = useState("");

    const {
        state: {curEnv, user}
    } = useAccounts();

    useEffect(() => {
        const onLoad = () => {
            getCurrentWithdrawalSettingsAPI(
                curEnv
            ).then(data => {                
                if (Array.isArray(data) && data.length > 0) {
                    const tempObject = {} as IWithdrawSettings;
                    
                    tempObject.alertThreshold = data[0]["alertthrehold"];
                    tempObject.balance = data[0]["balance"];
                    tempObject.createdBy = data[0]["createdby"]
                    tempObject.emailList = data[0]["emails"];                                     
                    tempObject.withdrawalId = data[0]["id"];                    
                    tempObject.alertMessage = data[0]["message"];
                    tempObject.startDate = getLocalDateTime(data[0]["startdate"]);
                    tempObject.endDate = getLocalDateTime(data[0]["enddate"]);
                    tempObject.status = data[0]["status"] === 1;
                    tempObject.withdrawalLimit = data[0]["totalamount"];
                    tempObject.totalWithdrawal = data[0]["totalwithdrawl"];
                    tempObject.updatedBy = data[0]["updatedby"];
                    setWithdrawSettings({
                        ...tempObject
                    });   
                    setHasDataLoaded(true);
                } else {
                    setHasDataLoaded(true);
                    setHasWithdrawalSettings(false);
                }
            }).catch(err => {
                setHasDataLoaded(false);                
                console.log(err);
                toast.error("Error while getting the details for the withdrawal settings");
            });
        }
        onLoad();
    }, [curEnv]);

    const handleEditClick = (e: any) => {
        e.preventDefault();
        if (isEditing) {
            setIsEditing(false);
            setEditingObject({} as IWithdrawSettings);
            return;
        } else {
            setIsEditing(true);
            setEditingObject({
                ...withdrawSettings
            });
            return;
        }        
    }

    const handleUpdateClick = (e: any) => {
        e.preventDefault();        
        updateWithdrawalSettingsAPI(
            curEnv,
            editingObject.withdrawalId,
            editingObject.withdrawalLimit,
            editingObject.alertThreshold,
            editingObject.alertMessage,
            editingObject.emailList.toString(),
            getUTCDateString(editingObject.startDate),
            getUTCDateString(editingObject.endDate),
            user?.email ? user?.email : 'feedback@investmates.io'
        ).then(data => {
            toast.success("Updated withdrawal settings successfully!")
            setWithdrawSettings({
                ...editingObject
            });            
        }).catch(err => {
            console.log(err);
            toast.error("Error updating the withdrawal settings");
        }).finally(() => {
            setEditingObject({} as IWithdrawSettings);
            setIsEditing(false);
        });
    }

    const toggleModal = () => {
        const emptyObject = {} as IWithdrawSettings;
        setTempEmail("");
        setTempEmailList([]);
        setIsEditing(false);
        setEditingObject({
            ...emptyObject,
            withdrawalLimit: 0,
            balance: 0,
            totalWithdrawal: 0,
            alertThreshold: 0,
            emailList: "",
            alertMessage: "",
            startDate: "",
            endDate: "",
            status: false,
            updatedBy: ""
        });
        setShowModal(!showModal);
    }

    const toggleHistoryModal = () => {
        if (!showHistoryModal) {
            loadHistory();
            setShowHistoryModal(true);
        } else {
            setShowHistoryModal(false);
        }        
    }

    const createWithdrawalSettings = (e: any) => {
        e.preventDefault();
        // withdrawalLimit: number,
        // thresholdLimit: number,
        // alertMessage: string,
        // emailList: string,
        // startDate: string,
        // endDate: string,
        // createdBy: string
        // createWithdrawalSettingsAPI()        
        if (isNewSettingsValid()) {
            createWithdrawalSettingsAPI(
                curEnv,
                editingObject.withdrawalLimit,
                editingObject.alertThreshold,
                editingObject.alertMessage,
                tempEmailList.toString(),
                editingObject.startDate,
                editingObject.endDate,
                user?.email ? user?.email : 'feedback@investmates.io'
            ).then(data => {
                toast.success("Created new withdrawal settings successfully!");
            }).catch(err => {
                toast.error("Error while creating withdrawal settings");
                console.log(err);
            }).finally(() => {
                toggleModal();
            });
            // thiyagarajan@investmates.io,vishal@investmates.io
        } else {
            return;
        }
    }

    const addEmail = (e: any) => {
        e.preventDefault();
        if (isValidEmail(tempEmail)) {
            setTempEmailList(oldVals => [...oldVals, tempEmail]);
            setTempEmail("");
        } else {
            toast.error("Please provide a valid email address");
            return;
        }
    }

    const removeEmail = (e: any, index: number) => {
        e.preventDefault();
        if (index >= 0) {
            setTempEmailList(
                tempEmailList.slice(0, index).concat(tempEmailList.slice(index + 1))
            );
        }
    }



    const isNewSettingsValid = () => {
        if (editingObject.withdrawalLimit <= 0) {
            toast.error("Please set proper withdrawal limit");
            return false;
        }
        if (editingObject.alertThreshold <= 0) {
            toast.error("Please set proper alert threshold");
            return false;
        }
        if (editingObject.alertMessage === undefined || editingObject.alertMessage === null || editingObject.alertMessage === '') {
            toast.error("Please set proper alert message");
            return false;
        }
        if (
            tempEmailList.length <= 0
        ) {
            toast.error("Please add at least one alert email address");
            return false;
        }

        if (
            editingObject.startDate === undefined ||
            editingObject.startDate === null ||
            editingObject.startDate === ''            
        ) {
            toast.error("Please set proper start date");
            return false;
        }

        if (
            editingObject.endDate === undefined ||
            editingObject.endDate === null ||
            editingObject.endDate === ''
        ) {
            toast.error("Please set proper end date");
            return false;
        }

        if (
            new Date(editingObject.endDate) < new Date(editingObject.startDate) 
        ) {
            toast.error("The end date must be higher than the start date");
            return false;
        }
        return true;
    }

    const loadHistory = () => {
        getWithdrawalSettingsHistoryAPI(curEnv)
            .then(data => {                
                if (Array.isArray(data) && data.length > 0) {
                    setHistoryItems([]);
                    data.forEach((item, index) => {
                        const tempObject = {} as IWithdrawSettings;
                        tempObject.alertThreshold = item["alertthrehold"];
                        tempObject.balance = item["balance"];
                        tempObject.createdBy = item["createdby"]
                        tempObject.emailList = item["emails"];                                     
                        tempObject.withdrawalId = item["id"];                    
                        tempObject.alertMessage = item["message"];
                        tempObject.startDate = getLocalDateTime(data[0]["startdate"]);
                        tempObject.endDate = getLocalDateTime(data[0]["enddate"]);
                        tempObject.status = item["status"] === 1;
                        tempObject.withdrawalLimit = item["totalamount"];
                        tempObject.totalWithdrawal = item["totalwithdrawl"];
                        tempObject.updatedBy = item["updatedby"];

                        setHistoryItems(oldData => [...oldData, tempObject]);
                    });
                    setIsHistoryLoaded(true);
                }
            }).catch(err => {
                console.log(err);
            });
    }

    const fillHistoryTable = () => {
        return !isHistoryLoaded ? <></>
        : histroyItems.map((item, index) => {
            return (
                <tr key={index}>
                    <td className="px-2">{item.withdrawalLimit}</td>
                    <td className="px-2">{item.totalWithdrawal}</td>
                    <td className="px-2">{item.balance}</td>
                    <td className="px-2">{item.alertThreshold}</td>
                    <td className="px-2">{item.emailList}</td>
                    <td className="px-2">{item.alertMessage}</td>
                    <td className="px-2">{item.startDate}</td>
                    <td className="px-2">{item.endDate}</td>
                    <td className={`${item.status ? 'text-green700' : 'text-red700'} px-2`}>
                        {item.status ? "Active" : "Inactive"}
                    </td>
                    <td className="px-2">{item.createdBy}</td>
                    <td className="px-2">{item.updatedBy}</td>
                </tr>
            );
        });
    }

    return (
        <div className="mb-8">
            <h2 className="text-xl font-bold">
                Withdrawal Settings
            </h2>
            <button className="mt-2" onClick={e => toggleModal()}>
                <FontAwesomeIcon icon={faPlus} color="green" /> Create New Settings
            </button>
            <div>
                <button className="mt-2 hover:underline" onClick={e => toggleHistoryModal()}>
                    Show History
                </button>
            </div>            
            {
                !hasDataLoaded
                ? <Loading />
                : 
                <div className="mt-4">
                    <div className="flex flex-col">
                        <div className="flex flex-row">
                            <div>
                                <span><b>Withdrawal Limit: </b></span>
                                <span className={`${isEditing ? 'hidden': 'visible'}`}>
                                    {withdrawSettings.withdrawalLimit}
                                </span>
                                <input 
                                    className={`${isEditing ? 'visible' : 'hidden'} rounded`}
                                    type="number" 
                                    value={editingObject.withdrawalLimit}
                                    onChange={e => setEditingObject({
                                        ...editingObject,
                                        withdrawalLimit: Number(e.target.value)
                                    } as IWithdrawSettings)}
                                />
                            </div>
                        </div>
                        <div>
                            <div className="flex flex-row">
                                <div>
                                    <b>Total Withdrawal:&nbsp;</b>
                                </div>
                                <div>
                                    {withdrawSettings.totalWithdrawal}
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="flex flex-row">
                                <div>
                                    <b>Balance:&nbsp;</b>
                                </div>
                                <div>
                                    {withdrawSettings.balance}
                                </div>
                            </div>
                        </div>
                        
                        <div>
                            <div className="flex flex-row">
                                <div>
                                    <b>Alert Threshold:&nbsp;</b>
                                </div>
                                <div className={`${isEditing ? 'hidden': 'visible'}`}>
                                    {withdrawSettings.alertThreshold}
                                </div>
                                <input             
                                    type="number"
                                    className={`${isEditing ? 'visible' : 'hidden'} rounded`}
                                    value={editingObject.alertThreshold}
                                    onChange={e => setEditingObject({
                                        ...editingObject,
                                        alertThreshold: Number(e.target.value)
                                    } as IWithdrawSettings)}
                                />
                            </div>
                        </div>

                        <div>
                            <div className="flex flex-row">
                                <div>
                                    <b>Email List:&nbsp;</b>
                                </div>
                                <div className={`${isEditing ? 'hidden': 'visible'}`}>
                                    {withdrawSettings.emailList}                                    
                                </div>
                                <textarea 
                                        className={`${isEditing ? 'visible' : 'hidden'} rounded`}
                                        value={editingObject.emailList}
                                        onChange={e => setEditingObject({
                                            ...editingObject,
                                            emailList: e.target.value
                                        })}
                                />
                            </div>
                        </div>

                        <div>
                            <div className="flex flex-row">
                                <div>
                                    <b>Alert Message:&nbsp;</b>
                                </div>
                                <div className={`${isEditing ? 'hidden': 'visible'} w-1/3`}>
                                    {withdrawSettings.alertMessage}
                                </div>
                                <textarea 
                                    className={`${isEditing ? 'visible' : 'hidden'} rounded`}
                                    value={editingObject.alertMessage}
                                    onChange={e => setEditingObject({
                                        ...editingObject,
                                        alertMessage: e.target.value
                                    } as IWithdrawSettings)}
                                />
                            </div>
                        </div>

                        <div>
                            <div className="flex flex-row">
                                <div>
                                    <b>Start Date:&nbsp;</b>
                                </div>
                                <div className={`${isEditing ? 'hidden': 'visible'}`}>
                                    {withdrawSettings.startDate}
                                </div>
                                <span className={`${isEditing ? 'visible' : 'hidden'} rounded`}>
                                    <TextInput                                     
                                        type={"datetime-local"}                                    
                                        value={editingObject.startDate && editingObject.startDate.split('.')[0]}
                                        onChange={e => setEditingObject({
                                            ...editingObject,
                                            startDate: e.target.value
                                        } as IWithdrawSettings)}
                                    />
                                </span>
                            </div>
                        </div>

                        <div>
                            <div className="flex flex-row">
                                <div>
                                    <b>End Date:&nbsp;</b>
                                </div>
                                <div className={`${isEditing ? 'hidden': 'visible'}`}>
                                    {withdrawSettings.endDate}
                                </div>
                                <span className={`${isEditing ? 'visible' : 'hidden'} rounded`}>
                                    <TextInput                                     
                                        type={"datetime-local"}                                    
                                        value={editingObject.startDate && editingObject.endDate.split('.')[0]}
                                        onChange={e => setEditingObject({
                                            ...editingObject,
                                            endDate: e.target.value
                                        } as IWithdrawSettings)}
                                    />
                                </span>
                            </div>
                        </div>

                        <div>
                            <div className="flex flex-row">
                                <div>
                                    <b>Status:&nbsp;</b>
                                </div>
                                <div className={`${withdrawSettings.status ? 'text-green-700' : 'text-red-700'}`}>
                                    {withdrawSettings.status ? "Active" : "Inactive"}
                                </div>
                            </div>
                        </div>

                        <div>
                            <div className="flex flex-row">
                                <div>
                                    <b>Last updated by:&nbsp;</b>
                                </div>
                                <div>
                                    {withdrawSettings.updatedBy}
                                </div>
                            </div>
                        </div>

                        <div className="mt-4">
                            <button 
                                className={`${PRIMARY_BUTTON_STYLE}`}
                                onClick={e => handleEditClick(e)}
                            >
                                {isEditing ? "Cancel" : "Edit" }
                            </button>
                            <button
                                className={`${PRIMARY_BUTTON_STYLE} ${isEditing ? 'visible' : 'hidden'}`}
                                onClick={e => handleUpdateClick(e)}
                            >
                                Update
                            </button>
                        </div>
                    </div>
                </div>
            }
            <Modal
                size={"xl"}
                popup={true}
                show={showModal}
                onClose={toggleModal}           
            >
                <Modal.Header>
                    Create New Withdraw Settings
                </Modal.Header>
                <Modal.Body>
                    <table>
                        <tbody>
                            <tr>
                                <td>Withdraw Limit</td>
                                <td>
                                    <input 
                                        type="number"
                                        value={editingObject.withdrawalLimit}
                                        onChange={e => setEditingObject({
                                            ...editingObject,
                                            withdrawalLimit: Number(e.target.value)
                                        })}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Alert Threshold
                                </td>
                                <td>
                                    <input 
                                        type="number"
                                        value={editingObject.alertThreshold}
                                        onChange={e => setEditingObject({
                                            ...editingObject,
                                            alertThreshold: Number(e.target.value)
                                        })}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>Alert Message</td>
                                <td>
                                    <textarea 
                                        value={editingObject.alertMessage}
                                        onChange={e => setEditingObject({
                                            ...editingObject,
                                            alertMessage: e.target.value
                                        })}
                                    />
                                </td>
                            </tr>

                            <tr>
                                <td>Email List</td>
                                <td>
                                    <input
                                        type="text"
                                        value={tempEmail}
                                        onChange={e => setTempEmail(e.target.value)}
                                    />
                                </td> 
                                <td>
                                    <button
                                        className="hover:underline"                                        
                                        onClick={e => addEmail(e)}
                                    >
                                        Add Email
                                    </button>
                                </td>                               
                            </tr>

                            <tr>
                                <td></td>
                                <td>
                                    {
                                        tempEmailList.map((email, index) => {
                                            return (
                                                <span key={index}>
                                                    {email}&nbsp;-&nbsp;
                                                    <button className="hover:underline" onClick={e => removeEmail(e, index)}>
                                                        remove
                                                    </button>
                                                    <br />
                                                </span>
                                            )
                                        })
                                    }
                                </td>
                            </tr>                       

                            <tr>
                                <td>Start Date</td>
                                <td>
                                    <TextInput
                                        type={"datetime-local"}
                                        value={editingObject.startDate}
                                        onChange={e => setEditingObject({
                                            ...editingObject,
                                            startDate: e.target.value
                                        })}
                                    />
                                </td>
                            </tr>

                            <tr>
                                <td>End Date</td>
                                <td>
                                    <TextInput
                                        type={"datetime-local"}
                                        value={editingObject.endDate}
                                        onChange={e => setEditingObject({
                                            ...editingObject,
                                            endDate: e.target.value
                                        })}
                                    />
                                </td>
                            </tr>                            
                        </tbody>
                    </table>
                </Modal.Body>
                <Modal.Footer>
                    <button
                        className={`${PRIMARY_BUTTON_STYLE}`}
                        onClick={e => createWithdrawalSettings(e)}
                    >
                        Create Withdraw Settings
                    </button>
                    <button
                        className={`${PRIMARY_BUTTON_STYLE_ALTERNATE}`}
                        onClick={e => toggleModal()}
                    >
                        Cancel
                    </button>
                </Modal.Footer>
            </Modal>

            <Modal
                size={"xxl"}
                popup={true}
                show={showHistoryModal}
                onClose={toggleHistoryModal}
            >
                <Modal.Header>
                    Settings History
                </Modal.Header>
                <Modal.Body>
                    <div className="overflow-auto">
                        <table className="border-collapse border w-full text-sm text-left text-gray-500 dark:text-gray-400">
                            <thead className="text-xs text-gray-700 bg-gray-100 dark:bg-gray-100 dark:text-gray-400">
                                <tr>
                                    <th className="border px-1 text-center">
                                        Withdrawal Limit
                                    </th>
                                    <th className="border px-1 text-center">
                                        Total Withdrawal
                                    </th>
                                    <th className="border px-1 text-center">
                                        Balance
                                    </th>
                                    <th className="border px-1 text-center">
                                        Alert Threshold
                                    </th>
                                    <th className="border px-1 text-center">
                                        Email List
                                    </th>
                                    <th className="border px-1 text-center">
                                        Alert Message
                                    </th>
                                    <th className="border px-1 text-center">
                                        Start Date
                                    </th>
                                    <th className="border px-1 text-center">
                                        End Date
                                    </th>
                                    <th className="border px-1 text-center">
                                        Status
                                    </th>
                                    <th className="border px-1 text-center">
                                        Created By
                                    </th>
                                    <th className="border px-1 text-center">
                                        Last Updated By
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    fillHistoryTable()
                                }
                            </tbody>
                        </table>
                    </div>
                </Modal.Body>
                <Modal.Footer>

                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default WithdrawalSettings;