import { useEffect, useMemo, useState } from "react";
import { getFBRatingsAPI } from "../../../../../api/feedback";
import { useAccounts } from "../../../../accounts/context";
import Loading from "../../../../Loading";

const FBRatings = () => {
  const [loading, setLoading] = useState(true);
  const [ratings, setRatings] = useState([0, 0, 0, 0, 0, 0]);
  const {
    state: { curEnv }
  } = useAccounts();

  useEffect(() => {
    const onLoad = async () => {
      try {
        const charts = await getFBRatingsAPI(curEnv);
        if (Array.isArray(charts)) {
          let newArr = Array(6).fill(0);
          charts.forEach((el: any) => newArr[el.rating] = el.count);
          setRatings(newArr);
        }
      } catch (error) {
      } finally {
        setLoading(false);
      }
    }
    onLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [curEnv]);

  let sum = useMemo(() => {
    let result = ratings.reduce((prev, curr) => prev + curr, 0);
    return result === 0 ? 1 : result;
  }, [ratings]);

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="self-center w-full max-w-sm">
      <h2 className="p-3 text-center">Feedback Ratings</h2>
      <div className="flex flex-col-reverse">
        {ratings.map((el, index) => (
          <div key={index} className="flex items-center my-2 space-x-4">
            <p className="w-5 text-lg font-bold">{index}</p>
            <div className="w-full h-6 my-auto bg-gray-200">
              <div className="h-6 bg-primary-500" style={{ width: `${Math.round(100 * el / sum)}%` }}></div>
            </div>
            <p className="w-5">{el}</p>
          </div>))}
      </div>
    </div>
  );
}

export default FBRatings;
