import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal, TextInput } from "flowbite-react";
import { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { getBannerHistoryAPI, updateBannerAPI } from "../../api/contest";
import { IBannerItem, uploadImage } from "../../utils";
import { useAccounts } from "../accounts/context";

const BannerHistory = () => {
    const [bannerHistoryList, setBannerHistoryList] = useState<Array<IBannerItem>>([]);
    const [editingItem, setEditingItem] = useState<IBannerItem>({} as IBannerItem);
    const [showModal, setShowModal] = useState(false);
    const inputFile = useRef<HTMLInputElement | null>(null);
    const [selectedFile, setSelectedFile] = useState();
    const [bannerImage, setBannerImage] = useState("");
    const [preview, setPreview] = useState("");
    const [loading, setLoading] = useState(false);

    const {
        state: {curEnv, user}
    } = useAccounts();

    useEffect(() =>{
        const onLoad = () => {
            getBannerHistoryAPI(curEnv)
                .then(data => {                    
                    if (data !== undefined && Array.isArray(data) && data.length > 0) {
                        setBannerHistoryList([]);
                        data.forEach(item => {
                            const tempItem = {} as IBannerItem;
                            tempItem.bannerId = item["bannerid"];
                            tempItem.bannerKey = item["bannerkey"];
                            tempItem.bannerSequence = item["bannersequence"];
                            tempItem.buttonText = item["btntext"];
                            tempItem.created = item["created"].split('T')[0];
                            tempItem.expiry = item["expiry"].split('T')[0];
                            tempItem.bannerImage = item["image"];
                            tempItem.metadata = item["metadata"];
                            tempItem.minBuild = item["minbuild"];
                            tempItem.status = item["status"];
                            tempItem.title = item["title"];
                            tempItem.updated = item["updated"];

                            setBannerHistoryList(oldData => [...oldData, tempItem]);
                        });
                    }
                }).catch(err => {
                    console.log(err);
                });
        }

        onLoad();
    }, [curEnv]);

    useEffect(() => {
        if (!selectedFile) {
            setPreview("")
            return
        }
        const objectUrl = URL.createObjectURL(selectedFile)
        setPreview(objectUrl)

        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl)
    }, [selectedFile]);

    useEffect(() => {
        if (bannerImage !== undefined && bannerImage !== "" && bannerImage !== null) {
            updateBannerAPI(
                1,
                editingItem.bannerId,
                editingItem.title,
                bannerImage,
                editingItem.bannerKey,
                editingItem.buttonText,
                editingItem.minBuild,
                editingItem.expiry,
                editingItem.metadata,
                editingItem.bannerSequence,
                editingItem.status            
            ).then(data => {
                toast.success("Banner Updated");
                setShowModal(false);
                setBannerHistoryList(bannerHistoryList.map(item => {
                    if (item.bannerId === editingItem.bannerId){
                        return {...editingItem};
                    } else {
                        return item;
                    }
                }));
                setEditingItem({} as IBannerItem);
            }).catch(err => {
                console.log(err);
                toast.error("Error while updating the banner");
                setShowModal(false);
                setEditingItem({} as IBannerItem);
            });
        }
    }, [bannerImage]);

    const handleFileUploadClick = (e: any) => {
        e.preventDefault();
        inputFile.current?.click();
    }

    const uploadBannerImage = () => {
        return new Promise((resolve, reject) => {
            if (inputFile.current?.files && inputFile.current?.files?.length > 0) {
                const fileName = "banner_" + Date.now() + ".jpg";
                uploadImage(fileName, "banners", inputFile.current?.files[0])
                    .then(data => {                        
                        setBannerImage("https://discovery.investmates.tech/Investmates/banners/" + fileName);
                        return resolve(data);
                    }).catch(err => {       
                        return reject(err);
                    });
            }
        });        
    }

    const handleFileInputChange = (e: any) => {
        if (!e.target.files || e.target.files.length === 0) {
            setSelectedFile(undefined);
            return;
        }
        setSelectedFile(e.target.files[0]);
    }
    
    const handleEditClick = (e: any, item: IBannerItem) => {
        e.preventDefault();
        setEditingItem(item);
        setShowModal(true);
    }

    const handleBannerClose = () => {
        setEditingItem({} as IBannerItem);
        setShowModal(false);
        setPreview("");
        setBannerImage("");
    }

    const updateBanner = (e: any) => {
        e.preventDefault();

        // Validation Checks

        if (editingItem.title === undefined || editingItem.title === "" || editingItem.title === null) {
            toast.error("Invalid banner title");
            return;
        }

        if (editingItem.buttonText === undefined || editingItem.buttonText === "" || editingItem.buttonText === null) {
            toast.error("Invalid button text");
            return;
        }

        if (editingItem.minBuild < 138) {
            toast.error("Invalid Minimum build");
            return;
        }

        // Done wit validation checks

        if (preview !== undefined && preview !== "" && preview !== null) {
            // toast.success("Banner image has been changed");
            setLoading(true);
            uploadBannerImage()
                .then(data => {
                    toast.success("Banner Image added");                    
                }).catch(err => {
                    setLoading(false);
                    console.log(err);
                    toast.error("Error uploading banner image");
                });
        } else {  
            // toast.success("Banner image not changed");
            updateBannerAPI(
                curEnv,
                editingItem.bannerId,
                editingItem.title,
                editingItem.bannerImage,
                editingItem.bannerKey,
                editingItem.buttonText,
                editingItem.minBuild,
                editingItem.expiry,
                editingItem.metadata,
                editingItem.bannerSequence,
                editingItem.status            
            ).then(data => {
                toast.success("Banner Updated");                
                setBannerHistoryList(bannerHistoryList.map(item => {
                    if (item.bannerId === editingItem.bannerId){
                        return {...editingItem};
                    } else {
                        return item;
                    }
                }));                
                handleBannerClose();
            }).catch(err => {
                console.log(err);
                toast.error("Error while updating the banner");                
                handleBannerClose();
            });
        }        
    }

    const fillTable = () => {
        return bannerHistoryList.sort((a, b) => {
            return b.status - a.status;
        }).map((item, index) => {
            return ( 
                <tbody key={"".concat(item.bannerId.toString(), index.toString())}>
                    <tr>
                        <td className="py-2 px-2 border-b border-slate-200">
                            <img src={item.bannerImage} />
                        </td>
                        <td className="border-b border-slate-200">
                            {item.title}
                        </td >
                        <td className="border-b border-slate-200">
                            {item.bannerKey}
                        </td>
                        <td className="border-b border-slate-200">
                            {item.buttonText}
                        </td>
                        <td className="border-b border-slate-200">
                            {item.bannerSequence}
                        </td>
                        <td className="border-b border-slate-200">
                            {item.metadata}
                        </td>
                        <td className="border-b border-slate-200">
                            {item.minBuild}
                        </td >
                        <td className={`${item.status === 1 ? 'text-green-600' : 'text-red-600'} border-b border-slate-200`}>
                            {item.status === 1 ? "Active" : "Inactive" }
                        </td>
                        <td className="border-b border-slate-200">
                            {item.created}                        
                        </td>
                        <td className="border-b border-slate-200">
                            <button onClick={e => handleEditClick(e, item)}>
                                <FontAwesomeIcon icon={faEdit} />
                            </button>                            
                        </td>
                    </tr>
                </tbody>                               
            );
        });
    }

    return (
        <div className="m-4 overflow-auto">
            <table className="w-full border-collapse border border-slate-200 ml-4 text-sm text-left text-gray-500 dark:text-gray-400">
                <thead className="bg-slate-200">
                    <tr>
                        <th scope="col" className="border border-slate-200 py-3 px-2">
                            Banner Image
                        </th>
                        <th scope="col" className="border border-slate-200 py-3 px-2">
                            Banner Title
                        </th>
                        <th scope="col" className="border border-slate-200 py-3 px-2">
                            Banner Key
                        </th>
                        <th scope="col" className="border border-slate-200 py-3 px-2">
                            Button Text
                        </th>
                        <th scope="col" className="border border-slate-200 py-3 px-2">
                            Sequence
                        </th>
                        <th scope="col" className="border border-slate-200 py-3 px-2">
                            Metadata
                        </th>
                        <th scope="col" className="border border-slate-200 py-3 px-2">
                            Minimum Build
                        </th>
                        <th scope="col" className="border border-slate-200 py-3 px-2">
                            Status
                        </th>
                        <th scope="col" className="border border-slate-200 py-3 px-2">
                            Created
                        </th>
                        <th scope="col" className="border border-slate-200 py-3 px-2">
                            Actions
                        </th>
                    </tr>                    
                </thead>                
                    {
                        fillTable()
                    }                
            </table>
            <Modal
                show={showModal}
                size="xl"
                popup={true}
                onClose={() => handleBannerClose()}
            >
                <Modal.Header>
                    Edit Banner
                </Modal.Header>
                <Modal.Body>
                    <table>
                        <tbody>
                            <tr>
                                <td className="py-2">
                                    Banner Image
                                </td>
                                <td className="py-2">
                                    <img 
                                        src={preview === undefined || preview === "" || preview === null ? editingItem.bannerImage : preview } 
                                    />
                                    <a 
                                        href="#"
                                        onClick={e => handleFileUploadClick(e)}
                                        className="hover:underline"
                                        >
                                        Change Banner Image
                                    </a>
                                </td>
                                <td className="hidden">
                                <input
                                    onChange={handleFileInputChange}
                                    ref={inputFile}
                                    type="file" hidden={true} 
                                />
                            </td>
                            </tr>
                            <tr>
                                <td className="py-2">
                                    Banner Title
                                </td>
                                <td className="py-2">
                                    <input 
                                        type="text" 
                                        className="rounded-full" 
                                        value={editingItem.title} 
                                        onChange={e => setEditingItem({
                                            ...editingItem,
                                            title: e.target.value
                                        })}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td className="py-2">
                                    Banner Key
                                </td>
                                <td className="py-2">
                                    <select 
                                        className="rounded-full" 
                                        value={editingItem.bannerKey}
                                        onChange={e => setEditingItem({
                                            ...editingItem,
                                            bannerKey: e.target.value
                                        })}
                                    >
                                        <option value="profile_complete">profile_complete</option>
                                        <option value="contest_page">contest_page</option>
                                        <option value="portfolio_complete">portfolio_complete</option>
                                        <option value="url">url</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td className="py-2">
                                    Button Text
                                </td>
                                <td className="py-2">
                                    <input 
                                        className="rounded-full" 
                                        type="text" 
                                        value={editingItem.buttonText}
                                        onChange={e => setEditingItem({
                                            ...editingItem,
                                            buttonText: e.target.value
                                        })}
                                    />
                                </td>
                            </tr>

                            <tr>
                                <td className="py-2">
                                    Sequence
                                </td>
                                <td className="py-2">
                                    <input 
                                        className="rounded-full" 
                                        type="number" 
                                        value={editingItem.bannerSequence} 
                                        onChange={e => setEditingItem({
                                            ...editingItem,
                                            bannerSequence: Number(e.target.value)
                                        })}
                                    />
                                </td>
                            </tr>
                            
                            <tr>
                                <td className="py-2">
                                    Min Build
                                </td>
                                <td className="py-2">
                                    <input 
                                        className="rounded-full" 
                                        type="number" 
                                        value={editingItem.minBuild}
                                        onChange={e => setEditingItem({
                                            ...editingItem,
                                            minBuild: Number(e.target.value)
                                        })}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Status
                                </td>
                                <td>
                                    <select className="rounded-full" value={editingItem.status} onChange={e => setEditingItem({
                                        ...editingItem,
                                        status: Number(e.target.value)
                                    })}>
                                        <option value={1}>Active</option>
                                        <option value={0}>Inactive</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td className="py-2">
                                    Expiry
                                </td>
                                <td className="py-2">
                                    <TextInput                                         
                                        type="date"
                                        placeholder="Expiry Date"
                                        value={editingItem.expiry}
                                        onChange={e => setEditingItem({
                                            ...editingItem,
                                            expiry: e.target.value                                        
                                        })}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td></td>
                                <td className="py-2">
                                    <button 
                                        className="bg-[#6415ff] hover:bg-[#5500fb] text-white font-bold py-2 px-4 rounded-full"
                                        onClick={e => updateBanner(e)}
                                    >
                                        Update
                                    </button>
                                    <button
                                        className="ml-2 bg-[#6415ff] hover:bg-[#5500fb] text-white font-bold py-2 px-4 rounded-full"
                                        onClick={e => {
                                            e.preventDefault();
                                            handleBannerClose();
                                        }}
                                    >
                                        Cancel
                                    </button>
                                </td>
                            </tr>
                        </tbody>                        
                    </table>                    
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default BannerHistory;