import { TextInput } from "flowbite-react";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import { number } from "yup/lib/locale";
import { getCampaignByIdAPI, updateCampaignAPI } from "../../api/rewards";
import { useAccounts } from "../../components/accounts/context";
import { EqualWidthContainer } from "../dashboard/community/all";
import { PageContainer } from "../dashboard/feedback";
import ErrorBoundary from "../ErrorBoundary";

const EditCampaign = () => {
    const {id} = useParams();
    const [campaignName, setCampaignName] = useState('');
    const [campaignDescription, setCampaignDescription] = useState('');
    const [campaignType, setCampaignType] = useState(0);
    const [rewardsCoin, setRewardsCoin] = useState(0);
    const [status, setStatus] = useState(0);
    const [endDate, setEndDate] = useState('');
    const [refreeCoin, setRefreeCoin] = useState(0);
    const [referralCoin, setReferralCoin] = useState(0);
    const [inProgress, setInprogress] = useState(false);
    const {
        state: { curEnv }
    } = useAccounts();
    
    const [campaignNameError, setCampaignNameError] = useState(false);
    const [campaignDescriptionError, setCampaignDescriptionError] = useState(false);
    const [refreeCoinError, setRefreeCoinError] = useState(false);
    const [referralCoinError, setReferralCoinError] = useState(false);
    const [rewardCoinsError, setRewardCoinsError] = useState(false);
    const [expiryDateError, setExpiryDateError] = useState(false);


    // Update the campaign
    const updateCampaign = async (e: any) => {
        e.preventDefault();
        // TODO: Validation for End Date
        if (isValid()) {            
            clearErrors();
            setInprogress(true);
            updateCampaignAPI(
                curEnv,
                Number(id),
                campaignName,
                campaignDescription,
                campaignType,
                refreeCoin + referralCoin,
                status,
                endDate,
                refreeCoin,
                referralCoin
            ).then((data: any) => {                
                toast.success("Campaign has been updated");
            }).catch((err: any) => {
                console.log("error updating campaign");
                console.log(err);
                toast.error("Error updating the campaign");
            }).finally(() => {
                setInprogress(false);
            });
        } else {
            toast.error("Please fill in the required fields");
            setErrors();
        }       
        // console.log(Date.parse(endDate));
    }

    const isValid = () => {
        if (campaignName === "") {
            return false;
        } else if (campaignDescription === "") {
            return false;
        } else if (rewardsCoin === 0 || rewardsCoin < 0) {
            return false;
        } else if (endDate === "") {
            return false;
        } else {
            return true;
        }
    }

    const clearErrors = () => {        
        setCampaignNameError(false);
        setCampaignDescriptionError(false);
        setRefreeCoinError(false);
        setReferralCoinError(false);
        setRewardCoinsError(false);
        setExpiryDateError(false);
    }

    const setErrors = () => {        
        (campaignName === "") ? setCampaignNameError(true) : setCampaignNameError(false);
        (campaignDescription === "") ? setCampaignDescriptionError(true) : setCampaignDescriptionError(false);
        (refreeCoin === 0 || refreeCoin < 0) ? setRefreeCoinError(true) : setRefreeCoinError(false);
        (referralCoin === 0 || referralCoin < 0) ? setReferralCoinError(true) : setReferralCoinError(false);
        (rewardsCoin === 0 || rewardsCoin < 0) ? setRewardCoinsError(true) : setRewardCoinsError(false);
        (endDate === "") ? setExpiryDateError(true) : setExpiryDateError(false);
    }

    // loading the campaign details by id
    useEffect(() => {
        async function getCampaignDetails() {
            const campaignData = await getCampaignByIdAPI(curEnv, Number(id));
            console.log(campaignData);
            setCampaignName(campaignData[0]["campaignname"]);
            setCampaignDescription(campaignData[0]["campaigndescription"]);
            setCampaignType(campaignData[0]["campaingtype"]);
            setRewardsCoin(campaignData[0]["rewardcoins"]);
            setStatus(campaignData[0]["status"]);
            setEndDate(campaignData[0]["expirydate"].split("T")[0]);
            setRefreeCoin(campaignData[0]["refreecoin"]);
            setReferralCoin(campaignData[0]["refrerralcoin"]);
        }
        getCampaignDetails();
    }, [curEnv, id]);

    const changeDate = (e: any) => {
        e.preventDefault();
        setEndDate(e.target.value);
    }

    
    return (
        <ErrorBoundary>
            <PageContainer>
                <EqualWidthContainer>
                    <h4 className="text-2xl">Edit Campaign</h4>
                    <br />
                    <form>
                        <div className="grid gap-6 mb-6 md:grid-cols-2">
                            <div>
                                <label 
                                    htmlFor="campaign_name" 
                                    className={`block mb-2 text-sm font-medium ${(campaignNameError ? "text-red-900": "text-gray-900")}`}>
                                        Campaign Name
                                </label>
                                <input 
                                    type="text" 
                                    id="campaign_name" 
                                    className={`bg-gray-50 border ${(campaignNameError ? "border-red-500" : "border-gray-300")} text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                                    placeholder="Campaign Name"
                                    value={campaignName}
                                    onChange = {e => setCampaignName(e.target.value)}
                                />                
                            </div>
                            <div>
                                <label 
                                    htmlFor="campaign_desc" 
                                    className={`block mb-2 text-sm font-medium ${(campaignDescriptionError ? "text-red-900": "text-gray-900")}`}>
                                        Campaign Description
                                </label>
                                <input 
                                    type="text" 
                                    id="campaign_desc" 
                                    className={`bg-gray-50 border ${(campaignDescriptionError ? "border-red-500" : "border-gray-300")} text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                                    placeholder="Campaign Description"
                                    value={campaignDescription}
                                    onChange={e => setCampaignDescription(e.target.value)}
                                />                
                            </div>

                            <div>
                                <label 
                                    htmlFor="campaign_type" 
                                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                                        Campaign Type <i>(0 for friend campaign 1 for community campaign)</i>
                                </label>
                                <input 
                                    type="number" 
                                    id="campaign_type" 
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                    placeholder="Campaign Type"
                                    min={0}
                                    max={1}
                                    value={campaignType}                        
                                    onChange = {e => setCampaignType(Number(e.target.value))}
                                />             
                            </div>
                            <div>
                                <label 
                                    htmlFor="reward_coins" 
                                    className={`block mb-2 text-sm font-medium ${(rewardCoinsError ? "text-red-900": "text-gray-900")}`}>
                                        Reward Coins
                                </label>
                                <input 
                                    type="number" 
                                    id="reward_coins" 
                                    className={`bg-gray-50 border ${(rewardCoinsError ? "border-red-500" : "border-gray-300")} text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                                    placeholder="Reward Coins"
                                    value = {refreeCoin + referralCoin}
                                    disabled={true}
                                    onChange = {e => setRewardsCoin(refreeCoin + referralCoin)}
                                />                
                            </div>
                            <div>
                                <label 
                                    htmlFor="status" 
                                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                                        Status <i>(0 for Inactive 1 for Active)</i>
                                </label>
                                <input 
                                    type="number" 
                                    id="status" 
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                    placeholder="Status"
                                    value={status}
                                    min={0}
                                    max={1}
                                    onChange={e => setStatus(Number(e.target.value))}
                                />                
                            </div>

                            <div>
                                <label 
                                    htmlFor="end_date" 
                                    className={`block mb-2 text-sm font-medium ${(expiryDateError ? "text-red-900": "text-gray-900")}`}>
                                        End Date <i>(YYYY-MM-DD)</i>
                                </label>
                                {/* <input 
                                    type="text" 
                                    id="end_date" 
                                    className={`bg-gray-50 border ${(expiryDateError ? "border-red-500" : "border-gray-300")} text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                                    placeholder="End Date"
                                    value={endDate}
                                    onChange={e => setEndDate(e.target.value)}
                                />                 */}
                                <div
                                    className = {`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 ${(expiryDateError ? "border-red-500" : "")}`}
                                    style={{padding: 0}}
                                >
                                    <TextInput
                                        type="date"
                                        required={true}
                                        onChange = {(e) => changeDate(e)}
                                    />
                                </div>                                
                            </div>

                            <div>
                                <label 
                                    htmlFor="refree_coin" 
                                    className={`block mb-2 text-sm font-medium ${(refreeCoinError ? "text-red-900": "text-gray-900")}`}>
                                        Joinee Coins
                                </label>
                                <input 
                                    type="number" 
                                    id="refree_coin" 
                                    className={`bg-gray-50 border ${(refreeCoinError ? "border-red-500" : "border-gray-300")} text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                                    placeholder="Refree Coins"
                                    value={refreeCoin}
                                    onChange = {e => setRefreeCoin(Number(e.target.value))}
                                />                
                            </div>
                            <div>
                                <label 
                                    htmlFor="referral_coins" 
                                    className={`block mb-2 text-sm font-medium ${(referralCoinError ? "text-red-900": "text-gray-900")}`}>
                                        Invitee Coins
                                </label>
                                <input 
                                    type="number" 
                                    id="referral_coins" 
                                    className={`bg-gray-50 border ${(referralCoinError ? "border-red-500" : "border-gray-300")} text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                                    placeholder="Referral Coins"
                                    value = {referralCoin}
                                    onChange = {e => setReferralCoin(Number(e.target.value))}
                                />                
                            </div>

                            <div>
                                <button 
                                    className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                                    onClick={e => updateCampaign(e)}
                                    disabled={inProgress}
                                >
                                    Update Campaign
                                </button>
                            </div>
                        </div>
                    </form>
                </EqualWidthContainer>
            </PageContainer>
        </ErrorBoundary>        
    );
}

export default EditCampaign;