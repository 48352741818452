import clsx from 'clsx';


type IFormSelectProps = {
  label: string;
  name: string;
  children: React.ReactNode;
  error?: string;
  readOnly?: boolean;
  value?: string | number;
  onChange?: React.ChangeEventHandler<HTMLSelectElement>
};

export const FormSelect = ({
  label,
  name,
  children,
  error,
  value,
  readOnly = false,
  onChange,
}: IFormSelectProps) => (
  <label className="block">
    <div className="text-sm text-default">{label}</div>
    <select
      className="block w-full mt-1 text-sm rounded-lg drop-shadow-md border-0"
      disabled={readOnly}
      value={value}
      onChange={onChange}
    >
      {children}
    </select>

    <span
      className={clsx('text-xs text-red-500', {
        hidden: !error,
      })}
    >
      {error}
    </span>
  </label>
);
