import API from '@aws-amplify/api';
import { getEnvApi } from '../utils';

export function getCountsAPI(env: number) {
  return API.post(getEnvApi(env), `/user/dash/count/admin`, {
    
  });
}

export function getSMSBal(env: number) {
  return API.post(getEnvApi(env), `/user/dash/sms_bal/all`, {

  });
}
