import HeadingSix from "../common/headingSix";
import {useState} from 'react';
import { useAccounts } from "../accounts/context";
import { addExploreAPI } from "../../api/explore";
import toast from "react-hot-toast";

const CreateExplore = () => {

    // explore keys
    // InvestMates Recommended: im_recommended, type: 1, layout: 1, scrollx: 1
    // Communities you may like: comm_suggestion, 1	1	1
    // Trending Topics: hashtag_trend, 1	2	4
    // Top Investors: investor_top, 1	1	2
    // Trending Assets: asset_trend, 1	1	3
    // Top Holdings: asset_topholding, 0	2	3
    // New Investors: investor_new, 1	1	2
    // Top Communities: comm_top, 1	1	1
    // Recently Added Communities: comm_recent, 1	1	1

    // layout: which layout to use
    // scrollx: ignore
    // sequence: 
    // key: which screen to navigate
    // sub sequence for: imates_recommended

    const {
        state : {curEnv, user}
    } = useAccounts();

    const [selectedCode, setSelectedCode] = useState(0);
    const [exploreTitle, setExploreTitle] = useState("");
    const [listLength, setListLength] = useState(0);
    const [sequence, setSequence] = useState(0);
    const [description, setDescription] = useState("");

    const handleOptionClick = (e: any, code: number) => {
        e.preventDefault();        
        setSelectedCode(code);
        setExploreTitle("");
        setListLength(0);
        setSequence(0);
    }

    const addExplore = (e: any) => {
        e.preventDefault();
        // Validation checks
        if (exploreTitle === undefined || exploreTitle === "" || exploreTitle === null) {
            toast.error("Please add title");
            return;
        }

        if (listLength < 5) {
            toast.error("the list is too short, please set it 5 or more");
            return;
        }

        if (sequence <= 0 || sequence >= 10) {
            toast.error("Enter the sequence between 1 and 9");
            return;
        }

        if (description === undefined || description === null || description === "") {
            toast.error("Please enter some description");
        }

        

        // env: number,
        // title: string,
        // key: string,
        // sequence: string,
        // createdBy: string,
        // listLength: number
        const key = getKey();
        if (key === undefined) {
            toast.error("Invalid key");
            return;
        }
        addExploreAPI(
            1,
            exploreTitle,
            key,
            sequence,
            user?.email !== undefined ? user?.email : "feedback@investmates.io",
            listLength,
            description
        ).then(data => {            
            toast.success("Added explore item");
        }).catch(err => {
            console.log(err);
            toast.error("Error while adding the explore item");
        });
    }

    const getKey = () => {
        switch (selectedCode) {
            case 0:
                return "im_recommended";
            case 1:
                return "comm_suggestion";
            case 2:
                return "hashtag_trend";
            case 3:
                return "investor_top";
            case 4:
                return "asset_trend";
            case 5:
                return "asset_topholding";
            case 6:
                return "investor_new";
            case 7:
                return "comm_top";
            case 8:
                return "comm_recent";            
            default: return undefined;
        }
    }    

    return (
        <div className="m-4">
            <HeadingSix content="Category" />
            {/**
             * Category buttons
             */
             }
            <div className="mt-2">
                <button
                    className={`
                    py-2.5 px-5 mr-2 mb-2 
                    text-sm font-medium
                    ${selectedCode === 0 ? 'text-stone-50' : 'text-gray-900'}
                    focus:outline-none 
                    rounded-full
                    ${selectedCode === 0 ? 'bg-[#6415ff]' : 'bg-white' }
                    border border-gray-200 
                    ${selectedCode === 0 ? '' : 'hover:bg-gray-100 hover:text-[#6415ff]'}
                    dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700
                    `}

                    onClick={e => handleOptionClick(e, 0)}
                >
                    InvestMates Recommended      
                </button> 
                <button
                    className={`
                    py-2.5 px-5 mr-2 mb-2 
                    text-sm font-medium
                    ${selectedCode === 1 ? 'text-stone-50' : 'text-gray-900'}
                    focus:outline-none 
                    rounded-full
                    ${selectedCode === 1 ? 'bg-[#6415ff]' : 'bg-white'}
                    border border-gray-200 
                    ${selectedCode === 1 ? '' : 'hover:bg-gray-100 hover:text-[#6415ff]'}               
                    dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700
                    `}
                    onClick={e => handleOptionClick(e, 1)}
                >
                    Communities you may like           
                </button> 

                <button
                    className={`
                    py-2.5 px-5 mr-2 mb-2 
                    text-sm font-medium
                    ${selectedCode === 2 ? 'text-stone-50' : 'text-gray-900'}
                    focus:outline-none 
                    rounded-full
                    ${selectedCode === 2 ? 'bg-[#6415ff]' : 'bg-white'}
                    border border-gray-200 
                    ${selectedCode === 2 ? '' : 'hover:bg-gray-100 hover:text-[#6415ff]'}               
                    dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700
                    `}
                    onClick={e => handleOptionClick(e, 2)}
                >
                    Trending Topics            
                </button>

                <button
                    className={`
                    py-2.5 px-5 mr-2 mb-2 
                    text-sm font-medium
                    ${selectedCode === 3 ? 'text-stone-50' : 'text-gray-900'}
                    focus:outline-none 
                    rounded-full
                    ${selectedCode === 3 ? 'bg-[#6415ff]' : 'bg-white'}
                    border border-gray-200 
                    ${selectedCode === 3 ? '' : 'hover:bg-gray-100 hover:text-[#6415ff]'}           
                    dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700
                    `}
                    onClick={e => handleOptionClick(e, 3)}
                >
                    Top Investors
                </button>

                <button
                    className={`
                    py-2.5 px-5 mr-2 mb-2 
                    text-sm font-medium
                    ${selectedCode === 4 ? 'text-stone-50' : 'text-gray-900'}
                    focus:outline-none 
                    rounded-full
                    ${selectedCode === 4 ? 'bg-[#6415ff]' : 'bg-white'}
                    border border-gray-200 
                    ${selectedCode === 4 ? '' : 'hover:bg-gray-100 hover:text-[#6415ff]'}            
                    dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700
                    `}
                    onClick={e => handleOptionClick(e, 4)}
                >
                    Trending Assets
                </button>   

                <button
                    className={`
                    py-2.5 px-5 mr-2 mb-2 
                    text-sm font-medium
                    ${selectedCode === 5 ? 'text-stone-50' : 'text-gray-900'}
                    focus:outline-none 
                    rounded-full
                    ${selectedCode === 5 ? 'bg-[#6415ff]' : 'bg-white'}
                    border border-gray-200 
                    ${selectedCode === 5 ? '' : 'hover:bg-gray-100 hover:text-[#6415ff]'}            
                    dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700
                    `}
                    onClick={e => handleOptionClick(e, 5)}
                >
                    Top Holdings
                </button>  

                <button
                    className={`
                    py-2.5 px-5 mr-2 mb-2 
                    text-sm font-medium
                    ${selectedCode === 6 ? 'text-stone-50' : 'text-gray-900'}
                    focus:outline-none 
                    rounded-full
                    ${selectedCode === 6 ? 'bg-[#6415ff]' : 'bg-white'}
                    border border-gray-200 
                    ${selectedCode === 6 ? '' : 'hover:bg-gray-100 hover:text-[#6415ff]'}            
                    dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700
                    `}
                    onClick={e => handleOptionClick(e, 6)}
                >
                    New Investors
                </button> 

                <button
                    className={`
                    py-2.5 px-5 mr-2 mb-2 
                    text-sm font-medium
                    ${selectedCode === 7 ? 'text-stone-50' : 'text-gray-900'}
                    focus:outline-none 
                    rounded-full
                    ${selectedCode === 7 ? 'bg-[#6415ff]' : 'bg-white'}
                    border border-gray-200 
                    ${selectedCode === 7 ? '' : 'hover:bg-gray-100 hover:text-[#6415ff]'}            
                    dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700
                    `}
                    onClick={e => handleOptionClick(e, 7)}
                >
                    Top Communities
                </button>  

                <button
                    className={`
                    py-2.5 px-5 mr-2 mb-2 
                    text-sm font-medium
                    ${selectedCode === 8 ? 'text-stone-50' : 'text-gray-900'}
                    focus:outline-none 
                    rounded-full
                    ${selectedCode === 8 ? 'bg-[#6415ff]' : 'bg-white'}
                    border border-gray-200 
                    ${selectedCode === 8 ? '' : 'hover:bg-gray-100 hover:text-[#6415ff]'}            
                    dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700
                    `}
                    onClick={e => handleOptionClick(e, 8)}
                >
                    Recently Added Communities
                </button>                       
                {/* <OutlineButton
                    backgroundColor={selectedCode === 0 ? '#6415ff' : ''}
                    text="Community"
                    onClick={}
                />
                <OutlineButton 
                    backgroundColor={selectedCode === 1 ? '#6415ff' : ''}
                    text="Hashtag" 
                />
                <OutlineButton
                    backgroundColor={selectedCode === 2 ? '#6415ff' : ''}
                    text="Top Holding" 
                />
                <OutlineButton
                    backgroundColor={selectedCode === 3 ? '#6415ff' : ''} 
                    text="Investmates Recommended" 
                />
                <OutlineButton
                    backgroundColor={selectedCode === 4 ? '#6415ff' : ''}
                    text="Investor" 
                /> */}
            </div>
            {/**
             * Editing section
             */}
            <div className="flex flex-row">
                <div className="flex flex-col w-4/12">
                    <div>Name of Category</div>
                    <div className="mt-2">
                        <input 
                            className="w-full rounded-full" 
                            type="text" 
                            value={exploreTitle}
                            onChange={e => setExploreTitle(e.target.value)}
                            placeholder="Name of Category" 
                        />
                    </div>
                </div>

                <div className="flex flex-col ml-4">
                    <div>Length of list</div>
                    <div className="mt-2">
                        <input 
                            className="rounded-full" 
                            type="number" 
                            value={listLength}
                            onChange={e => setListLength(Number(e.target.value))}
                            placeholder="Length of list" 
                        />
                    </div>
                </div>

                <div className="flex flex-col ml-4">
                    <div>Sequence</div>
                    <div className="mt-2">
                        <input 
                            className="rounded-full" 
                            type="number" 
                            value={sequence}
                            onChange={e => setSequence(Number(e.target.value))}
                            placeholder="Length of list" 
                        />
                    </div>
                </div>
            </div>
            <div>
                <input 
                    className="rounded-full mt-2 w-1/3"
                    type="text"
                    value={description}
                    onChange={e => setDescription(e.target.value)}
                    placeholder="Description"
                />
            </div>
            <div className="mt-2">
                <button
                    className={`
                    text-white 
                    bg-[#6415ff]
                    hover:bg-[#4300c6]
                    focus:outline-none focus:ring-4 focus:ring-blue-300 
                    font-medium 
                    rounded-full 
                    text-sm px-5 py-2.5 text-center 
                    mr-2 mb-2
                    dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800
                    `}
                    onClick={e => addExplore(e)}
                >
                    Create
                </button>
                {/* <RoundedButton 
                    text="Create"                    
                    backgroundColor="#6415ff"
                    hoverColor="#4300c6"
                /> */}
            </div>
        </div>
    );
}

export default CreateExplore;