import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { ReactComponent as FilterSVG } from '../../../../assets/filter.svg';

const QueryFilter = ({ value, onChange }: { value: boolean; onChange?: (x: boolean) => void }) => {
  return (
    <Menu as="div" className="relative inline-block mt-3 text-left">
      <div>
        <Menu.Button className="inline-flex justify-center w-full px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
          Filter
          <FilterSVG className="w-5 h-5 ml-2 -mr-1" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute left-0 z-20 w-56 mt-2 origin-top-left bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="m-1">
            <Menu.Item>
              <div className="flex p-3 form-check">
                <input className="float-left w-4 h-4 mt-1 mr-2 align-top transition duration-200 bg-white bg-center bg-no-repeat bg-contain border border-gray-300 rounded-sm appearance-none cursor-pointer form-check-input checked:bg-blue-600 checked:border-blue-600 focus:outline-none" type="checkbox" checked={value} onChange={evt => onChange && onChange(evt.target.checked)} id="flexCheckDefault" />
                <label className="w-full text-gray-800 cursor-pointer form-check-label" htmlFor="flexCheckDefault">
                  Show Deleted
                </label>
              </div>
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}

export default QueryFilter;
