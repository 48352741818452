import API from '@aws-amplify/api';
import { getEnvApi } from '../utils';

export function getUsersCountsAPI(env: number) {
  return API.post(getEnvApi(env), `/user/users/count/all`, {

  });
}

export function getNoCommunityMembersAPI(env: number) {
  return API.post(getEnvApi(env), `/user/community/not_member/all`, {

  });
}


export function getAllChartsDataAPI(env: number) {
  return API.post(getEnvApi(env), `/user/users/growth/all`, {

  })
}

export function getAllPostsChartsDataAPI(env: number) {
  return API.post(getEnvApi(env), `/user/users/posts/all`, {

  })
}

// getting the time series data for content creators
export function getContentCreatorsCountAPI(env: number) {
  return API.post(getEnvApi(env), `/user/users/content_creators/all`, {

  });
}

// getting the top engaging posts
export function getTopEngagingPostsAPI(env: number) {
  return API.post(getEnvApi(env), `/user/users/engaging_posts/all`, {

  });
}

export function getRawCohortDataAPI(env: number) {
    return API.post(getEnvApi(env), `/user/users/raw_cohort/all`, {

    });
}

export function getRawChurnDataAPI(env: number) {
    return API.post(getEnvApi(env), `/user/users/raw_churn/all`, {
        
    });
}

export function getBasicChurnDataAPI(env: number) {
    return API.post(getEnvApi(env), `/user/users/basic_churn/all`, {

    });
}

// getting the list of admin users
export function getAdminUsersAPI(env: number) {
  return API.post(getEnvApi(env), `/user/users/admin_users/all`, {

  });
}

export function searchUserAPI(env: number, searchQuery: string) {
  return API.post(getEnvApi(env), '/user/users/search_user/all', {
    body: {
      "search_query": searchQuery
    }
  });
}

export function getUserRedeemStatusAPI(env: number, userId: number) {
  return API.post(getEnvApi(env), '/user/users/redeem_status/all', {
    body: {
      "userId": userId
    }
  });
}

export function updateUserRedeemStatusAPI(
  env: number, 
  userId: number, 
  status: number,
  updatedBy: string
) {
  return API.post(getEnvApi(env), '/user/users/update_user_redeem_status/all', {
    body: {
      "userId": userId,
      "status": status,
      "updatedBy": updatedBy
    }
  });
}

export function updateSebiStatusAPI(
  env: number,
  userId: number,
  sebiStatus: number,
  sebiBadge: string
) {
  return API.post(getEnvApi(env), '/user/users/update_sebi_status/all', {
    body: {
      "userId": userId,
      "sebiStatus": sebiStatus,
      "sebiBadge": sebiBadge
    }
  })
}